import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
  FGVProduct,
  Period,
  ProductSelectionComponent,
  ProductWithPrice,
} from "./definitions";
import useCheckout from "./useCheckout";
import { keyBy, currencyFormat } from "../utils/Util";
import { GoogleAnalyticsActions } from "../../hooks/useGoogleAnalytics";
import Select from "../pages/dashboards/Select";

type State = {
  error?: string;
  loading: boolean;
  categories: { id: string; name: string; hasHistory: boolean }[];
  availableCategories?: { id: string; name: string }[];
  init: boolean;
  period: "MONTH" | "YEAR" | "ONCE";
  installments: number;
  price: any;
};

type Action =
  | { type: "LOADING_OPTIONS" }
  | { type: "LOAD_OPTIONS_ERROR"; error: string }
  | { type: "OPTIONS_LOADED"; categories: { id: string; name: string }[] }
  | { type: "ADD_CATEGORY"; categoryId: string }
  | { type: "REMOVE_CATEGORY"; categoryId: string }
  | { type: "TOGGLE_HISTORY"; categoryId: string }
  | { type: "SET_PERIOD"; period: Period }
  | { type: "SET_INSTALLMENTS"; installments: number }
  | { type: "PRODUCT_CHANGED"; product: ProductWithPrice<FGVProduct> | null };

const compareFgvProduct = (
  a: ProductWithPrice<FGVProduct> | null,
  b: ProductWithPrice<FGVProduct> | null
): boolean => {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.product.categories.length !== b.product.categories.length) return false;
  return !a.product.categories.find((aCategory) => {
    return !b.product.categories.find((bCategory) => {
      return (
        aCategory.id === bCategory.id &&
        aCategory.hasHistory === bCategory.hasHistory
      );
    });
  });
};

const initialState: State = {
  categories: [],
  loading: true,
  init: false,
  period: "MONTH",
  installments: 1,
  price: [],
};

type Reducer = (prev: State, action: Action) => State;

const reducer: Reducer = function (prev, action) {
  if (action.type === "LOADING_OPTIONS") {
    return { ...prev, loading: true, error: undefined };
  }
  if (action.type === "LOAD_OPTIONS_ERROR") {
    return { ...prev, loading: false, error: action.error };
  }
  if (action.type === "OPTIONS_LOADED") {
    return {
      ...prev,
      loading: false,
      error: undefined,
      availableCategories: action.categories,
    };
  }

  if (action.type === "ADD_CATEGORY") {
    if (prev.categories.find((c) => c.id === action.categoryId)) {
      return prev;
    }
    const newCategory = prev.availableCategories!.find(
      (c) => c.id === action.categoryId
    );
    if (!newCategory) {
      return prev;
    }
    return {
      ...prev,
      categories: [...prev.categories, { ...newCategory, hasHistory: false }],
    };
  }
  if (action.type === "REMOVE_CATEGORY") {
    return {
      ...prev,
      categories: prev.categories.filter((c) => c.id !== action.categoryId),
    };
  }
  if (action.type === "TOGGLE_HISTORY") {
    return {
      ...prev,
      categories: prev.categories.map((c) =>
        c.id !== action.categoryId ? c : { ...c, hasHistory: !c.hasHistory }
      ),
    };
  }
  if (action.type === "PRODUCT_CHANGED") {
    return {
      ...prev,
      categories: !action.product ? [] : action.product.product.categories,
      price: action.product,
      init: true,
    };
  }
  if (action.type === "SET_PERIOD") {
    return { ...prev, period: action.period, installments: 1 };
  }
  if (action.type === "SET_INSTALLMENTS") {
    return { ...prev, installments: action.installments };
  }
  return prev;
};

const calculatePrice = function (
  newState: State
): ProductWithPrice<FGVProduct> | null {
  if (!newState.categories.length) {
    return null;
  }
  const categorySize = newState.categories.length;
  let categoryValue: number;

  if (categorySize <= 1) {
    categoryValue = 1757;
  } else if (categorySize <= 4) {
    categoryValue = 1582;
  } else if (categorySize <= 10) {
    categoryValue = 1494;
  } else {
    categoryValue = 1706;
  }

  categoryValue *= categorySize;

  let subscriptionValue: number;

  if (newState.period === "MONTH") {
    subscriptionValue = categoryValue;
  } else if (newState.period === "YEAR") {
    subscriptionValue = categoryValue * 9;
  } else {
    throw new Error("Invalid period selected");
  }

  let onceValue: number;

  const historyLength = newState.categories.filter((c) => c.hasHistory).length;

  if (historyLength === 0) {
    onceValue = 0;
  } else if (historyLength === 1) {
    onceValue = 3515;
  } else if (historyLength <= 4) {
    onceValue = 3164;
  } else if (historyLength <= 10) {
    onceValue = 2988;
  } else {
    onceValue = 2812;
  }

  onceValue *= historyLength;

  const price: ProductWithPrice<FGVProduct> = {
    product: {
      installments: newState.installments,
      recurrence: newState.period,
      categories: newState.categories,
    },
    type: "fgv",
    billings: [
      {
        recurrence: newState.period,
        value: subscriptionValue,
        installments: newState.installments,
      },
    ],
  };

  if (onceValue) {
    price.billings.push({
      value: onceValue * historyLength,
      recurrence: "ONCE",
      installments: 1,
    });
  }

  return price;
};

const useDeathCounter = (size: number) => {
  const ref = useRef(size);

  return useCallback(() => {
    ref.current--;

    if (ref.current === 0) {
      throw new Error("Reached limit");
    }
  }, [ref]);
};

const FGVForm: ProductSelectionComponent<FGVProduct> = function (props) {
  const { getAvailableFgvCategories } = useCheckout();

  const { value, onChange, onNext } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [accept, setAccept] = useState(false);
  const price = calculatePrice(state);

  function handleOnNext() {
    onChange(price);
    onNext();
  }
  new GoogleAnalyticsActions("G-HTD2B4DL40");

  useEffect(() => {
    const fn = async () => {
      dispatch({ type: "LOADING_OPTIONS" });

      try {
        const categories = await getAvailableFgvCategories();
        dispatch({ type: "OPTIONS_LOADED", categories });
      } catch (e) {
        dispatch({
          type: "LOAD_OPTIONS_ERROR",
          error: "Erro ao pesquisar categorias disponíveis",
        });
      }
    };

    fn();
  }, [getAvailableFgvCategories, dispatch]);

  useEffect(
    () => dispatch({ type: "PRODUCT_CHANGED", product: props.value }),
    [dispatch, props.value]
  );
  useEffect(() => {
    if (!state.init) {
      // Acredite em mim, isso aqui é uma gambiarra que fiz pra prevenir loop infinito,
      return;
    }
    const newPrice = calculatePrice(state);
    if (!compareFgvProduct(newPrice, value)) {
      onChange(newPrice);
    }
  }, [onChange, value, state]);

  return (
    <>
      <p className="h-sumw__info-text">
        Selecione as categorias que deseja assinar:
      </p>
      {!state.availableCategories ? (
        "Carregando..."
      ) : (
        <>
          <div className="h-sumw__spacing-before">
            <select
              className="h-sumw__input"
              onChange={(e) => {
                dispatch({ type: "ADD_CATEGORY", categoryId: e.target.value });
                GoogleAnalyticsActions.event(
                  "categoria_carrinho",
                  "",
                  e.target.value
                );
              }}
            >
              <option>Selecione uma categoria...</option>
              {state.availableCategories
                .filter((c) => !state.categories.find((c2) => c.id === c2.id))
                .map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
            </select>
          </div>
          <table className="h-sumw__table h-sumw__spacing-after">
            <tbody>
              {state.categories.map((category) => (
                <tr key={category.id}>
                  <td style={{ width: "100%" }}>
                    <span>{category.name}</span>
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <label title="Adquirir histórico da categoria">
                      <input
                        type="checkbox"
                        checked={category.hasHistory}
                        onChange={() => {
                          dispatch({
                            type: "TOGGLE_HISTORY",
                            categoryId: category.id,
                          });
                          GoogleAnalyticsActions.event(
                            "com_historico",
                            "",
                            "&#8287;"
                          );
                        }}
                      />
                      Com histórico
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="infoIcon"
                        viewBox="0 0 512 512"
                      >
                        <title>
                          Histórico de 6 meses disponível para compra.
                        </title>
                        <path
                          fill="#fff"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="40"
                          d="M196 220h64v172"
                        ></path>
                        <path
                          fill="#fff"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          stroke-width="40"
                          d="M187 396h138"
                        ></path>
                        <path
                          d="M256 160a32 32 0 1132-32 32 32 0 01-32 32z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </label>
                  </td>
                  <td>
                    <button
                      className="h-sumw__button"
                      onClick={() =>
                        dispatch({
                          type: "REMOVE_CATEGORY",
                          categoryId: category.id,
                        })
                      }
                    >
                      Remover
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="h-sumw__info-text">
            Valor da assinatura para as categorias selecionadas:
          </p>
          <div
            className={
              state.period !== "YEAR"
                ? "h-sumw__grid h-sumw__spacing-before"
                : "h-sumw__grid h-sumw__spacing-before fulled"
            }
          >
            <div className="h-sumw__col-3">
              <div className="h-sumw__input-wrapper ">
                <Select
                  label="Plano"
                  required
                  options={[
                    { key: "MONTH", value: "Mensal" },
                    { key: "YEAR", value: "Anual" },
                  ]}
                  value={state.period}
                  onChange={(e) => {
                    dispatch({
                      type: "SET_PERIOD",
                      period: e as Period,
                    });
                  }}
                />
              </div>
            </div>
            <div className="h-sumw__col-3">
              <div className="h-sumw__input-wrapper ">
                {state.period !== "YEAR" ? null : (
                  <Select
                    label="Parcelas"
                    required
                    options={[
                      { key: "1", value: "1 Parcela" },
                      { key: "2", value: "2 Parcelas" },
                      { key: "3", value: "3 Parcelas" },
                    ]}
                    value={state.installments + ""}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_INSTALLMENTS",
                        installments: Number(e),
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {props.value && state ? (
            <>
              {(function () {
                const billings = keyBy(props.value!.billings, "recurrence");

                if (state.period === "MONTH") {
                  if (billings["ONCE"] && billings["MONTH"]) {
                    const firstValue =
                      billings["ONCE"].value + billings["MONTH"].value;
                    return (
                      <div className="h-sumw__spacing-before h-sumw__spacing-after">
                        <span className="hd-featured">
                          {currencyFormat(firstValue)}
                        </span>
                        &nbsp; no primeiro mês e{" "}
                        <span className="hd-featured">
                          {currencyFormat(billings["MONTH"].value)}
                        </span>{" "}
                        nos meses seguintes.
                      </div>
                    );
                  }
                  return (
                    <div className="h-sumw__spacing-before h-sumw__spacing-after">
                      <span className="hd-featured">
                        {price?.billings[1]?.value
                          ? currencyFormat(
                              (price?.billings[0]?.value +
                                price?.billings[1]?.value) /
                                price?.billings[0]?.installments
                            )
                          : price?.billings[0] &&
                            currencyFormat(
                              price?.billings[0]?.value /
                                price?.billings[0]?.installments
                            )}
                      </span>

                      {price?.billings[1]?.value ? (
                        <>
                          &nbsp;no primeiro mês e{" "}
                          <span className="hd-featured">
                            {currencyFormat(
                              price?.billings[0]?.value /
                                price?.billings[0]?.installments
                            )}
                          </span>{" "}
                          nos meses seguintes.
                        </>
                      ) : (
                        <>&nbsp;ao mês.</>
                      )}
                    </div>
                  );
                }

                if (state.period === "YEAR") {
                  if (billings["ONCE"] && billings["YEAR"]) {
                    return (
                      <div className="h-sumw__spacing-before h-sumw__spacing-after">
                        {state.installments === 1 ? null : (
                          <span style={{ paddingLeft: "8px" }}>
                            {state.installments}x sem juros de
                          </span>
                        )}
                        <span className="hd-featured">
                          {price &&
                            currencyFormat(
                              (price?.billings[0]?.value +
                                price?.billings[1]?.value) /
                                price?.billings[0]?.installments
                            )}
                        </span>{" "}
                        no primeiro ano e{" "}
                        {state.installments === 1 ? null : (
                          <span style={{ marginLeft: "0" }}>
                            &nbsp;{state.installments}x sem juros de
                          </span>
                        )}
                        <span className="hd-featured">
                          {price?.billings[0]?.installments &&
                            currencyFormat(
                              billings["YEAR"].value /
                                price?.billings[0]?.installments
                            )}
                        </span>{" "}
                        nos anos seguintes.
                      </div>
                    );
                  }
                  return (
                    <div className="h-sumw__spacing-before h-sumw__spacing-after">
                      {state.installments === 1 ? null : (
                        <span style={{ paddingLeft: "8px" }}>
                          {state.installments}x sem juros de
                        </span>
                      )}
                      <span className="hd-featured">
                        {price?.billings[1]?.value
                          ? currencyFormat(
                              (price?.billings[0]?.value +
                                price?.billings[1]?.value) /
                                price?.billings[0]?.installments
                            )
                          : price?.billings[0] &&
                            currencyFormat(
                              price?.billings[0]?.value /
                                price?.billings[0]?.installments
                            )}
                      </span>

                      {price?.billings[1]?.value ? (
                        <>
                          no primeiro ano e{" "}
                          {state.installments === 1 ? null : (
                            <span style={{ paddingLeft: "0px" }}>
                              &nbsp;{state.installments}x sem juros de
                            </span>
                          )}
                          <span className="hd-featured">
                            {currencyFormat(
                              price?.billings[0]?.value /
                                price?.billings[0]?.installments
                            )}
                          </span>{" "}
                          nos anos seguintes.
                        </>
                      ) : (
                        <> ao ano.</>
                      )}
                    </div>
                  );
                }
                throw new Error("");
              })()}
            </>
          ) : (
            <p className="h-sumw__warn-box">
              Selecione ao menos uma categoria.
            </p>
          )}
          <div className=" h-sumw__spacing-after h-sumw_renewal">
            Renovação {state.period !== "YEAR" ? "mensal" : "anual"  }  automática
          </div>

          <div className="umw_next">
            <button
              className="h-sumw__button"
              disabled={!props.value || !accept}
              onClick={() => {
                GoogleAnalyticsActions.event("prox_passo_pag1", "", "&#8287;");
                handleOnNext();
              }}
            >
              Próximo passo
            </button>

            <div className="terms-accept">
              <input
                type="checkbox"
                name="accept"
                id="accept"
                checked={accept}
                onChange={({ target }) => setAccept(target.checked)}
              />
              Li e concordo com os{" "}
              <a
                href="https://cestaconsumo.ehorus.com.br/termos-de-uso"
                target="blank"
                title="Termos de Uso"
              >
                {" "}
                Termos de Uso
              </a>{" "}
              e{" "}
              <a
                href="https://cestaconsumo.ehorus.com.br/politica-de-privacidade"
                target="blank"
                title="Política de Privacidade"
              >
                Política de Privacidade
              </a>
            </div>
          </div>
          <div className="h-sumw__history-information">
            *Histórico de 6 meses disponível para compra.
          </div>
        </>
      )}
    </>
  );
};

export default FGVForm;
