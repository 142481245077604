import styled, { css } from "styled-components";
interface IToggleProps {
  active: boolean;
}

export const ToggleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleContainer = styled.div<IToggleProps>`
  border: 1px solid #d3d3d3;
  border-radius: 100px;
  max-width: 30px;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 15px;
  width: 100%;
  height: 100%;
  max-height: 15px;
  transition: all 0.2s ease;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 100px;
    left: -1px;
    width: 30px;
    transition: all 0.2s ease;
    height: 15px;
    background-color: #1cc8ff;
    transform: translateX(-15px);
  }

  ${(props) =>
    props.active &&
    css`
      border: 1px solid white;
      &::before {
        transform: translateX(0);
      }
    `}
`;

export const ToggleSlider = styled.div<IToggleProps>`
  width: 15px;
  height: 15px;
  border-radius: 1000px;
  position: absolute;
  transition: all 0.2s ease;
  background-color: #d3d3d3;

  ${(props) =>
    props.active &&
    css`
      transform: translateX(15px);
      background-color: white;
    `}
`;
