import { useCallback, useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { FgvUser } from "./definitions";
import FgvContext from "./FgvContext";

function useFgv() {
  const { fgvUser, setFgvUser, currentDashboard, setCurrentDashboard } = useContext(FgvContext);
  const fetch = useFetch();

  const register = useCallback(async function (data: FgvUser) {
    const { data: body } = await fetch({
      data, method: "POST", url: "/api/v1/fgv/register", options: { auth: false }
    });

    const user = {
      ...data, registerToken: body.registerToken
    };

    setCurrentDashboard("PLUS");
    setFgvUser(user);
  }, [ fetch, setFgvUser, setCurrentDashboard ]);

  return { fgvUser, register, currentDashboard, setCurrentDashboard };
}


export default useFgv;