export const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    fill="none"
    viewBox="0 0 17 16"
  >
    <path
      fill="#fff"
      d="M4.316 15.929H.95V5.335h3.366zM2.633 3.85c-1.089 0-1.88-.792-1.88-1.88A1.88 1.88 0 0 1 2.632.087c1.09 0 1.881.792 1.881 1.881 0 1.09-.792 1.881-1.88 1.881m13.96 12.079h-3.367v-5.742c0-1.683-.693-2.178-1.683-2.178s-1.98.792-1.98 2.277v5.643H6.197V5.335h3.168v1.486c.297-.693 1.485-1.783 3.168-1.783 1.882 0 3.862 1.09 3.862 4.357v6.534z"
    ></path>
  </svg>
);
