import { FunctionComponent } from "react";
import styled, { CSSProperties } from "styled-components";
import { mainColor, secondColor } from "../../../constants";
import HeaderPage from "../../home/HeaderPage";
import SelectComponent from "../../ui/SelectComponent";
import GraphicBar from "../../ui/GraphicBar";

const Content = styled.div`
	width: 96%;
	margin: 0 auto;
	max-width: 96%;
	margin-top: -680px;
	position: relative;
	display: flex;
	flex-flow: wrap;
  justify-content: space-between;
`;

const ContentCard = styled.div`
  background-color: ${ secondColor };
  height: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0.5% 0.5%;
  padding-bottom: 1%;
`;

const Title = styled.div`
	margin: 1% 0;
	position: relative;
	display: inline-block;
	width: 100%;
	text-align: center;
  font-size: 25px;
`;

const CardBody = styled.div`
  width: 98%;
  height: calc(100% - 70px);
  background-color: white;
  margin: 0 auto 1% auto;
  position: relative;
  display: flow-root;
`;

/*Table SKUs style*/
const Table = styled.table`
  width: 94%;
  margin: 2% 0;
  border-collapse: collapse;
`;
const HeaderTable = styled.thead`
  font-weight: bold;
  text-align: left;
  color: ${ mainColor }
`;
const BodyTable = styled.tbody`
  margin-top: 2%;
  border-top: 2px solid deepskyblue;
`;
const Line = styled.tr`

:nth-child(even) {
  background: lightgray;
}

:nth-child(odd) {
  background: white;
}

`;
const Column = styled.td`

  height: 25px;
  text-align: right;

  :nth-child(1) {
    text-align: left;
  }
  :first-child {
    border-right: 2px solid deepskyblue;
  }
`;
const ContainerTable = styled.div`

  display: flex;
  place-content: center;
  margin: 1% 10%;
`;

/*Perform Market Styles */
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  color: ${ mainColor };
  font-weight: bold;
`;

const IndicatorData = styled.p``;

const PerformMarket = styled.div`
  margin: 1% 4%;
`;

const ContentPerformMarket = styled.div`
  width: 25%;
  text-align: center;
  margin: 15px 10px;
`;

const Legend = styled.div`
  
`;


const Card: FunctionComponent<{titleCard?:string, columnSize?:string, style?:Object}> = (props) => {

	const { titleCard, columnSize, children } = props;

	return (
		<>
			<ContentCard style={{flexBasis: columnSize ? columnSize+"%" : '100%'}}>
				<Title>{ titleCard }</Title>
				<CardBody>
					{ children }
				</CardBody>
			</ContentCard>
		</>
	)
}

const BasicDashboard: FunctionComponent = () => {

  let styleContentParticipantMarket = {
    width: '100%',
    height: '270px',    
    margin: '0 0'
  }

  return (
		<>
			<HeaderPage>
				<Content>
					<Card titleCard={"Performance de Mercado"} columnSize={'65'}>
            <ParticipationMarket />
					</Card>
					<Card titleCard={"Participação de Mercado"} columnSize={'33'}>
          <PerformMarket>
            <ContentPerformMarket id='content' style={styleContentParticipantMarket} >
              <Label >Top 3 - Fabricantes</Label>
              <p></p>
              <GraphicBar
              label= 'Camil Alimentos'
              value='28.2'/>
              <p></p>
              <GraphicBar 
              label= 'Joaquim Alimentos'
              value='12.0'/>
              <p></p>
              <GraphicBar 
              label= 'Solito Alimentos'
              value='9.5'/>
              <Row>
                <Legend>Teste</Legend>
                <Legend>Teste</Legend>
                <Legend>Teste</Legend>
                <Legend>Teste</Legend>
              </Row>
            </ContentPerformMarket>
            <ContentPerformMarket id='content' style={{backgroundColor: 'black', width: '1px'}} >
              <Label ></Label>
            </ContentPerformMarket>
            <ContentPerformMarket id='content' style={styleContentParticipantMarket} >
              <Label >Top 3 - Marcas</Label>
              <p></p>
              <GraphicBar 
              label= 'Camil'
              value='90.2'/>
              <p></p>
              <GraphicBar 
              label= 'Tio João'
              value='50.4'/>
              <p></p>
              <GraphicBar 
              label= 'Solito'
              value='9.5'/>
            </ContentPerformMarket>
          </PerformMarket>
					</Card>
					<Card titleCard={"Preço Médio (R$) - Top 10 skus"} >
						<TableSKUs />
					</Card>
          <Card titleCard={"Glossário"} >
						<p>PA: Período Anterior</p>
            <p>PY: Mesmo Período do ano passado</p>
					</Card>
				</Content>
			</HeaderPage>	
		</>
	)
}

const ParticipationMarket: FunctionComponent = () => {

  let style1: CSSProperties = {
    color: 'gray'
  }

  let style2: CSSProperties = {
    color: mainColor,
    fontWeight: 'bold',
    fontSize: '35px',
    margin: '10% 0px'
  }

  let style3: CSSProperties = {
    backgroundColor: 'black', 
    width: '1px',
    margin: '0px 0px 40px 0'
  }

  return (
    <>
     <PerformMarket>
        <Row style={{ margin: '10% 0' }}>
          <ContentPerformMarket >
            <Label>UF</Label>
            <IndicatorData>
              <SelectComponent data={[{key: 1, value: "SP", text: "SP"}]}/>
            </IndicatorData>
          </ContentPerformMarket>
          <ContentPerformMarket >
            <Label>CATEGORIA</Label>
            <IndicatorData>
              <SelectComponent data={[{key: 2, value: "88", text: "Arroz"}]}/>
            </IndicatorData>
          </ContentPerformMarket>
          <ContentPerformMarket >
            <Label>PERIODO</Label>
            <IndicatorData>
              <SelectComponent data={[{key: 3, value: "2101", text: "Janeiro/2021"}]}/>
            </IndicatorData>
          </ContentPerformMarket>
        </Row>
        <Row style={{ margin: '10% 0' }}>
          <ContentPerformMarket >
            <Label style={style1} >Vendas da Categoria (R$)</Label>
            <IndicatorData style={style2} >523,46 MI</IndicatorData>
          </ContentPerformMarket>
          <ContentPerformMarket id='content'  style={style3} >
            <Label></Label>
          </ContentPerformMarket>
          <ContentPerformMarket >
            <Label style={style1} >Variação - PA (%)</Label>
            <IndicatorData style={style2} >-72,1</IndicatorData>
          </ContentPerformMarket>
          <ContentPerformMarket id='content' style={style3} >
            <Label></Label>
          </ContentPerformMarket>
          <ContentPerformMarket >
            <Label style={style1} >Variação - PY (%)</Label>
            <IndicatorData style={style2} >42,5</IndicatorData>
          </ContentPerformMarket>
        </Row>        
      </PerformMarket>
    </>
  )
}

const TableSKUs : FunctionComponent = () => {  

  return (
    <>
      <ContainerTable>
        <Table>
          <HeaderTable>
            <Line>
              <Column style={{ width: '40%' }}>Descrição Produto</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
            </Line>
          </HeaderTable>
          <BodyTable>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
          </BodyTable>
        </Table>   
        {/*<LegendTable>* Top 10 skus</LegendTable>*/}
      </ContainerTable>
    </>
  )
}

export default BasicDashboard;