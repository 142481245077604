import { FunctionComponent } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker/dist/entry.nostyle';

export interface Props extends DatePickerProps {
  label: string
}

const DateInput: FunctionComponent<Props> = props => {
  const { label, ...datePickerProps } = props;
  return (
    <div className={ `hdmsps-filter ${ datePickerProps.disabled ? 'hdmsps-filter--disabled' : '' }` }>
      <div className="hdmsps-filter-label">
        { label }
      </div>
      <DatePicker { ...datePickerProps } format="dd/MM/yyyy" />
    </div>
  )
}

export default DateInput;