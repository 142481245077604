import { InputHTMLAttributes } from "react";
import { ToggleContainer, ToggleSlider, ToggleWrapper } from "./styles";

interface IToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  toggled: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}

function Toggle({ toggled, onToggle, children, ...props }: IToggleProps) {
  return (
    <ToggleWrapper onClick={() => onToggle()}>
      {children}
      <ToggleContainer active={toggled}>
        <ToggleSlider active={toggled} />
      </ToggleContainer>
      <input type="checkbox" style={{ display: "none" }} {...props} />
    </ToggleWrapper>
  );
}

export default Toggle;
