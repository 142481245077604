import { GoogleAnalyticsActions } from "../../hooks/useGoogleAnalytics";
import { authCredentials } from "./authCredentials";
import { nullAuthUserCredentials } from "./authCredentials/getCredentials";

const userAuth = authCredentials.get.authUser() ?? nullAuthUserCredentials;
const userCompany = () =>
  userAuth?.company + "/" + userAuth?.user?.split("@")[0]?.toLowerCase();

type EventType = {
  action: string;
  label: string;
  user?: string;
};

const eventType = ({ action, label }: EventType) => {
  return {
    action: action,
    label: label,
    user: userCompany().toString(),
  };
};

new GoogleAnalyticsActions("G-SX5Z63SCPY");

export const normalizeDataSelect = (description: any) => {
  let selected = description.dataPoints[0].identity[0].equals
    .replaceAll(" ", "_")
    .toLowerCase();
  let event = eventType({
    action: `click_dash_${selected}`,
    label: "acesso_categoria",
  });

  GoogleAnalyticsActions.event(event.action, event.user, event.label);
};

export const normalize = (description: string) => {
  if (
    description.toLowerCase().match("horus") &&
    description.toLowerCase().match("promo")
  ) {
    let event = eventType({
      action: "click_dash_horus_promo",
      label: "acesso_horus_promo",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (
    description.toLowerCase().match("abas") &&
    description.toLowerCase().match("customizadas")
  ) {
    let event = eventType({
      action: "click_dash_horus_customizado",
      label: "acesso_horus_customizadoa",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (
    description.match("Retail Metrics") &&
    description.match("Incidencia") &&
    description.match("Presença")
  ) {
    let event = eventType({
      action: "click_dash_retail_metrics_incidência_presença",
      label: "acesso_retail_metrics_share_incidência_presença",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (
    description.match("Retail Metrics") &&
    (description.toLowerCase().match("preço") ||
      description.toLowerCase().match("preços"))
  ) {
    let event = eventType({
      action: "click_dash_retail_metrics_preço",
      label: "acesso_retail_metrics_preço",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (
    description.match("Retail Metrics") &&
    description.match("Incidencia") &&
    description.match("Presença")
  ) {
    let event = eventType({
      action: "click_dash_retail_metrics_incidência_presença",
      label: "acesso_retail_metrics_share_incidência_presença",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (
    description.match("Retail Metrics") &&
    (description.match("Share") || description.match("Shares"))
  ) {
    let event = eventType({
      action: "click_dash_retail_metrics_share",
      label: "acesso_retail_metrics_share",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Retail Metrics Essential")) {
    let event = eventType({
      action: "click_dash_retail_metrics_essential",
      label: "acesso_retail_metrics_essential",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Retail Metrics Professional")) {
    let event = eventType({
      action: "click_dash_retail_metrics_professional",
      label: "acesso_retail_metrics_professional",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Retail Metrics Alpha")) {
    let event = eventType({
      action: "click_dash_retail_metrics_alpha",
      label: "acesso_retail_metrics_alpha",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Retail Metrics Associação")) {
    let event = eventType({
      action: "click_dash_retail_metrics_associao",
      label: "acesso_retail_metrics_associacao",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Retail Metrics")) {
    let event = eventType({
      action: "click_dash_retail_metrics",
      label: "acesso_retail_metrics",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Horus Price")) {
    let event = eventType({
      action: "click_dash_horus_price",
      label: "acesso_horus_price",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }

  if (description.match("Horus & FGV IBRE - Premium")) {
    let event = eventType({
      action: "click_dash_fgv_premium",
      label: "acesso_fgv_premium",
    });

    GoogleAnalyticsActions.event(event.action, event.user, event.label);
  }
};
