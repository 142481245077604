import { FunctionComponent, useEffect, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingFrame from '../ui/LoadingFrame';

const UsesAuthRoute : FunctionComponent<RouteProps> = props => {
  const { children } = props
  const { user, restore } = useAuth();
  const [ restoring, setRestoring ] = useState(true);

  console.log("restoring: ", restoring);

  useEffect(() => {
    if (user) {
      setRestoring(false);
      return;
    }

    const fn = async () => {
      try {
        await restore();
        setRestoring(false);
      } catch (e) {
        setRestoring(false);
      }
    }

    fn();
  }, [ user, restore, setRestoring ])

  return (
    <Route { ...props } children={ !restoring ? children : <LoadingFrame /> }/>
  )
}

export default UsesAuthRoute