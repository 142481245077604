import { FunctionComponent, useContext, useEffect, useState } from "react";
import EmbeddedDashboard from "./EmbeddedDashboard";
import FgvContext from "./FgvContext";
import FgvPage from "./FgvPage";

const FgvPublicDashboard: FunctionComponent = function () {
  const { setDashboardLoaded } = useContext(FgvContext);
  const [dashboardId, setDashboardId] = useState(
    "111E140_crs_brands_retail_metrics_professional"
  );

  function onDashboardLoaded(e: { link: string }) {
    setDashboardLoaded(true);
  }
  useEffect(() => () => setDashboardLoaded(false), [setDashboardLoaded]);
  useEffect(() => {
    if (window.location.hash === "#plus") {
      setDashboardId("70E104_horus_radar");
    }
    if (window.location.hash === "#ferramenta") {
      setDashboardId("111E140_crs_brands_retail_metrics_professional");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);
  return (
    <FgvPage fullPage>
      <EmbeddedDashboard
        className="hd-fgb__body"
        link={dashboardId}
        onLoadDashboard={onDashboardLoaded}
      />
    </FgvPage>
  );
};

export default FgvPublicDashboard;
