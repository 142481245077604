import { createContext, Dispatch, SetStateAction } from "react";
import { FgvUser } from "./definitions";

export type DashboardType = "BASIC" | "PLUS";

export interface FgvContextProps {
  setFgvUser: Dispatch<SetStateAction<FgvUser | null>>;
  fgvUser: FgvUser | null;
  setVisits: Dispatch<SetStateAction<number>>;
  visits: number;
  setDashboardLoaded: Dispatch<SetStateAction<boolean>>;
  dashboardLoaded: boolean;
  currentDashboard: DashboardType;
  setCurrentDashboard: Dispatch<SetStateAction<DashboardType>>;
}

export default createContext<FgvContextProps>(undefined as any);