import { FunctionComponent } from "react"

const CookiePolicy: FunctionComponent = function () {
  return (
    <>
      <h2>
          POLÍTICA DE COOKIES
      </h2>
      <h3>
          DEFINIÇÃO DE COOKIE
      </h3>
      <p>
          Cookies são pequenos arquivos de texto que guardam determinados dados sobre o usuário ao acessar sites ou serviços na internet. São pequenos arquivos baixados no seu computador, para melhorar sua experiência. 
      </p>
      <p>
          Esta política descreve quais informações eles coletam, como as usamos e por que, às vezes, precisamos armazenar esses cookies. Também compartilharemos como você pode impedir que esses cookies sejam armazenados. No entanto, isso pode fazer alterar certos elementos da funcionalidade do site.
      </p>
      <h2>
          FINALIDADE E COLETA
      </h2>
      <p>
          Os cookies são utilizados para garantir o bom funcionamento de sites e demais serviços online, assim como para fornecer informações sobre o endereço IP, o tipo de navegador, o sistema operacional, as páginas visitadas, a duração da visita, entre outras.
      </p>
      <p>
          Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site. É recomendável que você aceite todos os cookies se não tiver certeza se serão ou não necessários para fornecer um serviço que você usa.
      </p>
      <p>
          Ao acessar o conteúdo da PLATAFORMA, poderão ser coletados cookies pelo navegador. Nós utilizamos cookies para melhorar o uso e a funcionalidade de nosso site e dos serviços disponíveis na PLATAFORMA. Os cookies também nos fornecem informações que nos ajudam a entender melhor como os usuários utilizam esses serviços.
      </p>
      <p>
          Portanto, o uso de cookies no site e serviços online tem as seguintes finalidades: tratamento de problemas técnicos; manutenção da proteção e da segurança; entendimento de como são utilizados o site e seus serviços correspondentes; adequação do conteúdo conforme os dados de utilização.
      </p>
      <p>
          Para atingir essas finalidades, a HORUS usa os seguintes tipos de cookies: 
      </p>
      <p className="no-gap"><strong>Cookies de sessão</strong>, que são cookies de uso temporário, que são excluídos logo que o usuário fecha seu navegador; </p>
      <p className="no-gap"><strong>Cookies persistentes</strong>, que são aqueles cookies que permanecem no navegador do usuário até que sejam deletados manualmente ou até o navegador deletá-los, de acordo com o período de duração estabelecido pelos cookies; e</p>
      <p className="no-gap"><strong>Cookies necessários</strong>, aqueles estritamente necessários para a operação de um site ou serviço de internet. Eles permitem que o usuário navegue pelo site e use todos os recursos necessários para o atendimento da operação.</p>
      <p>
          Dentro desses tipos, temos os cookies definidos na PLATAFORMA: 
      </p>
      <ul>
          <li>
              Cookies relacionados à conta
          </li>
      </ul>
      <p>
          Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de cadastro e assinatura e administração geral. Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.
      </p>
      <ul>
          <li>
              Cookies relacionados ao login
          </li>
      </ul>
      <p>
          Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.
      </p>
      <ul>
          <li>
              Pedidos processando cookies relacionados
          </li>
      </ul>
      <p>
          Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.
      </p>
      <ul>
          <li>
              Cookies relacionados a formulários
          </li>
      </ul>
      <p>
          Quando você envia dados por meio de um formulário, como os encontrados nas páginas de contato, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.
      </p>
      <ul>
          <li>
              Cookies de preferências da Plataforma
          </li>
      </ul>
      <p>
          Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.
      </p>
      <ul>
          <li>Cookies de Terceiros</li>
      </ul>
      <p>
          Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. 
      </p>
      <h2>
          DESATIVAÇÃO
      </h2>
      <p>
          A maioria dos navegadores permite que o usuário estabeleça regras para avisá-lo antes de aceitar cookies ou simplesmente recusá-los. Entretanto, se o usuário recusar o uso de cookies, nem todos os recursos de navegação no site e/ou nos serviços relacionados poderão ser acessados.
      </p>
      <p>
          Na Plataforma da HORUS você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.
      </p>
    </>
  )
}

export default CookiePolicy