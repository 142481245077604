import { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react'
import useRequest from '../../../../hooks/useRequest';
import DashboardWrapper from '../../../ui/DashboardWrapper';
import { baseUrl } from '../../../../constants';
import { PriceData, Params, Filters, Bucket } from './definitions';
import { useModal } from '../../../utils/Modal';
import LoadingFrame from '../../../ui/LoadingFrame';
import DateInput from '../DateInput';
import moment from 'moment';
import MultiSelect, { Option as MultiSelectOption } from '../MultiSelect';
import { optional, repeat, overrideNulls, liquidCategories } from '../../../utils/Util';
import Select from '../Select';

const loadingOptions = [ { value: "Carregando...", disabled: true } ];

interface BucketWithBounds extends Bucket {
  upper: number,
  lower: number
}

const HistogramBar: FunctionComponent<{ first?: boolean, max: number, bucket: BucketWithBounds }> = props => {
  const { first = false, bucket, max } = props;
  const container = useRef<HTMLDivElement>(null);
  const bar = useRef<HTMLDivElement>(null);
  const value = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var maxHeight = container.current?.offsetHeight || 0;
    var height = (bar.current?.offsetHeight || 0) + (value.current?.offsetHeight || 0);
    if (height > maxHeight - 5) {
      value.current?.classList.add("__inset");
    } else {
      value.current?.classList.remove("__inset");
    }
  })

  return (
    <div className="__x-axis-bar-wrapper" ref={ container }>
      <div className="__x-axis-bar" style={{ height: `${ 100 * (bucket.val / max) }%` }} ref={ bar }>
        <div className="__x-axis-bar-val" ref={ value }>
          { bucket.val.toFixed(2).replace(".", ",") }
        </div>
      </div>
      {
        !first ? null : (
          <div className="__x-axis-bar-bound __x-axis-bar-lower">
            { bucket.lower.toFixed(2).replace(".", ",") }
          </div>
        )
      }
      <div className="__x-axis-bar-bound __x-axis-bar-val-upper">
        { bucket.upper.toFixed(2).replace(".", ",") }
      </div>
    </div>
  )
}

const Histogram: FunctionComponent<{ 
  buckets: Bucket[], 
  max: number, 
  min: number,
  xAxisName: string,
  yAxisName: string
}> = props => {
  const { buckets, max, min, xAxisName, yAxisName } = props;

  const bucketsIndexed: Record<number, Bucket> = {};
  buckets.forEach(bucket => bucketsIndexed[bucket.bucket] = bucket);

  let maxShare = 0;
  const allBuckets: BucketWithBounds[] = [];
  for (let i = 0; i < 12; i++) {
    const bucket = {
      ...(bucketsIndexed[i] || { bucket: i, val: 0 }),

      lower: min + ((max - min) / 12) * i,
      upper: min + ((max - min) / 12) * (i + 1)
    };
    maxShare = bucket.val > maxShare ? bucket.val : maxShare;
    allBuckets.push(bucket);
  }

  const yAxisSize = maxShare / Math.max(maxShare / 6, 0.1);

  return (
    <div className="hd-histogram">
      <div className="__y-axis-title">
        { yAxisName }
      </div>
      <div className="__x-axis-title">
        { xAxisName }
      </div>
      <div className="__y-axis">
        {
          repeat(yAxisSize + 1, i => (
            <div className="__y-axis-indicator" style={{ bottom: `${ (100 / yAxisSize) * i }%` }}>
              { ((maxShare / yAxisSize) * i).toFixed(2).replace(".", ",") }
            </div>
          )).reverse()
        }
      </div>
      <div className="__x-axis">
        {
          repeat(yAxisSize + 1, i => (
            <div className="__y-axis-indicator-line" style={{ bottom: `${ (100 / yAxisSize) * i }%` }} />
          )).reverse()
        }
        {
          allBuckets.map((bucket, index) => (
            <HistogramBar max={ maxShare } bucket={ bucket } first={ index === 0 } />
          ))
        }
      </div>
    </div>
  ) 
}

type FilterType = "uf" | "city" | "channel" | "franchise" | 
  "category" | "segment" | "manufacturer" | "brand" | "ean" |
  "sku" | "qtd";

const formatNumber = (n: number | null) => {
  return n === null ? null : n.toFixed(2).replace(".", ",");
}

const formatQtd = (n: number | null) => {
  return n === null ? null : n.toFixed(3).replace(".", ",");
}

const adjustFilters = (filters: Partial<Params>): Record<string, any> => {
  const params: Record<string, any> = {};

  if (filters.ufs) params.ufs = filters.ufs.join(",");
  if (filters.cities) params.cities = filters.cities.join(",");
  if (filters.channelIds) params.channelIds = filters.channelIds.join(",");
  if (filters.brandIds) params.brandIds = filters.brandIds.join(",");
  if (filters.categoryId) params.categoryId = filters.categoryId;
  if (filters.segmentIds) params.segmentIds = filters.segmentIds.join(",");
  if (filters.manufacturerIds) params.manufacturerIds = filters.manufacturerIds.join(",");
  if (filters.franchiseIds) params.franchiseIds = filters.franchiseIds.join(",");
  if (filters.skus) params.skus = filters.skus.join(",");
  if (filters.eans) params.eans = filters.eans.join(",");
  if (filters.start) params.start = filters.start;
  if (filters.end) params.end = filters.end;

  return params;
}

const mergeFilters = (oldValue: Filters | undefined, newValue: Filters, params: Partial<Params>): Filters => {
  if (!oldValue) {
    return newValue;
  }

  const result: Filters = { ...newValue };

  if (oldValue.ufs) result.ufs = oldValue.ufs.filter(entry => params.ufs?.includes(entry.key));
  if (oldValue.cities) result.cities = oldValue.cities.filter(city => params.cities?.includes(city));
  if (oldValue.channels) result.channels = oldValue.channels.filter(entry => params.channelIds?.includes(entry.key));
  if (oldValue.brands) result.brands = oldValue.brands.filter(entry => params.brandIds?.includes(entry.key));
  if (oldValue.segments) result.segments = oldValue.segments.filter(entry => params.segmentIds?.includes(entry.key));
  if (oldValue.manufactures) result.manufactures = oldValue.manufactures.filter(entry => params.manufacturerIds?.includes(entry.key));
  if (oldValue.franchises) result.franchises = oldValue.franchises.filter(entry => params.franchiseIds?.includes(entry.key));
  if (oldValue.eans) result.eans = oldValue.eans.filter(ean => params.eans?.includes(ean));
  if (oldValue.skus) result.skus = oldValue.skus.filter(sku => params.skus?.includes(sku));

  return result;
}

const makeOptions = (isLoaded: boolean, options: MultiSelectOption[] | null | undefined): MultiSelectOption[] => {
  if (isLoaded) {
    return options || [];
  }
  if (!options) {
    return loadingOptions;
  }
  return [ ...options, ...loadingOptions ];
}

const getErrorMessage = (e: any) => {
  if (e.response) {
    const { response: { data: { error = null } = {} } = {} } = e;

    if (error === 'NO_DATA') {
      return "Os dados para seu dashboard ainda estão sendo gerados, tente novamente mais tarde";
    }
  }

  return "Ocorreu um erro ao acessar o dashboard";
}

export interface State {
  data?: PriceData;
  loading: boolean;
  params: Partial<Params>;
  filtersOpen: boolean;
  filtersLoaded: FilterType[];
  pageLoading: boolean;
  page: number;
  pageLoadHadError: boolean;
  updatingFilters: boolean;
}

const initialState: State = { 
  loading: true,
  params: {},
  filtersOpen: false,
  filtersLoaded: [],
  pageLoading: false,
  page: 0,
  pageLoadHadError: false,
  updatingFilters: false
};

const PriceDashboard: FunctionComponent = () => {
  const ref = useRef<{ lastSync?: any, preventScrollListener: boolean }>({
    preventScrollListener: false
  });
  const tableHeader = useRef<HTMLTableElement>(null);
  const tableBody = useRef<HTMLTableElement>(null);
  const responsiveDiv = useRef<HTMLDivElement>(null);
  
  const [ { data, loading, params, filtersLoaded, ...state }, setState ] = useState<State>(initialState);
  const { openModal } = useModal();

  const request = useRequest<PriceData>("GET", baseUrl + "/api/v1/dashboard/price");
  const filterRequest = useRequest<Filters>("GET", baseUrl + "/api/v1/dashboard/price/filters/{filter}");

  const fetchData = useCallback(async (filters: Partial<Params> = {}) => {
    setState(prev => ({ ...prev, loading: true }));
    try {
      const params = adjustFilters(filters);
      const { data } = await request({ params });

      setState(prev => ({ 
        ...prev, 
        
        data: { ...data, filters: mergeFilters(prev.data?.filters, data.filters, data.params) }, 
        params: data.params, 
        loading: false, 
        filtersLoaded: [],
        page: 0,
        pageLoadHadError: false
      
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [ request, openModal, setState, ref ]);

  const fetchPage = async (page: number, filters: Partial<Params> = {}) => {
    const params = adjustFilters(filters);
    params.page = page;
    const { data } = await request({ params });
    return data;
  }

  const fetchFilter = async (filter: FilterType) => {
    if (filtersLoaded.includes(filter)) {
      return;
    }

    setState(prev => ({ ...prev, updatingFilters: true }));

    const adjustedParams = adjustFilters(params);
    const { data: filters } = await filterRequest({ pathParams: { filter }, params: adjustedParams });
    
    setState(prev => ({ 
      ...prev, 

      updatingFilters: false,
      
      filtersLoaded: [ ...prev.filtersLoaded, filter ],

      data: !prev.data ? undefined : {
        ...prev.data, filters: overrideNulls(prev.data.filters, filters)
      }
    }));
  }

  const onFiltersChange = (params: Partial<Params> = {}) => {
    setState(prev => ({ ...prev, params }));
    fetchData(params);
  }

  useEffect(() => { fetchData() }, [ fetchData ]);

  const syncTableWidths = () => {
    const currentTableHeader = tableHeader.current;
    const currentTableBody = tableBody.current;

    ref.current.lastSync = setTimeout(() => {
      if (!currentTableHeader || !currentTableBody) {
        syncTableWidths();
        return;
      };

      const tds = currentTableBody.querySelectorAll("tr:first-child td");
      const ths = currentTableHeader.querySelectorAll("tr:first-child th");

      for (let i = 0; i < Math.min(tds.length, ths.length); i++) {
        const td = tds[i] as HTMLElement;
        const th = ths[i] as HTMLElement;

        if (td.offsetWidth !== th.offsetWidth) {
          th.style.width = td.offsetWidth + "px";
        }
      }

      syncTableWidths();
    }, 500);
  };

  const handleTableScroll = () => {
    const div = responsiveDiv.current;

    if (
      ref.current.preventScrollListener || !div ||
      div.scrollTop < div.scrollHeight - (div.clientHeight * 6)
    ) return;

    ref.current.preventScrollListener = true;
    setState(prev => ({ ...prev, pageLoading: true }));

    fetchPage(state.page + 1, params)
      .then(data => {
        setState(prev => ({ 
          ...prev, 

          pageLoading: false,
          data: !prev.data ? data : { 
            ...prev.data, 
            table: [ ...prev.data.table, ...data.table ],
            hasMore: data.hasMore
          },

          page: prev.page + 1
        }));
        ref.current.preventScrollListener = false;
      })
      .catch(err => {
        setState(prev => ({ ...prev, pageLoadHadError: true }))
      })
  }

  useEffect(() => {
    syncTableWidths();
    return () => { if (ref.current.lastSync) clearTimeout(ref.current.lastSync) }
  });

  if (!data) {
    return loading ? <LoadingFrame /> : null;
  }

  return (
    <DashboardWrapper title="Preço por Loja">
      {
        loading ? <LoadingFrame /> : null
      }
      <div className="hdppl-body">
        <div className="hdppl-filters">
          <MultiSelect
            label="Estados"
            value={ params.ufs || [] }
            onChange={ ufs => onFiltersChange({ ...params, ufs }) }
            options={ makeOptions(filtersLoaded.includes("uf"), data.filters.ufs) }
            onSelectOpen={ () => fetchFilter("uf") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Cidades"
            value={ params.cities || [] }
            onChange={ cities => onFiltersChange({ ...params, cities }) }
            options={ 
              optional(data.filters.cities)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(list => list.map(value => ({ key: value, value })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("city"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("city") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Canais"
            value={ optional(params.channelIds || []).map(s => s.map(i => String(i))).getOrDefault([]) }
            onChange={ channelIds => onFiltersChange({ ...params, channelIds: channelIds.map(i => parseInt(i)) }) }
            options={  
              optional(data.filters.channels)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("channel"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("channel") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Redes"
            value={ optional(params.franchiseIds || []).map(s => s.map(i => String(i))).getOrDefault([]) }
            onChange={ franchiseIds => onFiltersChange({ ...params, franchiseIds: franchiseIds.map(i => parseInt(i)) }) }
            options={ 
              optional(data.filters.franchises)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("franchise"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("franchise") }
            disabled={ state.updatingFilters }
          />
          <DateInput 
            label="Início"
            value={ params.start ? moment(params.start).toDate() : null }
            onChange={ newValue => {
              const start = newValue ? moment(newValue).format("YYYY-MM-DD") : params.start;
              onFiltersChange({ ...params, start }) 
            }}
            disabled={ state.updatingFilters }
            required
          />
          <DateInput 
            label="Fim" 
            value={ params.end ? moment(params.end).toDate() : null }
            onChange={ newValue => {
              const end = newValue ? moment(newValue).format("YYYY-MM-DD") : params.end;
              onFiltersChange({ ...params, end }) 
            }}
            disabled={ state.updatingFilters }
            required
          />
        </div>
        <div className="hdppl-filters">
          <Select
            label="Categoria"
            value={ optional(params.categoryId).map(i => String(i)).get() || null }
            required
            onChange={ categoryId => onFiltersChange({ ...params, categoryId: categoryId ? parseInt(categoryId) : null }) }
            options={
              optional(data.filters.categories)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .getOrDefault([])
            }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Segmentos"
            value={ optional(params.segmentIds || []).map(s => s.map(i => String(i))).getOrDefault([]) }
            onChange={ segmentIds => onFiltersChange({ ...params, segmentIds: segmentIds.map(i => parseInt(i)) }) }
            options={ 
              optional(data.filters.segments)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("segment"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("segment") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Fabricantes"
            value={ optional(params.manufacturerIds || []).map(s => s.map(i => String(i))).getOrDefault([]) }
            onChange={ manufacturerIds => onFiltersChange({ ...params, manufacturerIds: manufacturerIds.map(i => parseInt(i)) }) }
            options={ 
              optional(data.filters.manufactures)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("manufacturer"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("manufacturer") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Marcas"
            value={ optional(params.brandIds || []).map(s => s.map(i => String(i))).getOrDefault([]) }
            onChange={ brandIds => onFiltersChange({ ...params, brandIds: brandIds.map(i => parseInt(i)) }) }
            options={ 
              optional(data.filters.brands)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ ...j, key: String(j.key) })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("brand"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("brand") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="SKUs"
            value={ params.skus || [] }
            onChange={ skus => onFiltersChange({ ...params, skus }) }
            options={ 
              optional(data.filters.skus)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ value: j })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("sku"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("sku") }
            disabled={ state.updatingFilters }
          />
          <MultiSelect
            label="Eans"
            value={ params.eans || [] }
            onChange={ eans => onFiltersChange({ ...params, eans }) }
            options={ 
              optional(data.filters.eans)
                .mapNullToUndefined()
                .map<MultiSelectOption[]>(i => i.map(j => ({ value: j })))
                .mapAnyway(l => makeOptions(filtersLoaded.includes("ean"), l))
                .get()
            }
            onSelectOpen={ () => fetchFilter("ean") }
            disabled={ state.updatingFilters }
          />
        </div>
        <div className="hdppl-content hdppl-content-active">
          <div className="hdppl-histogram-wrapper">
            <div className="hdppl-histogram hdppl-card">
              <div className="hdppl-info-boxes">
                <div className="__info-box-wrapper">
                  <div className="__info-box">
                    <div className="__info-box-title">
                      Moda
                    </div>
                    <div className="__info-box-description">
                      { data.mode.map(i => formatNumber(i)).join(", ") }
                    </div>
                  </div>
                </div>
                <div className="__info-box-wrapper">
                  <div className="__info-box">
                    <div className="__info-box-title">
                      Mediana
                    </div>
                    <div className="__info-box-description">
                      { formatNumber(data.median) }
                    </div>
                  </div>
                </div>
              </div>
              <div className="hdppl-histogram-title">Histograma de Preços</div>
              <Histogram 
                buckets={ data.buckets }
                max={ data.max }
                min={ data.min }
                xAxisName="Faixa de Preços por Und"
                yAxisName="Densidade" />
            </div>
          </div>
          <div className="hdppl-table-wrapper">
            <table className="hdppl-table" ref={ tableHeader }>
              <thead>
                <tr>
                  <th>UF</th>
                  <th>Cidade</th>
                  <th>Marca</th>
                  <th>SKU</th>
                  <th>EAN</th>
                  <th>Rede</th>
                  <th>Período</th>
                  <th>Preço<br />Und</th>
                  <th>Preço<br />{ liquidCategories.includes(params.categoryId || NaN) ? "Lt" : "Kg" }</th>
                  <th>Qtd</th>
                </tr>
              </thead>
            </table>
            <div className="hdppl-table-responsive hd-scroll hd-scroll-principal" onScroll={ handleTableScroll } ref={ responsiveDiv }>
              <table className="hdppl-table" ref={ tableBody }>
                <tbody>
                  {
                    data.table.map((line, index) => {
                      const weekParts = line.week.split(" - ");
                      return (
                        <tr key={ index }>
                          <td>{ line.uf }</td>
                          <td>{ line.city }</td>
                          <td>{ line.brand }</td>
                          <td>{ line.sku }</td>
                          <td>{ line.ean }</td>
                          <td>{ line.franchise }</td>
                          <td>{ weekParts[0] }<br />{ weekParts[1] }</td>
                          <td>{ formatNumber(line.price) }</td>
                          <td>{ formatNumber(line.price / line.qtd) }</td>
                          <td>{ formatQtd(line.qtd) }</td>
                        </tr>
                      )
                    })
                  }
                  {
                    !data.hasMore ? null : (
                      !state.pageLoadHadError
                        ? <tr><td colSpan={ 10 }>Carregando...</td></tr>
                        : <tr><td colSpan={ 10 }>Erro ao carregar mais linhas</td></tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default PriceDashboard;