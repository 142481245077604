
import useRequest from './useRequest';

const useIntegrity = () => {
    
  const checkIntegrityRequest = useRequest("GET", "/check/integrity");

  const checkIntegrity = async (token:String) : Promise<any> => {
       
    return checkIntegrityRequest({ params: { token }, options: {auth: false} });
  };

  return {
    checkIntegrity
  };

};

export default useIntegrity;