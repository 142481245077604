import { FunctionComponent } from 'react';
import { ShareMetric } from './definitions';

const formatValue = (val?: number) : string => {
  return val ? val.toFixed(2).replace(".", ",") : "0"
}

const formatPercent = (val?: number) : string => {
  return val ? val.toFixed(1).replace(".", ",") + "%" : "0%"
}

const TopBoxContent : FunctionComponent<{ data: ShareMetric, title: string, image: any }> = props => {
  const { title, data, image } = props
  return (
    <div className="hdpd-box hdpd-tbc-wrapper">
      <div className="hdpd-tbc-header">
        { title }
      </div>

      <div className="hdpd-tdc-content">

        <div className="hdpd-tbc-percent-wrapper clearfix">
          <div className="hdpd-tbc-percent-icon">
            { image }
          </div> 
          <div className="hdpd-tbc-percent">{ formatPercent(data.actualIndex * 100) }</div>
        </div>
        
        <div className="hdpd-tbc-pp-py-tables">
          <div className="hdpd-tbc-pp-py-table">
            <div className="hdpd-tbc-pp-py-table-header">
              Vs. PP
            </div>
            <div className="hdpd-tbc-pp-py-table-body">
              <div className={ `hdpd-tbc-pp-py-table-indicator${ data.lastMonthIndex < 0 ? " __down" : "" }` } />
              <div className={ `hdpd-tbc-pp-py-table-value` }>
                { formatValue(data.lastMonthIndex) }
              </div>
            </div>
          </div>
          <div className="hdpd-tbc-pp-py-table">
            <div className="hdpd-tbc-pp-py-table-header">
              Vs. PY
            </div>
            <div className="hdpd-tbc-pp-py-table-body">
            <div className={ `hdpd-tbc-pp-py-table-indicator${ data.lastYearIndex < 0 ? " __down" : "" }` } />
              <div className="hdpd-tbc-pp-py-table-value">
                { formatValue(data.lastYearIndex) }
              </div>
            </div>
          </div>
        </div>

        <table className="hdpd-tbc-bt">
          <thead>
            <tr>
              <th>Mês Ano</th>
              <th>(%) Meses Anteriores</th>
            </tr>
          </thead>
          <tbody>
            {
              data.previous.map((val, index) => (
                <tr key={ index }>
                  <td>
                    { val.month }  
                  </td>    
                  <td>
                    { formatPercent(val.index * 100) }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default TopBoxContent;