import {
  ReactNode,
  ForwardRefRenderFunction,
  useState,
  useEffect,
  Fragment,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Link } from "react-router-dom";
import { classMap, makeUrl } from "../utils/Util";
import { useHome } from "../../service/home";
import {
  Dialog,
  DialogButton,
  DialogTitle,
  useDialogs,
} from "../../service/dialogs";
import useAuth from "../../hooks/useAuth";
import UserConfig from "./UserConfig";

interface State {
  open: boolean;
}

const initialState: State = {
  open: false,
};

const Dashboard: ForwardRefRenderFunction<
  { toggle: () => void },
  {
    title?: string;
    showHeader?: boolean;
    children?: ReactNode;
    navigation?: ReactNode;
  }
> = (props, ref) => {
  const { title, showHeader = title !== undefined } = props;
  const { home } = useHome();
  const { children, navigation } = props;
  const [{ open }, setState] = useState<State>(initialState);
  const { openDialog, closeDialog } = useDialogs();
  const { logout } = useAuth();

  useEffect(() => {
    if (localStorage.getItem("dismiss-tip-menu") === null) {
      let id = openDialog(({ onClose }) => (
        <Dialog onClose={onClose}>
          <DialogTitle>DICA</DialogTitle>
          Clique no canto esquerdo da tela para abrir o menu.
          <DialogButton
            onClick={() => {
              localStorage.setItem("dismiss-tip-menu", "1");
              onClose();
            }}
          >
            Não mostrar novamente
          </DialogButton>
        </Dialog>
      ));

      setTimeout(() => {
        localStorage.setItem("dismiss-tip-menu", "1");
        closeDialog(id);
      }, 5000);
    }
  }, [openDialog, closeDialog]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== "Escape") {
        return;
      }
      setState((prev) => ({ ...prev, open: false }));
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const toggle = () => {
    setState((prev) => ({ ...prev, open: !prev.open }));
  };

  const toggleStatus = () => {
    return open;
  };

  const [ filter, setFilter ] = useState<string>("");

  useImperativeHandle(ref, () => ({
    toggle,
    toggleStatus,
  }));

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const openMenu = params.get("openMenu");

    if (openMenu) {
      setState({ open: true });
    }
  }, []);

  const filteredDashboards = home?.dashboards.filter(i => filter === "" || i.description.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));

  const dashboardsLength = home?.dashboards?.length || 0;

  return (
    <>
      <div
        className={classMap({
          "hd-dash": true,
          "hd-dash-open": open,
        })}
      >
        <div className="hd-dash-menu hd-scroll">
          <div className="hd-dash-menu-top">
            <div className="hd-dash-menu-top-title">{home?.user.name}</div>
            <div className="hd-dash-menu-top-sub-title">
              {home?.user.companyName}
            </div>
          </div>
          {navigation ? (
            <>
              {navigation}
              <UserConfig />
            </>
          ) : (
            <>
              <Link className="hd-dash-menu-item" onClick={toggle} to="/">
                Voltar à Home
              </Link>
              <div className="hd-dash-menu-item" onClick={logout}>
                Sair
              </div>
              <div className="hd-dash-menu-section-title">Dashboards</div>
              {
                dashboardsLength < 5 ? null : (
                  <div className="hd-dash-menu-filter-input-wrapper">
                    <input
                      className="hd-dash-menu-filter-input"
                      placeholder="Filtrar..."
                      value={ filter }
                      onChange={ e => setFilter(e.target.value) }
                    />
                  </div>
                )
              }
            </>
          )}
          {navigation
            ? null
            : (
              !filteredDashboards?.length ? <div style={{ color: "white", textAlign: "center", padding: "6px" }}>Nenhum relatório encontrado!</div> : (
                filteredDashboards.map((dashboard, index) => {
                  const url = makeUrl(dashboard);
                  return (
                    <Fragment key={index}>
                      {!url ? null : (
                        <Link
                          className="hd-dash-menu-item"
                          to={url}
                          onClick={toggle}
                        >
                          {dashboard.description}
                        </Link>
                      )}
                    </Fragment>
                  )
                })
              )
            )
          }
        </div>
        {showHeader ? null : (
          <div className="hd-pbi-btn" onClick={toggle}>
            <i className="fa fa-bars" />
          </div>
        )}
        <div className="hd-dash-backdrop" onClick={toggle} />
        <div className="hd-dash-content">
          <div className="hd-dash-button" onClick={toggle} />
          {!showHeader ? null : (
            <div className="hdmsps-header">
              <div className="hd-dash-menu-btn" onClick={toggle}>
                <i className="fa fa-bars"></i>
              </div>
              <img
                className="hdmsps-logo"
                src="/static/horus-negative.png"
                alt="Horus Logo"
              />
              <div className="hdmsps-title-separator" />
              <h2 className="hdmsps-title">{title}</h2>
            </div>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default forwardRef(Dashboard);
