import { useScrollDirection } from "../../../../../hooks/useScrollDirection";
import useFgv from "../../useFgv";
import FVG_LOGO from "../images/logo-fvg-neogrid.png";
import { HEADER_MENU_LIST } from "./mapped";

export const FGVHeader = () => {
  const { fgvUser } = useFgv();
  const scrollDirection = useScrollDirection();

  let navStyle = "";
  if (scrollDirection !== "initial") {
    navStyle = "fgv-header_scrolling";
  }
  return (
    <div className={"fgv-header fgv-section " + navStyle}>
      <div className="fgv-header_container fgv-container">
        <a href="/">
          <img src={FVG_LOGO} alt="Logotipo FVG" />
        </a>
        <nav className="fgv-header-right">
          <ul>
            {HEADER_MENU_LIST.map(({ name, to }) => (
              <li key={to}>
                <a href={to}>{name}</a>
              </li>
            ))}
          </ul>
          {!fgvUser ? (
            <a href="#cadastro" className="fgv-signup">
              Cadastrar
            </a>
          ) : (
            <button className="fgv-signup" disabled>
              Cadastrado
            </button>
          )}
        </nav>
      </div>
    </div>
  );
};
