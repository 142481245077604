import * as React from "react";
import { Link } from "react-router-dom";
import { GoogleAnalyticsActions } from "../../../hooks/useGoogleAnalytics";

type Props = {
  fgvUser: any;
  onRegisterClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  goToPlans?:
  | React.MouseEventHandler<HTMLAnchorElement>
  | undefined
  | HTMLButtonElement;
  urlToPlans?: string;
  urlToSignUp?: string;
};

const FgvMenu: React.FC<Props> = ({
  fgvUser,
  onRegisterClick,
  urlToPlans,
  urlToSignUp,
  goToPlans,
}: Props) => {
  return (
    <>
      <div className="hd-fgv__menu">
        <div className="hd-fgv__content">
          <div className="hd-fgv__menu__logo">
            <a href="./#horus-top">
              <img src="/static/logo-horus-w.png" alt="Logo Horus" />
              <div className="hd-fgv__menu__logo__separator" />
              <img src="/static/fgv.png" alt="Logo Horus" />
            </a>
          </div>
          <nav className="hd-fgv__menu__content">

            <button
              className="hd-fgv__menu__content__item"
              onClick={(e) => {
                e.preventDefault();
                GoogleAnalyticsActions.event("botao_menu_sobre", "", "ㅤ");
                window.location.href = "./#sobre";
              }}
            >
              Sobre
            </button>

            <button
              className="hd-fgv__menu__content__item"
              onClick={(e) => {
                e.preventDefault();
                GoogleAnalyticsActions.event("botao_menu_produtos", "", "ㅤ");
                window.location.href = "./#produtos";
              }}
            >
              Produtos
            </button>

            <button
              className="hd-fgv__menu__content__item"
              onClick={(e) => {
                e.preventDefault();
                GoogleAnalyticsActions.event("botao_menu_conheca_ferramenta", "", "ㅤ");
                window.location.href = "./#ferramenta";
              }}
            >
              Conheça a Ferramenta
            </button>

            <button
              className="hd-fgv__menu__content__item"
              onClick={(e) => {
                e.preventDefault();
                GoogleAnalyticsActions.event("botao_menu_planos", "", "ㅤ");
                window.location.href = "./#planos";
              }}
            >
              <b>Planos</b>
            </button>
          </nav>
        </div>
      </div>
    </>
  );
};

export default FgvMenu;
