const mask = () => {

	const cnpj = (cnpj:string, e:any) => {
		if (e.nativeEvent.inputType !== 'insertText' && e.nativeEvent.inputType !== 'insertFromPaste') {
			return cnpj		
		}
		const regex = /(\d{1,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/gm;
		const regexAnyNumber = /\D+/gm;		
		return cnpj.replace(regexAnyNumber, '')
		.replace(regex, '$1.$2.$3/$4-$5');
	}

	return { cnpj }

}

export default mask;


export const maskPhone = (phone: string | undefined) => {

	if(!phone) {
		return phone
	}
	
	let value = phone.replace(/\D/g,""); 
    value = value.replace(/^(\d{2})(\d)/g,"($1) $2"); 
    value = value.replace(/(\d)(\d{4})$/,"$1-$2"); 
    return value;
}