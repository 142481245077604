import { FunctionComponent, useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingFrame from '../ui/LoadingFrame';

const PrivateRoute : FunctionComponent<RouteProps> = props => {
  const { children } = props
  const { user, restore } = useAuth()
  const [ restoreFailed, setRestoredFailed ] = useState(false)

  useEffect(() => {
    if (user) {
      return;
    }
    restore().catch(() => setRestoredFailed(true));
  }, [ user, restore, setRestoredFailed ])

  return (
    <Route { ...props } children={ user ? children : (restoreFailed ? <Redirect to="/login" /> : <LoadingFrame />) }/>
  )
}

export default PrivateRoute