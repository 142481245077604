import styled from "styled-components";
import ToggleNotifications from "./ToggleNotifications";

const UserConfigContainer = styled.div`
  display: flex;
  padding: 0 15px;
  flex-direction: column;

  span {
    color: white;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    margin-bottom: 10px;
  }
`;

function UserConfig() {
  return (
    <>
      <UserConfigContainer>
        <span>Configurações</span>
        <ToggleNotifications />
      </UserConfigContainer>
    </>
  );
}

export default UserConfig;
