import { ChangeEvent, FunctionComponent, HTMLProps, RefObject, useEffect, useRef, useState } from "react";
import useFirstValue from "../../hooks/useFirstValue";

import { makeClassName } from "./definitions";

const Input: FunctionComponent<Omit<HTMLProps<HTMLInputElement>, "onChange"> & { 
  label?: string,
  validate?: (value: string) => string | null,
  onValidate?: (result: boolean) => void,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  inputRef?: RefObject<HTMLInputElement>
}> = function (props) {
  const [ { validationMessage, touched, passwordHidden }, setState ] = useState<{ 
    validationMessage?: string,
    touched: boolean,
    passwordHidden: boolean
  }>({ touched: false, passwordHidden: true });
  const { label, className, onChange, validate, onValidate, inputRef: originalInputRef, ...inputProps } = props;

  const internalInputRef = useRef<HTMLInputElement>(null);
  const inputRef = originalInputRef || internalInputRef;

  const firstValidate = useFirstValue(props.validate);
  const firstOnValidate = useFirstValue(props.onValidate);

  const doValidation = useFirstValue((value: string, touch: boolean) => {
    if (!validate) {
      return;
    }
    const message = validate(value);
    setState(prev => ({ ...prev, 
      validationMessage: message || undefined, 
      touched: touch || prev.touched
    }));
    if (onValidate) onValidate(!message);
  });

  const theOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.value !== undefined) {
      setState(prev => ({ ...prev, touched: true }));
    } else {
      doValidation(e.target.value, true);
    }
    if (onChange) onChange(e);
  }

  // This useEffect do the initial first validation immediately
  // after the input is rendered
  useEffect(() => {
    if (firstValidate && firstOnValidate) {
      firstOnValidate(!firstValidate(inputRef.current!.value));
    }
  }, [ firstValidate, firstOnValidate, inputRef ]);

  const value = props.value as string;
  useEffect(() => {
    if (value === undefined) return;
    doValidation(value, false);
  }, [ value, doValidation ]);

  return (
    <div 
      className={ makeClassName({
        'h-sumw__input-wrapper': true,
        'h-sumw__input-wrapper--error': !!validationMessage && touched,
        [className || ""]: true
      }) } >
      {
        label && <label 
          htmlFor={ inputProps.id }
          className="h-sumw__label"
        >{ label }</label>
      }
      <input 
        { ...inputProps }
        onChange={ theOnChange }
        className={ makeClassName({
          "h-sumw__input": true,
          "h-sumw__input-passwd": props.type === "password",
        }) }
        type={ passwordHidden ? props.type : "text" }
        ref={ inputRef }
      />
      {
        props.type !== "password" ? null : (
          passwordHidden ? (
            <div className="h-sumw__input__toggle-visibility" onClick={ () => setState(prev => ({ ...prev, passwordHidden: false })) }>
              <i className="fa fa-eye"></i>
            </div>
          ) : (
            <div className="h-sumw__input__toggle-visibility" onClick={ () => setState(prev => ({ ...prev, passwordHidden: true })) }>
              <i className="fa fa-eye-slash"></i>
            </div>
          )
        )
      }
      {
        !touched || !validationMessage ? null : (
          <div className="h-sumw__input__message">
            { validationMessage }
          </div>
        )
      }
    </div>
  )
}

export default Input