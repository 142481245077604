import React, {
  FormEvent,
  FormEventHandler,
  forwardRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Input from "./Input";
import {
  makeClassName,
  UserRegister,
  CompanyRegister,
  UserStatus,
} from "./definitions";
import { User } from "../../model";
import useAuth from "../../hooks/useAuth";
import useCheckout from "./useCheckout";
import { Credentials, ForgetPassword } from "../../service/auth";
import { getErrorMessage, validCNPJ, zeroPad } from "../utils/Util";
import Modal from "../pages/modals/Modal";
import useSetInterval from "../../hooks/useSetInterval";
import useStable from "../../hooks/useStable";
import { GoogleAnalyticsActions } from "../../hooks/useGoogleAnalytics";
import { toast } from "react-toastify";

function validateEmail(email: string) {
  if (!email) return "O E-mail é obrigatório";
  if (!email.match(/[^@]+@[^@]+/)) return "Insira um E-Mail válido";
  return null;
}

function useTimer(props: { onFinished?: () => void }) {
  const [seconds, setSeconds] = useState<number>(0);
  const setInterval = useSetInterval();
  const intervalRef = useRef<any>();
  const onFinished = useStable(props.onFinished);

  let secondPart = seconds % 60;
  let minutePart = (seconds - secondPart) / 60;

  const time = `${zeroPad(minutePart, 2)}:${zeroPad(secondPart, 2)}`;

  const stop = useCallback(
    function stop() {
      clearInterval(intervalRef.current);
    },
    [intervalRef]
  );

  const start = useCallback(
    function (time: number) {
      stop();
      setSeconds(time);
      intervalRef.current = setInterval(function () {
        setSeconds((prev) => prev - 1);
      }, 1000);
    },
    [stop, setSeconds, intervalRef, setInterval]
  );

  useEffect(
    function () {
      if (seconds === 0) {
        stop();
        onFinished.current?.();
      }
    },
    [seconds, onFinished, stop]
  );

  return { time, start, stop };
}

interface RegisterData {
  email: string;
  name: string;
  lastName?: string;
  password: string;
  job: string;
  cnpj: string;
  companyName: string;
  activity: string;
}

interface Props {
  onNext: () => void;
}

function useRequests() {
  const register = function (registerData: RegisterData) {
    return new Promise<User>((resolve, reject) => {
      setTimeout(() => {
        if (registerData.job === "erro") {
          reject(new Error());
          return;
        }
        resolve({ name: "João Gabriel" });
      }, 1000);
    });
  };

  return { register };
}

interface SendEmailFormProps {
  onCheckUser: (event: { status: UserStatus; email: string }) => void;
}

const SendEmailForm: FunctionComponent<SendEmailFormProps> = function (props) {
  const [{ valid, sending, error }, setState] = useState<{
    valid: boolean;
    sending: boolean;
    error?: string;
  }>({ valid: false, sending: false });
  const { checkEmail } = useCheckout();

  const emailInput = useRef<HTMLInputElement>(null);

  const onSubmit: FormEventHandler = async function (e) {
    e.preventDefault();
    GoogleAnalyticsActions.event("enviar_pag2", "", "&#8287;");
    if (!emailInput.current) return;

    setState((prev) => ({ ...prev, sending: true, error: undefined }));

    const email = emailInput.current.value;

    try {
      const status = await checkEmail(email);
      setState((prev) => ({ ...prev, sending: false }));
      props.onCheckUser({ status, email });
    } catch (e) {
      setState((prev) => ({
        ...prev,
        sending: false,
        error: "Erro ao checar e-mail",
      }));
    }
  };

  return (
    <>
      <p className="h-sumw__info-text">Informe seu e-mail:</p>
      <div className="h-sumw__grid">
        <div className="h-sumw__col-5">
          <form className="h-sumw__form-step" onSubmit={onSubmit}>
            <Input
              inputRef={emailInput}
              placeholder="E-mail"
              className="h-sumw__spacing-before"
              validate={validateEmail}
              onValidate={(valid) => setState((prev) => ({ ...prev, valid }))}
            />
            <div className="umw_next">
              <button
                className="h-sumw__button h-sumw__spacing-before"
                disabled={!valid || sending}
              >
                {!sending ? "Enviar" : "Carregando..."}
              </button>
            </div>

            <div className="h-sumw__button-side">{error}</div>
          </form>
        </div>
      </div>
    </>
  );
};

type RegisterFormRef = { email: string };
type PasswordStrength = {
  moreThanEight: boolean;
  specialCharacters: boolean;
  aNumber: boolean;
  anUppercase: boolean;
  aLowerCase: boolean;
  message: string;
  rank: number;
  strength: number;
};

const initialPasswordStrength = {
  moreThanEight: false,
  specialCharacters: false,
  aNumber: false,
  anUppercase: false,
  aLowerCase: false,
  message: "",
  rank: 0,
  strength: 0,
};

function calcPasswordStrength(password: string): PasswordStrength {
  const result: PasswordStrength = { ...initialPasswordStrength };
  if (password.length >= 8) {
    result.moreThanEight = true;
    result.strength += 20;
    result.rank += 1;
  }
  if (password.match(/[!@#$%&*()=+-/]/)) {
    result.specialCharacters = true;
    result.strength += 20;
    result.rank += 1;
  }
  if (password.match(/\d/)) {
    result.aNumber = true;
    result.strength += 20;
    result.rank += 1;
  }
  if (password.match(/[A-Z]/)) {
    result.anUppercase = true;
    result.strength += 20;
    result.rank += 1;
  }
  if (password.match(/[a-z]/)) {
    result.aLowerCase = true;
    result.strength += 20;
    result.rank += 1;
  }
  if (result.rank <= 2) {
    result.message = "Senha Fraca";
  } else if (result.rank <= 4) {
    result.message = "Senha Média";
  } else {
    result.message = "Senha Forte";
  }
  return result;
}

const RegisterForm: ForwardRefExoticComponent<
  RefAttributes<RegisterFormRef> & {
    onRegisted: (userStatus: UserStatus) => void;
  }
> = forwardRef((props, ref) => {
  const [state, setState] = useState({
    confirmEmailModalOpen: false,
    confirmEmailCode: "",
    loading: false,
    error: undefined as string | undefined,
    invalid: [] as string[],
    passwordStrength: initialPasswordStrength,
    password: "",
  });

  const { time, start: startTimer } = useTimer({});

  const { registerUser, requestEmailConfirmation } = useCheckout();
  const { login } = useAuth();

  const emailInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const jobInputRef = useRef<HTMLInputElement>(null);
  const cnpjInputRef = useRef<HTMLInputElement>(null);
  const companyNameInputRef = useRef<HTMLInputElement>(null);
  const activityInputRef = useRef<HTMLInputElement>(null);

  new GoogleAnalyticsActions("G-6TXX26VFFT");

  useImperativeHandle(ref, () => ({
    set email(email: string) {
      emailInputRef.current!.value = email;
      emailInputRef.current!.disabled = true;
    },
  }));

  const makeOnValidate = (label: string) => {
    return (valid: boolean) => {
      setState((prev) => {
        if (valid) {
          return {
            ...prev,
            invalid: prev.invalid.filter((i) => i !== label),
          };
        }
        if (prev.invalid.includes(label)) {
          return prev;
        }
        return {
          ...prev,
          invalid: [...prev.invalid, label],
        };
      });
    };
  };

  const onModalSendClick = async function (e: React.MouseEvent) {
    e.preventDefault();

    setState((prev) => ({ ...prev, confirmEmailModalOpen: false }));

    const data: UserRegister = {
      email: emailInputRef.current!.value,
      firstName: nameInputRef.current!.value,
      lastName: lastNameInputRef.current!.value,
      password: passwordInputRef.current!.value,
      job: jobInputRef.current!.value,
      cnpj: cnpjInputRef.current!.value,
      companyName: companyNameInputRef.current!.value,
      activity: activityInputRef.current!.value,
      employeeAmount: "1+",
      emailConfirmCode: state.confirmEmailCode,
    };

    setState((prev) => ({ ...prev, loading: true, error: undefined }));

    try {
      await registerUser(data);

      try {
        await login({ user: data.email, password: data.password });
        props.onRegisted("OK");
      } catch (e) {
        props.onRegisted("REGISTERED");
      }
    } catch (e) {
      console.error(e);
      setState((prev) => ({
        ...prev,
        loading: false,
        error: getErrorMessage(e),
      }));
    }
  };

  async function onFormSubmit(e: FormEvent) {
    e.preventDefault();

    GoogleAnalyticsActions.event("cadastrar_pag2", "", "&#8287;");

    setState((prev) => ({ ...prev, loading: true, error: undefined }));

    try {
      startTimer(300);
      await requestEmailConfirmation(emailInputRef.current!.value);
      setState((prev) => ({
        ...prev,
        loading: false,
        confirmEmailModalOpen: true,
        confirmEmailCode: "",
      }));
    } catch (e) {
      console.error(e);
      setState((prev) => ({
        ...prev,
        loading: false,
        error: getErrorMessage(e),
      }));
    }
  }

  return (
    <>
      <h2 className="h-sumw__sub-title">Cadastro</h2>
      <p className="h-sumw__info-text">
        Você ainda não é um usuário Horus! Por favor cadastre-se para prosseguir
        com sua compra.
      </p>
      <form className="h-sumw__form-step" onSubmit={onFormSubmit}>
        <Modal
          open={state.confirmEmailModalOpen}
          onClose={() =>
            setState((prev) => ({ ...prev, confirmEmailModalOpen: false }))
          }
        >
          <div className="h-sumw__email-confirm__wrapper">
            <h2>Confirmação de E-mail</h2>
            <p>
              Digite o código enviado para
              <br />
              {emailInputRef.current?.value}
            </p>
            <input
              maxLength={6}
              type="text"
              placeholder="XXXXXX"
              value={state.confirmEmailCode}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  confirmEmailCode: e.target.value,
                }))
              }
            />
            <div className="h-sumw__email-confirm__countdown">{time}</div>
            <div className="h-sumw__email-confirm__buttons">
              <button
                onClick={(e) => {
                  GoogleAnalyticsActions.event(
                    "confirmacao_email_pag2",
                    "",
                    "&#8287;"
                  );
                  onModalSendClick(e);
                }}
                disabled={state.confirmEmailCode.length !== 6}
              >
                Enviar
              </button>
            </div>
          </div>
        </Modal>
        <Input
          inputRef={emailInputRef}
          label="E-Mail*"
          placeholder="E-mail"
          className="h-sumw__spacing-before"
        />
        <Input
          inputRef={nameInputRef}
          name="name"
          placeholder="Nome"
          className="h-sumw__spacing-before"
          label="Nome*"
          validate={(value) => (value ? null : "O nome é obrigatório")}
          onValidate={makeOnValidate("name")}
        />
        <Input
          inputRef={lastNameInputRef}
          name="last_name"
          placeholder="Sobrenome"
          className="h-sumw__spacing-before"
          label="Sobrenome"
        />
        <Input
          inputRef={passwordInputRef}
          name="password"
          type="password"
          placeholder="Crie sua senha"
          className="h-sumw__spacing-before"
          label="Crie sua senha*"
          defaultValue={state.password}
          validate={(value) => {
            if (!value) return "A senha é obrigatória";
            if (value.length < 5) return "Sua senha é muito pequena";
            return null;
          }}
          onValidate={makeOnValidate("password")}
          onChange={(e) =>
            setState((prev) => ({
              ...prev,
              password: e.target.value,
              passwordStrength: calcPasswordStrength(e.target.value),
            }))
          }
        />
        <div className="h-sumw__password__indicator">
          <div
            className={makeClassName({
              "h-sumw__password__indicator__bar": true,
              "h-sumw__password__indicator__bar--low":
                state.passwordStrength.rank <= 2,
              "h-sumw__password__indicator__bar--medium":
                state.passwordStrength.rank <= 4,
              "h-sumw__password__indicator__bar--high":
                state.passwordStrength.rank > 4,
            })}
            style={{ width: `${state.passwordStrength.strength}%` }}
          />
        </div>
        <div
          className={makeClassName({
            "h-sumw__password__message": true,
            "h-sumw__password__message--fail":
              !state.passwordStrength.moreThanEight,
          })}
        >
          Possui mais de 8 caracteres.
        </div>
        <div
          className={makeClassName({
            "h-sumw__password__message": true,
            "h-sumw__password__message--fail":
              !state.passwordStrength.anUppercase,
          })}
        >
          Possui letra maíuscula
        </div>
        <div
          className={makeClassName({
            "h-sumw__password__message": true,
            "h-sumw__password__message--fail":
              !state.passwordStrength.aLowerCase,
          })}
        >
          Possui letra minúscula
        </div>
        <div
          className={makeClassName({
            "h-sumw__password__message": true,
            "h-sumw__password__message--fail": !state.passwordStrength.aNumber,
          })}
        >
          Possui números
        </div>
        <div
          className={makeClassName({
            "h-sumw__password__message": true,
            "h-sumw__password__message--fail":
              !state.passwordStrength.specialCharacters,
          })}
        >
          Possuem caracteres especiais
        </div>
        <Input
          inputRef={jobInputRef}
          name="job"
          placeholder="Cargo"
          className="h-sumw__spacing-before"
          label="Cargo*"
          validate={(value) => (value ? null : "O cargo é obrigatório")}
          onValidate={makeOnValidate("job")}
        />
        <Input
          inputRef={cnpjInputRef}
          name="cnpj"
          placeholder="XX.XXX.XXX/XXXX-XX"
          className="h-sumw__spacing-before"
          label="CNPJ*"
          onChange={(e) => {
            e.target.value = e.target.value.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
          }}
          validate={(value) => {
            if (!value) return "O cnpj é obrigatório";
            if (!value.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/))
              return "O cnpj é inválido";
            if (!validCNPJ(value)) return "O cnpj é inválido";
            return null;
          }}
          onValidate={makeOnValidate("cnpj")}
        />
        <Input
          inputRef={companyNameInputRef}
          name="company_name"
          placeholder="Nome da Empresa"
          className="h-sumw__spacing-before"
          label="Nome da Empresa*"
          validate={(value) =>
            value ? null : "O nome da empresa é obrigatório"
          }
          onValidate={makeOnValidate("companyName")}
        />
        <Input
          inputRef={activityInputRef}
          name="activity"
          placeholder="Ramo de Atividade"
          className="h-sumw__spacing-before"
          label="Ramo de Atividade"
        />
        <button
          className="h-sumw__button h-sumw__spacing-before"
          disabled={!!state.invalid.length}
        >
          {state.loading
            ? "Carregando..."
            : `Cadastrar-se ${
                state.invalid.length
                  ? "(" + state.invalid.length + " erros)"
                  : ""
              }`}
        </button>
        <div className="h-sumw__button-side">{state.error}</div>
      </form>
    </>
  );
});

const LoginForm: ForwardRefExoticComponent<
  RefAttributes<RegisterFormRef> & {
    onLogin: () => void;
  }
> = forwardRef((props, ref) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const { login, forgetPassword } = useAuth();

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const id = toast.loading("Por favor, aguarde...", {
      position: "top-right",
    });

    try {
      await forgetPassword({ email: emailInputRef.current?.value || "" });

      toast.update(id, {
        render: "Enviamos um e-mail com instruções para redefinir sua senha.",
        type: "info",
        isLoading: false,
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.update(id, {
        render:
          "Não foi possível finalizar a solicitação, por favor tente novamente!",
        type: "error",
        isLoading: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [state, setState] = useState({
    loading: false,
    error: undefined as string | undefined,
    invalid: [] as string[],
  });

  useImperativeHandle(ref, () => ({
    set email(email: string) {
      emailInputRef.current!.value = email;
      emailInputRef.current!.disabled = true;
    },
  }));

  const makeOnValidate = (label: string) => {
    return (valid: boolean) => {
      setState((prev) => {
        if (valid) {
          return {
            ...prev,
            invalid: prev.invalid.filter((i) => i !== label),
          };
        }
        if (prev.invalid.includes(label)) {
          return prev;
        }
        return {
          ...prev,
          invalid: [...prev.invalid, label],
        };
      });
    };
  };

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const loginData = {
      user: emailInputRef.current!.value,
      password: passwordInputRef.current!.value,
    };

    setState((prev) => ({ ...prev, loading: true, error: undefined }));

    try {
      await login(loginData);
      setState((prev) => ({ ...prev, loading: false }));
      props.onLogin();
    } catch (err) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: "Erro ao fazer login",
      }));
    }
  }

  return (
    <>
      <h2 className="h-sumw__sub-title">Login</h2>
      <p className="h-sumw__info-text">Informe sua senha para continuar</p>
      <form className="h-sumw__form-step" onSubmit={onSubmit}>
        <Input
          label="E-Mail*"
          placeholder="E-mail"
          className="h-sumw__spacing-before"
          validate={validateEmail}
          onValidate={makeOnValidate("email")}
          inputRef={emailInputRef}
        />
        <Input
          name="password"
          type="password"
          placeholder="Senha"
          className="h-sumw__spacing-before"
          label="Senha*"
          inputRef={passwordInputRef}
          validate={(passwd) => (!passwd ? "A senha é obrigatória" : null)}
          onValidate={makeOnValidate("password")}
        />
        <div className="h-sumw__forgot-password">
          <p onClick={handleOnSubmit}>Recuperar minha senha</p>
        </div>
        <button
          className="h-sumw__button h-sumw__spacing-before"
          disabled={state.loading}
        >
          {state.loading ? "Carregando..." : "Login"}
        </button>
        <div className="h-sumw__button-side">{state.error}</div>
      </form>
    </>
  );
});

const AuthSuccess: FunctionComponent<{
  onLogout: () => void;
  onNext: () => void;
}> = (props) => {
  const { user } = useAuth();

  return (
    <>
      <h2 className="h-sumw__sub-title">Login</h2>
      <p className="h-sumw__info-text">Você foi autenticado com sucesso!</p>
      <div className="h-sumw__spacing-before">
        Olá {user?.name}, clique embaixo para prosseguir com sua compra!
      </div>
      <button
        className="h-sumw__button h-sumw__spacing-before"
        onClick={() => {
          GoogleAnalyticsActions.event("pagamento_pag2", "", "&#8287;");
          props.onNext();
        }}
      >
        Pagamento
      </button>{" "}
      &nbsp;
      <button
        className="h-sumw__button h-sumw__button--secundary h-sumw__spacing-before"
        onClick={() => {
          GoogleAnalyticsActions.event("outra_conta_pag2", "", "&#8287;");
          props.onLogout();
        }}
      >
        Desejo comprar como outra conta
      </button>
    </>
  );
};

const CompanyRegisterForm: FunctionComponent<{
  onCompanyRegistered: (event: { logged: boolean }) => void;
  currentUser: string | undefined;
}> = function (props) {
  const [state, setState] = useState({
    loading: false,
    error: undefined as string | undefined,
    invalid: [] as string[],
  });

  const { registerCompany } = useCheckout();
  const { login } = useAuth();

  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const cnpjInputRef = useRef<HTMLInputElement>(null);
  const companyNameInputRef = useRef<HTMLInputElement>(null);
  const activityInputRef = useRef<HTMLInputElement>(null);
  new GoogleAnalyticsActions("G-HTD2B4DL40");

  const makeOnValidate = (label: string) => {
    return (valid: boolean) => {
      setState((prev) => {
        if (valid) {
          return {
            ...prev,
            invalid: prev.invalid.filter((i) => i !== label),
          };
        }
        if (prev.invalid.includes(label)) {
          return prev;
        }
        return {
          ...prev,
          invalid: [...prev.invalid, label],
        };
      });
    };
  };

  const handleSubmit = async function (e: FormEvent) {
    e.preventDefault();

    const credentials: Credentials = {
      user: emailInputRef.current!.value,
      password: passwordInputRef.current!.value,
    };

    const data: CompanyRegister = {
      cnpj: cnpjInputRef.current!.value,
      companyName: companyNameInputRef.current!.value,
      activity: activityInputRef.current!.value,
      employeeAmount: "1+",
    };

    setState((prev) => ({ ...prev, loading: true, error: undefined }));

    try {
      await login(credentials);
    } catch (e) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error:
          e.response?.status === 401
            ? "E-mail ou senha incorretos"
            : "Erro ao autenticar",
      }));
      return;
    }

    try {
      await registerCompany(data);
      const response = await login(credentials);
      props.onCompanyRegistered({ logged: response.status === 200 });
    } catch (e) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: getErrorMessage(e),
      }));
    }
  };

  return (
    <>
      <h2 className="h-sumw__sub-title">Cadastro</h2>
      <p className="h-sumw__info-text">
        Nós precisamos de alguns dados da sua empresa!
      </p>
      <form className="h-sumw__form-step" onSubmit={handleSubmit}>
        <Input
          value={props.currentUser}
          readOnly
          label="E-mail*"
          placeholder="E-mail"
          className="h-sumw__spacing-before"
          inputRef={emailInputRef}
        />
        <Input
          name="password"
          type="password"
          placeholder="Senha"
          className="h-sumw__spacing-before"
          label="Senha*"
          inputRef={passwordInputRef}
          validate={(passwd) => (!passwd ? "A senha é obrigatória" : null)}
          onValidate={makeOnValidate("password")}
        />
        <div className="h-sumw__forgot-password">
          <a target="__blank" href="/forgot/password">
            Recuperar minha senha
          </a>
        </div>
        <Input
          inputRef={cnpjInputRef}
          name="cnpj"
          placeholder="XX.XXX.XXX/XXXX-XX"
          className="h-sumw__spacing-before"
          label="CNPJ*"
          onChange={(e) => {
            e.target.value = e.target.value.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
          }}
          validate={(value) => {
            if (!value) return "O cnpj é obrigatório";
            if (!value.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/))
              return "O cnpj é inválido";
            return null;
          }}
          onValidate={makeOnValidate("cnpj")}
        />
        <Input
          inputRef={companyNameInputRef}
          name="company_name"
          placeholder="Nome da empresa"
          className="h-sumw__spacing-before"
          label="Nome da Empresa*"
          validate={(value) =>
            value ? null : "O nome da empresa é obrigatório"
          }
          onValidate={makeOnValidate("companyName")}
        />
        <Input
          inputRef={activityInputRef}
          name="activity"
          placeholder="Ramo de atividade"
          className="h-sumw__spacing-before"
          label="Ramo de Atividade"
        />
        <button
          className="h-sumw__button h-sumw__spacing-before"
          disabled={!!state.invalid.length}
        >
          {state.loading
            ? "Carregando..."
            : `Cadastrar-se ${
                state.invalid.length
                  ? "(" + state.invalid.length + " erros)"
                  : ""
              }`}
        </button>
        <div className="h-sumw__button-side">{state.error}</div>
      </form>
    </>
  );
};

const StepRegister: FunctionComponent<Props> = (props) => {
  const registerFormRef = useRef<RegisterFormRef>(null);
  const loginFormRef = useRef<RegisterFormRef>(null);

  const { user } = useAuth();

  const [state, setState] = useState({
    userStatus: user ? "OK" : ("UNKNOWN" as UserStatus),
    currentUser: undefined as string | undefined,
  });

  const onCheckUser = function (event: { status: UserStatus; email: string }) {
    setState((prev) => ({
      ...prev,
      userStatus: event.status,
      currentUser: event.email,
    }));
    if (registerFormRef.current) registerFormRef.current.email = event.email;
    if (loginFormRef.current) loginFormRef.current.email = event.email;
  };

  const onRegisted = function (userStatus: UserStatus) {
    setState((prev) => ({ ...prev, userStatus }));
  };

  const onLogin = function () {
    setState((prev) => ({ ...prev, userStatus: "OK" }));
  };

  const onLogout = function () {
    setState((prev) => ({ ...prev, userStatus: "UNKNOWN" }));
  };

  const onCompanyRegisted = function (event: { logged: boolean }) {
    setState((prev) => ({
      ...prev,
      userStatus: event.logged ? "OK" : "REGISTERED",
    }));
  };

  return (
    <>
      {state.userStatus === "UNKNOWN" && (
        <SendEmailForm onCheckUser={onCheckUser} />
      )}
      {state.userStatus === "UNREGISTERED" && (
        <RegisterForm onRegisted={onRegisted} ref={registerFormRef} />
      )}
      {state.userStatus === "REGISTERED" && (
        <LoginForm onLogin={onLogin} ref={loginFormRef} />
      )}
      {state.userStatus === "NO_COMPANY" && (
        <CompanyRegisterForm
          currentUser={state.currentUser}
          onCompanyRegistered={onCompanyRegisted}
        />
      )}
      {state.userStatus === "OK" && (
        <AuthSuccess onNext={props.onNext} onLogout={onLogout} />
      )}
    </>
  );
};

export default StepRegister;
