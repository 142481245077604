import { useEffect, useRef } from "react";
import useFetch from "./useFetch";
import axios from "axios";
import { getTokens } from "../service/auth";
import useAuth from "./useAuth";

const useMarkVisitActive = (accessId: number | null) => {
  const fetch = useFetch();
  const { restore } = useAuth()
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const markVisitActive = async (id: number | null) => {
    if (!id) return;
    try {
      let token = getTokens();
      await axios.post("/api/v1/dashboard/access", {
         id,
      }, { headers: {Authorization: "Bearer " + token?.token }});
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        try {
          restore();
        } catch(error) {
          window.location.reload();
        }
      }
      console.error("Erro ao marcar visita ativa:", error);
    }
  };

  const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
    event.preventDefault();

    try {
      await markVisitActive(accessId);
      delete event.returnValue;
    } catch (error) {
      console.error("Erro ao marcar visita ativa:", error);
    }
  };

  useEffect(() => {
    timerRef.current = setInterval(() => markVisitActive(accessId), 20000);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      markVisitActive(accessId);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessId, fetch]);
};

export default useMarkVisitActive;
