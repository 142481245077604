import { useEffect, useRef, useState } from "react";

type ScrollDirection = "up" | "down" | "initial";

export const useScrollDirection = (): ScrollDirection => {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection>("initial");
  const lastScrollY = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        setScrollDirection("initial");
      } else if (currentScrollY > lastScrollY.current) {
        setScrollDirection("down");
      } else if (currentScrollY < lastScrollY.current) {
        setScrollDirection("up");
      }

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollDirection;
};
