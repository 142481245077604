import React, { ChangeEvent, FormEvent, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useHttp, { Http } from "../../../hooks/useHttp";
import { History } from 'history';
import { OpenModal, useModal } from "../../utils/Modal";
import makeModal from "../../utils/MakeModal";

type Form = { password: string, confirmPassword: string };
type Touched = Record<keyof Form, boolean>;

interface CreatePasswordParams { 
  token: string;
  password: string;
}

const initialFormState: Form = {
  password: "",
  confirmPassword: ""
};

const initialTouchedState: Touched = {
  password: false,
  confirmPassword: false
};

function createPassword(http: Http, data: CreatePasswordParams) {
  return http.request({
    method: "PUT", url: "/api/v1/user/password", data, options: { auth: false }
  })
}

async function baseHandleOnSubmit(
  params: {
    e: FormEvent,
    http: Http, 
    form: Form,
    setLoading: (value: boolean) => void,
    openModal: OpenModal,
    history: History<any>,
    token: string
  }
) {

  const { e, http, form, setLoading, history, openModal, token } = params;

  e.preventDefault();

  try {
    setLoading(true);

    await createPassword(http, { password: form.password, token });

    const modal = makeModal("Info", "Senha definida com sucesso");

    openModal(modal);

    history.push("/login");

  } catch (e: any) {

    const modal = makeModal("Erro", e.userMessage);

    setLoading(false);

    openModal(modal);

  }

}

function validatePassword(form: Form): string | null {

  if (!form.password) {
    return "A senha é obrigatória"
  }
  if (form.password.length < 8) {
    return "A senha deve ter no mínimo 8 caracteres"
  }
  return null;

}

function validateConfirmPassword(form: Form): string | null {

  if (!form.confirmPassword) {
    return "A confirmação de senha é obrigatória"
  }
  if (form.password !== form.confirmPassword) {
    return "As senhas não estão iguais"
  }
  return null;

}

function validateForm(form: Form) {

  const password = validatePassword(form);
  const confirmPassword = validateConfirmPassword(form);
  const ok = !password && !confirmPassword;
  return { password, confirmPassword, ok };

}

function baseTouch(setTouched: (action: React.SetStateAction<Touched>) => void, attribute: keyof Form) {
  setTouched(prev => ({ ...prev, [attribute]: true }))
}

function baseHandleOnChange(setForm: (action: React.SetStateAction<Form>) => void, e: ChangeEvent<HTMLInputElement>) {
  
  const { name, value } = e.target
  setForm(prev => ({ ...prev, [name]: value }))
}

export function useCreatePassword() {

  const [ form, setForm ] = useState<Form>(initialFormState);
  const [ touched, setTouched ] = useState<Touched>(initialTouchedState);
  const [ loading, setLoading ] = useState(false);
  const { openModal } = useModal();
  const history: History = useHistory();
  const { token } = useParams<{ token: string }>();

  const http = useHttp();

  const handleOnSubmit = (e: FormEvent) => baseHandleOnSubmit({ e, http, setLoading, form, openModal, history, token });

  const touch = (attribute: keyof Form) => baseTouch(setTouched, attribute);

  const validation = validateForm(form);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => baseHandleOnChange(setForm, e);

  return {
    form, loading, touched, validation, history,
    handleOnChange, handleOnSubmit, touch
  }

}