import { FunctionComponent } from "react"
import styled from "styled-components";
import { mainColor } from "../../constants";

const PerformMarket = styled.div`
  margin: 1% 4%;
`;

const BarChart = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-left: 1%;
  background-color: white;
`;

const BarChartContent = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid ${ mainColor };

    :before {
        content: "20%";
        float: right;
        margin-right: 5px;
        margin-top: 10px;
        position: relative;
        color: ${ mainColor };
`;

const BarChartLabel = styled.label`
  color: gray;
  width: 180px;
  height: 40px;
  margin-right: 5px;
  font-weight: bold;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translate(10px, 10px);
`;

const GraphicBar: FunctionComponent<{ label: string, value: string, isLabelValue?:boolean }> = (props) => {
    const { label, value, isLabelValue } = props
  
    let style = {
      width: '100%',
      height: 'auto',
      display: 'flex',
      margin: '25px 0'
    }
  
    let styleLabelText = {
      text: 'right',
      paddingRight: '1%'
    }

    let styleValue2 = {
        width: value+'%',
        content: `${ value }%`      
    }

    return (
      <>
        <PerformMarket style={style}>
          <BarChartLabel style={styleLabelText}>{label}</BarChartLabel>
            <BarChart>
              <BarChartContent style={styleValue2}/>
            </BarChart>
          {isLabelValue ? (
            <BarChartLabel style={{width: '100px' }}>{value + '%'}</BarChartLabel>
          ) : null}
        </PerformMarket>
      </>
    )
  }

export default GraphicBar;