import axios, { AxiosResponse } from "axios";
import ReactGA from "react-ga";
import { getClaims } from "../components/utils/JwtReader";
import { authCredentials } from "../components/utils/authCredentials";
import { AuthUserModel } from "../components/utils/authCredentials/setCredentials";
import { GoogleAnalyticsActions } from "../hooks/useGoogleAnalytics";
import { User } from "../model";

export const nullAuthUserCredentials = {
  user: null,
  firstName: null,
  lastName: null,
  role: null,
  company: null,
  sudoToken: null,
};

export interface Credentials {
  user: string;
  password: string;
}

export interface Tokens {
  user: string;
  token: string;
  refreshToken: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  company: string | null;
  sudoToken: string | null;
}

export interface ForgetPassword {
  email: string;
}

new GoogleAnalyticsActions("G-SX5Z63SCPY");

export const loginWithCredentials = async (
  credentials: Credentials
): Promise<any> => {
  const response = await axios.post("/api/v1/auth", credentials);

  const claims = getClaims(response.data.token);

  setCredentials(response, credentials.user, claims);

  GoogleAnalyticsActions.event(
    claims.company?.toLowerCase(),
    "Login",
    claims.username.split("@")[0]?.toLowerCase()
  );

  // GA - PageView
  ReactGA.pageview(
    `/event/login/${claims.company?.toLowerCase()}/${
      claims.username?.toLowerCase().split("@")[0]
    }`
  );

  return response;
};

export const verifyCredentials = async (
  credentials: Credentials
): Promise<any> => {
  const response = await axios.post("/api/v1/auth", credentials);

  const claims = getClaims(response.data.token);

  setCredentials(response, credentials.user, claims);

  return response;
};

export const loginWithRefreshToken = async (): Promise<any> => {
  const tokens = getTokens();

  if (!tokens || !tokens.refreshToken) {
    throw new Error("Você precisa estar autenticado para acessar este recurso");
  }

  const { refreshToken: token, user } = tokens;

  var response = await axios.post("/api/v1/auth/refresh", { token, user });

  const claims = getClaims(response.data.token);

  setCredentials(response, user, claims);

  return response;
};

export const forgotPassword = async (
  forgetPassword: ForgetPassword
): Promise<void> => {
  await axios.post("/api/v1/auth/forget-password", forgetPassword);
  clearTokens();
};

/*
  export const changedPassword = async (forgetPassword:ForgetPassword) : Promise<void> => {
    await axios.post(authUrl + "/v1/forget-password?change=1", forgetPassword, {
      headers: {
        'Token': 'YXPbX2F1dGhfc2VjcmV0X2tleV9ncmdpOS5jb20='
      }
    });
  }
*/

export const getUser = (): User | null => {
  const token = authCredentials.get.accessToken();
  const userAuth = authCredentials.get.authUser() ?? nullAuthUserCredentials;

  if (!token || !userAuth?.user || userAuth?.role === "MODERATION") {
    return null;
  }
  return { name: userAuth?.user };
};

export const getTokens = (): Tokens | null => {
  const refreshToken = authCredentials.get.refreshToken();
  const token = authCredentials.get.accessToken();
  const { user, firstName, lastName, role, company } =
    authCredentials.get.authUser() ?? nullAuthUserCredentials;
  const sudoToken = authCredentials.get.accessTokenSudo() || null;

  if (!token || !user || role === "MODERATION") {
    return null;
  }

  return {
    token,
    refreshToken,
    user,
    firstName,
    lastName,
    role,
    company,
    sudoToken,
  };
};

export const clearToken = () => {
  authCredentials.remove.refreshToken();
  authCredentials.remove.accessToken();
};

export const clearTokens = () => {
  authCredentials.remove.refreshToken();
  authCredentials.remove.accessToken();
  authCredentials.remove.authUser();
  authCredentials.remove.accessTokenSudo();
};

export const setSudoToken = (sudoToken: string) =>
  authCredentials.set.accessTokenSudo(sudoToken);

export const getSudoToken = () => authCredentials.get.accessTokenSudo();

export const clearSudoToken = () => authCredentials.remove.accessTokenSudo();

const setCredentials = (
  response: AxiosResponse<any>,
  user: string,
  claims: AuthUserModel
) => {
  authCredentials.set.accessToken(response.data.token);
  authCredentials.set.authUser(user, claims);
  authCredentials.set.refreshToken(response.data.refresh_token);
};
