import { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as pbi from "powerbi-client";
import { useParams } from "react-router-dom";
import useDashboardEmbed from "../../hooks/useDashboardEmbed";
import useDashboardEmbedEvent from "../../hooks/useDashboardEmbedEvents";
import { useModal } from "../utils/Modal";
import DashboardWrapper from "../ui/DashboardWrapper";
import LoadingFrame from "../ui/LoadingFrame";
import { getErrorMessage } from "../utils/Util";
import { normalizeDataSelect as ReportEvent } from "../utils/NormalizeGoogleAnalytics";
import useMarkVisitActive from "../../hooks/useMarkVisit";

const Container = styled.div`
  margin: 0px 0%;
  height: 100vh;
`;

const EmbedDashboard = styled.div`
  height: 100%;
`;

class CancelToken {
  isCancelled: boolean;
  subscribers: Array<() => void>;

  constructor() {
    this.isCancelled = false;
    this.subscribers = [];
  }

  cancel() {
    this.isCancelled = true;
    this.subscribers.forEach((fn) => fn());
  }

  subscribe(fn: () => void) {
    if (this.isCancelled) {
      fn();
      return;
    }
    this.subscribers.push(fn);
  }
}

const DashBoardPowerBI: FunctionComponent = () => {
  const { link } = useParams<any>();
  const { getEmbedDashboardByLink } = useDashboardEmbed();
  const { postDashboardEmbedEvent } = useDashboardEmbedEvent();
  const { openModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [accessId, setAccessId] = useState<number | null>(null);
  const wrapperRef = useRef<{ toggle: () => void }>(null);
  useMarkVisitActive(accessId);

  const renderPowerBiDashboard = async (
    link: any,
    cancelToken: CancelToken
  ) => {
    const { data: dashboardEmbedData } = await getEmbedDashboardByLink({
      link,
      cancelToken,
    });

    setAccessId(dashboardEmbedData.accessId);

    let config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      accessToken: dashboardEmbedData.embedToken,
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${dashboardEmbedData.relatoryId}&groupId=${dashboardEmbedData.groupRelatory}&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOmZhbHNlfX0%3d`,
      id: dashboardEmbedData.relatoryId,
      permissions: pbi.models.Permissions.All,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true,
      },
    };

    let reportContainer = document.getElementById("dashboard");

    if (!reportContainer) return;

    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    let report = powerbi.embed(reportContainer, config);

    report.on("dataSelected", (event) => {
      console.log("dataSelected", event);
      ReportEvent(event?.detail);
    });

    report.on("pageChanged", (event: any) => {
      postDashboardEmbedEvent({
        action: dashboardEmbedData.relatoryDesc,
        label: (event as any).detail.newPage.displayName,
      });
    });

    postDashboardEmbedEvent({
      action: "view",
      label: dashboardEmbedData.relatoryDesc,
    });

    cancelToken.subscribe(() => {
      if (reportContainer) powerbi.reset(reportContainer);
    });
  };

  useEffect(() => {
    const token = new CancelToken();

    const fn = async () => {
      setLoading(true);
      try {
        await renderPowerBiDashboard(link, token);
      } catch (err: any) {
        if (!token.isCancelled && !err.handled) openModal(getErrorMessage(err));
      }
      setLoading(false);
    };

    fn();

    return () => token.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, openModal]);

  return (
    <DashboardWrapper ref={wrapperRef}>
      {loading ? <LoadingFrame /> : null}
      <Container id="container">
        <EmbedDashboard id="dashboard" />
      </Container>
    </DashboardWrapper>
  );
};

export default DashBoardPowerBI;
