import axios, { AxiosError, Method } from 'axios'
import useAuth from './useAuth'
import { getTokens, clearSudoToken } from '../service/auth'
import { useCallback, useMemo } from 'react'
import { baseUrl } from '../constants'

export interface HttpRequest {
  method: Method,
  url: string,
  data?: any;
  headers?: any;
  params?: any;
  options?: {
    auth?: boolean;
  };
}

export interface HttpResponse<T> {
  data: T
}

export type Http = {
  request: <T> (request: HttpRequest) => Promise<HttpResponse<T>>
}

axios.defaults.baseURL = baseUrl;

const isAuthError = (err: any): boolean => {
  return err.response && err.response.status === 401;
}

export function isAxiosError(error: any): error is AxiosError {
  return error.response !== undefined
} 

async function request<T>(request: HttpRequest): Promise<HttpResponse<T>> {

  const { method, url, params, data, options = {}, headers = {} } = request;
  const { auth = true } = options;

  const headersToSend = { ...headers };

  if (auth) {
    const tokens = getTokens();
    
    if (!tokens) {
      throw Object.assign(new Error("Você precisa estar autenticado para acessar este recurso"), { code: 401 });
    }

    if (tokens.sudoToken) {
      headersToSend.Token = tokens.sudoToken;
    } else {
      headersToSend.Authorization = `Bearer ${ tokens.token }`;
    }

  }

  const axiosResponse = await axios.request({
    url, method, data, headers: headersToSend, params
  });

  return {
    data: axiosResponse.data
  }

}

function adjustError(error: any): { userMessage: string } {

  return { userMessage: error.response?.data?.message || "Um erro interno ocorreu" }

}

async function tryRequest<T>(
  params: { restore: () => void },
  requestParams: HttpRequest
): Promise<HttpResponse<T>> {

  const { restore } = params;
  const { options: { auth: usesAuth = true } = {} } = requestParams;

  try {

    return await request(requestParams);

  } catch (err: any) {

    const authError = isAuthError(err)

    if (!usesAuth) {
      throw adjustError(err);
    }

    if (!authError) {
      throw adjustError(err);
    }

    if (authError) {
      clearSudoToken();
    }

  }

  await restore();

  try {
    return await request(requestParams);
  } catch (err: any) {
    throw adjustError(err);
  }

}

function useHttp(): Http {

  const { restore } = useAuth()

  const request = useCallback(<T> (params: HttpRequest) => tryRequest({ restore }, params) as Promise<HttpResponse<T>>, [ restore ]);

  const http = useMemo(() => ({ request }), [ request ]);

  return http;

}

export default useHttp;