import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { mainColor } from '../../constants'

const ContainerOut = styled.div`
  background-color: write;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Dashboard = styled.div`
  width: 70%;
  margin 0 auto;
  border: 3px dotted;
  border-top: none;
`;

const Header = styled.span`
  background-color: ${ mainColor };
  position: relative;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  font-size: xx-large;
  font-weight: bold;
`;

const Topic = styled.div`
  position: relative;
`;

const Title = styled.span`
  background-color: ${ mainColor };
  position: relative;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 4%;
  color: white;
  font-size: x-large;
  font-weight: bold;
`;

const Label = styled.span`
  color: ${ mainColor };
  font-weight: bold;
`;

const PerformMarket = styled.div`
  margin: 0 4%;
`;
const IndicatorData = styled.p``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 25%;
  text-align: center;
  margin: 15px 50px;
`;

const BarChart = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-left: 1%;
  background-color: white;
`;

const BarChartContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: gray;
`;

const BarChartLabel = styled.label`
  color: gray;
  width: 180px;
  height: 40px;
  margin-right: 5px;
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translate(10px, 10px);
`;

const Table = styled.table`
  width: 94%;
  margin: 2% 0;
  border-collapse: collapse;
`;
const HeaderTable = styled.thead`
  font-weight: bold;
  text-align: left;
`;
const BodyTable = styled.tbody`
  margin-top: 2%;
  border-top: 2px solid deepskyblue;
`;
const Line = styled.tr`

:nth-child(even) {
  background: lightgray;
}

:nth-child(odd) {
  background: white;
}

`;
const Column = styled.td`

  height: 25px;
  text-align: right;

  :nth-child(1) {
    text-align: left;
  }
  :first-child {
    border-right: 2px solid deepskyblue;
  }
`;
const ContainerTable = styled.div`

  display: flex;
  place-content: center;
  border: 1px outset gray;
  margin: 1% 10%;
`;

const TableSKUs : FunctionComponent = () => {
  

  return (
    <>
      <ContainerTable>
        <Table>
          <HeaderTable>
            <Line>
              <Column style={{ width: '40%' }}>Descrição Produto</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
              <Column style={{ width: '15%' }}>13/12 - 19/12</Column>
            </Line>
          </HeaderTable>
          <BodyTable>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
              <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
            <Line>
            <Column style={{ width: '40%' }}>Arroz Agulhinha Tipo 1 Máximo 5kg</Column>
              <Column style={{ width: '15%' }}>2,5</Column>
              <Column style={{ width: '15%' }}>30,95</Column>
              <Column style={{ width: '15%' }}>0,53</Column>
              <Column style={{ width: '15%' }}>2,58</Column>
            </Line>
          </BodyTable>
        </Table>   
        {/*<LegendTable>* Top 10 skus</LegendTable>*/}
      </ContainerTable>
    </>
  )
}

const Graphic : FunctionComponent<{ label: string, value: string }> = (props) => {
  const { label, value } = props

  let style = {
    width: '100%',
    height: 'auto',
    display: 'flex',
    margin: '25px 0'
  }

  let styleLabelText = {
    text: 'right',
    paddingRight: '1%'
  }

  return (
    <>
      <PerformMarket style={style}>
        <BarChartLabel style={styleLabelText}>{label}</BarChartLabel>
          <BarChart>
            <BarChartContent style={{width: `${ value }%`}} />
          </BarChart>
        <BarChartLabel style={{width: '100px' }}>{value + '%'}</BarChartLabel>
      </PerformMarket>
    </>
  )
}

const MarketPerformanceDashboard : FunctionComponent = () => {

  let styleContentPerformMarket = {
    height: '130px',
    borderStyle: 'outset',
    borderWidth: '1.5px',
    borderColor: 'gray'
  }

  let styleContentParticipantMarket = {
    width: '40%',
    height: '270px',
    borderStyle: 'outset',
    borderWidth: '1.5px',
    borderColor: 'gray'
  }

  let styleIndicatorDataPerformMarket = {
    fontSize: 'xxx-large',
    margin: '10% 0px'
  }

  return (
    <>
      <ContainerOut>
        <Dashboard id='dashboard'>
          <Topic>
            <Header>
              Performance de Mercado
            </Header>
            <PerformMarket>
              <Row>
                <Content >
                  <Label>UF</Label>
                  <IndicatorData>SP</IndicatorData>
                </Content>
                <Content >
                  <Label>CATEGORIA</Label>
                  <IndicatorData>Arroz</IndicatorData>
                </Content>
                <Content >
                  <Label>PERIODO</Label>
                  <IndicatorData>Janeiro/2021</IndicatorData>
                </Content>
              </Row>
              <Row id='indicator' >            
                <Content id='content' style={styleContentPerformMarket} >
                  <Label >Vendas da Categoria (R$)</Label>
                  <IndicatorData style={styleIndicatorDataPerformMarket}>523,46 MI</IndicatorData>
                </Content>
                <Content id='content' style={styleContentPerformMarket} >
                  <Label >Variação - PA (%)</Label>
                  <IndicatorData style={styleIndicatorDataPerformMarket}>-72,1</IndicatorData>
                </Content>
                <Content id='content' style={styleContentPerformMarket} >
                  <Label >Variação - PY (%)</Label>
                  <IndicatorData style={styleIndicatorDataPerformMarket}>42,5</IndicatorData>
                </Content>
              </Row>  
            </PerformMarket>
          </Topic>
          <Topic>
            <Title>
              Participação de Mercado
            </Title>
            <PerformMarket>
              <Row>
                <Content id='content' style={styleContentParticipantMarket} >
                  <Label >Top 3 - Fabricantes</Label>
                  <p></p>
                  <Graphic 
                  label= 'Camil Alimentos'
                  value='28.2'/>
                  <p></p>
                  <Graphic 
                  label= 'Joaquim Alimentos'
                  value='12.0'/>
                  <p></p>
                  <Graphic 
                  label= 'Solito Alimentos'
                  value='9.5'/>
                </Content>
                <Content id='content' style={{backgroundColor: 'black', width: '1px'}} >
                  <Label ></Label>
                </Content>
                <Content id='content' style={styleContentParticipantMarket} >
                  <Label >Top 3 - Marcas</Label>
                  <p></p>
                  <Graphic 
                  label= 'Camil'
                  value='90.2'/>
                  <p></p>
                  <Graphic 
                  label= 'Tio João'
                  value='50.4'/>
                  <p></p>
                  <Graphic 
                  label= 'Solito'
                  value='9.5'/>
                </Content>
              </Row>
            </PerformMarket>
          </Topic>
          <Title>
            Preço Médio (R$) - Top 10 skus
          </Title>
          <PerformMarket>
            <TableSKUs />
          </PerformMarket>
        </Dashboard>
      </ContainerOut>
    </>   
  )
}

export default MarketPerformanceDashboard