import { createContext, FunctionComponent, useCallback, useContext, useState } from 'react'

type DialogComponent = FunctionComponent<{
  onClose: () => void
}>

interface DialogWrapper {
  id: number;
  Component: DialogComponent;
}

interface Ctx {
  openDialog: (dialog: DialogComponent) => number;
  closeDialog: (id: number) => void
}

let lastDialog = { n: 0 };

const DialogContext = createContext<Ctx>({ 
  openDialog: () => NaN,
  closeDialog: () => {}
});

export const withDialogs = (Component: FunctionComponent): FunctionComponent => {
  return props => {
    const [ { dialogs }, setState ] = useState<{ dialogs: DialogWrapper[] }>({ dialogs: [] })

    const openDialog = useCallback((Component: DialogComponent) => {
      const id = ++lastDialog.n;
      setState(prev => ({ ...prev, dialogs: [ ...prev.dialogs, { Component, id } ] }))
      return id;
    }, [ setState ]);

    const closeDialog = useCallback((id: number) => {
      setState(prev => ({ ...prev, dialogs: [ ...prev.dialogs.filter(d => d.id !== id) ]}));
    }, [ setState ]);

    const makeOnClose = (id: number) => {
      return () => closeDialog(id);
    }

    return (
      <DialogContext.Provider value={{ openDialog, closeDialog }}>
        <div className="hd-dialogs">
          {
            dialogs.map(({ Component, id }) => <Component key={ id } onClose={ makeOnClose(id) } />)
          }
        </div>
        <Component { ...props } />
      </DialogContext.Provider>
    )
  }
}

export const Dialog: FunctionComponent<{ onClose?: () => void }> = props => (
  <div className="hd-dialog-body">
    {
      props.onClose ? <div className="hd-dialog-close" onClick={ props.onClose }><i className="fa fa-times" /></div> : null
    }
    { props.children }
  </div>
)
export const DialogTitle: FunctionComponent = props => <div className="hd-dialog-title">{ props.children }</div>
export const DialogButton: FunctionComponent<{ onClick?: () => void }> = props => <button onClick={ props.onClick } className="hd-dialog-button">{ props.children }</button>

export const useDialogs = () => {
  return useContext(DialogContext);
}