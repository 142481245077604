import Cookies from "js-cookie";
import { AUTH_CREDENTIALS } from "../../../../constants";
import { AuthUserModel } from "../setCredentials";

export const nullAuthUserCredentials = {
  user: null,
  firstName: null,
  lastName: null,
  role: null,
  company: null,
  sudoToken: null,
};

const stringAuthUserCredentials = JSON.stringify(nullAuthUserCredentials);

const accessToken = () => Cookies.get(AUTH_CREDENTIALS.ACCESS_TOKEN);
const refreshToken = () =>
  window.localStorage.getItem(AUTH_CREDENTIALS.REFRESH_TOKEN);
const authUser = () =>
  JSON.parse(
    Cookies.get(AUTH_CREDENTIALS.AUTH_USER) ?? stringAuthUserCredentials
  ) as AuthUserModel;
const accessTokenSudo = () => Cookies.get(AUTH_CREDENTIALS.ACCESS_TOKEN_SUDO);

export const getCredentials = {
  accessToken,
  authUser,
  refreshToken,
  accessTokenSudo,
};
