import { FunctionComponent, useEffect, useState } from "react";
import Select from "./Select";
import Input from "./Input";
import useCheckout from "./useCheckout";
import { PaymentProfile } from "./definitions";
import { GoogleAnalyticsActions } from "../../hooks/useGoogleAnalytics";

const acceptedCreditCards: Record<string, { pattern: RegExp, name: string }> = {
  visa: { pattern: /^4[0-9]{12}(?:[0-9]{3})?$/, name: "Visa" },
  mastercard: { pattern: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/, name: "Mastercard" },
  amex: { pattern: /^3[47][0-9]{13}$/, name: "American Express" },
  discover:{ pattern:  /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/, name: "Discover" },
  diners_club: { pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, name: "Dinners Club" },
  jcb: { pattern: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/, name: "JCB" }
};

const creditCardNames: Record<string, string> = {};
Object.entries(acceptedCreditCards).map(entry => {
  creditCardNames[entry[0]] = entry[1].name;
});

function checkSupported(value: string) {
  var value = value.replace(/\D/g, '');
  
  return Object.keys(acceptedCreditCards).find(function(key) {
    var regex = acceptedCreditCards[key].pattern;
    return regex.test(value) ? true : false;
  }) ? true : false;
}

function findFlag(value: string): string | undefined {
  value = value.replace(/\D/g, '');
  return Object.keys(acceptedCreditCards).find(function(key) {
    var regex = acceptedCreditCards[key].pattern;
    return regex.test(value) ? true : false;
  });
}


function validateDv(value: string) {
  var value = value.replace(/\D/g, '');
  var sum = 0;
  var shouldDouble = false;

  for (var i = value.length - 1; i >= 0; i--) {
    var digit = parseInt(value.charAt(i));

    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  
  return (sum % 10) === 0;
}

function validateCreditCart(value: string) {
  if (!validateDv(value)) {
    return "Número de cartão inválido";
  }
  if (!checkSupported(value)) {
    return "Número de cartão inválido";
  }
  return null;
}

function validateCVV(creditCardNumber: string) {
  return (cvv: string): string | null => {
    const creditCard = creditCardNumber.replace(/\D/g, '');
    var cvv = cvv.replace(/\D/g, '');
    if ((acceptedCreditCards.amex.pattern).test(creditCard)) {
      if ((/^\d{4}$/).test(cvv)) return null;
    } else if ((/^\d{3}$/).test(cvv)) {
      return null;
    }
    return "O cvv é inválido";
  }
}

function formatCreditCardNumber(value: string) {
  let match = value.match(/^((\d{4} ){0,3}\d{0,4})$/);
  if (!match) {
    value = value.replaceAll(/[^0-9]/g, "").substring(0, 16);
  }
  match = value.match(/^(\d{4})(?: )?(\d+)$/);
  if (match) {
    value = match[1] + (match[2] ? " " + match[2] : "");
  }
  match = value.match(/^((?:\d{4} )(?:\d{4}))(?: )?(\d+)$/);
  if (match) {
    value = match[1] + (match[2] ? " " + match[2] : "");
  }
  match = value.match(/^((?:\d{4} ){2}(?:\d{4}))(?: )?(\d+)$/);
  if (match) {
    value = match[1] + (match[2] ? " " + match[2] : "");
  }
  return value
}

function formatValidate(value: string) {
  let match = value.match(/^\d{0,2}\/?\d{0,4}$/g);
  if (!match) {
    value = value.replaceAll(/[^0-9]/g, "").substring(0, 6);
  }
  match = value.match(/^(\d{2})(?:\/)?(\d+)$/);
  if (match) {
    value = match[1] + (match[2] ? "/" + match[2] : "");
  }
  return value
}

function validThruValidate(value: string) {
  const match = value.match(/^(\d{2})\/(\d{4})$/);
  if (!match) {
    return "Vencimento inválido";
  }
  const month = parseInt(match[1]);
  if (month < 1 || month > 12) {
    return "Vencimento inválido";
  }
  const now = new Date();
  const currentYear = now.getFullYear();
  const year = parseInt(match[2]);
  if (currentYear > year) {
    return "Esse cartão já está vencido";
  }
  const currentMonth = now.getMonth() + 1;
  if (currentYear === year && currentMonth > month) {
    return "Esse cartão já está vencido";
  }
  return null;
}

const PaymentStep: FunctionComponent<{ onNext: (data: { paymentProfile: PaymentProfile }) => void }> = function (props) {
  const { createPaymentProfile } = useCheckout();

  new GoogleAnalyticsActions("G-6TXX26VFFT");

  const [ state, setState ] = useState({
    creditCard: {
      number: "",
      name: "",
      validate: "",
      cvv: "",
      flag: "",
    },
    paymentProfile: null as PaymentProfile | null,
    savingPaymentProfile: false,
    savePaymentProfileError: null as string | null,
    invalid: [] as string[]
  });

  const makeOnValidate = (label: string) => {
    return (valid: boolean) => {
      setState(prev => {
        if (valid) {
          return {
            ...prev, invalid: prev.invalid.filter(i => i !== label)
          }
        }
        if (prev.invalid.includes(label)) {
          return prev;
        }
        return {
          ...prev, invalid: [ ...prev.invalid, label ]
        }
      })
    }
  }

  const onSavePaymentProfile = async function () {
    setState(prev => ({ ...prev, savingPaymentProfile: true }));
    try {
      const paymentProfile = await createPaymentProfile({
        ...state.creditCard,
        number: state.creditCard.number.replaceAll(/[^0-9]/g, "")
      });
      props.onNext({ paymentProfile });
    } catch (err) {
      setState(prev => ({ ...prev, savePaymentProfileError: "Erro ao salvar dados do cartão" }))
    }
  }

  return (
    <>
    
      <p className="h-sumw__info-text">
        Dados do Cartão de Crédito
      </p>
      {
        state.paymentProfile ? (
          <div>
            <table className="h-sumw__table h-sumw__table--credit-cards h-sumw__spacing-after h-sumw__spacing-before">
              <thead>
                <tr>
                  <th>Bandeira</th>
                  <th>Número</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr key={ state.paymentProfile.gatewayToken }>
                  <td>
                    { creditCardNames[state.paymentProfile.flag] }
                  </td>
                  <td>
                    { state.paymentProfile.creditCardLabel }
                  </td>
                  <td style={{ width: "100%" }}>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="h-sumw__payment-content">
            <div className="h-sumw__credit-card">
              <div className="h-sumw__credit-card__chip"></div>
              <div className="h-sumw__credit-card__number">
                { state.creditCard.number || "XXXX XXXX XXXX XXXX" }
              </div>
              <div className="h-sumw__credit-card__name">
                { state.creditCard.name || "NOME" }
              </div>
              <div className="h-sumw__credit-card__validate">
                { state.creditCard.validate || "MM/YYYY" }
              </div>
            </div>
            <form className="h-sumw__spacing-before">
              <div className="h-sumw__spacing-after">
                <Input
                  label="Nome"
                  placeholder="Nome que está escrito no cartão"
                  validate={ value => value ? null : "O nome é obrigatório" }
                  value={ state.creditCard.name }
                  onChange={ e => setState(prev => ({ ...prev, creditCard: {  ...prev.creditCard, name: e.target.value.toUpperCase().replaceAll(/[^A-Z ]/g, "") } })) }
                  onValidate={ makeOnValidate("name") }
                  autoComplete="cc-number"
                />
              </div>
              <div className="h-sumw__spacing-after">
                <Input
                  label="Número"
                  validate={ validateCreditCart }
                  placeholder="XXXX XXXX XXXX XXXX"
                  value={ state.creditCard.number }
                  onChange={ e => {
                    setState(prev => ({ ...prev, creditCard: { 
                      ...prev.creditCard, 
                      number: formatCreditCardNumber(e.target.value),
                      flag: findFlag(e.target.value) || ""
                    }}))
                  } }
                  onValidate={ makeOnValidate("number") }
                  autoComplete="cc-number"
                />
              </div>
              <div className="h-sumw__spacing-after">
                <Select
                  label="Bandeira do Cartão"
                  options= { Object.entries(creditCardNames).map(i => ({ key: i[0], value: i[1] })) }
                  value={ state.creditCard.flag }
                  onChange={ e => setState(prev => ({ ...prev, creditCard: {
                    ...prev.creditCard, flag: e.target.value
                  }})) }
                  validate={ value => value ? null : "A bandeira é obrigatória" }
                  onValidate={ makeOnValidate("flag") }
                />
              </div>
              <div className="h-sumw__grid h-sumw__spacing-after">
                <div className="h-sumw__col-5">
                  <Input
                    label="Vencimento"
                    placeholder="MM/YYYY"
                    value={ state.creditCard.validate }
                    onChange={ e => setState(prev => ({
                      ...prev, creditCard: { ...prev.creditCard, validate: formatValidate(e.target.value) }
                    })) }
                    validate={ validThruValidate }
                    onValidate={ makeOnValidate("validate") }
                    autoComplete="cc-exp"
                  />
                </div>
                <div className="h-sumw__col-5">
                  <Input
                    label="CVV"
                    placeholder="XXX"
                    validate={ validateCVV(state.creditCard.number) }
                    value={ state.creditCard.cvv }
                    onChange={ e => setState(prev => ({
                      ...prev, creditCard: {
                        ...prev.creditCard, cvv: e.target.value.substring(0, 4)
                      }
                    })) }
                    onValidate={ makeOnValidate("cvv") }
                    autoComplete="cc-csc"
                  />
                </div>
              </div>
            </form>
          </div>
        )
      }
      <div className="h-sumw__spacing-before">
        {
          state.paymentProfile ? (
            <>
              <button className="h-sumw__button" onClick={ () => props.onNext({ paymentProfile: state.paymentProfile as PaymentProfile }) }>
                Revisar Compra
              </button>
              &nbsp;
              <button 
                className="h-sumw__button h-sumw__button--secundary"
                onClick={ () => setState(prev => ({ ...prev, paymentProfile: null })) }>
                Usar outro cartão
              </button>
            </>
          ) : (
            <>
              <button className="h-sumw__button aaa" disabled={ state.invalid.length > 0 || state.savingPaymentProfile } onClick={ () => {GoogleAnalyticsActions.event('revisar_pgto_pag3', '', '&#8287;'); onSavePaymentProfile(); }  }>
                { state.savingPaymentProfile ? "Enviando..." : "Revisar Pagamento" }
              </button>
              &nbsp;
              {
                !state.savePaymentProfileError ? null : (
                  <>
                    <div className="h-sumw__button-side">
                      { state.savePaymentProfileError }
                    </div>
                    &nbsp;
                  </>
                )
              }
            </>
          )
        }
      </div>
    </>
  )
}

export default PaymentStep;