import React, { useState } from "react";
import { useHistory } from "react-router";
import useAccessSudo from "../../../../hooks/useAccessSudo";
import DashboardWrapper from "../../../ui/DashboardWrapper";
import ListAccessSudoModal from "../../modals/ListAccessSudoModal";
import MenuBox from "../components/boxMenu/MenuBox";
import HeaderPlataform from "../components/header/Header";
import styles from "./Styles.module.css";
import useAuth from "../../../../hooks/useAuth";
import { ClientCompany } from "../../../../model";
import { getTokens } from "../../../../service/auth";
import makeModal from "../../../utils/MakeModal";
import { useModal } from "../../../utils/Modal";
import Research from "../../../research/research";



const HomePlataform: React.FC = () => {

  const { logout } = useAuth();
  const { getClientCompanies } = useAccessSudo();
  const { openModal } = useModal();
  const tokens = getTokens();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const isHorus = ():Boolean => {
    return getTokens()?.company === "HORUS";
  }

  const wrapperRef = React.useRef<{ toggle: () => void, toggleStatus: () => any }>(null);

  const toggleMenu = () => {
    wrapperRef?.current?.toggle()
  }

  const sendForgetPassword = async () => {
    if (!tokens?.token) {
      openModal(makeModal("Erro", "Faça login novamente na plataforma."));
      return;
    }

    try {
      setLoading(true);
      history.push("/change-password/" + tokens?.token);
      logout();
    } catch (err: any) {
      openModal(
        makeModal(
          "Erro",
          err.response?.status === 500
            ? "Erro interno do Servidor"
            : err.message
        )
      );
    }

    setLoading(false);
  };

  const openModalListAccessSudo = async () => {
    try {
      let response = await getClientCompanies();
      const clientCompanies: ClientCompany[] = response.data;
      openModal(
        <ListAccessSudoModal
          clientCompany={clientCompanies.filter((x) => x.id != 1)}
        />
      );
    } catch (err: any) {
      openModal(
        makeModal(
          "Erro",
          err.response?.status === 500
            ? "Erro interno do Servidor"
            : err.message
        )
      );
    }
  };

  const toPrivacyPolicy = () => history.push("/politicas/privacidade");
  const toCookiesPolicy = () => history.push("/politicas/cookies");
  const toTermsOfUse = () => history.push("/politicas/termos-de-uso");

  const navigation = <><nav className={styles.menu}>
    <ul>
      {!isHorus() ? null :
        <li className="hd-dash-menu-item">
          <button onClick={() => { openModalListAccessSudo(); toggleMenu()} } >
            <i className="fas fa-glasses"></i> Acesso Super Usuário
          </button>
        </li>
      }
      <li className="hd-dash-menu-item">
        <button onClick={ toPrivacyPolicy }>
          <i className="fas fa-user-secret"></i> Política de Privacidade
        </button>
      </li>
      <li className="hd-dash-menu-item">
        <button onClick={ toTermsOfUse }>
          <i className="fas fa-scroll"></i> Termos de Uso
        </button>
      </li>
      <li className="hd-dash-menu-item">
        <button onClick={ toCookiesPolicy }>
          <i className="fas fa-cookie-bite"></i> Política de Cookies
        </button>
      </li>
      <li className="hd-dash-menu-item">
        <button onClick={sendForgetPassword}>
          <i
            className={`fas ${
              !loading ? "fa-key" : "fa-spinner fa-pulse"
            }`}
          />{" "}
          Trocar a senha{" "}
        </button>
      </li>
      <li className="hd-dash-menu-item">
        <button onClick={logout}>
          <i
            className={`fas ${
              !loading ? "fa-sign-out-alt" : "fa-spinner fa-pulse"
            }`}
          />{" "}
          Sair{" "}
        </button>
      </li>
    </ul>
  </nav></>;


    return (<>
      <div id="HomeDashboard">
          <DashboardWrapper title="Market Share por Segmento" ref={wrapperRef}  showHeader={false} navigation={navigation} >
            <div className={`${styles.content}`} >
              <HeaderPlataform  />
              <MenuBox />
              <Research />
            </div>
          </DashboardWrapper>
      </div>
    </>);
}
  
export default HomePlataform
  