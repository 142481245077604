import React, {
  ComponentProps,
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { GoogleAnalyticsActions } from "../../../hooks/useGoogleAnalytics";
import useTitle from "../../../hooks/useTitle";
import { maskPhone } from "../../utils/Mask";
import { getErrorMessage } from "../../utils/Util";
import Modal from "../modals/Modal";
import { AuxiliaryNav } from "./components/AuxiliaryNav/AuxiliaryNav";
import { FGVHeader } from "./components/Header/FVGHeader";
import { FgvFaq } from "./components/section/FgvFaq/FgvFaq";
import { FgvFooter } from "./components/section/FgvFooter/FgvFooter";
import { FgvHero } from "./components/section/FgvHero/FgvHero";
import { FgvIBRE } from "./components/section/FgvIBRE/FgvIBRE";
import { FgvPricing } from "./components/section/FgvPricing/FgvPricing";
import { MediaCarousel } from "./components/section/MediaCarousel/MediaCarousel";
import { FGVBackgroundPage } from "./components/svg/FVGBackgroundPage";
import "./FgvPage.css";
import useFgv from "./useFgv";

const AvailableCategoriesModal: FunctionComponent<
  ComponentProps<typeof Modal>
> = function (props) {
  return (
    <Modal {...props}>
      <div className="hd-fgv__categories-list">
        <h2 className="hd-fgv__title">Categorias Disponíveis</h2>
        <ul>
          <li>Achocolatado/Modificador</li>
          <li>Açúcar</li>
          <li>Água Mineral</li>
          <li>Água Sanitária</li>
          <li>Amaciantes para Roupa</li>
          <li>Arroz</li>
          <li>Azeite</li>
          <li>Batata Congelada</li>
          <li>Biscoitos</li>
          <li>Bovino</li>
          <li>Café em Pó e em Grãos</li>
          <li>Cerveja</li>
          <li>Chocolate</li>
          <li>Creme de Leite</li>
          <li>Creme Dental</li>
          <li>Desodorante</li>
          <li>Detergente Líquido</li>
          <li>Enlatados e Conservas</li>
          <li>Farinha de Mandioca</li>
          <li>Farinha de Trigo</li>
          <li>Fubá e Farinhas de Milho</li>
          <li>Feijão</li>
          <li>Frango</li>
          <li>Frutas (Banana)</li>
          <li>Frutas (Laranja)</li>
          <li>Frutas (Maçã)</li>
          <li>Hambúrguer</li>
          <li>Iogurte</li>
          <li>Legumes (Batata Inglesa)</li>

          <li>Legumes (Cebola)</li>
          <li>Legumes (Cenoura)</li>
          <li>Leite Condensado</li>
          <li>Leite em Pó</li>
          <li>Leite UHT</li>
          <li>Linguiça</li>
          <li>Manteiga</li>
          <li>Margarina</li>
          <li>Massas Alimentícias</li>
          <li>Massas Instantâneas</li>
          <li>Molho de Tomate</li>
          <li>Óleo</li>
          <li>Ovos</li>
          <li>Pão</li>
          <li>Papel Higiênico</li>
          <li>Queijos</li>
          <li>Refrigerante</li>
          <li>Requeijão</li>
          <li>Sabão para Roupa</li>
          <li>Sabonete</li>
          <li>Shampoo</li>
          <li>Snacks e Salgadinhos</li>
          <li>Suco Pronto</li>
          <li>Suíno</li>
          <li>Verduras (Alface)</li>
          <li>Verduras (Couve)</li>
          <li>Verduras (Repolho)</li>
          <li>Vinho</li>
        </ul>
      </div>
    </Modal>
  );
};

const RegisterModalWithoutRef: ForwardRefRenderFunction<
  {
    setEmail: (email: string) => void;
    focusEmail: () => void;
    focusFirstName: () => void;
  },
  ComponentProps<typeof Modal>
> = function (props) {
  const { register } = useFgv();
  const { open } = props;

  const [state, setState] = useState({
    user: {
      email: "",
      name: "",
      phoneNumber: "",
    },
    error: undefined as undefined | string,
    loading: false,
  });

  useEffect(
    function () {
      if (!open)
        setState((prev) => ({
          ...prev,
          error: undefined,
          loading: false,
          user: {
            email: "",
            name: "",
            phoneNumber: "",
          },
        }));
    },
    [setState, open]
  );

  const onFormSubmit = async function (e: React.FormEvent) {
    e.preventDefault();

    try {
      setState((prev) => ({ ...prev, loading: true, error: undefined }));
      await register({ ...state.user });
      props.onClose?.();
      window.scrollTo(0, 0);
      window.location.href = "/plus";
    } catch (e) {
      setState((prev) => ({
        ...prev,
        loading: false,
        error: getErrorMessage(e),
      }));
    }
  };

  const disabled =
    !state.user.email ||
    !state.user.name ||
    !state.user.phoneNumber ||
    state.loading;

  return (
    <Modal {...props}>
      <h1>Faça o seu cadastro</h1>
      <p className="hd-fgv__register__text">
        Veja informações mais completas de categorais da cesta do consumidor
      </p>

      <form className="hd-fgv__register__form" onSubmit={onFormSubmit}>
        <div className="input-container">
          <label>Nome completo</label>
          <input
            type="text"
            value={state.user.name}
            autoFocus
            onChange={(e) =>
              setState((prev) => ({
                ...state,
                user: { ...prev.user, name: e.target.value },
              }))
            }
            className="hd-fgv__register__input"
          />
        </div>
        <div className="input-container">
          <label>E-mail corporativo</label>
          <input
            type="text"
            value={state.user.email}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                user: { ...prev.user, email: e.target.value },
              }))
            }
            className="hd-fgv__register__input"
          />
        </div>

        <div className="input-container">
          <label>Telefone</label>
          <input
            type="text"
            value={state.user.phoneNumber}
            onChange={(e) => {
              let phone: string = maskPhone(e.target.value) || "";

              setState((prev) => ({
                ...state,
                user: { ...prev.user, phoneNumber: phone },
              }));
            }}
            className="hd-fgv__register__input"
          />
        </div>
        <button
          className="hd-fgv__register__btn"
          onClick={() => GoogleAnalyticsActions.event("finalizou_cadastro", "")}
          disabled={disabled}
        >
          {state.loading ? "Carregando..." : "Entrar"}
        </button>

        {!state.error ? null : (
          <div
            style={{ marginTop: ".5em", fontWeight: "bolder", color: "red" }}
          >
            {state.error}
          </div>
        )}
      </form>
    </Modal>
  );
};
const RegisterModal = forwardRef(RegisterModalWithoutRef);

type Props = {
  fullPage?: boolean;
  children?: React.ReactNode;
};

const FgvPage = function ({ children, fullPage = false }: Props) {
  useTitle("HORUS & FGV IBRE");

  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [availableCategoriesModalOpen, setAvailableCategoriesModalOpen] =
    useState(false);

  const registerFormRef = useRef<ElementRef<typeof RegisterModal>>(null);

  const tagging = new GoogleAnalyticsActions("G-SX5Z63SCPY");

  const onShowCategories = () => {
    GoogleAnalyticsActions.event("botao_cat_disponiveis", "cestaconsumo", "ㅤ");
    setAvailableCategoriesModalOpen(true);
  };

  useEffect(() => {
    if (window.location.hash === "#cadastro") {
      registerFormRef.current?.focusEmail?.();
      tagging.eventName("plano_plus");
      setRegisterModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <div className="hd-fgv">
      <RegisterModal
        ref={registerFormRef}
        open={registerModalOpen}
        onClose={() => setRegisterModalOpen(false)}
      />
      <AvailableCategoriesModal
        open={availableCategoriesModalOpen}
        onClose={() => setAvailableCategoriesModalOpen(false)}
      />
      <FGVBackgroundPage className="fgv-bg" />
      <FGVHeader />
      <FgvHero {...{ fullPage }} />
      <AuxiliaryNav {...{ fullPage }} />
      <section className="fgv-section">
        <div className="fgv-container_hd-fgb">{children}</div>
      </section>
      <FgvIBRE {...{ fullPage, onShowCategories }} />
      <FgvPricing />
      <MediaCarousel {...{ fullPage }} />
      <FgvFaq {...{ fullPage }} />
      <FgvFooter />
    </div>
  );
};

export default FgvPage;
