import axios, { Method } from 'axios'
import useAuth from './useAuth'
import { getTokens, clearSudoToken } from '../service/auth'
import { useCallback } from 'react'
import { baseUrl } from '../constants'

interface RequestParams {
  data?: any;
  headers?: any;
  pathParams?: any;
  params?: any;
  options?: {
    auth?: boolean;
  };
}

axios.defaults.baseURL = baseUrl;

const isAuthError = (err: any): boolean => {
  return err.response && err.response.status === 401;
}

const doRequest = async <T extends any>(method: Method, url: string, requestParams: RequestParams): Promise<T> => {
  const { pathParams, params, data, options = {}, headers = {} } = requestParams;
  const { auth = true } = options;

  if (pathParams) {
    Object.entries(pathParams).forEach(entry => {
      url = url.replace(`{${entry[0]}}`, String(entry[1]));
    });
  }

  const headersToSend = { ...headers };

  if (auth) {
    const tokens = getTokens();
    
    if (!tokens) {
      throw Object.assign(new Error("Você precisa estar autenticado para acessar este recurso"), { code: 401 });
    }

    if (tokens.sudoToken) {
      headersToSend.Token = tokens.sudoToken;
    } else {
      headersToSend.Authorization = `Bearer ${ tokens.token }`;
    }

  }

  return await axios.request({
    url, method, data, headers: headersToSend, params
  });
}

interface HttpResponse<T> {
  data: T
}

const useRequest = <T extends any = any> (method: Method, url: string) : (params?: RequestParams) => Promise<HttpResponse<T>> => {
  const { restore } = useAuth()

  return useCallback(async (params = {}) => {

    const { options: { auth = true } = {} } = params;

    try {
      return await doRequest(method, url, params)

    } catch (err) {
      if ( !auth || !isAuthError(err) ) {
        throw err;
      }

      if (isAuthError(err)) {
        clearSudoToken();
      }
    }

    await restore();

    return await doRequest(method, url, params);

  }, [ method, url, restore ]);
};

export default useRequest;