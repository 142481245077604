import { Dashboard, PowerBIDashboard, StaticDashboard } from "../../model";

export const validPassword = (password:string) => {
	const regex = /(?=.*[!@#$%¨&*()//*-+_{}\[\];<>?/\|'"])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}/gm;
	let m = regex.exec(password)

	return (m !== null && m.length > 0)
} 

export function keyBy<T>(arr: Array<T>, keyBy: keyof T): Record<string, T> {
  const map: Record<string, T> = {};
  arr.forEach(i => {
    const key = String(i[keyBy]);
    if (!map[key]) {
      map[key] = i;
    }
  });
  return map;
}

export function parseQuery(queryString: string) {
  var query: Record<string, string> = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function currencyFormat(n: number): string {
  return n.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export const validEmail = (email:string) => {
	return email.match(/^[a-zA-Z0-9-_+]+(\.[a-zA-Z0-9-_+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/);
}

export const verifyEmptyFields = (obj:Object):Object | boolean => {
	let a = 0;
	Object.entries(obj).forEach(([key, value]) => {
		if (value !== undefined && value.length === 0) {
			a++;
		}
	});
	return (a > 0) ? false : obj;
}

export const parseFromUTF8 = (val: string | null | undefined) => {
	return val;
}

export function zeroPad(num: number, numZeros: number) {
  var n = Math.abs(num);
  var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
  var zeroString = Math.pow(10,zeros).toString().substr(1);
  if (num < 0) {
      zeroString = '-' + zeroString;
  }
  return zeroString+n;
}

export const validCPF = (cpf:any)  => {
  if (typeof cpf !== "string") return false
  cpf = cpf.replace(/[\s.-]*/igm, '')
  if (
      !cpf ||
      cpf.length != 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999" 
  ) {
      return false
  }
  var soma = 0
  var resto
  for (var i = 1; i <= 9; i++) 
      soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11))  resto = 0
  if (resto != parseInt(cpf.substring(9, 10)) ) return false
  soma = 0
  for (var i = 1; i <= 10; i++) 
      soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11))  resto = 0
  if (resto != parseInt(cpf.substring(10, 11) ) ) return false
  return true
}

export const validCNPJ = (cnpj:string) => {
	if (!cnpj) {
		return false;
	}
	cnpj = cnpj.replace(/[^\d]+/g,'');
 
	if(cnpj === '')
		return false;
		
	if (cnpj.length != 14) {
		return false;
	}

	if (cnpj === "00000000000000" || cnpj === "11111111111111" ||
			cnpj === "22222222222222" || cnpj === "33333333333333" ||
			cnpj === "44444444444444" || cnpj === "55555555555555" ||
			cnpj === "66666666666666" || cnpj === "77777777777777" ||
			cnpj === "88888888888888" || cnpj === "99999999999999" )
		return false;
				
	let length:number;
	let numbers:any;
	let digits:any;
	let sum:number;
	let pos:number;
	let result:number;

	length = cnpj.length - 2
	numbers = cnpj.substring(0,length);
	digits = cnpj.substring(length);
	sum = 0;
	pos = length - 7;
	for (let i = length; i >= 1; i--) {
		sum += numbers.charAt(length - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	result = sum % 11 < 2 ? 0 : 11 - sum % 11;

	if (result != digits.charAt(0))
		return false;
				
	length = length + 1;
	numbers = cnpj.substring(0,length);
	sum = 0;
	pos = length - 7;
	for (let i = length; i >= 1; i--) {
		sum += numbers.charAt(length - i) * pos--;
		if (pos < 2)
			pos = 9;
	}
	result = sum % 11 < 2 ? 0 : 11 - sum % 11;

	return (result == digits.charAt(1));
    
}

export const times = <T extends Object>(n: number, callback: (n: number) => T) : T[] => {
	const list : T[] = [];
	for (var i = 0; i < n; i++) {
		list.push(callback(i));
	}
	return list;
}

export const getRandomColor = () : string => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

type NoUndefined<T> = T extends undefined ? never : T;

type OnlyUndefined<T> = T extends undefined ? undefined : never;

type MapNullToUndefined<T> = T extends null ? undefined : T;

class Optional<T> {

  private val: T;

  constructor(val: T) {
    this.val = val;
  }

  map<R>(fn: (val: NoUndefined<T>) => R): Optional<R | OnlyUndefined<T>> {
    if (this.val === undefined) {
      return this as any;
    }
    return new Optional<any>(fn(this.val as any));
  }

  mapAnyway<R>(fn: (val: T) => R): Optional<R> {
    return new Optional<R>(fn(this.val));
  }

  mapNullToUndefined(): Optional<MapNullToUndefined<T>> {
    if (this.val !== null) {
      return this as any;
    }
    return new Optional<any>(undefined);
  }

  mapIfEmpty(fn: () => T): Optional<T> {
    if (this.val !== undefined) {
      return this;
    }
    return new Optional(fn());
  }

  filter(fn: (val: T) => boolean): Optional<T | undefined> {
    if (this.val === undefined) {
      return this;
    }
    if (!fn(this.val)) {
      return new Optional<T | undefined>(undefined);
    }
    return this;
  }

  ifPresent(fn: (val: T) => void): Optional<T> {
    if (this.val !== undefined) {
      fn(this.val);
    }
    return this;
  }

  ifEmpty(fn: () => void): Optional<T> {
    if (this.val === undefined) {
      fn();
    }
    return this;
  }

  anyWay(fn: (val?: T) => void): Optional<T> {
    fn(this.val);
    return this;
  }

  get(): T {
    return this.val;
  }

  getOrDefault<O>(other: O): NoUndefined<T> | O {
    return (this.val !== undefined ? this.val : other) as any;
  }

}

export const optional = <T extends any>(val: T) : Optional<T> => {
  return new Optional(val);
}

export const arraysEqual = (a: any[], b: any[]) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;
  
	// If you don't care about the order of the elements inside
	// the array, you should sort both arrays here.
	// Please note that calling sort on an array will modify that array.
	// you might want to clone your array first.
  
	for (var i = 0; i < a.length; ++i) {
	  if (a[i] !== b[i]) return false;
	}
	return true;
}

export const floatCompare = (a: number, b: number, tolerance: number) => {
  const diff = a - b;
  if (Math.abs(diff) <= tolerance) {
    return 0;
  }
  return diff < 0 ? -1 : 1;
}

export const classMap = (map: { [key: string]: boolean }) => {
  return Object.entries(map).filter(i => i[1]).map(i => i[0]).join(" ");
}

export const isPowerBIDashboard = (dashboard: Dashboard): dashboard is PowerBIDashboard => {
  return (dashboard as PowerBIDashboard).link !== undefined;
}

export const isStaticDashboard = (dashboard: Dashboard): dashboard is StaticDashboard => {
  return (dashboard as PowerBIDashboard).link === undefined;
}

const linkMap: { [key: string]: string } = {
  professional_dash: "/app/dashboards/professional",
  market_share_per_segment: "/app/dashboards/market-share-per-segment",
  price: "/app/dashboards/price",
}

export const makeUrl = (dashboard: Dashboard): string | undefined => {
  if (isStaticDashboard(dashboard)) {
    return linkMap[dashboard.id];
  }
  if (isPowerBIDashboard(dashboard)) {
    return `/app/dashboards/embed/${dashboard.link}`;
  }
}

export const repeat = <T extends any>(times: number, fn: (index: number) => T) => {
  const result: T[] = [];
  for (let i = 0; i < times; i++) {
    result.push(fn(i));
  }
  return result;
}

export const overrideNulls = <T extends Object>(a: T, b: T): T => {
  const result: any = { ...a };
  Object.entries(b).forEach(entry => {
    if (entry[1] !== null) {
      result[entry[0]] = entry[1];
    }
  });
  return result as T;
}

export const liquidCategories: number[] = [
  9, 292, 26, 81, 136, 157, 158, 173, 174, 181, 175, 176, 179, 180, 177, 182, 183, 184, 185, 186, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 159, 291, 44, 160, 36, 37, 40, 41, 42, 43, 33, 47, 48, 49, 50, 51, 53, 60, 70, 71, 75, 103, 105, 115, 120, 141, 178, 187, 200, 202, 205, 211, 213, 577, 214, 217, 222, 226, 461, 462, 463, 464, 34, 104, 107, 108, 113, 114, 116, 119
];


export class CancelToken {
  isCancelled: boolean;
  subscribers: Array<() => void>;

  constructor() {
    this.isCancelled = false;
    this.subscribers = [];
  }

  cancel() {
    this.isCancelled = true;
    this.subscribers.forEach(fn => fn());
  }

  subscribe(fn: () => void) {
    if (this.isCancelled) {
      fn();
      return;
    }
    this.subscribers.push(fn);
  }

}

export function getErrorMessage(e: any) {

  if (e?.message === 'Network Error') {
    return "Houve um problema de comunicação entre seu computador e a Horus. Verifique sua internet. Caso persistir entre em contato com o administrador da sua rede.";
  }

  if (e?.response?.data?.message) {
    return e?.response?.data?.message;
  }

  if (e?.response?.status === 400) {
    return "Confira os dados e tente novamente.";
  }

  if (e?.response?.status === 401) {
    return "Usuário e senha inválidos.";
  }

  if (e?.response?.status === 403) {
    return "Você não tem autorização para realizar a ação.";
  }

  if (e?.response.data.code === "OUT_OF_WORK_TIME_EXCEPTION") {
      return null;
  }

  return "Um erro não esperado ocorreu.";

}