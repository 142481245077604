import ReactGA from "react-ga";
export class GoogleAnalyticsActions {
  static registerEvent = "";

  constructor(private readonly ga: string) {
    ReactGA.initialize(ga, { debug: false });
  }

  public static event(
    event: string,
    user: string,
    params: string = GoogleAnalyticsActions.registerEvent
  ): void {
    try {
      if(user.toString().match('HORUS')) {
        return;
      }
      this.googleTAG(event, user, params)
    } catch (error) {
      console.log(error)
    }
  }

  public eventName(eventName: any) {
    GoogleAnalyticsActions.registerEvent = eventName;
  }

  private static googleTAG(event: string, user: string, params: string) {
    try {
      // @ts-ignore
      function gtag() {
        // @ts-ignore
        dataLayer.push(arguments);
      }
      // @ts-ignore
      gtag('event', event, {
        'event_category': params,
        'event_label': user,
      });
    } catch (error) {}


  }
}
