import jzImg from './assets/profileImg/jz-layer.png'
import lzImg from './assets/profileImg/lzImg.png'
import grImg from './assets/profileImg/grImg.png'
import btImg from './assets/profileImg/btImg.png'
import nlImg from './assets/profileImg/nlImg.png'
import vmImg from './assets/profileImg/vmImg.png'
import jgImg from './assets/profileImg/jgImg.png'
import ahImg from './assets/profileImg/ahImg.png'
import ppImg from './assets/profileImg/ppImg.png'
import raImg from './assets/profileImg/raImg.png'
import rgImg from './assets/profileImg/rgImg.png'

const profile: any  =  {
	"jaziel.leite": {
		"name": "Jaziel Leite",
		"position": "CTO",
		"phone": "+5521971432337",
		"profileImg": jzImg
	},
	"luiza.zacharias": {
		"name": "Luiza Zacharias",
		"position": "Head Parcerias / Negócios",
		"phone": "+5521988665445",
		"profileImg": lzImg
	},
	"gustavo.rebello": {
		"name": "Gustavo Rebello",
		"position": "CEO",
		"phone": "+5521979605807",
		"profileImg": grImg
	},
	"bruno.touguinho": {
		"name": "Bruno Touguinho",
		"position": "Pessoas & Felicidade",
		"phone": "+5521992576865",
		"profileImg": btImg
	},
	"natalia.lins": {
		"name": "Natalia Lins",
		"position": "Financeiro / Administrativo",
		"phone": "+5521970227828",
		"profileImg": nlImg

	},
	"vinicius.mendes": {
		"name": "Vinícius Mendes",
		"position": "CDO",
		"phone": "+5521967436411",
		"profileImg": vmImg
	},
	"joao.goncalves": {
		"name": "João Gonçalves",
		"position": "CSO",
		"phone": "+5521979770110",
		"profileImg": jgImg
	},
	"alexandre.coelho": {
		"name": "Alexandre Henrique",
		"position": "Head of Growth",
		"phone": "+5521986362722",
		"profileImg": ahImg
	},
	"pedro.pinto":{
		"name": "Pedro Pinto",
		"position": "Líder Data Expansion",
		"phone": "+5521993240396",
		"profileImg": ppImg
	},
	"roberta.atherino":{
		"name": "Roberta Atherino",
		"position": "Head Produto",
		"phone": "+5521996369118",
		"profileImg": raImg
	},
	"rogerio.garber":{
		"name": "Rogério Garber",
		"position": "Diretor de Estatística",
		"phone": "+5521993237237",
		"profileImg": rgImg
	}
}

export default profile