import { SVGProps } from "react";

export const HexagonBG = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="108"
    viewBox="0 0 44 48"
    fill="none"
    {...props}
  >
    <path
      d="M18.5483 0.956424C20.6894 -0.257166 23.3106 -0.257166 25.4517 0.956424L32.875 5.16395L40.2305 9.48895C42.352 10.7364 43.6626 13.0064 43.6822 15.4675L43.75 24L43.6822 32.5325C43.6626 34.9936 42.352 37.2636 40.2305 38.511L32.875 42.8361L25.4517 47.0436C23.3106 48.2572 20.6894 48.2572 18.5483 47.0436L11.125 42.8361L3.76953 38.511C1.64797 37.2636 0.33739 34.9936 0.317827 32.5325L0.25 24L0.317827 15.4675C0.33739 13.0064 1.64797 10.7364 3.76953 9.48895L11.125 5.16395L18.5483 0.956424Z"
      fill="#F0000B"
    />
  </svg>
);
