import { FunctionComponent } from "react";
import { FGVProduct, ProductWithPrice } from "./definitions";

const ReviewFGV: FunctionComponent<{ product: ProductWithPrice<FGVProduct> }> = function (props) {
  return (
    <div className="h-sumw__spacing-after h-sumw__spacing-before">
      <h3 className="h-sumw__sub-title-2">Categorias</h3>
      <table className="h-sumw__table h-sumw__spacing-after">
        <tbody>
          {
            props.product.product.categories.map(category => (
              <tr key={ category.id }>
                <td>
                  <span className="h-sumw__uf-name">
                    <b>{ category.name }</b> { (category.hasHistory ? " (com histórico da categoria)" : " (sem histórico da categoria)" ) }
                  </span>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default ReviewFGV;