import { Dashboard } from "../components/domain/Dashboard";
import { CancelToken, getErrorMessage } from "../components/utils/Util";
import useRequest from "../hooks/useRequest";
import useFetch, { HttpResponse } from "./useFetch";
import { useModal } from "../components/utils/Modal";
import ModalTitle from "../components/ui/ModalTitle";

interface EmbedDashboard {
  accessId: number;
  accessToken: string;
  embedToken: string;
  groupRelatory: string;
  relatoryDesc: string;
  relatoryId: string;
}

interface GetEmbedDashboardByLinkRequestResult {
  accessId: number;
  id: number;
  reportBiId: string;
  groupBiId: string;
  datasetBiId: string;
  link: string;
  roleBi: string;
  customData: string;
  description: string;
  embedToken: string;
  accessToken: string;
}

const useDashboardEmbed = () => {
  const makeModal = (
    title: string,
    message: string,
    instances: Array<{ name: string; url: string }> | null
  ): JSX.Element => {
    return (
      <>
        <ModalTitle>{title}</ModalTitle>
        <p style={{ marginTop: "20px", textAlign: "center" }}>
          {message}{" "}{instances && <a href={instances[0].url}>{instances[0].name}</a>}
        </p>
      </>
    );
  };

  const { openModal } = useModal();
  const dashboardEmbedRequest = useRequest("POST", "/embed/info");
  const fetch = useFetch();

  const getDashboardEmbed = async (dashboard: Dashboard): Promise<any> => {
    let data = {
      id: dashboard.id,
      reportBiId: dashboard.reportBiId,
      groupBiId: dashboard.groupBiId,
      datasetBiId: dashboard.datasetBiId,
      description: dashboard.description,
      roleBi: dashboard.roleBi,
      menu: dashboard.menu,
    };
    return dashboardEmbedRequest({ data });
  };

  const getEmbedDashboardByLink = async (params: {
    link: string;
    cancelToken?: CancelToken;
  }): Promise<{ data: EmbedDashboard }> => {
    try {
      const response = (await fetch({
        method: "GET",
        url: `/api/v1/dashboard/embed/token/${params.link}`,
        cancelToken: params.cancelToken,
      })) as HttpResponse<GetEmbedDashboardByLinkRequestResult>;
      const data = response.data;

      return {
        data: {
          accessId: data.accessId,
          accessToken: data.accessToken,
          embedToken: data.embedToken,
          groupRelatory: data.groupBiId,
          relatoryDesc: data.description,
          relatoryId: data.reportBiId,
        },
      };
    } catch (e: any) {

      if (e.response?.data?.code === "OUT_OF_WORK_TIME_EXCEPTION") {
        const exceptionData = e.response.data;
        const instances = exceptionData.instances;
        const errorMessage = exceptionData.message;

        e.handled = true;

        openModal(makeModal("Informação", errorMessage, instances));

      } else if (e.response?.data?.message) {

        e.handled = true;

        openModal(makeModal("Mensagem", getErrorMessage(e), null));

      }

      throw e;

    }
  };

  return {
    getDashboardEmbed,
    getEmbedDashboardByLink,
  };
};

export default useDashboardEmbed;
