import {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  FormEvent,
  useContext,
} from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import useFetch, { isUnhandled } from "../../../hooks/useFetch";
import {
  clearOnRecaptchaLoaded,
  executeRecaptcha,
  loadRecaptcha,
  onRecaptchaLoaded,
  showRecaptcha,
  resetRecaptcha,
} from "../../../service/recaptcha";
import { maskPhone } from "../../utils/Mask";
import { useModal } from "../../utils/Modal";
import { FgvUser } from "./definitions";
import FgvContext from "./FgvContext";
import FgvMenu from "./FgvMenu";
import useFgv from "./useFgv";

interface State {
  allFilled: boolean;
  loading: boolean;
  recatpcha: "loading" | "ok" | "fail";
}

const defaultState: State = {
  allFilled: false,
  loading: false,
  recatpcha: "loading",
};

const Message: FunctionComponent = ({ children }) => (
  <div style={{ marginTop: "70px", textAlign: "center" }}>{children}</div>
);

const FgvRegisterPage: FunctionComponent = function () {
  const { fgvUser, register } = useFgv();
  const { openModal } = useModal();
  const inputFullName = useRef<HTMLInputElement>(null);
  const inputEmail = useRef<HTMLInputElement>(null);
  const inputPhone = useRef<HTMLInputElement>(null);

  const fetch = useFetch();
  const history = useHistory();
  const [{ allFilled, loading, recatpcha }, setState] =
    useState<State>(defaultState);
  const recaptchaDiv = useRef<HTMLDivElement>(null);

  // # Recaptcha
  useEffect(() => {
    loadRecaptcha();

    if (!recaptchaDiv.current) return;

    // Every time recaptcha is rendered, it should be in a different DOM node
    const div = document.createElement("div");
    recaptchaDiv.current.appendChild(div);

    const ids: any = { listenerId: null, recaptchaId: null };

    ids.listenerId = onRecaptchaLoaded(() => {
      if (!recaptchaDiv.current) return;

      ids.recaptchaId = showRecaptcha(div, {
        onSuccess: () => setState((prev) => ({ ...prev, recatpcha: "ok" })),
        onFail: () => setState((prev) => ({ ...prev, recatpcha: "fail" })),
      });

      executeRecaptcha(ids.recaptchaId);
    });

    return () => {
      clearOnRecaptchaLoaded(ids.listenerId);
      resetRecaptcha(ids.recaptchaId);
      div.remove();
    };
  }, []);

  // # Logged users shouldn't be here
  useEffect(() => {
    if (fgvUser) history.replace("/");
  }, [fgvUser]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!allFilled) {
      openModal(<Message>Todos os campos são obrigatórios!</Message>);
      return;
    }

    const data: FgvUser = {
      name: inputEmail.current?.value as string,
      email: inputEmail.current?.value as string,
      phoneNumber: inputPhone.current?.value as string
    };

    if (!data.email.match(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i)) {
      openModal(<Message>O e-mail é inválido</Message>);
      return;
    }

    try {
      setState((prev) => ({ ...prev, loading: true }));
      register(data);
    } catch (e) {
      setState((prev) => ({ ...prev, loading: false }));
      if (isUnhandled(e))
        openModal(<Message>Ocorreu um erro ao fazer o cadastro</Message>);
    }
  };

  const onChange = (event: any) => {
    setState((prev) => ({
      ...prev,
      allFilled: [inputFullName.current?.value, inputEmail.current?.value, maskPhone(inputPhone.current?.value)].find(
        (i) => i === undefined
      )
        ? false
        : true,
    }));
  };

  return (
    <>
      <FgvMenu fgvUser={fgvUser} urlToPlans="/#planos" urlToSignUp="/#cadastro"/>
      <div className="fgvr">
        <div className="fgvr__center">
          <form className="fgvr__form" onSubmit={onSubmit}>
            <p>Cadastre-se para ver os preços por categoria!</p>
            <div className="fgvr__input-wrapper">
              <label className="fgvr__label" htmlFor="fgvr__input__full-name">
                Nome Completo
              </label>
              <input
                id="fgvr__input__full-name"
                placeholder="Nome Completo"
                type="text"
                className="fgvr__input"
                ref={inputFullName}
                onChange={onChange}
              />
            </div>

            <div className="fgvr__input-wrapper">
              <label className="fgvr__label" htmlFor="fgvr__input__email">
                E-Mail
              </label>
              <input
                id="fgvr__input__email"
                placeholder="E-Mail"
                type="text"
                className="fgvr__input"
                ref={inputEmail}
                onChange={onChange}
              />
            </div>

            <div className="fgvr__input-wrapper">
              <label className="fgvr__label" htmlFor="fgvr__input__phone">
                Telefone
              </label>
              <input
                id="fgvr__input__phone"
                placeholder="Telefone"
                type="text"
                value={maskPhone(inputPhone.current?.value)}
                className="fgvr__input"
                ref={inputPhone}
                onChange={onChange}
              />
            </div>
            <div ref={recaptchaDiv} />
            {recatpcha === "fail" ? (
              "Erro na verificação de robô"
            ) : (
              <button
                className="fgvr__btn"
                type="submit"
                disabled={loading || recatpcha === "loading"}
              >
                {loading ? "Carregando..." : "Enviar"}
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default FgvRegisterPage;
