import React from "react";
import styles from './styles.module.css'
import logo from '../../assets/img/logo-horus-w.png'
import qrCodeHorusLogo from './assets/iconImg/qrCodeHorusLogo.png'
import iconPhone from './assets/iconImg/phone.png';
import iconEmail from './assets/iconImg/email.png';
import iconAdd from './assets/iconImg/add.png';
import iconWhatsapp from './assets/iconImg/whatsappIcon.png';
import { useParams } from "react-router-dom";
import Page404 from "../Page404/Page404";
import profile from "./teams";
import { QRCodeSVG } from 'qrcode.react';


const BusinessCard: React.FC = () => {

    const { user }: any = useParams();
    const QRCodeValue: any = user ? "https://team.ehorus.com.br/" + user.toString() : null;
    const emailSuffix = "@ehorus.com.br";

    if(!profile[user?.toString()])  return <Page404 />

    const { 
        name, 
        position, 
        phone,
        profileImg
    } =  profile[user]

    return  <>

        <div className={styles.wrapdbs}>
            <header>
                <h1>
                <a href="https://www.ehorus.com.br"><img src={logo} alt="" /></a>
                </h1>
            </header>

            <main>
                <div>
                    <img height="150px" src={profileImg} alt="" />

                    <h2>{name}</h2>
                    <p>{position}</p>

                    <ul>
                        <li><a href={`tel:${phone}`}><img src={iconPhone} alt=""  />{phone}</a></li>
                        <li><a href={`mailto:${user?.toString()}${emailSuffix}`}> <img src={iconEmail} alt="" /> {`${user?.toString()}${emailSuffix}`}</a></li>
                        <li><a href={`https://wa.me/${phone.replaceAll("+", "")}`}><img src={iconWhatsapp} alt="" />{phone}</a></li>
                    </ul>

                    <div style={{backgroundColor: "white", padding:"10px", marginTop:"12px", marginBottom:"12px"}}>
                        <QRCodeSVG
                            value={ QRCodeValue }
                            fgColor="#d14432"
                            size={160}
                            level="H"
                            imageSettings={{src:qrCodeHorusLogo, height:40, width:40, excavate:false}}
                        />
                    </div>

                    <button onClick={() => window.open(`tel:${phone}`)}><img src={iconAdd} alt="" />Adicionar contato</button>

                </div>
            </main>


        </div>

    </>


}

export default BusinessCard