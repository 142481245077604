import React from "react";
import { IQuestion, IResearch } from "../../hooks/useResearch";

interface IResearchProps {
  state: any;
  visible: boolean;
  question: IQuestion | null;
  addQuestion: (question: IQuestion) => void;
  doNotAnswer: () => void;
  answerLater: () => void;
  research: ({ evaluation, resume }: IResearch) => void;
  close: () => void;
}
export const ResearchContext = React.createContext<IResearchProps>({
  state: null,
  visible: false,
  question: null,
  addQuestion: () => {},
  doNotAnswer: () => {},
  answerLater: () => {},
  research: ({ evaluation, resume }) => {},
  close: () => {},
});
