import React from 'react'
import logo from '../../assets/img/logo-horus-w.png';

function CheckoutHeader() {

    const GoHome = () => {
        let page = window.location.pathname;
        if(page.includes('premium')) {
            window.location.href = "https://cestaconsumo.ehorus.com.br/";
        } else if (page.includes('metricsweb')) {
            window.location.href = "https://metricsweb.ehorus.com.br/";
        } else {
            window.location.href = "https://www.ehorus.com.br/";
        }           
    }

    return (<>
        <div className="h-sumw__header">
            <img onClick={GoHome} src={logo} className="h-sumw__header__logo" alt="Horus Logo" />
        </div>
    </>)
}

export default CheckoutHeader
