import Cookies from "js-cookie";
import { AUTH_CREDENTIALS } from "../../../../constants";
import { getCurrentDomain as domain } from "../getCurrentDomain";

const accessToken = () =>
  Cookies.remove(AUTH_CREDENTIALS.ACCESS_TOKEN, { domain });
const authUser = () => Cookies.remove(AUTH_CREDENTIALS.AUTH_USER, { domain });
const refreshToken = () =>
  window.localStorage.removeItem(AUTH_CREDENTIALS.REFRESH_TOKEN);
const accessTokenSudo = () =>
  Cookies.remove(AUTH_CREDENTIALS.ACCESS_TOKEN_SUDO);

export const removeCredentials = {
  accessToken,
  refreshToken,
  authUser,
  accessTokenSudo,
};
