import { FunctionComponent, useEffect, useRef } from 'react';
import { DashboardData, Filters, ShareOscilation, ManufacturerOscilation, ShareEvolution } from './definitions';

interface BarGraphLine {
  label: string,
  value: number,
  width: number
}

const BarGraphLine : FunctionComponent<BarGraphLine> = props => {
  const { label, value, width } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var maxWidth = containerRef.current?.offsetWidth || 0;
    var width = (barRef.current?.offsetWidth || 0) + (labelRef.current?.offsetWidth || 0);
    if (width > maxWidth - 5) {
      labelRef.current?.classList.add("__inset");
    } else {
      labelRef.current?.classList.remove("__inset");
    }
  })

  return (
    <div className="hdpd-bbc-bars-bar clearfix">
      <div className="hdpd-bbc-bars-label">{ label }</div>
      <div className="hdpd-bbc-bars-value" ref={ containerRef }>
        <div className="hdpd-bbc-bars-value-bar" style={{ width: width + "%" }} ref={ barRef }>
          <div className="hdpd-bbc-bars-value-bar-label" ref={ labelRef }>{ formatValue(value) }</div>
        </div>
      </div>
    </div>
  )
}

const formatValue = (val?: number) : string => {
  return val != undefined && !isNaN(val) ? val.toFixed(1).replace(".", ",") + "%" : "--"
}

type Oscilations = { worst: ManufacturerOscilation[], best: ManufacturerOscilation[] };

const extractValue = (item: ShareOscilation, filters: Filters) : Oscilations  => {
  switch (filters.metric) {
    case "UNIT": return { worst: item.worstUnity, best: item.bestUnity };
    case "VAL": return { worst: item.worstVal, best: item.bestVal };
    case "VOL": return { worst: item.worstVol, best: item.bestVol };
  }
}

const extractShareMonthValue = (item: ShareEvolution, filters: Filters) : number => {
  switch (filters.metric) {
    case "UNIT": return item.unity;
    case "VAL": return item.value;
    case "VOL": return item.volume;
  }
}

const BottomBoxContent : FunctionComponent<{ data: DashboardData, filters: Filters }> = props => {
  const { data, filters } = props;

  const brandOscilations : Oscilations = extractValue(data.shareBrandOscilation, filters);
  const manufacturerOscilations : Oscilations = extractValue(data.shareOscilation, filters);

  const sorted = data.shareMonthInfo.map(i => ({ ...i, val: extractShareMonthValue(i, filters) * 100 })).filter(i => i.val !== 0).sort((a, b) => b.val - a.val);
  const max = sorted.length ? sorted[0].val : 100;

  return (
    <div className="hdpd-box hdpd-bbc-wrapper">
      <div className="hdpd-bbc-left">
        <div className="hdpd-bbc-header">
          Cenário Mês Atual por Fabricante
        </div>
        <div className="hdpd-bbc-bars">
          {
            sorted.map((item, index) => (
              <BarGraphLine 
                label={ item.manufacture || "Outros" } 
                value={ item.val } 
                width={ item.val * 100 / max }
                key={ index } />
            ))
          }
        </div>
      </div>
      <div className="hdpd-bbc-right">
        <div className="hdpd-bbc-header">
          PRINCIPAIS OSCILAÇÕES<br />
          <div className="hdpd-bbc-header-small">
            Mês Atual vs Mês Anterior
          </div>
        </div>
        <div className="hdpd-bbc-tables">
          <div>
            <div className="hdpd-bbc-table-wrapper">
              <table className="hdpd-bbc-table">
                <thead>
                  <tr>
                    <th>Fabricante</th>
                    <th>vs.</th>
                    <th>PP</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    manufacturerOscilations.best.map((item, index) => (
                      <tr key={ index }>
                        <td>{ item.manufacture }</td>
                        <td>
                          <span className={ `hdpd-bbc-indicator${ item.idx < 0 ? ' __down' : '' }` } />
                        </td>
                        <td>
                          { item.idx }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className="hdpd-bbc-table-wrapper">
              <table className="hdpd-bbc-table">
                <thead>
                  <tr>
                    <th>Marca</th>
                    <th>vs.</th>
                    <th>PP</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    brandOscilations.best.map((item, index) => (
                      <tr key={ index }>
                        <td>{ item.manufacture }</td>
                        <td>
                          <span className={ `hdpd-bbc-indicator${ item.idx < 0 ? ' __down' : '' }` } />
                        </td>
                        <td>
                          { item.idx }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
          <div>
            <div className="hdpd-bbc-table-wrapper">
              <table className="hdpd-bbc-table">
                <thead>
                  <tr>
                    <th>Fabricante</th>
                    <th>vs.</th>
                    <th>PP</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    manufacturerOscilations.worst.map((item, index) => (
                      <tr key={ index }>
                        <td>{ item.manufacture }</td>
                        <td>
                          <span className={ `hdpd-bbc-indicator${ item.idx < 0 ? ' __down' : '' }` } />                          
                        </td>
                        <td>
                          { item.idx }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className="hdpd-bbc-table-wrapper">
            <table className="hdpd-bbc-table">
              <thead>
                <tr>
                  <th>Marca</th>
                  <th>vs.</th>
                  <th>PP</th>
                </tr>
              </thead>
              <tbody>
                {
                  brandOscilations.worst.map((item, index) => (
                    <tr key={ index }>
                      <td>{ item.manufacture }</td>
                      <td>
                        <span className={ `hdpd-bbc-indicator${ item.idx < 0 ? ' __down' : '' }` } />
                      </td>
                      <td>
                        { item.idx }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomBoxContent