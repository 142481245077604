export const Youtube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="11"
    fill="none"
    viewBox="0 0 16 11"
  >
    <path
      fill="#fff"
      d="M15.747 2.282s-.147-1.036-.6-1.492c-.573-.6-1.214-.602-1.508-.637C11.534 0 8.372 0 8.372 0h-.005S5.206 0 3.1.153C2.806.188 2.165.19 1.592.79c-.453.456-.597 1.492-.597 1.492S.842 3.502.842 4.718v1.14c0 1.216.15 2.435.15 2.435s.147 1.037.597 1.492c.573.6 1.326.579 1.661.643 1.206.115 5.12.15 5.12.15s3.164-.006 5.269-.156c.294-.035.935-.038 1.508-.637.453-.456.6-1.492.6-1.492s.15-1.217.15-2.436v-1.14c0-1.215-.15-2.435-.15-2.435M6.814 7.241V3.014l4.067 2.12z"
    ></path>
  </svg>
);
