import { MouseEventHandler } from "react";
import useFgv from "../../../useFgv";
import { FGV_IBRE_BENEFITS_LIST, FGV_IBRE_LIST } from "./mapped";

type Props = {
  fullPage: boolean;
  onShowCategories: MouseEventHandler;
};
export const FgvIBRE = ({ fullPage, onShowCategories }: Props) => {
  const { fgvUser } = useFgv();

  if (!fullPage) return null;
  return (
    <section className="fgv-section fgv-ibre">
      <i id="sobre" className="break-scroll" />
      <div className="fgv-container">
        <div className="fgv-ibre_header">
          <h1>Horus & FGV IBRE</h1>
          <p>
            A Horus e Instituto Brasileiro de Economia da FGV se uniram para
            levar conhecimento sobre o comportamento de preço de itens básicos
            para todos os brasileiros. Ao aliar a tecnologia da HORUS na análise
            em tempo real de grandes quantidades de informações do varejo aos
            mais de 70 anos de experiência do FGV IBRE na produção de
            indicadores econômicos, conseguimos trazer uma nova visão dos
            produtos mais relevantes para o bolso do consumidor.
          </p>
          <ul>
            {FGV_IBRE_LIST.map(({ key, icon: Icon, description }) => (
              <li key={key}>
                <Icon />
                <span>{description}</span>

                {key === "category" ? (
                  <button onClick={onShowCategories}>
                    Ver categorias disponíveis
                  </button>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
        <ul className="fgv-benefits">
          <i id="produtos" className="break-scroll" />
          {FGV_IBRE_BENEFITS_LIST.map(
            ({ buttonTitle, description, image, title, to }) => (
              <li key={to}>
                <div className="fgv-benefits_container">
                  <div className="fgv-benefits_detail">
                    <h2>{title}</h2>
                    <span>{description}</span>
                    <a href={fgvUser && to === "#cadastro" ? "#plus" : to}>
                      {buttonTitle}
                    </a>
                  </div>
                  <img src={image} alt={description} />
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </section>
  );
};
