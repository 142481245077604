import {  FunctionComponent } from 'react'
import { useCreatePassword } from './CreatePassword.functions'
import styles from './CreatePassword.module.css'

const CreatePassword: FunctionComponent = () => {

  const {

    validation, touched, history, loading, form,
    handleOnSubmit, handleOnChange, touch

  } = useCreatePassword();

  return (
    <div className={ styles.background }>
      <div className={ styles.wrapper }>
        <div className={ styles.centerBox }>
          <form className={ styles.loginBox } onSubmit={ handleOnSubmit }>

            <img
              className={ styles.logo }
              src="/static/logo-horus-w.png"
              alt="Logo Horus" />

            <div className={ styles.inputGroup }>
              <input
                className={ styles.input }
                placeholder="Senha"
                name="password"
                type="password"
                value={ form.password }
                onChange={ handleOnChange }
                onKeyUp={ () => touch("password") }/>
              <div className={ styles.validation }>
                { touched.password ? validation.password : "" }
              </div>
            </div>

            <div className={ styles.inputGroup }>
              <input
                className={ styles.input }
                placeholder="Repita a senha"
                name="confirmPassword"
                type="password"
                value={ form.confirmPassword }
                onChange={ handleOnChange }
                onKeyUp={ () => touch("confirmPassword") }/>

              <div className={ styles.validation }>
                { touched.confirmPassword ? validation.confirmPassword : "" }
              </div>
            </div>

            {
              loading ? <i className={ `${ styles.loading } fas fa-spinner fa-pulse` } /> : (
                <>
                  <button
                    className={ styles.button }
                    type="submit"
                    disabled={ loading || !validation.ok }
                    children={ loading ? 'Carregando...' : 'Criar Senha' } /> 

                  <div className={ styles.signUpText }>
                    <div className={ styles.signUpLink } onClick={ () => history.push("/login") } style={{ cursor: 'pointer' }}>
                      Cancelar
                    </div>
                  </div> 
                </>
              )
            }

          </form>

          <div className={ styles.trademark }>
            2022 © HORUS
          </div>

        </div>
      </div>
    </div>
  )
}

export default CreatePassword