type Props = {
  fullPage: boolean;
};
export const FgvHero = ({ fullPage }: Props) => {
  if (!fullPage) return null;
  return (
    <section className="fgv-section fgv-hero">
      <div className="fgv-container">
        <div className="fgv-hero_column">
          <h1>Acompanhe os valores de cestas de consumo do brasileiro</h1>
          <p>
            Acesse <strong>gratuitamente</strong> e conheça também os preços e
            variações dos principais produtos que o brasileiro coloca em seu
            carrinho quando vai ao supermercado.
          </p>
        </div>
        <div className="fgv-hero_column">
          <img
            src="/static/cesta.png"
            alt="Imagem da cesta de consumo vermelha"
          />
        </div>
      </div>
    </section>
  );
};
