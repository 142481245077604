import { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { mainColor } from "../../../constants";
import ModalTitle from "../../ui/ModalTitle";
import { useModal } from "../../utils/Modal";
import { ClientCompany, ClientCompanyUser } from "../../../model";
import useAccessSudo from "../../../hooks/useAccessSudo";
import makeModal from "../../utils/MakeModal";
import { getTokens, setSudoToken } from '../../../service/auth';

const Button = styled.button`
  background-color: ${ mainColor };
  border: none;
  height: 30px;
  margin: 20px auto;
  display: block;
  color: white;
  font-size: larger;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  ${ 
    props => {
      if (props.disabled) {
        return css`background-color: lightgray`
      }
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
`;

const InputGroup = styled.div`
`;

const SelectItems = styled.div`
  max-height: 150px;
  overflow: auto;
`;

const ItemGroup = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px gray;
  align-items: center;

  :last-child {
    border-bottom: 0;
  }

  :hover {
    background-color: lightgray;
  }
`;

const Item = styled.div`
  padding: 5px;
`;

const ItemButtonWrapper = styled.div`
  padding-right: 5px;
`;

const ItemButton = styled.button<{ layout: "cancel" | "confirm" }>`
  background-color: green;
  color: white;
  font-weight: bolder;
  border: 0;
  padding: 3px 8px;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: darkgreen;
  }

  ${ 
    props => {
      if (props.layout === "cancel") {
        return css`
          background-color: red;
          :hover {
            background-color: darkred;
          }
        `;
      }
    }
  }
`;

const ListAccessSudoModal : FunctionComponent<{clientCompany: ClientCompany[] }> = (props) => {

  const { openModal } = useModal();
  const { clientCompany } = props;
  const { getClientCompanyUsers, getRequireSudo } = useAccessSudo();

  const [ getclientCompanyUsersState, setClientCompanyUsers ] = useState<ClientCompanyUser[]>([]);
  const [ getclientUser, setClientUser ] = useState<string | null>(null);
  const [ clientId, setClientId ] = useState<string | null>(null);

  const loginSudo = async () => {
    if (!getclientUser) {
      return;
    }
    try {
      var response = await getRequireSudo(getTokens()?.token!, getclientUser)
      setSudoToken(response.data.access_token);
      window.location.reload();
    } catch (err: any) {
      openModal(makeModal("Erro", err.response?.status === 500 ? "Erro interno do Servidor" : err.message ));
    }
  }

  useEffect(() => {

    const fn = async () => {

      if (!clientId) {
        setClientCompanyUsers([]);
        return;
      }
      try {
        var response = await getClientCompanyUsers(clientId);
        const clientCompanyUsers: ClientCompanyUser[] = response.data;
        setClientCompanyUsers(clientCompanyUsers);
      } catch (err: any) {
        openModal(makeModal("Erro", err.response?.status === 500 ? "Erro interno do Servidor" : err.message ));
      }

    }

    fn();

  }, [ clientId, openModal, setClientCompanyUsers, getClientCompanyUsers ])

  return (
    <>
      <ModalTitle>
        Lista de Clientes
      </ModalTitle>
      <Select
        value={ clientId }
        onChange={ value => setClientId(value) }
        options={ clientCompany.map(cc => ({ id: String(cc.id), name: cc.name })) }
      />
      <div style={{ height: "12px" }}></div>
      {
        !getclientCompanyUsersState || getclientCompanyUsersState.length === 0 ? null : (
          <Select
            value={ getclientUser }
            onChange={ getclientUser => setClientUser(getclientUser) }
            options={ getclientCompanyUsersState.filter(c => c.active == true)?.map(cu => ({ id: cu.username, name: cu.username })) }
          />
        )
      }
      <Button type="button" onClick={ loginSudo } disabled={ getclientUser && clientId ? false : true }>Acessar</Button>
    </>
  )
}

function Select(props: { onChange: (value: string | null) => void, value: string | null, options: Array<{ id: string, name: string }> }) {

  const [ search, setSearch ] = useState<string>("");

  return (
    <SelectWrapper>
      <InputGroup>
        <Input
          value={ search }
          onChange={ e => setSearch(e.target.value) }
          placeholder="Filtrar..."
        />
      </InputGroup>
      <SelectItems>
        {
          props.options.filter(o => !search || o.name.toLowerCase().includes(search.toLocaleLowerCase())).map(item => (
            <ItemGroup key={ item.id }>
              <Item>
                { item.name }
              </Item>
              <ItemButtonWrapper>
                <ItemButton
                  layout={ props.value === item.id ? "cancel" : "confirm" }
                  onClick={ () => props.onChange(props.value === item.id ? null : item.id) }
                >
                  {
                    props.value === item.id ? "Remover" : "Selecionar"
                  }
                </ItemButton>
              </ItemButtonWrapper>
            </ItemGroup>
          ))
        }
      </SelectItems>
    </SelectWrapper>
  )
}

export default ListAccessSudoModal;