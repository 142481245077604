import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';

interface Props {
  open: boolean;
  onClose?: () => void;
}

const Modal: FunctionComponent<Props> = function (props) {
  const { open, onClose } = props;
  const modal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key !== 'Escape') return;
        onClose?.();
      }
      document.addEventListener('keydown', handleKeyDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [ onClose, open ]);

  return (
    <CSSTransition
      nodeRef={ modal }
      in={ props.open }
      timeout={ 500 }
      classNames="hd__modal__open"
    >
      <div className="hd__modal__back" ref={ modal }>
        <div className="hd__modal__body">
          {
            props.onClose ? <button type="button" className="hd__modal__body-close" onClick={ props.onClose }>+</button> : null
          }
          { props.children }
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal;