import React from "react";
import { Link } from "react-router-dom";
import { logo } from "../../constants";


const CheckoutFooter : React.FC <{pageName?:string}> = props => {
    const { pageName } = props

  return (
    <>
    <div className="hd-fgv__footer">
          <div className="hd-fgv__content">
            <div className="hd-fgv__footer__row">
              <div className="hd-fgv__footer__col">
                <img className="hd-fgv__footer__horus-logo" src={logo}alt="Logo Horus" />
                {pageName === "fgv"  && <img className="hd-fgv__footer__fgv-logo" src="/static/fgv.png" alt="Logo Horus" />}
              </div>
              <div className="hd-fgv__footer__col">
                <h3>Menu</h3>
                <nav>
                  <ul>
                    <li><a target="__blank" href="https://www.ehorus.com.br/blog">Blog</a></li>
                    <li><a target="__blank" href="https://www.ehorus.com.br/na-midia">Na Mídia</a></li>
                    <li><a target="__blank" href="https://www.ehorus.com.br/contato">Contato</a></li>
                    <li><Link to="/politica-de-privacidade">Política de Privacidade</Link></li>
                    <li><Link to="/politicas/cookies">Política de Cookies</Link></li>
                    <li><Link to="/politicas/termos-de-uso">Termos de Uso</Link></li>
                    
                  </ul>
                </nav>
              </div>
              <div className="hd-fgv__footer__col">
                <h3>E-mail</h3>
                <nav>
                  <ul>
                    <li><a href="mailto:contato@horus.com.br">contato@horus.com.br</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default CheckoutFooter;
