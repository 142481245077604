import React from "react";
import styles from './MetricsWebFooter.module.css'

const MetricsWebFooter: React.FC = (): JSX.Element => {

    return (<>

        <footer className={styles.wrap}>

            <div className={styles.container}>
                <ul>
                    <li>
                        <a href="https://www.linkedin.com/company/horusinteligencia/" target="_blank">
                            <img src="https://metricsweb.ehorus.com.br/assets/img/linkedin.png" alt="LinkedIn" /></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/horusinteligencia/" target="_blank">
                            <img src="https://metricsweb.ehorus.com.br/assets/img/instagram.png" alt="Instagram" /></a>
                    </li>

                    <li>
                        <a href="https://www.facebook.com/Horus-Inteligencia-de-Mercado-110852024661782">
                            <img src="https://metricsweb.ehorus.com.br/assets/img/facebook.png" alt="Facebook" /></a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="mailto:contato@ehorus.com.br">contato@ehorus.com.br</a>
                    </li>
                    <li>
                        <a href="./termos.html" target="_blank">| Termos de
                            Uso |</a>
                    </li>
                    <li>
                        <a href="./politica-de-privacidade.html" target="_blank">Política de
                            Privacidade</a>
                    </li>
                </ul>
                <span>Copyright @ 2022. Todos os direitos reservados.</span>
            </div>
        </footer>


    </>)

}

export default MetricsWebFooter
