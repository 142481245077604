import { FunctionComponent } from "react";
import styled, { CSSProperties } from "styled-components";
import { mainColor } from "../../constants";

const Input = styled.input`
  height: 30px;
  font-size: large;
  color: black;
  padding-left: 10px;  
`;

const Label = styled.span`
  color: ${ mainColor };
  font-weight: bold;
  margin-top: 2%;
`;

const Content = styled.div``;

interface SingUpProps {
  style?:CSSProperties, 
  type?:string,
  label?:string, 
  placeholder?:string,
  disabled?:boolean,
  value?:any, 
  maxLength?:any,
  onChange?:React.ChangeEventHandler<HTMLInputElement>, 
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

const InputComponent: FunctionComponent<SingUpProps> = (props) => {
    const { type, label, placeholder, disabled, value, maxLength, onChange, onBlur } = props;

    return (
      <>
        <Label>{ label }</Label>
        <Input
          disabled={ disabled }
          type={ type }
          placeholder={ placeholder }
          value={ value }
          maxLength={ maxLength }
          onChange={ onChange } 
          onBlur= { onBlur }
        />
      </>
    )
}

export default InputComponent;