import { useContext, useRef } from 'react'
import AuthContext from '../components/context/AuthContext'
import { loginWithCredentials, loginWithRefreshToken, Credentials, clearTokens, 
ForgetPassword, forgotPassword, verifyCredentials, getTokens } from '../service/auth'
import { useCallback } from 'react'

export const timeoutRefreshToken = 8 * 60 * 1000;

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext)
  const refreshTokenTimeOutIdRef = useRef<number | null>(null);

  const verifyLegacyCredentials = useCallback(async (credentials: Credentials) : Promise<any> => {
    try {
      const response = await verifyCredentials(credentials);
      /*if (response.status === 200){
        setUser({
          name: credentials.user
        });
      }*/
      
      return response

    } catch (e) {
      setUser(null);
      throw e;

    }
  }, [ setUser ])
  

  const forgetPassword = useCallback(async (forgetPassword: ForgetPassword) : Promise<any> => {
    try {
      await forgotPassword(forgetPassword);

    } catch (e) {
      setUser(null);
      throw e;

    }
  }, [ setUser ])

  /*const changePassword = useCallback(async (forgetPassword: ForgetPassword) : Promise<any> => {
    try {
      await changedPassword(forgetPassword);

    } catch (e) {
      setUser(null);
      throw e;

    }
  }, [ setUser ])*/

  const restore = useCallback(async () : Promise<any> => {
    try {
      
      const response = await loginWithRefreshToken()

      return response;

    } catch (e) {
      logout();
      throw e;

    }
  }, [ setUser ])

  const logout = useCallback(() => {
    clearTokens();
    if (refreshTokenTimeOutIdRef?.current) {
      clearTimeout(refreshTokenTimeOutIdRef.current)
    }
    setUser(null);
  }, [ setUser, refreshTokenTimeOutIdRef ]);

  const login = useCallback(async (credentials: Credentials) : Promise<any> => {
    logout();

    try {
      const response = await loginWithCredentials(credentials);

      if (response.status === 200) {
        setUser({
          name: credentials.user
        });
      }

    } catch (e) {
      logout();
      throw e;
    }

  }, [ setUser, logout ])

  const setRefreshTokenTimeout = useCallback(() => {
    if (refreshTokenTimeOutIdRef?.current) {
      return;
    }

    const emptyString = (str: string | null | undefined) => !str || str.trim() === '';
  
    const getRefreshTokenTimeoutId = async (startTimeout?: boolean) => {
      if (refreshTokenTimeOutIdRef?.current) {
        clearTimeout(refreshTokenTimeOutIdRef.current)
      }
      refreshTokenTimeOutIdRef.current = null;

      const tokens = getTokens();
  
      if (emptyString(tokens?.refreshToken)) {
        return;
      }
        
      if (emptyString(tokens?.token)) {
        logout();
        window.location.reload();
      }
  
      if (!startTimeout) {
        await restore();
      }
      
      const timeoutRefreshToken = 8 * 60 * 1000
      refreshTokenTimeOutIdRef.current = setTimeout(() => getRefreshTokenTimeoutId(), timeoutRefreshToken) as any;
      
      window.addEventListener("beforeunload", function(e) {
        if (refreshTokenTimeOutIdRef?.current) {
          clearTimeout(refreshTokenTimeOutIdRef.current)
        }
      });
    }
  
    getRefreshTokenTimeoutId(true)
    
  }, [ restore, logout ]);

  return {
    user, setUser, login, restore, logout, forgetPassword, verifyLegacyCredentials, setRefreshTokenTimeout
  }
}



export default useAuth