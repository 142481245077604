import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import Toggle from "../Toggle";
import useFetch from "../../hooks/useFetch";
import { baseUrl } from "../../constants";

const UPDATE_DASHBOARDS = "UPDATE_DASHBOARDS";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    i {
      font-size: 12px;
    }
  }
`;

function ToggleNotifications() {
  const [notifications, setNotifications] = useState(true);
  const fetch = useFetch();

  const subscribeNotifications = useCallback(async () => {
    const data = await fetch({
      method: "POST",
      url: `${baseUrl}email_notification/${UPDATE_DASHBOARDS}/${
        notifications ? 0 : 1
      }`,
    });

    return data;
  }, [fetch, notifications]);

  useEffect(() => {
    (async () => {
      const data = await fetch({
        method: "GET",
        url: `${baseUrl}email_notification/${UPDATE_DASHBOARDS}`,
      });

      if (data.status === 200 && data.data) {
        setNotifications(true);
        return;
      }

      setNotifications(false);
    })();
  }, [fetch]);

  return (
    <>
      <Container>
        <Toggle
          toggled={notifications}
          onToggle={async () => {
            const subscribeEvent = await subscribeNotifications();

            if (subscribeEvent.status === 200) {
              setNotifications(!notifications);
            }
          }}
        >
          <div>
            {notifications ? (
              <i className="fas fa-bell"></i>
            ) : (
              <i className="fas fa-bell-slash"></i>
            )}
            Notificações por email
          </div>
        </Toggle>
      </Container>
    </>
  );
}

export default ToggleNotifications;
