import { FunctionComponent, useEffect, useState, useRef } from "react";
import { optional } from "../../utils/Util";

export interface Option {
  key?: string,
  value: string,
  disabled?: boolean
}

export interface Props {
  label: string,
  value: string | null,
  placeholder?: string,
  required?: boolean,
  options: Option[],
  onChange?: (val: string | null) => void,
  onSelectOpen?: () => void,
  disabled?: boolean
}

interface State {
  open: boolean
}

const Select: FunctionComponent<Props> = props => {
  const { 
    label, 
    options, 
    value, 
    placeholder = "Selecione...",
    required = false,
    onSelectOpen,
    onChange,
    disabled = false
  } = props;
  const [ { open }, setState ] = useState<State>({ open: false });
  const selectDivRef = useRef<HTMLDivElement>(null);

  const toggleOptions = (open: boolean) => setState(prev => ({ ...prev, open }));

  const optionSelected = optional(options.find(i => i.key === value))
    .map(i => i.value)
    .getOrDefault(placeholder);

  const handleOnChange = (val: string | null) => {
    if (disabled) return;
    if (onChange) onChange(val);
    toggleOptions(false);
  }

  useEffect(() => {
    let listener : ((e: MouseEvent) => void) | null = null;

    if (open) {
      listener = (e: MouseEvent) => { if (!selectDivRef.current?.contains(e.target as Node)) toggleOptions(false) };
      document.addEventListener("click", listener);
    }

    return () => { if (listener) document.removeEventListener("click", listener) }
  }, [ open ])

  return (
    <div className={ `hdmsps-filter ${ disabled ? 'hdmsps-filter--disabled' : '' }` }>
      <div className="hdmsps-filter-label">
        { label }
      </div>
      <div ref={ selectDivRef } className="hdmsps-options-wrapper">
        <div className="hdmsps-filter-box" onClick={ () => {
          if (onSelectOpen) onSelectOpen();
          toggleOptions(!open);
        } }>
          { optionSelected }
        </div>
        <div className="hdmsps-options" style={{ display: open ? "block" : "none" }}>
          {
            required ? null : (
              <div className="hdmsps-option" onClick={ () => handleOnChange(null) }>
                { placeholder }
              </div>
            )
          }
          {
            options.map(option => (
              <div 
                className={ `hdmsps-option ${ option.disabled ? 'hdmsps-option-disabled' : '' }` }
                onClick={ () => !option.disabled && handleOnChange(option.key || option.value) }
                key={ option.key || option.value }
              >
                { option.value }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Select;