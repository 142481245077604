import {
  createContext,
  FunctionComponent,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import AuthContext from "../components/context/AuthContext";
import { ResearchContext } from "../components/context/ResearchContext";
import LoadingFrame from "../components/ui/LoadingFrame";
import useHttp, { Http } from "../hooks/useHttp";
import { IQuestion } from "../hooks/useResearch";
import { Dashboard } from "../model";
import LoginPage from "../components/pages/LoginPage";

export interface Home {
  dashboards: Dashboard[];
  plan: any[];
  publishedResearch: {
    researchPublishedId: number;
    question: string;
  };
  user: {
    companyName: string;
    name: string;
  };
}

interface HomeContextProps {
  home?: Home;
}

const HomeContext = createContext<HomeContextProps>({});

export const withHome = (Component: FunctionComponent): FunctionComponent => {
  return (props) => {
    const { user } = useContext(AuthContext);
    const [{ home, loading }, setState] = useState<{
      home?: Home;
      loading: boolean;
    }>({ loading: true });

    const http = useHttp();

    const { addQuestion } = useContext(ResearchContext);

    const userId = user ? user.name : undefined;

    useLayoutEffect(() => {
      if (userId) {
        setState((prev) => ({ ...prev, loading: true }));

        getHome(http)
          .then((home) => {
            setState((prev) => ({ ...prev, home, loading: false }));

            if (!!home.publishedResearch) {
              const questionData: IQuestion = {
                id: home.publishedResearch.researchPublishedId,
                question: home.publishedResearch.question,
              };
              addQuestion(questionData);
            }
          })
          .catch(() => setState((prev) => ({ ...prev, loading: false })));
      } else {
        setState((prev) => ({ ...prev, loading: false }));
      }
    }, [addQuestion, userId, http]);

    return (
      <HomeContext.Provider value={{ home }}>
        {loading ? (
          <LoadingFrame />
        ) : user ? (
          <Component {...props} />
        ) : (
          <LoginPage />
        )}
      </HomeContext.Provider>
    );
  };
};

async function getHome(http: Http): Promise<Home> {
  const homeResult = await http.request<Home>({
    method: "GET",
    url: "/webtool/home",
  });
  const dashboardResult = await http.request<{ result: Dashboard[] }>({
    method: "GET",
    url: "/api/v1/dashboard/embed/available",
  });

  return {
    ...homeResult.data,
    dashboards: dashboardResult.data.result,
  };
}

export const useHome = () => useContext(HomeContext);
