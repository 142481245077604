
import { useCallback } from 'react';
import { Dashboard } from '../components/domain/Dashboard';
import useRequest from './useRequest';

const useAccessSudo = () => {

  const clientCompaniesRequest = useRequest("GET", "/client-company");

  const clientCompanyUsersRequest = useRequest("GET", "/client-company-users/{companyId}");  

  const requireSudoRequest = useRequest("GET", "/require-sudo/token/{token}/user/{userSudo}");   

  const getClientCompanies = useCallback(async () : Promise<any> => {
    return clientCompaniesRequest({});
  }, [ clientCompaniesRequest ]);

  const getClientCompanyUsers = useCallback(async (companyId: String) : Promise<any> => {
    return clientCompanyUsersRequest({ pathParams: { companyId } });
  }, [ clientCompanyUsersRequest ]);

  const getRequireSudo = useCallback(async (token: String, userSudo: String) : Promise<any> => {
    return requireSudoRequest({ pathParams: { token, userSudo } });
  }, [ requireSudoRequest ]);  

  return {
    getClientCompanies, getClientCompanyUsers, getRequireSudo
  };

};

export default useAccessSudo;