import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { mainColor } from '../../constants'
import useAuth from '../../hooks/useAuth';


const Header = styled.div`
  background-color: ${ mainColor };
  text-align: center;
  position: relative;
  height: 1300px;
`;

const TopNav = styled.nav`
  position: absolute;
  width: 96%;
  top: 30px;
  color: white;
  padding: 0 0px;
  left: 2%;

  @media (max-width: 520px) {
    right: 5%;
  }
`;

const TopNavItem = styled.div`
  cursor: pointer;
`;

const Logo = styled.img`
  max-height: 50px;
  max-width: 90%;
  margin: 35px auto;
`;

const Content = styled.div`
	width: 96%;
	margin: 0 auto;
	max-width: 96%;
	margin-top: -1190px;
	position: relative;
`;

const HeaderPage : FunctionComponent = (props) => {
  const { children } = props;
  const { logout } = useAuth(); 

  return (
    <>
      <Header>
        <TopNav>
          <TopNavItem style={{float: 'left'}} onClick={ logout }>
            <i className="fas fa-angle-left" /> Voltar
          </TopNavItem>
          <TopNavItem style={{float: 'right'}} onClick={ logout }>
            <i className="fas fa-sign-out-alt" /> Sair
          </TopNavItem>
        </TopNav>
        <Logo
          src="/static/logo-horus-w.png"
          alt="Logo Horus" />
      </Header>
      <Content>
        { children }
      </Content>
    </>
  );
};

export default HeaderPage;