import jwtDecode from "jwt-decode";
import { authCredentials } from "../components/utils/authCredentials";

export const sessionValidator = () => {
  try {
    const token: string = authCredentials.get.accessToken() || "";
    let { exp }: any = jwtDecode(token);
    let timer = new Date(Number(new Date(exp * 1000)) - Number(+new Date()));
    if (new Date(exp * 1000).getTime() - new Date().getTime() > 0) {
      if (timer.getMinutes() > 0) {
        return `${timer.getMinutes()} minutos`;
      } else if (timer.getSeconds() >= 0) {
        return `${timer.getSeconds()} segundos`;
      }
    }
  } catch (error) {}
};
