import axios, { CancelTokenSource, Method } from 'axios';
import useAuth from './useAuth';
import { getTokens } from '../service/auth';
import { useCallback, useEffect, useRef } from 'react';
import { baseUrl } from '../constants';
import { CancelToken } from '../components/utils/Util';

export interface RequestParams {
  cancelToken?: CancelToken;
  method: Method;
  url: string;
  data?: any;
  headers?: any;
  pathParams?: any;
  params?: any;
  options?: {
    auth?: boolean;
  };
}

export const isAuthError = (err: any): boolean => {
  return err.response && err.response.status === 401;
}

export function isCancel(err: any): boolean {
  return axios.isCancel(err);
}

export function isUnhandled(err: any): boolean {
  return !isCancel(err) && !isAuthError(err);
}

export interface HttpResponse<T> {
  data: T,
  status: number
}

export type RequestCallback<T> = (error: Error | undefined, response: HttpResponse<T>) => void;

export type Fetch = <T = any>(params: RequestParams, callback?: RequestCallback<T>) => Promise<HttpResponse<T>>;

axios.defaults.baseURL = baseUrl;

const doRequest = async <T extends any>(requestParams: RequestParams, cancelToken: CancelTokenSource): Promise<HttpResponse<T>> => {
  const { method, url: originalUrl, pathParams, params, data, options = {}, headers = {} } = requestParams;
  const { auth = true } = options;

  let url = originalUrl;

  if (pathParams) {
    Object.entries(pathParams).forEach(entry => {
      url = url.replace(`{${entry[0]}}`, String(entry[1]));
    });
  }

  const headersToSend = { ...headers };

  if (auth) {
    const tokens = getTokens();
    
    if (!tokens) {
      throw Object.assign(new Error("Você precisa estar autenticado para acessar este recurso"), { code: 401 });
    }

    if (tokens.sudoToken) {
      headersToSend.Token = tokens.sudoToken;
    } else {
      headersToSend.Authorization = `Bearer ${ tokens.token }`;
    }
  }

  return await axios.request({
    url, method, data, headers: headersToSend, params, cancelToken: cancelToken.token
  });
}

interface FetchOptions {
  autoCancel?: boolean
}

const useFetch = (options: FetchOptions = {}): Fetch => {
  const { autoCancel = true } = options;

  const ref = useRef<{ requestNumber: number, cancelTokens: Record<number, any> }>({ requestNumber: 0, cancelTokens: {} });
  const { restore } = useAuth();

  useEffect(() => autoCancel ? () => Object.values(ref.current.cancelTokens).forEach(token => token.cancel()) : undefined, [ ref, autoCancel ]);

  const fetch = useCallback(<T>(params: RequestParams, cb?: RequestCallback<T>): Promise<HttpResponse<T>> => {
    const id = ref.current.requestNumber++;
    const cancelToken = axios.CancelToken.source();

    ref.current.cancelTokens[id] = cancelToken;
    const removeCancelToken = () => { delete ref.current.cancelTokens[id] };

    if (params.cancelToken) params.cancelToken.subscribe(() => cancelToken.cancel());

    const result = new Promise<HttpResponse<T>>(async (resolve, reject) => {
      const fn = async (): Promise<HttpResponse<T>> => {
        const { options: { auth = true } = {} } = params;

        try {
          return await doRequest(params, cancelToken);

        } catch (err) {
          if (
            !auth || !isAuthError(err)
          ) {
            throw err;
          }
        }

        await restore();
        return await doRequest(params, cancelToken);
      }

      fn().then(resolve).catch(reject);
    });

    return result.then(data => {
      removeCancelToken();
      if (cb) cb(undefined, data);
      return data;
    }).catch(err => {
      removeCancelToken();
      if (cb && isUnhandled(err)) cb(err, undefined as any);
      throw err;
    });

  }, [ restore ]);

  return fetch;
};

export default useFetch;