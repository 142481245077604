import { SVGProps } from "react";

export const ShopCart = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="48"
    fill="none"
    viewBox="0 0 54 48"
    {...props}
  >
    <g clipPath="url(#clip0_8_164)">
      <path
        fill="#00263C"
        d="M.405 4.468c.306-.54.625-1.027 1.305-1.138s3.122-.097 3.844 0c.749.098 1.137.556 1.373 1.222.57 1.582.764 3.538 1.277 5.162l.153.263h4.62V4.524c0-.042.39-.708.459-.777.138-.125.652-.375.846-.403h7.008V1.207c0-.347.75-1.096 1.138-1.151L43.784 0c.25.028.75.25.93.416.18.167.458.736.458.777v8.784h6.286c.639 0 1.457 1.25 1.166 1.902l-4.982 19.94c-.028.43-.79 1.222-1.166 1.222H14.02l.846 3.358.153.166h37.176c1.249.18 1.845 1.624 1.026 2.61-.818.985-.763.527-.832.527h-2.859c1.901 3.663-.721 8.132-4.829 8.312-4.357.194-7.34-4.427-5.356-8.312H26.258c1.901 3.663-.722 8.132-4.83 8.312-4.357.194-7.34-4.427-5.356-8.312h-2.747c-.375 0-1.138-.791-1.166-1.221L4.235 6.619c-.027-.139-.11-.152-.236-.18-1.276-.25-2.914.583-3.58-1.138v-.833zm41.659-1.346H24.398v6.855h17.666zM21.29 6.44H16.1v3.538h5.19zM17.543 19.94l-1.818-6.855H9.023l1.818 6.855zm10.394-6.855H19l1.818 6.855h7.119zm12.045 0h-8.937v6.855h7.12zm9.145 0h-5.87l-1.818 6.855h5.87zM20.04 29.919l-1.818-6.856h-6.702l1.817 6.856zm7.896-6.856h-6.439l1.818 6.856h4.62zm9.547 0h-6.439v6.856h4.621zm9.145 0h-5.87l-1.818 6.856h5.87zM20.735 39.702c-3.247.513-2.609 5.592.805 5.162 3.358-.43 2.72-5.717-.805-5.162m23.271 0c-3.247.513-2.608 5.592.805 5.162 3.358-.43 2.72-5.717-.805-5.162"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_8_164">
        <path fill="#fff" d="M.405 0h53.19v48H.405z"></path>
      </clipPath>
    </defs>
  </svg>
);
