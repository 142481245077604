import { FunctionComponent } from "react";
import ReactDOM from 'react-dom';
import Loading from './Loading';

const LoadingFrame : FunctionComponent = () => {
  return ReactDOM.createPortal(
    <Loading />,
    document.getElementById("horus-top") as HTMLElement
  )
}

export default LoadingFrame;