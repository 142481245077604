import { FunctionComponent, useRef, useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import TopBoxContent from './TopBoxContent';
import BottomBoxContent from './BottomBoxContent';
import EvolutionaryChart from "./EvolutionaryChart";
import useRequest from "../../../../hooks/useRequest";
import { DashboardData, Filters } from './definitions';
import { useModal } from "../../../utils/Modal";
import ModalTitle from "../../../ui/ModalTitle";
import { useHistory } from "react-router";
import LoadingFrame from "../../../ui/LoadingFrame";
import DashboardWrapper from "../../../ui/DashboardWrapper";
import Select from '../Select';
import { liquidCategories } from "../../../utils/Util";

//#region ProfessionalDashboardSelect

const SelectContent = styled.div`
  position: relative;
`

const SelectedOption = styled.div`
  width: 100%;
  border: solid 1px gray;
  padding: 5px;
  color: gray;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

const SelectExpandIcon = styled.div`
  position: absolute;
  color: black;
  right: 5px;
  top: 5px;
`;

interface SelectOptionsProps {
  open: boolean
}

const SelectOptions = styled.div<SelectOptionsProps>`
  position: absolute;
  border: solid 1px gray;
  border-top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  background-color: white;
  ${ ({ open }) => !open ? css`display: none` : css`` };
`;

interface SelectOptionProps {
  selected: boolean
}

const SelectOption = styled.div<SelectOptionProps>`
  padding: 3px;
  font-size: 14px;
  cursor: pointer;

  ${ ({ selected }) => selected ? css`background-color: lightgray` : css`` };

  :hover {
    background-color: #3385ff;
    color: white;
  }
`;

interface SelectProps {
  options: Array<{ key: string, value: string }>,
  value?: string | null,
  placeholder?: string,
  required?: boolean,
  onChange?: (value: string | null) => void
}

//#endregion

//#region Header

const Header : FunctionComponent<{ 
  filters: Filters,
  data: DashboardData,
  onChangeFilters: (filters: Filters, refresh?: boolean) => void
}> = props => {
  const { filters, onChangeFilters, data } = props;
  return (
    <div className="hdmsps-filters">
      <Select 
        options={ data.filters.periods }
        value={ filters.period }
        label="PERÍODO"
        required
        onChange={ period => onChangeFilters({ ...filters, period, categoryId: data.filters.categories[0].key, segmentId: null, uf: null, channelId: null }, true) }
      />
      <Select
        options={ data.filters.categories }
        value={ filters.categoryId }
        label="CATEGORIA"
        required
        onChange={ categoryId => onChangeFilters({ ...filters, categoryId, segmentId: null, uf: null, channelId: null }, true) }
      />
      <Select
        options={ data.filters.segments }
        value={ filters.segmentId }
        label="SEGMENTO"
        placeholder="Todos"
        onChange={ segmentId => onChangeFilters({ ...filters, segmentId, uf: null, channelId: null }, true) }
      />
      <Select
        options={ data.filters.states }
        value={ filters.uf }
        label="UF"
        placeholder="Todos"
        onChange={ uf => onChangeFilters({ ...filters, uf, channelId: null }, true) }
      />
      <Select
        options={ data.filters.channels }
        value={ filters.channelId }
        label="CANAL"
        placeholder="Todos"
        onChange={ channelId => onChangeFilters({ ...filters, channelId }, true) }
      />
      <div className="hdmsps-filter hdmsps-filter-metric">
        <div
          className={ `hdmsps-filter-metric-item ${ filters.metric === "VOL" ? "hdmsps-filter-metric-item-selected" : null }` }
          onClick={ () => onChangeFilters({ ...filters, metric: "VOL" }) }>
          { liquidCategories.includes(parseInt(filters.categoryId || "")) ? "Litros" : "Quilos" }
        </div>
        <div 
          className={ `hdmsps-filter-metric-item ${ filters.metric === "VAL" ? "hdmsps-filter-metric-item-selected" : null }` }
          onClick={ () => onChangeFilters({ ...filters, metric: "VAL" }) }>
          Valor
        </div>
        <div
          className={ `hdmsps-filter-metric-item ${ filters.metric === "UNIT" ? "hdmsps-filter-metric-item-selected" : null }` }
          onClick={ () => onChangeFilters({ ...filters, metric: "UNIT" }) }>
          Transação
        </div>
      </div>
    </div>
  )
}

//#endregion

const LeftPart = styled.div`
  height: calc(100vh - 120px);
  width: 50%;
  float: left;
`;
const TopBox = styled.div`
  float: left;
  width: 33.3333%;
  height: 45%;
  padding: 5px;
  box-sizing: border-box;
`;
const BottomBox = styled.div`
  width: 100%;
  height: 55%;
  padding: 5px;
  box-sizing: border-box;
`;

const Body : FunctionComponent<{ data: DashboardData, filters: Filters }> = props => {
  const { data , filters } = props;
  return (
    <div className="hdmspf-content clearfix">
      <LeftPart>
        <TopBox>
          <TopBoxContent
            data={ data.shareMetrics.Vol }
            image={ <img src="/static/vol.png" className="hdpd-metric-image" /> }
            title="(%) Volume mês atual" />
        </TopBox>
        <TopBox>
          <TopBoxContent
            data={ data.shareMetrics.Val }
            image={ <img src="/static/val.png" className="hdpd-metric-image" /> }
            title="(%) Valor mês atual"
          />
        </TopBox>
        <TopBox>
          <TopBoxContent 
            data={ data.shareMetrics.Unid } 
            image={ <img src="/static/unit.png" className="hdpd-metric-image" /> }
            title="(%) Transações mês atual"/>
        </TopBox>
        <div className="clearfix" />
        <BottomBox><BottomBoxContent data={ data } filters={ filters } /></BottomBox>
      </LeftPart>
      <div className="hdpd-right-part">
        <EvolutionaryChart filters={ filters } data={ data } />
      </div>
    </div>
  )
}

//#region ProfessionalDashboard

const initialFilters : Filters = { 
  metric: "VAL",
  categoryId: null,
  period: null,
  segmentId: null,
  channelId: null,
  uf: null
};

const ErrorModal : FunctionComponent<{ e: Error }> = props => {
  const { e } = props;

  const { response: { status = null } = {} } = e as any;

  let message;

  if (status === 400) {
    message = "Os dados do seu dashboard ainda não foram gerados! Por favor aguarde alguns minutos."
  } else {
    message = "Ocorreu um erro ao acessar o dashboard."
  }

  return (
    <>
      <ModalTitle>
        Alerta
      </ModalTitle>
      <div className="hdpd-error">
        { message }
      </div>
    </>
  );
}

const ProfessionalDashboard : FunctionComponent = () => {
  const { openModal } = useModal();
  const history = useHistory();
  const fetchDashboard = useRequest<DashboardData>("GET", "/api/v1/dashboard/professional");
  const [ { data, filters, loading }, setState ] = useState<{ 
    data?: DashboardData, 
    filters: Filters,
    loading: boolean
  }>({ filters: initialFilters, loading: false });

  const fetchDashboardData = useCallback(async filters => {
    setState(prev => ({ ...prev, loading: true }))
    try {
      const { data } = await fetchDashboard({ 
        params: filters
      });
      setState(prev => ({  ...prev, data, loading: false, filters: { ...prev.filters, period: data.refDate, categoryId: "" + data.category } }));
    } catch (e) {
      setState(prev => ({ ...prev, loading: false }));
      openModal(() => <ErrorModal e={ e } />);
      history.push("/");
    }
  }, [ fetchDashboard ]);

  const handleOnChangeFilters = (filters: Filters, refresh: boolean = false) => {
    setState(prev => ({ ...prev, filters }));
    if (refresh) {
      fetchDashboardData(filters);
    }
  };

  useEffect(() => { fetchDashboardData(initialFilters) }, [ fetchDashboardData, initialFilters ]);

  if (!data || !filters) {
    return <LoadingFrame />
  }

  return (
    <DashboardWrapper title="Market Share por Fabricante">
      {
        loading ? <LoadingFrame /> : null
      }
      <div className="hdmspf-body">
        <Header 
          onChangeFilters={ handleOnChangeFilters }
          filters={ filters }
          data={ data }
        />
        <Body 
          data={ data }
          filters={ filters }  
        />
      </div>
    </DashboardWrapper>
  )
}

//#endregion

export default ProfessionalDashboard;