import { FunctionComponent, useEffect, useState, useCallback, Fragment, useRef } from "react";
import { baseUrl } from "../../../../constants";
import useRequest from "../../../../hooks/useRequest";
import LoadingFrame from "../../../ui/LoadingFrame";
import ModalTitle from "../../../ui/ModalTitle";
import { useModal } from "../../../utils/Modal";
import { FiltersSelected, MarketPerShareData, ManufacturerData, MetricData, Metric, Metrics } from "./definitions";
import MultiSelect from "../MultiSelect";
import Select from "../Select";
import { liquidCategories, optional } from "../../../utils/Util";
import Dashboard from '../../../ui/DashboardWrapper';

interface State {
  data?: MarketPerShareData;
  filters: FiltersSelected;
  loading: boolean
}

const makeErrorModal = (e: Error): any => {
  const message = "Ocorreu um erro ao acessar o dashboard";

  return (
    <>
      <ModalTitle>Erro!</ModalTitle>
      <div className="modal-msg">
        { message }
      </div>
    </>
  )
}

const extractVal = (item: Metrics, metric: Metric) => {
  switch (metric) {
    case "VAL": return item.val;
    case "VOL": return item.vol;
    case "UNIT": return item.unit;
  }
}

const OthersModal: FunctionComponent<{ data: string[] }> = props => {
  const { data } = props;
  const [ filter, setFilter ] = useState("");

  return (
    <>
      <ModalTitle>Outros</ModalTitle>
      <input className="hdmsps-others-filter" type="text" value={ filter } onChange={ e => setFilter(e.target.value) } />
      <div className="hdmsps-others-list">
        { 
          data
            .filter(i => i.toLowerCase().includes(filter.toLowerCase()))
            .sort((a, b) => a.localeCompare(b))
            .map((name, index) => (
            <div key={ index }>
              { name }
            </div>
          ))
        }
      </div>
    </>
  )
}

const makeOrder = (val: number, length: number, index: number) => {
  return val !== 0 ? val + (1000 * ((length - index - 1) * 3)) : 0;
}

const sortRecursive = (a: number[], b: number[], i: number = 0) : number => {
  if (a.length <= i && b.length <= i) {
    return 0;
  }
  const val = (a[i] || 0) - (b[i] || 0);
  return val !== 0 ? val : sortRecursive(a, b, i + 1);
}

const makeTable = (data: MarketPerShareData, metric: Metric, openModal: (modal: any) => void) => {
  const index: { [key: string]: { [key: string]: { manufacturer: ManufacturerData, brands: { [key: string]: MetricData } } } } = {};
  const manufacturerNames: { [key: string]: string } = {}
  const brandNames: { [key: string]: string } = {}
  const manufacturerOrderMap: { [key: number]: number[] } = {};
  const brandOrderMap: { [key: number]: { [key: number]: number[] } } = {};
  const periods: string[] = []

  Object.entries(data.manufactures).sort((b, a) => a[0].localeCompare(b[0])).forEach((manufacturerEntry, i) => {
    periods.push(manufacturerEntry[0]);
    index[manufacturerEntry[0]] = {};

    manufacturerEntry[1].forEach(manufacturer => {
      index[manufacturerEntry[0]][manufacturer.id] = {
        manufacturer, brands: {}
      }
      manufacturerNames[manufacturer.id] = manufacturer.description;
      if (manufacturerOrderMap[manufacturer.id] === undefined) {
        brandOrderMap[manufacturer.id] = {};
      }
      if (
        manufacturerOrderMap[manufacturer.id] === undefined 
      ) {
        manufacturerOrderMap[manufacturer.id] = [];
      }
      manufacturerOrderMap[manufacturer.id][i] = extractVal(manufacturer, metric);
      manufacturer.brands.forEach(brand => {
        brandNames[brand.id] = brand.description;
        index[manufacturerEntry[0]][manufacturer.id].brands[brand.id] = brand;
        if (
          brandOrderMap[manufacturer.id][brand.id] === undefined
        ) {
          brandOrderMap[manufacturer.id][brand.id] = [];
        }
        brandOrderMap[manufacturer.id][brand.id][i] = extractVal(brand, metric);
      })
    })
  });

  const manufacturerOrderList: string[] = Object.entries(manufacturerOrderMap).sort((a, b) => sortRecursive(b[1], a[1])).map(i => i[0]);
  const brandOrderList: { [key: string]: string[] } = {};
  Object.entries(brandOrderMap).forEach(brand => {
    brandOrderList[brand[0]] = Object.entries(brand[1]).sort((a, b) => sortRecursive(b[1], a[1])).map(i => i[0]);
  })

  const table: any[][] = [];
  const yAxis: { value: string, type: "MANUFACTURER" | "BRAND" }[] = [];
  const manufacturerOthers: string[] = [];

  manufacturerOrderList.forEach(manufacturerId => {
    const line: any[] = [];

    let shouldAddManufacturerLine = false;
    let manufacturerName: string | undefined = undefined;

    periods.forEach(period => {
      if (index[period][manufacturerId]) {
        manufacturerName = index[period][manufacturerId].manufacturer.description;
      }

      const value = optional(index[period][manufacturerId])
        .map(i => extractVal(i.manufacturer, metric))
        .filter(i => i !== 0)
        .get();

      if (value) {
        shouldAddManufacturerLine = true;
      }

      line.push(value ? <td>{ formatNumber(value) }</td> : <td />);
    });

    if (shouldAddManufacturerLine) {
      line.push(
        <td>
          { formatNumber(extractVal(data.manufacturerTotals[manufacturerId], metric)) }
        </td>
      );
      yAxis.push({ value: manufacturerNames[manufacturerId], type: "MANUFACTURER" });
      table.push(line);
    } else {
      if (manufacturerName) {
        manufacturerOthers.push(manufacturerName);
      }
      return;
    }

    const others: string[] = [];

    brandOrderList[manufacturerId].forEach(brandId => {
      const line: any[] = [];
      let shouldAddLine = false;
      let name: string | undefined = undefined;
      periods.forEach(period => {
        const brand = optional(index[period][manufacturerId])
          .map(i => i.brands[brandId])
          .get();

        if (brand) {
          name = brand.description;
        }

        const value = optional(brand)
          .map(i => extractVal(i, metric))
          .filter(i => i !== 0)
          .map(i => formatNumber(i))
          .get();

        if (value) shouldAddLine = true;
        line.push(!value ? <td /> : <td>{ value }</td>);
      });
      if (shouldAddLine) {
        line.push(
          <td>
            { formatNumber(extractVal(data.brandTotals[manufacturerId][brandId], metric)) }
          </td>
        );
        yAxis.push({ value: brandNames[brandId], type: "BRAND" });
        table.push(line);
      } else {
        if (name) {
          others.push(name);
        }
      }
    });

    if (others.length) {
      if (others.length == 1) {
          yAxis.push({ value: others[0], type: "BRAND" });
          table.push([ <td colSpan={ periods.length + 1 } /> ])
      } else {
        yAxis.push({ value: "Outros", type: "BRAND" });
        table.push([ <td colSpan={ periods.length + 1 } style={{ textAlign: "left" }}>{ others.join(", ") }</td> ])
      }
    }

  });

  if (manufacturerOthers.length) {
    yAxis.push({ value: "Outros", type: "MANUFACTURER" });
    table.push([ 
      <td 
        colSpan={ periods.length + 1 } 
        style={{ textAlign: "left" }}
      >
        <button className="hdmsps-others-btn" onClick={ () => {
          openModal(<OthersModal data={ manufacturerOthers } />)
        }}>
          { "+ " + manufacturerOthers.length + " fabricantes" } 
        </button>
      </td>
    ])
  }
  
  return {
    xAxis: [ ...periods, "TOTAL" ], yAxis, table
  }
}

const formatNumber = (n: number | null) => {
  return n === null ? null : n.toFixed(1).replace(".", ",");
}

const initialFilters: FiltersSelected = {
  categoryIds: [],
  channelIds: [],
  periods: [],
  ufs: [],
  segmentIds: [],
  metric: "VAL"
};

type OptionsName = "categories" | "channels" | "periods" | "ufs" | "segments";
type FilterName = "categoryIds" | "channelIds" | "periods" | "ufs" | "segmentIds";

const filterMap: {
  categories: FilterName,
  channels: FilterName,
  periods: FilterName,
  ufs: FilterName,
  segments: FilterName
} = {
  categories: "categoryIds",
  channels: "channelIds",
  periods: "periods",
  ufs: "ufs",
  segments: "segmentIds"
}

const MarketSharePerSegmentDashboard: FunctionComponent = () => {
  const dashboardRequest = useRequest<MarketPerShareData>("GET", baseUrl + "/api/v1/dashboard/market-share-per-segment");
  const { openModal } = useModal();
  const ref = useRef<{ 
    filters: FiltersSelected
  }>({ 
    filters: initialFilters
  });

  const [ { data, loading, filters }, setState ] = useState<State>({
    filters: initialFilters,
    loading: true
  });

  useEffect(() => { ref.current.filters = filters }, [ filters ]);

  const fetchReport = useCallback(async (filters: FiltersSelected, firstLoad: boolean, preserveFilter?: OptionsName) => {
    try {
      setState(prev => ({ ...prev, loading: true }));

      const params: any = {};

      if (filters.categoryIds.length) {
        params.categoryIds = filters.categoryIds.join(",")
      }
      if (filters.channelIds.length) {
        params.channelIds = filters.channelIds.join(",")
      }
      if (filters.periods.length) {
        params.periods = filters.periods.join(",")
      }
      if (filters.ufs.length) {
        params.ufs = filters.ufs.join(",")
      }
      if (filters.segmentIds.length) {
        params.segmentIds = filters.segmentIds.join(",")
      }

      const { data } = await dashboardRequest({
        params
      });

      setState(prev => {
        let newOptions = { ...data.filters };

        if (preserveFilter) {
          const filterParams = filters[filterMap[preserveFilter]];
          if (filterParams && filterParams.length) {
            delete newOptions[preserveFilter];
          }
        }

        return { 
          ...prev, 
          data: { ...data, filters: { ...(prev.data?.filters || {}), ...newOptions } }, 
          loading: false, 
          filters: { ...prev.filters, ...data.params }
        }
      })
    } catch (e) {
      openModal(makeErrorModal(e), { noClose: firstLoad });
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [ openModal, setState, dashboardRequest ])

  useEffect(() => { fetchReport(ref.current.filters, true) }, [ ref, fetchReport ])

  if (!data && loading) {
    return <LoadingFrame />
  }

  if (!data) {
    return null;
  }

  const updateDashboard = (filters: FiltersSelected, filter: OptionsName) => {
    setState(prev => ({ ...prev, filters }));
    fetchReport(filters, false, filter);
  }

  const table = makeTable(data, filters.metric, openModal);
  let row = 0;

  return (
    <Dashboard title="Market Share por Segmento">
      <div className="hdmsps-body">
        { loading ? <LoadingFrame /> : null }
        <div className="hdmsps-filters">
          <MultiSelect
            label="PERÍODOS"
            value={ filters.periods }
            onChange={ periods => updateDashboard({ ...filters, periods }, "periods") }
            options={ data.filters.periods }
          />
          <Select
            label="CATEGORIA"
            value={ filters.categoryIds.length ? String(filters.categoryIds[0]) : null }
            onChange={ category => updateDashboard({ 
              ...filters, 
              categoryIds: category ? [ parseInt(category) ] : [],
              periods: [],
              ufs: [],
              segmentIds: [],
              channelIds: []
            }, "categories") }
            options={ data.filters.categories.map(i => ({ key: String(i.key), value: i.value })) }
            required
          />
          <MultiSelect
            label="SEGMENTOS"
            value={ filters.segmentIds.map(i => String(i)) }
            onChange={ val => updateDashboard({ ...filters, segmentIds: val.map(i => parseInt(i)) }, "segments") }
            options={ data.filters.segments.map(i => ({ key: String(i.key), value: i.value })) }
          />
          <MultiSelect
            label="UFs"
            value={ filters.ufs }
            onChange={ ufs => updateDashboard({ ...filters, ufs }, "ufs") }
            options={ data.filters.ufs }
          />
          <MultiSelect
            label="CANAIS"
            value={ filters.channelIds.map(i => String(i)) }
            onChange={ val => updateDashboard({ ...filters, channelIds: val.map(i => parseInt(i))}, "channels") }
            options={ data.filters.channels.map(i => ({ key: String(i.key), value: i.value })) }
          />
          <div className="hdmsps-filter hdmsps-filter-metric">
            <div
              className={ `hdmsps-filter-metric-item ${ filters.metric === "VOL" ? "hdmsps-filter-metric-item-selected" : null }` }
              onClick={ () => setState(prev => ({ ...prev, filters: { ...prev.filters, metric: "VOL" } })) }>
              { liquidCategories.includes(filters.categoryIds[0]) ? "Litros" : "Quilos" }
            </div>
            <div 
              className={ `hdmsps-filter-metric-item ${ filters.metric === "VAL" ? "hdmsps-filter-metric-item-selected" : null }` }
              onClick={ () => setState(prev => ({ ...prev, filters: { ...prev.filters, metric: "VAL" } })) }>
              Valor
            </div>
            <div
              className={ `hdmsps-filter-metric-item ${ filters.metric === "UNIT" ? "hdmsps-filter-metric-item-selected" : null }` }
              onClick={ () => setState(prev => ({ ...prev, filters: { ...prev.filters, metric: "UNIT" } })) }>
              Transação
            </div>
          </div>
        </div>
        <table className="hdmsps-table">
          <thead>
            <tr>
              <td>Fabricante/Marca</td>
              {
                table.xAxis.map((item, index) => (
                  <td key={ index }>{ item.replace(/(\d{4})(\d{2})/, "$2/$1") }</td>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              table.table.map((line, index) => {
                if (table.yAxis[index].type === "MANUFACTURER") {
                  row = 0;
                } else {
                  row++;
                }

                const classes: string[] = [];
                classes.push(table.yAxis[index].type === "MANUFACTURER" ? "hdmsps-table-manufacturer" : "hdmsps-table-brand");
                if (row % 2 == 0) {
                  classes.push("hdmsps-table-color");
                }

                return (
                  <tr key={ index } className={ classes.join(" ") }>
                    <td>
                      {
                        table.yAxis[index].value
                      }
                    </td>
                    {
                      line.map((column, index) => (
                        <Fragment key={ index }>
                          { column }
                        </Fragment>
                      ))
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </Dashboard>
  );
}

export default MarketSharePerSegmentDashboard;