
import { SingUpUser } from '../components/domain/SingUpUser';
import useRequest from '../hooks/useRequest';

const useUser = () => {
    
  const singUpRequest = useRequest("POST", "/embed/info");

  const saveCompleteUserRequest = useRequest("POST", "/user/complete");

  const changePasswordRequest = useRequest("POST", "/user/change/password");

  

  const singUp = async (singUpUser: SingUpUser) : Promise<any> => {
    let data = {
      username: singUpUser.userName,
      firstName: singUpUser.firstName,
      lastName: singUpUser.lastName,
      email: singUpUser.corporativeEmail
    }    
    return singUpRequest({data});
  };

  const saveCompleteUser = async (singUpUser: SingUpUser) : Promise<any> => {
    let data = {
      password: singUpUser.password,
      username: singUpUser.userName,
      firstName: singUpUser.firstName,
      lastName: singUpUser.lastName,
      email: singUpUser.corporativeEmail,
      jobTitle: singUpUser.jobTitle,
      clientCompany : {
        name:singUpUser.clientCompany.name,
        cnpj:singUpUser.clientCompany.cnpj,
        qtdFunc:singUpUser.clientCompany.qtdFunc,
        companyActivity:singUpUser.clientCompany.companyActivity
      }
    }    
    return saveCompleteUserRequest({data, options: { auth: false }});
  };

  const changePassword = async (singUpUser: SingUpUser, token:string) : Promise<any> => {
    let data = {
      password: singUpUser.password
    }    
    return changePasswordRequest({data, options: { auth: false }, headers: {Token: token}});
  };

  return {
    singUp, saveCompleteUser, changePassword
  };

};

export default useUser;