import { useEffect, useState, Dispatch, SetStateAction } from "react";

type NonUndefined<T> = T extends undefined ? never : T;

function useLocalStorage<T>(defaultValue: NonUndefined<T>, key: string): [ NonUndefined<T>, Dispatch<SetStateAction<NonUndefined<T>>> ] {
  const [ value, setValue ] = useState(() => {
    const initialValue = window.localStorage.getItem(key);
    return initialValue !== null
      ? JSON.parse(initialValue) as NonUndefined<T>
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [ key, value ]);

  return [ value, setValue ];
}

export default useLocalStorage;