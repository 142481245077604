import { useCallback, useEffect, useRef } from "react";

function useSetInterval() {
  const handle = useRef<any>();

  useEffect(() => {
    return () => { if (handle.current) clearInterval(handle.current) };
  }, [ handle ])

  const setInterval = useCallback((fn, delay) => {
    if (handle.current) clearInterval(handle.current);
    
    handle.current = window.setInterval(fn, delay);

    return handle.current;
  }, [ handle ])

  return setInterval;
}

export default useSetInterval;