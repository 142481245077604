import React, { useEffect, useReducer } from "react";
import { currencyFormat } from "../utils/Util";
import {
  MetricsWebProduct,
  Period,
  ProductSelectionComponent,
  ProductWithPrice,
} from "./definitions";
import Select from "./Select";
import useCheckout from "./useCheckout";

interface Product {
  categoryId: number;
  categoryName: string;
  ufs: {
    uf: string;
    ufName: string;
  }[];
}

type Action =
  | { type: "PRODUCTS_LOADED"; products: Array<Product> }
  | { type: "PRODUCTS_LOAD_FAIL"; error: any }
  | { type: "ADD_CATEGORY"; id: number }
  | { type: "REMOVE_CATEGORY"; id: number }
  | { type: "ADD_UF"; uf: string; cat: number }
  | { type: "REMOVE_UF"; uf: string; cat: number }
  | { type: "VALUE_CHANGED"; value: ProductWithPrice<MetricsWebProduct> | null }
  | { type: "SET_PERIOD"; period: Period }
  | { type: "SET_INSTALLMENTS"; installments: number };

type Props = Parameters<ProductSelectionComponent<MetricsWebProduct>>[0];

interface State {
  period: Period;
  selectedCategories: Array<number>;
  selectedUfs: Record<number, Array<string>>;
  loadingValue: boolean;
  productsAvailable?: Array<Product>;
  loadingProducts: boolean;
  loadingProductsFailed: boolean;
  installments: number;
}

const calculatePrice = (
  state: State
): ProductWithPrice<MetricsWebProduct> | null => {
  if (
    !state.productsAvailable ||
    !state.selectedCategories.length ||
    Object.values(state.selectedUfs).find((uf) => !uf.length)
  ) {
    return null;
  }

  let total = 0;
  const categories: { id: number; name: string; ufs: string[] }[] = [];
  const totalCat = state.selectedCategories.length;

  state.selectedCategories.forEach((categoryId) => {
    const selectedUfs = state.selectedUfs[categoryId];
    const ufAmount = selectedUfs.length;
    const category = state.productsAvailable!.find(
      (p) => p.categoryId === categoryId
    );

    let ufValue: number;

    if (ufAmount === 1) {
      ufValue = 348.76;
    } else if (ufAmount <= 4) {
      ufValue = 313.88;
    } else if (ufAmount <= 9) {
      ufValue = 296.44;
    } else if (ufAmount <= 14) {
      ufValue = 285.98;
    } else {
      ufValue = 268.54;
    }

    const categoryValue = 647.69;
    const categoryTotal = ufValue * ufAmount;

    total += categoryTotal + categoryValue;

    categories.push({
      id: categoryId,
      name: category!.categoryName,
      ufs: selectedUfs,
    });
  });

  let discount: number;

  if (totalCat <= 1) {
    discount = 0;
  } else if (totalCat <= 4) {
    discount = 0.1;
  } else if (totalCat <= 10) {
    discount = 0.15;
  } else {
    discount = 0.2;
  }

  total = total - total * discount;

  if (state.period === "YEAR") {
    total *= 9;
  }

  return {
    product: {
      categories,
      installments: state.installments,
      recurrence: state.period,
    },
    type: "metricsweb",
    billings: [
      {
        recurrence: state.period,
        value: total,
        installments: state.installments,
      },
    ],
  };
};

const reducer = function (prev: State, action: Action): State {
  if (action.type === "PRODUCTS_LOADED") {
    return {
      ...prev,
      productsAvailable: action.products,
      loadingProducts: false,
    };
  }
  if (action.type === "PRODUCTS_LOAD_FAIL") {
    return { ...prev, loadingProducts: false, loadingProductsFailed: false };
  }
  if (action.type === "ADD_CATEGORY") {
    if (prev.selectedCategories.includes(action.id)) {
      return prev;
    }
    const selectedCategories = [...prev.selectedCategories, action.id];
    const selectedUfs = {
      ...prev.selectedUfs,

      [action.id]: prev.selectedUfs[action.id] || [],
    };
    return { ...prev, selectedCategories, selectedUfs };
  }
  if (action.type === "REMOVE_CATEGORY") {
    const selectedUfs = { ...prev.selectedUfs };
    delete selectedUfs[action.id];
    const selectedCategories = prev.selectedCategories.filter(
      (c) => c !== action.id
    );
    return { ...prev, selectedUfs, selectedCategories };
  }
  if (action.type === "ADD_UF") {
    const selectedUfs = {
      ...prev.selectedUfs,

      [action.cat]: !prev.selectedUfs[action.cat].includes(action.uf)
        ? [...prev.selectedUfs[action.cat], action.uf]
        : prev.selectedUfs[action.cat],
    };
    return { ...prev, selectedUfs };
  }
  if (action.type === "REMOVE_UF") {
    if (!prev.selectedUfs[action.cat]) {
      return prev;
    }
    const selectedUfs = {
      ...prev.selectedUfs,
      [action.cat]: prev.selectedUfs[action.cat].filter((i) => i !== action.uf),
    };
    let selectedCategories = prev.selectedCategories;
    if (!selectedUfs[action.cat].length) {
      delete selectedUfs[action.cat];
      selectedCategories = [...selectedCategories].filter(
        (i) => i !== action.cat
      );
    }
    return { ...prev, selectedUfs, selectedCategories };
  }
  if (action.type === "VALUE_CHANGED") {
    const newValues = {
      selectedUfs: {} as Record<number, string[]>,
      selectedCategories: [] as number[],
    };
    if (action.value) {
      newValues.selectedCategories = action.value.product.categories.map(
        (c) => c.id
      );
      action.value.product.categories.forEach((category) => {
        newValues.selectedUfs[category.id] = category.ufs;
      });
    }
    return { ...prev, ...newValues };
  }
  if (action.type === "SET_PERIOD") {
    return { ...prev, period: action.period, installments: 1 };
  }
  if (action.type === "SET_INSTALLMENTS") {
    return { ...prev, installments: action.installments };
  }
  return prev;
};

const makeInitialProps = function (props: Props): State {
  return {
    loadingValue: false,
    loadingProducts: true,
    loadingProductsFailed: false,
    selectedUfs: [],
    selectedCategories: [],
    period: "MONTH",
    installments: 1,
  };
};

function keyBy<T>(arr: Array<T>, keyBy: keyof T): Record<string, T> {
  const map: Record<string, T> = {};
  arr.forEach((i) => {
    const key = String(i[keyBy]);
    if (!map[key]) {
      map[key] = i;
    }
  });
  return map;
}

function hash(product: ProductWithPrice<MetricsWebProduct>) {
  const value = product.product.categories
    .sort((a, b) => a.id - b.id)
    .map((category) => {
      return (
        category.id +
        ":" +
        category.ufs.sort((a, b) => a.localeCompare(b)).join(",")
      );
    })
    .join("|");
  return value;
}

function compare(
  a: ProductWithPrice<MetricsWebProduct> | null,
  b: ProductWithPrice<MetricsWebProduct> | null
): boolean {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.product.categories.length !== b.product.categories.length) return false;
  return hash(a) === hash(b);
}

const MetricsWebForm: ProductSelectionComponent<MetricsWebProduct> =
  function MetricsWebForm(props) {
    const [state, dispatch] = useReducer(reducer, props, makeInitialProps);
    const { value, onChange } = props;
    const { getAvailableMetricsWebCategories: getProducts } = useCheckout();
    const [accept, setAccept] = React.useState(false);

    useEffect(() => {
      const fn = async () => {
        try {
          const products = await getProducts();
          dispatch({ type: "PRODUCTS_LOADED", products });
        } catch (error) {
          dispatch({ type: "PRODUCTS_LOAD_FAIL", error });
        }
      };

      fn();
    }, [getProducts]);

    const indexedCategories = keyBy(
      state.productsAvailable || [],
      "categoryId"
    );
    const price = calculatePrice(state);

    function handleOnNext() {
      onChange(price);
      props.onNext();
    }

    useEffect(() => {
      dispatch({ type: "VALUE_CHANGED", value });
    }, [dispatch, value]);

    return (
      <div className="h-sumw__grid h-sumw_metricsweb">
        <div className="h-sumw__col-10">
          {!state.productsAvailable ? (
            "Carregando categorias..."
          ) : (
            <>
              <p className="h-sumw__info-text">
                Selecione as categorias e estados que deseja acompanhar
              </p>
              <div>
                <div className="h-sumw__spacing-after h-sumw__spacing-before">
                  <select
                    className="h-sumw__input"
                    onChange={(e) =>
                      dispatch({
                        type: "ADD_CATEGORY",
                        id: parseInt(e.target.value),
                      })
                    }
                  >
                    <option>Selecione uma categoria...</option>
                    {state.productsAvailable
                      .filter(
                        (p) => !state.selectedCategories.includes(p.categoryId)
                      )
                      .map((cat) => (
                        <option key={cat.categoryId} value={cat.categoryId}>
                          {cat.categoryName}
                        </option>
                      ))}
                  </select>
                </div>
                {[...state.selectedCategories]
                  .reverse()
                  .map((selectedCategory) => {
                    const category =
                      indexedCategories[String(selectedCategory)];
                    const indexedUfs = keyBy(category.ufs, "uf");
                    return (
                      <div
                        key={category.categoryId}
                        className="h-sumw__spacing-after h-sumw__spacing-before"
                      >
                        <h3 className="h-sumw__sub-title">
                          {category.categoryName}
                        </h3>
                        <table className="h-sumw__table h-sumw__spacing-after">
                          <tbody>
                            {state.selectedUfs[category.categoryId].map(
                              (selectedUf) => {
                                const uf = indexedUfs[String(selectedUf)];
                                return (
                                  <tr key={uf.uf}>
                                    <td>
                                      <span className="h-sumw__uf-name">
                                        {uf.ufName}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        className="h-sumw__button"
                                        onClick={() =>
                                          dispatch({
                                            type: "REMOVE_UF",
                                            uf: uf.uf,
                                            cat: category.categoryId,
                                          })
                                        }
                                      >
                                        Remover
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            <tr>
                              <td style={{ width: "100%" }}>
                                <select
                                  className="h-sumw__input"
                                  onChange={(e) =>
                                    dispatch({
                                      type: "ADD_UF",
                                      uf: e.target.value,
                                      cat: category.categoryId,
                                    })
                                  }
                                >
                                  <option>Selecione um estado...</option>
                                  {category.ufs
                                    .filter(
                                      (uf) =>
                                        !state.selectedUfs[
                                          category.categoryId
                                        ].includes(uf.uf)
                                    )
                                    .map((uf) => (
                                      <option key={uf.uf} value={uf.uf}>
                                        {uf.ufName}
                                      </option>
                                    ))}
                                </select>
                              </td>
                              <td>
                                <button
                                  className="h-sumw__button"
                                  onClick={() =>
                                    dispatch({
                                      type: "REMOVE_CATEGORY",
                                      id: category.categoryId,
                                    })
                                  }
                                >
                                  Remover Categoria
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
              </div>
              <p className="h-sumw__info-text">
                Valor mensal para assinatura das categorias e estados
                selecionados
              </p>
              {price ? (
                <>
                  <div
                    className={
                      state.period !== "YEAR"
                        ? "h-sumw__grid h-sumw__spacing-before"
                        : "h-sumw__grid h-sumw__spacing-before fulled"
                    }
                  >
                    <div className="h-sumw__col-3">
                      <Select
                        label="Plano"
                        required
                        options={[
                          { key: "MONTH", value: "Mensal" },
                          { key: "YEAR", value: "Anual" },
                        ]}
                        value={state.period}
                        onChange={(e) =>
                          dispatch({
                            type: "SET_PERIOD",
                            period: e.target.value as Period,
                          })
                        }
                      />
                    </div>
                    <div className="h-sumw__col-3">
                      {state.period !== "YEAR" ? null : (
                        <Select
                          label="Parcelas"
                          required
                          options={[
                            { key: "1", value: "1 Parcela" },
                            { key: "2", value: "2 Parcelas" },
                            { key: "3", value: "3 Parcelas" },
                          ]}
                          value={state.installments + ""}
                          onChange={(e) =>
                            dispatch({
                              type: "SET_INSTALLMENTS",
                              installments: parseInt(e.target.value),
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="h-sumw__currency">
                    <div>
                      {state.installments === 1 ? null : (
                        <span
                          className="h-sumw__currency__info"
                          style={{ marginLeft: "0" }}
                        >
                          {state.installments}x sem juros de
                        </span>
                      )}
                      <span className="hd-featured">
                        {currencyFormat(
                          price.billings[0].value / state.installments
                        )}
                      </span>
                      <span className="h-sumw__currency__info">
                        (
                        {price.billings[0].recurrence === "MONTH"
                          ? "ao mês"
                          : "ao ano"}
                        )
                      </span>
                    </div>
                    <div style={{ fontWeight: "normal", fontSize: "0.5em" }}>
                     {state.period !== "YEAR" ? null : <>equivalente a 12 meses X {currencyFormat(
                          price.billings[0].value / 12
                        )} - 25% de desconto</>}
                    </div>
                  </div>
                </>
              ) : (
                <p className="h-sumw__warn-box">
                  Selecione ao menos uma categoria e um estado por categoria.
                </p>
              )}
              <div className="h-sumw_buttonwrap">
                <button
                  className="h-sumw__button"
                  disabled={!calculatePrice(state) || !accept }
                  onClick={handleOnNext}
                >
                  Próximo
                </button>
                <div className="terms-accept">
                  <input type="checkbox" name="accept" id="accept" checked={accept} onChange={({target}) => setAccept(target.checked)}/>
                  Li e concordo com os <a href="https://metricsweb.ehorus.com.br/termos.html" target="blank" title="Termos de Uso"> Termos de Uso</a> e <a href="https://dashboard.ehorus.com.br/politica-de-privacidade" target="blank" title="Política de Privacidade">Política de Privacidade</a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

export default MetricsWebForm;
