export const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    fill="none"
    viewBox="0 0 17 17"
  >
    <g fill="#fff" clipPath="url(#clip0_13_492)">
      <path d="M8.81 2.307c2.115 0 2.366.009 3.198.046.774.034 1.191.164 1.47.272.368.143.634.316.91.591.278.279.448.542.59.91.109.278.239.699.273 1.47.037.835.046 1.085.046 3.198 0 2.117-.01 2.367-.046 3.2-.034.773-.164 1.19-.273 1.469a2.45 2.45 0 0 1-.59.91 2.4 2.4 0 0 1-.91.59c-.279.109-.7.239-1.47.273-.835.037-1.086.046-3.199.046-2.116 0-2.366-.01-3.199-.046-.773-.034-1.19-.164-1.47-.273a2.45 2.45 0 0 1-.909-.59 2.4 2.4 0 0 1-.59-.91c-.109-.278-.239-.7-.273-1.47-.037-.835-.046-1.086-.046-3.199 0-2.116.009-2.366.046-3.199.034-.773.164-1.19.272-1.47a2.45 2.45 0 0 1 .591-.909c.279-.278.542-.448.91-.59.278-.109.699-.239 1.47-.273.832-.037 1.082-.046 3.198-.046m0-1.427c-2.15 0-2.42.01-3.265.047-.841.037-1.42.173-1.92.368a3.9 3.9 0 0 0-1.405.916 3.9 3.9 0 0 0-.916 1.401c-.195.505-.331 1.08-.368 1.921C.898 6.381.889 6.65.889 8.801s.01 2.419.047 3.264c.037.841.173 1.42.368 1.92.204.524.473.966.916 1.405.439.44.881.712 1.401.913.504.195 1.08.331 1.921.368.845.037 1.114.047 3.264.047s2.42-.01 3.264-.047c.842-.037 1.42-.173 1.922-.368a3.9 3.9 0 0 0 1.4-.913c.44-.439.713-.881.914-1.401.194-.504.33-1.08.368-1.921.037-.845.046-1.114.046-3.264s-.01-2.42-.046-3.264c-.037-.842-.174-1.42-.368-1.922a3.7 3.7 0 0 0-.907-1.407 3.9 3.9 0 0 0-1.401-.913c-.505-.195-1.08-.331-1.922-.368C11.23.89 10.96.88 8.81.88"></path>
      <path d="M8.81 4.732a4.07 4.07 0 0 0 0 8.137 4.07 4.07 0 0 0 0-8.137m0 6.707a2.64 2.64 0 1 1 0-5.278 2.64 2.64 0 0 1 0 5.278M13.989 4.571a.95.95 0 1 1-1.9 0 .95.95 0 0 1 1.9 0"></path>
    </g>
    <defs>
      <clipPath id="clip0_13_492">
        <path fill="#fff" d="M.89.88h15.84v15.84H.89z"></path>
      </clipPath>
    </defs>
  </svg>
);
