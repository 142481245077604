import { HexagonBG } from "../../svg/HexagonBG";
import { Instagram } from "../../svg/Instagram";
import { LinkedIn } from "../../svg/LinkedIn";
import { Spotify } from "../../svg/Spotify";
import { Youtube } from "../../svg/Youtube";

export const FgvFooter = () => {
  const menus = [
    {
      title: "Sobre",
      items: [
        { text: "Quem somos", link: "https://neogrid.com/quem-somos/" },
        { text: "Trabalhe conosco", link: "https://neogridcarreiras.gupy.io/" },
        {
          text: "Relatório de Transparência e Igualdade Salarial",
          link: "https://neogrid.com/relatorio-igualdade-salarial/",
        },
        {
          text: "Relação com Investidores",
          link: "https://ri.neogrid.com/",
          target: "_blank",
          rel: "noopener",
        },
        {
          text: "Seja um parceiro",
          link: "https://neogrid.com/programa-neogrid-partner/",
        },
        { text: "Notícias", link: "https://neogrid.com/noticia/" },
      ],
    },
    {
      title: "Conteúdo",
      items: [
        { text: "Blog", link: "https://neogrid.com/blog/" },
        { text: "Guias", link: "https://neogrid.com/conteudo/" },
        { text: "Histórias de sucesso", link: "https://neogrid.com/cases/" },
        { text: "Podcast", link: "https://neogrid.com/podcasts/" },
        { text: "Eventos", link: "https://neogrid.com/evento/" },
      ],
    },
    {
      title: "Contatos",
      items: [
        {
          text: "Fale com vendas",
          link: "https://neogrid.com/fale-com-um-especialista/",
        },
        { text: "Suporte técnico", link: "https://neogrid.com/suporte/" },
        {
          text: "Fale com financeiro",
          link: "https://neogrid.com/contato-financeiro/",
        },
        {
          text: "Fale com jurídico",
          link: "https://neogrid.com/contato-juridico/",
        },
        {
          text: "Fale com o RI",
          link: "https://ri.neogrid.com/servicos-aos-investidores/fale-com-o-ri/",
        },
        {
          text: "Atendimento ao titular de dados pessoais",
          link: "https://neogrid.com/atendimento-ao-titular-de-dados-pessoais/",
        },
        { text: "Imprensa", link: "https://neogrid.com/imprensa/" },
        { text: "Ouvidoria", link: "https://help.neogrid.com/pt-BR/ouvidoria" },
      ],
    },
    {
      title: "Legal",
      items: [
        {
          text: "Canal de ética",
          link: "https://www.contatoseguro.com.br/pt/neogrid",
          target: "_blank",
          rel: "noopener",
        },
        {
          text: "Política de cookies",
          link: "https://neogrid.com/politica-de-cookies/",
        },
        {
          text: "Política de gestão",
          link: "https://www.neogrid.com/pdf/politica-de-gestao-portugues.pdf",
          target: "_blank",
          rel: "noopener",
        },
        {
          text: "Política de privacidade",
          link: "https://neogrid.com/politica-de-privacidade/",
        },
        {
          text: "Termos de uso",
          link: "https://neogrid.com/termos-e-condicoes-de-uso/",
        },
        {
          text: "Política de segurança da informação para terceiros",
          link: "https://neogrid.com/politica-de-seguranca-da-informacao-para-terceiros/",
        },
      ],
    },
  ];

  const socialLinks = [
    {
      href: "https://www.linkedin.com/company/neogrid",
      Icon: LinkedIn,
    },
    { href: "https://www.instagram.com/neogrid", Icon: Instagram },
    {
      href: "https://www.youtube.com/user/neogridscm",
      Icon: Youtube,
    },
    {
      href: "https://open.spotify.com/show/3xsJUpzGgqLLOFBrUnh3RT?si=Q_JczaArT3uhI-Xoe0XX6w",
      Icon: Spotify,
    },
  ];

  const partnerLinks = [
    {
      href: "https://arker.com.br/?__hstc=250945689.67b78183b16d28ec72af384dfc5f8f55.1716497400282.1733514656418.1733579989315.9&__hssc=250945689.1.1733579989315&__hsfp=2926623495",
      src: "https://neogrid.com/wp-content/themes/neogrid/img/footer/1.png",
      alt: "ARKER",
    },
    {
      href: "https://www.ehorus.com.br/?__hstc=250945689.67b78183b16d28ec72af384dfc5f8f55.1716497400282.1733514656418.1733579989315.9&__hssc=250945689.1.1733579989315&__hsfp=2926623495",
      src: "https://neogrid.com/wp-content/themes/neogrid/img/footer/2.png",
      alt: "HORUS",
    },
    {
      href: "https://lett.digital/",
      src: "https://neogrid.com/wp-content/themes/neogrid/img/footer/3.png",
      alt: "LETT",
    },
    {
      href: "https://predify.me/?__hstc=250945689.67b78183b16d28ec72af384dfc5f8f55.1716497400282.1733514656418.1733579989315.9&__hssc=250945689.1.1733579989315&__hsfp=2926623495",
      src: "https://neogrid.com/wp-content/themes/neogrid/img/footer/4.png",
      alt: "PREDIFY",
    },
    {
      href: "https://smarket.com.br/?__hstc=250945689.67b78183b16d28ec72af384dfc5f8f55.1716497400282.1733514656418.1733579989315.9&__hssc=250945689.1.1733579989315&__hsfp=2926623495",
      src: "https://neogrid.com/wp-content/themes/neogrid/img/footer/5.png",
      alt: "SMARKET",
    },
  ];

  return (
    <footer className="fgv-section fgv-footer">
      <div className="fgv-container flex-col">
        <div className="row">
          <div className="col-12 d-block d-sm-none mb-5">
            <small className="d-block mb-3 text-muted mt-3">
              A Neogrid é uma multinacional referência em soluções inteligentes
              para a gestão da cadeia de suprimentos.
            </small>
            <small>© 2024 Neogrid — Todos os direitos reservados</small>
          </div>
          {menus.map((menu, index) => (
            <div key={index} className="col-12 col-md-2">
              <section className="widget widget_nav_menu">
                <h3 className="widget-title">{menu.title}</h3>
                <ul className="menu list-unstyled text-small">
                  {menu.items.map((item, idx) => (
                    <li key={idx}>
                      <a
                        href={item.link}
                        target={item.target || "_self"}
                        rel={item.rel || ""}
                      >
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          ))}
          <div className="col-12 col-md-2">
            <section className="widget widget_block">
              <h5 style={{ opacity: 0 }}>Neogrid News</h5>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="footer-social">
              {socialLinks.map(({ Icon, href }, index) => (
                <a key={index} {...{ href }}>
                  <HexagonBG width={38} height={40} />
                  <i>
                    <Icon />
                  </i>
                </a>
              ))}
            </div>
            <div className="d-none d-md-block d-lg-block">
              <section id="block-7" className="widget widget_block">
                <small className="d-block mb-3 text-muted mt-3">
                  A Neogrid é uma multinacional referência em soluções
                  inteligentes para a gestão da cadeia de suprimentos.
                </small>
              </section>
              <small>© 2024 Neogrid — Todos os direitos reservados</small>
            </div>
          </div>
          <section
            id="block-8"
            className="d-flex align-items-end bd-highlight mb-3 widget_block"
          >
            {partnerLinks.map((partner, index) => (
              <div key={index} className="p-2 bd-highlight">
                <a
                  href={partner.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img decoding="async" src={partner.src} alt={partner.alt} />
                </a>
              </div>
            ))}
          </section>
        </div>
      </div>
    </footer>
  );
};
