import { FunctionComponent } from "react";
import ModalTitle from "../ui/ModalTitle";
import Modal from "./Modal";

const makeModal = (title:string, message: string) : any => {
  return (
    <>
      <ModalTitle> { title } </ModalTitle>
      <p style={{ marginTop: "20px", textAlign: "center" }}>
        { message }
      </p>
    </>
  )
}

export default makeModal;

export const makeModalChild: FunctionComponent<{ 
  title: string, 
  body: FunctionComponent
}> = (props) => {
  const { title, body } = props;
  
  return (
    <>
      <ModalTitle>{ title }</ModalTitle>
      { body }
    </>
  )
}