import { FunctionComponent, useEffect, useState, useRef } from "react";
import { arraysEqual, optional } from "../../utils/Util";

export interface Option {
  key?: string,
  value: string,
  disabled?: boolean
}

export interface Props {
  label: string,
  value: string[],
  placeholder?: string,
  required?: boolean,
  options: Option[],
  onChange?: (val: string[]) => void,
  onSelectOpen?: () => void,
  onLeave?: () => {},
  disabled?: boolean
}

interface State {
  open: boolean,
  valueCopy: string[],
}

const MultiSelect: FunctionComponent<Props> = props => {
  const { 
    label, 
    options, 
    value, 
    placeholder = "Todos",
    required = false,
    onLeave,
    onSelectOpen,
    onChange,
    disabled = false
  } = props;
  const [ { open, valueCopy }, setState ] = useState<State>({ open: false, valueCopy: value });
  const selectDivRef = useRef<HTMLDivElement>(null);

  const toggleOptions = (open: boolean) => {
    if (open) {
      if (onSelectOpen) onSelectOpen();
      setState(prev => ({ ...prev, valueCopy: value }));
    } else {
      if (onChange && !arraysEqual(valueCopy, value)) onChange(valueCopy)
    }
    setState(prev => ({ ...prev, open }))
  };

  const optionSelected = value.length 
    ? (value.length === 1 
        ? optional(options.find(i => i.key === value[0] || i.value === value[0])).map(i => i.value).getOrDefault("1 Selecionado")
        : value.length + " Selecionados") 
    : placeholder

  const makeNewValue = (item: string | null): string[] => {
    if (!item) {
      return [];
    }
    if (valueCopy.includes(item)) {
      return valueCopy.filter(i => i !== item);
    }
    return [ ...valueCopy, item ];
  }

  const handleOnChange = (item: string | null) => {
    if (disabled) return;
    setState(prev => ({ ...prev, valueCopy: makeNewValue(item) }));
  }

  useEffect(() => {
    let listener : ((e: MouseEvent) => void) | null = null;

    if (open) {
      listener = (e: MouseEvent) => { 
        if (!selectDivRef.current?.contains(e.target as Node)) toggleOptions(false);
        if (onLeave) onLeave();
      };
      document.addEventListener("click", listener);
    }

    return () => { if (listener) document.removeEventListener("click", listener) }
  })

  return (
    <div className={ `hdmsps-filter ${ disabled ? 'hdmsps-filter--disabled' : '' }` }>
      <div className="hdmsps-filter-label">
        { label }
      </div>
      <div ref={ selectDivRef } className="hdmsps-options-wrapper">
        <div className="hdmsps-filter-box" onClick={ () => toggleOptions(!open) }>
          { optionSelected }
        </div>
        <div className="hdmsps-options" style={{ display: open ? "block" : "none" }}>
          {
            required ? null : (
              <div className="hdmsps-option" onClick={ () => handleOnChange(null) }>
                <div 
                  className={ `hdmsps-option-select ${ !valueCopy.length ? "hdmsps-option-select-active" : "" }` } 
                  onClick={ () => handleOnChange(null) }
                />
                <span>
                  { placeholder }
                </span>
              </div>
            )
          }
          <div className="hdmsps-options-separator"></div>
          {
            options.map(option => (
              <div 
                className={ `hdmsps-option ${ option.disabled ? 'hdmsps-option-disabled' : '' }` }
                onClick={ () => !option.disabled && handleOnChange(option.key || option.value) }
                key={ option.key }
              >
                {
                  option.disabled ? null : (
                    <div 
                      className={ `hdmsps-option-select ${ valueCopy.includes(option.key || option.value) ? "hdmsps-option-select-active" : "" }` } 
                    />
                  )
                }
                <span>
                  { option.value }
                </span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default MultiSelect;