import { ReactNode, useState } from "react";

type Props = {
  fullPage: boolean;
};

const slide_list = [
  {
    href: "/",
    backgroundImage: "url(/static/noticia1.png)",
  },
  {
    href: "/",
    backgroundImage: "url(/static/noticia2.png)",
  },
  {
    href: "/",
    backgroundImage: "url(/static/noticia3.png)",
  },
  {
    href: "/",
    backgroundImage: "url(/static/noticia4.png)",
  },
  {
    href: "/",
    backgroundImage: "url(/static/noticia5.png)",
  },
];

export const MediaCarousel = ({ fullPage }: Props) => {
  const [page, setPage] = useState<number>(0);
  const items: ReactNode[] = [];

  const canGoForward = page + 3 < slide_list.length;
  const canGoBackward = page > 0;

  for (let i = page; i < page + 3; i++) {
    const { backgroundImage } = slide_list[i];
    items.push(
      <div className="hd-fgv__on-media__col" key={i}>
        <div className="hd-fgv__on-media__item" style={{ backgroundImage }} />
      </div>
    );
  }

  const onGoForward = () => {
    if (canGoForward) setPage(page + 1);
  };

  const onGoBackward = () => {
    if (canGoBackward) setPage(page - 1);
  };

  if (!fullPage) return null;
  return (
    <section className="fgv-section">
      <div className="fgv-container flex-col media-carousel">
        <h2 className="hd-fgv__title">
          Você pode já ter ouvido falar sobre
          <br /> a HORUS na mídia
        </h2>
        <div className="hd-fgv__on-media__row">
          <div
            onClick={onGoBackward}
            className={`horus__slider__control horus__slider__control__left ${
              canGoBackward ? "horus__slider__control--active" : ""
            }`}
          >
            <div></div>
            <div></div>
          </div>
          {items}
          <div
            onClick={onGoForward}
            className={`horus__slider__control horus__slider__control__right ${
              canGoForward ? "horus__slider__control--active" : ""
            }`}
          >
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};
