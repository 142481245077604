type Props = {
  fullPage: boolean;
};

const AUXILIARY_NAV_LIST = [
  {
    name: "Cestas de Consumo",
    to: "#ferramenta",
  },
  {
    name: "Categorias de Produto",
    to: "#plus",
  },
  {
    name: "Fabricantes e Marcas",
    to: "#subscribe",
  },
];
export const AuxiliaryNav = ({ fullPage }: Props) => {
  if (!fullPage) return null;
  return (
    <div className="fgv-section">
      <i id="ferramenta" className="small-break-scroll" />
      <i id="plus" className="small-break-scroll" />
      <div className="fgv-auxiliary-nav">
        <ul>
          {AUXILIARY_NAV_LIST.map(({ name, to }) => (
            <li key={to}>
              <a href={to}>{name}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
