import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { mainColor } from '../../constants';


const Header = styled.div`
  background-color: ${ mainColor };
  text-align: center;
  position: relative;
  height: 1300px;
`;

const Logo = styled.img`
  max-height: 50px;
  max-width: 90%;
  margin: 35px auto;
`;

const Content = styled.div`
	width: 96%;
	margin: 0 auto;
	max-width: 96%;
	margin-top: -1190px;
	position: relative;
`;

const HeaderPageGuest : FunctionComponent = (props) => {
  const { children } = props;

  return (
    <>
      <Header>
        <Logo
          src="/static/logo-horus-w.png"
          alt="Logo Horus" />
      </Header>
      <Content>
        { children }
      </Content>
    </>
  );
};

export default HeaderPageGuest;