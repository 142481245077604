import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { mainColor } from '../../constants'

const Title = styled.div`
  text-align: center;
  font-size: 28px;
  margin-top: 30px;
`

const Separator = styled.div`
  border: solid 1px ${ mainColor };
  width: 43px;
  margin: 8px auto;
  height: 0.9px;
  background-color: ${ mainColor };
`

const ModalTitle : FunctionComponent = props => {
  const { children } = props
  return (
    <>
      <Title>
        { children }
      </Title>
      <Separator />
    </>
  )
}

export default ModalTitle