import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { getRandomColor, optional } from '../../../utils/Util';
import { DashboardData, Filters, ShareEvolution, MetricType } from './definitions';

const formatValue = (val?: number) : string => {
  return val ? val.toFixed(1).replace(".", ",") + "%" : "--"
}

type ColorMap = { [id: string]: string };

const staticColors = [
  "#C0504E",
  "#F79649",
  "#1F487C",
  "#49ADC4",
  "#604A7B",
  "#9BBB58",
  "#4F81BC",
];

const fixedColors : ColorMap = {
  "0": "lightgray"
}

const typeMap = {
  VAL: "value",
  VOL: "volume",
  UNIT: "unity"
}

const extractMetric = (metric: MetricType, item: ShareEvolution) => {
  switch (metric) {
    case "UNIT": return item.unity;
    case "VAL": return item.value;
    case "VOL": return item.volume;
  }
}

const minHeight = 3;

const makeHeightMaps = (data: DashboardData, metric: MetricType): Array<{ [key: number]: number }> => {
  const heightMaps: Array<{ [key: number]: number }> = [];

  data.shareEvolution.forEach(monthShares => {
    
    let smallest: number | null = null;
    let overAvgAmount = 0;
    let underAvgAmount = 0;
    const onlyValids = monthShares.map(i => ({ ...i, val: extractMetric(metric, i) * 100 })).filter(i => i.val > 0);

    const avg = onlyValids.map(i => {
      smallest = smallest === null ? i.val : (i.val < smallest ? i.val : smallest);
      return i.val;
    }).reduce((a, b) => a + b) / onlyValids.length;

    const map: { [key: number]: number } = {}

    if (!smallest || smallest > minHeight) {
      onlyValids.forEach(i => {
        map[i.id] = i.val;
      })

    } else {

      onlyValids.forEach(i => {
        if (i.val >= avg) {
          overAvgAmount++;
        } else {
          underAvgAmount++;
        }
      });

      const increaseNeeded = minHeight - smallest;
      const valueToMove = increaseNeeded * underAvgAmount;

      onlyValids.forEach(i => {
        map[i.id] = i.val >= avg ? i.val - (valueToMove / overAvgAmount) : i.val + increaseNeeded;
      })
    }

    let sum = 0;
    let biggest = 0;
    let biggestId: number | null = null;

    (Object.keys(map) as unknown as number[]).forEach(id => {
      sum += map[id];
      if (map[id] > biggest) {
        biggest = map[id];
        biggestId = id;
      }
    });

    if (biggestId) {
      map[biggestId] = biggest + (100 - sum);
    }

    heightMaps.push(map);
  });

  return heightMaps;
}

const makeOrder = (data: DashboardData, metric: MetricType): { [key: number]: number } => {
  const order: { [key: number]: number } = {};
  if (data.shareEvolution.length) {
    data.shareEvolution[data.shareEvolution.length - 1]
      .filter(i => i.id !== 0)
      .sort((a, b) => extractMetric(metric, a) - extractMetric(metric, b))
      .forEach((item, index) => {
        order[item.id] = index;
      });
    order[0] = -1;
  }
  return order;
}

const EvolutionaryChart : FunctionComponent<{ data: DashboardData, filters: Filters }> = props => {
  const { data, filters } = props;
  const [ { colors, legend, order, heights }, setState ] = useState<{ 
    colors: ColorMap, 
    legend: { id: number, name: string }[], 
    order: { [key: number]: number },
    heights: { [key: number]: number }[]
  }>({ 
    colors: {}, legend: [], order: {}, heights: [] 
  });
  const metric = filters.metric;

  useEffect(() => {
    let i = 0;
    const colors: ColorMap = {};
    const legend: { id: number, name: string }[] = [];

    data.shareEvolution.forEach(month => {
      month.forEach(manufacturer => {
        if (!colors[manufacturer.id]) {
          legend.push({ id: manufacturer.id, name: manufacturer.manufacture || "Outros" });
          if (fixedColors[manufacturer.id]) {
            colors[manufacturer.id] = fixedColors[manufacturer.id];
            return;
          }
          if (i < staticColors.length) {
            colors[manufacturer.id] = staticColors[i];
            i++;
            return;
          }
          colors[manufacturer.id] = getRandomColor();
        }
      });
    });

    setState(prev => ({ ...prev, colors, legend }))
  }, [ data, setState ])

  useEffect(() => {
    const order = makeOrder(data, metric);
    const heights = makeHeightMaps(data, metric);
    setState(prev => ({ ...prev, order, heights }));
  }, [ data, metric, setState ]);

  return (
    <div className="hdpd-box hdpd-evol-wrapper">
      <div className="hdpd-evol-title">
        Evolutivo Mensal por Principais Fabricantes
      </div>
      <div className="hdpd-evol-content clearfix">
        <div className="hdpd-evol-bars">
          <div className="hdpd-evol-x-axis">
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "100%" }}>
              100%
            </div>
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "80%" }}>
              80%
            </div>
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "60%" }}>
              60%
            </div>
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "40%" }}>
              40%
            </div>
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "20%" }}>
              20%
            </div>
            <div className="hdpd-evol-x-axis-indicator" style={{ bottom: "0%" }}>
              0%
            </div>
          </div>
          {
            data.shareEvolution.map((month, index1) => (
              <div className="hdpd-evol-bar" key={ index1 }>
                <div className="hdpd-evol-bar-label">{ month[0].month }</div>
                {
                  month.filter(i => extractMetric(metric, i)).sort((a, b) => order[b.id] - order[a.id]).map((item, index2) => (
                    <div 
                      className="hdpd-evol-bar-item" 
                      style={{ 
                        height: optional(heights[index1]).map(i => i[item.id]).getOrDefault(extractMetric(metric, item) * 100) + "%", 
                        backgroundColor: colors[item.id] 
                      }} 
                      key={ index2 }
                    >
                      <span className="hdpd-evol-bar-value">{ formatValue(extractMetric(metric, item) * 100) }</span>
                    </div>
                  ))
                }
              </div>
            ))
          }
        </div>
        <div className="hdpd-evol-legend">
          {
            legend.map(i => (
              <div className="hdpd-evol-legend-item" key={ i.id }>
                <span className="hdpd-evol-legend-item-indicator" style={{ backgroundColor: colors[i.id] }} />
                <span className="hdpd-evol-legend-item-name">{ i.name }</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default EvolutionaryChart;