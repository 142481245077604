function Thankyou() {
  return (
    <>
      <p className="h-sumw__info-text">
        Obrigado!
      </p>
      <p className="h-sumw__spacing-before h-sumw__spacing-after thankyou">
        Você fez sua compra com sucesso. Após o pagamento ser aprovado você terá acesso ao produto.
      </p>
    </>
  )
} 

export default Thankyou;