import React, { FunctionComponent, useEffect, useState } from "react";
import { GoogleAnalyticsActions } from "../../hooks/useGoogleAnalytics";
import { currencyFormat, getErrorMessage, keyBy } from "../utils/Util";
import { Billing, ProductWithPrice } from "./definitions";
import Input from "./Input";
import useCheckout from "./useCheckout";
new GoogleAnalyticsActions("G-6TXX26VFFT");

const ReviewStep: FunctionComponent<{
  product: ProductWithPrice<unknown>;
  onDiscountApplied: (product: ProductWithPrice<unknown>) => void;
  component: React.ComponentType<{ product: ProductWithPrice<unknown> }>;
  paymentProfile: string;
  onNext: () => void;
}> = function (props) {
  const Component = props.component;
  const { getPrices, buy } = useCheckout();
  const { product } = props;
  const [state, setState] = useState({
    loadingPrices: true,
    loadPricesError: null as string | null,
    prices: null as Billing[] | null,
    buying: false,
    buyError: null as string | null,
    discountCoupon: "",
    discount: false as boolean,
  });

  useEffect(
    function () {
      const fn = async function () {
        try {
          const prices = await getPrices(product);
          setState((prev) => ({
            ...prev,
            prices,
            loadingPrices: false,
            loadPricesError: null,
          }));
        } catch (err) {
          setState((prev) => ({
            ...prev,
            loadPricesError: getErrorMessage(err),
            loadingPrices: false,
          }));
        }
      };

      fn();
    },
    [getPrices, setState, product]
  );

  const applyDiscount = async function () {
    const productWithCoupon = {
      ...product,
      product: {
        ...(product.product as any),
        discountCoupon: state.discountCoupon,
      },
    };
    setState((prev) => ({ ...prev, discountCoupon: "" }));
    props.onDiscountApplied(productWithCoupon);
  };

  const removeDiscount = async function () {
    const newProduct = { ...product };
    delete (newProduct.product as any).discountCoupon;
    props.onDiscountApplied(newProduct);
  };

  const onConfirm = async function () {
    try {
      setState((prev) => ({ ...prev, buying: true }));
      await buy({
        product: props.product,
        paymentProfileId: props.paymentProfile,
      });
      props.onNext();
    } catch (err) {
      setState((prev) => ({
        ...prev,
        buying: false,
        buyError: "Erro ao realizar a compra",
      }));
    }
  };

  const toggleDiscount = () => state.discount
  ? setState({ ...state, discount: false })
  : setState({ ...state, discount: true });

  return (
    <div className="h-sumw__revision-step">
      <p className="h-sumw__info-text" onDoubleClick={() => toggleDiscount()}>
        Revisar Compra
      </p>
      <div>
        <div>
          <br />
          <p className="h-sumw__info-text">Produtos</p>
          <p>Assinatura {state.prices && (state?.prices[0]?.recurrence === 'YEAR' ? 'anual' : 'mensal') }: </p>

          <Component product={props.product} />
        </div>
        <div>
          {!(props.product.product as any).discountCoupon ? (
            <>
              {" "}
              {state.discount && (
                <div className="h-sumw__spacing-before h-sumw__spacing-after h-sumw__wrapper_cumpom">
                  <Input
                    label="Cupom de desconto"
                    value={state.discountCoupon}
                    readOnly={state.buying}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        discountCoupon: e.target.value,
                      }))
                    }
                  />
                  <button
                    className="h-sumw__button"
                    onClick={applyDiscount}
                    disabled={state.buying || !state.discountCoupon}
                    style={{ marginTop: "33px" }}
                  >
                    {state.buying ? "Carregando..." : "Aplicar Cupom"}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="h-sumw__spacing-before">
              <button
                className="h-sumw__button"
                onClick={removeDiscount}
                disabled={state.buying}
              >
                {state.buying ? "Carregando..." : "Remover Cupom"}
              </button>
            </div>
          )}
        </div>
        <div>
          <p className="h-sumw__info-text">
            Valor da assinatura para as categorias selecionadas:
          </p>
          <div>{state.loadingPrices ? "Calculando valor..." : null}</div>
          <div>
            {!state.prices
              ? null
              : (function () {
                  const billings = keyBy(state.prices, "recurrence");

                  if (billings["MONTH"]) {
                    if (billings["ONCE"]) {
                      const firstValue =
                        billings["ONCE"].value + billings["MONTH"].value;
                      return (
                        <div className="h-sumw__spacing-before h-sumw__spacing-after">
                          <span className="hd-featured">
                            {currencyFormat(firstValue)}
                          </span>{" "}
                          no primeiro mês e{" "}
                          <span className="hd-featured">
                            {currencyFormat(billings["MONTH"].value)}
                          </span>{" "}
                          nos meses seguintes.
                        </div>
                      );
                    }
                    return (
                      <div className="h-sumw__spacing-before h-sumw__spacing-after">
                        <span className="hd-featured">
                          {currencyFormat(billings["MONTH"].value)}
                        </span>{" "}
                        ao mês
                      </div>
                    );
                  }
                  if (billings["YEAR"]) {
                    const installments = billings["YEAR"].installments;
                    if (billings["ONCE"]) {
                      const firstValue =
                        billings["ONCE"].value + billings["YEAR"].value;
                      return (
                        <div className="h-sumw__spacing-before h-sumw__spacing-after">
                          Assinatura de{" "}
                          {installments > 1 ? installments + "x de " : ""}{" "}
                          <span className="hd-featured">
                            {currencyFormat(firstValue / installments)}
                          </span>
                          no primeiro ano e{" "}
                          {installments > 1 ? installments + "x de " : ""}{" "}
                          <span className="hd-featured">
                            {currencyFormat(
                              billings["YEAR"].value / installments
                            )}
                          </span>
                          nos anos seguintes.
                        </div>
                      );
                    }
                    return (
                      <div className="h-sumw__spacing-before h-sumw__spacing-after">
                        Assinatura de{" "}
                        {installments > 1 ? installments + "x de " : ""}{" "}
                        <span className="hd-featured">
                          {currencyFormat(
                            billings["YEAR"].value / installments
                          )}
                        </span>{" "}
                        (ao ano).
                      </div>
                    );
                  }
                  throw new Error("");
                })()}
          </div>
        </div>
      </div>
      <div className="h-sumw__spacing-before h-sumw__checkout_wrap">
        {(function () {
          if (state.loadPricesError) {
            return (
              <div
                className="h-sumw__button-side"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                {state.loadPricesError}
              </div>
            );
          }
          if (state.loadingPrices) {
            return "Carregando...";
          }
          return (
            <>
              <button
                className="h-sumw__button"
                onClick={() => {
                  GoogleAnalyticsActions.event("finalizar_pag4", "", "&#8287;");
                  onConfirm();
                }}
                disabled={state.buying}
              >
                {state.buying ? "Carregando..." : "Finalizar Compra"}
              </button>
              <div className="h-sumw__button-side">{state.buyError}</div>
            </>
          );
        })()}
      </div>
    </div>
  );
};

export default ReviewStep;
