import { SVGProps } from "react";

export const Alert = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#F0000A"
      d="m25.732 6 19.052 33a2 2 0 0 1-1.732 3H4.947a2 2 0 0 1-1.732-3L22.268 6a2 2 0 0 1 3.464 0M8.412 38h31.176L24 11zM22 32h4v4h-4zm0-14h4v10h-4z"
    ></path>
  </svg>
);
