import * as React from "react";
import Footer from "../checkout/Footer";
import Header from "../checkout/Header";
import styles from "./Styles.module.css";
import setting from '../../assets/icon/setting.svg';


const Page404: React.FC = () => {
  return (
    <>
      <Header />
        <div className={styles.wrapper}>
            <div>
                <img src={setting} alt="" />
                <h2>Desculpe, algo deu errado:</h2>
                <p>A página que você estava buscando não existe ou não está disponível. </p>
                <a href="/" style={{ textDecoration: "none", color: "#99152b" }}>Ir para página inicial</a>
            </div>
        </div>
      <Footer />
    </>
  );
};

export default Page404;
