import { FunctionComponent, useState } from 'react'
import { FormEvent } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { mainColor } from '../../constants'
import useAuth from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import { SingUpUser } from '../domain/SingUpUser'
import ModalTitle from '../ui/ModalTitle'
import makeModal from '../utils/MakeModal'
import { useModal } from '../utils/Modal'
import { validPassword } from '../utils/Util'

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: auto;
`

const CenterBox = styled.div`
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LoginBox = styled.form`
  background-color: ${ mainColor };
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`

const Logo = styled.img`
  max-height: 35px;
  max-width: 95%;
  margin: 60px 0;
`

const Input = styled.input`
  width: 95%;
  background-color: transparent;
  border: solid 1px white;
  padding: 10px;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;

  :focus {
  }

  ::placeholder {
    color: white;
  }
`

const Button = styled.button`
  border: none;
  background-color: white;
  color: ${ mainColor };
  font-size: 18px;
  padding: 8px 23px;
  margin-top: 10px;
  margin-bottom: 80px;
  outline: none;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`

const SignUpText = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
`

const SignUpLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
`

const Trademark = styled.span`
  color: ${ mainColor };
  padding: 10px;
  display: block;
`

const MarkObrigatory = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
  float: left;
  margin-top: 4%;
`

const Loading = styled.i`
  font-size: 40px;
  margin: 50px 0 50px 0;
`;

const Info = styled.div`
  text-align: center;
`

const ChangePassword : FunctionComponent = () => {
	const [ singUpUser, setSingUpUser ] = useState<SingUpUser>({
    clientCompany : {
      name:"",
      cnpj:""
    },
    plan:"",
    corporativeEmail:"",
    firstName:"",
    lastName:"",
		userName:"",
		password:"",
    confirmPassword:""
	})

  const [ loading, setLoading ] = useState(false);
  const { openModal } = useModal();
  const { changePassword } = useUser();
  const { login } = useAuth();
  const { token } = useParams<any>();
  const history = useHistory();

  const attempLoginUser = async (user:string, password:string) => {
    return login({ user, password });
  }

  const verifyValidPassword = (password:string) => {
    if (!validPassword(password)) {
      openModal(
        <>
          <ModalTitle> Info </ModalTitle>
          <Info>
            A senha deve ser composta por 8 digítos e conter letras minúsculas, maiúsculas, números e caracter especial
          </Info>
        </>
      )
      return false;
    }
    return true;
  }

  const verifyConfirmPassword = (password:any) => {
    if (singUpUser.password !== password) {
      openModal(makeModal("Info", "As senhas precisam ser iguais." ));
      return false;
    }
    return true;
  }


  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!singUpUser.password && !singUpUser.confirmPassword) {
      openModal(makeModal("Info", "Preencha o(s) campo(s) obrigatório(s)."));
      return;
    }

    if (singUpUser.password !== singUpUser.confirmPassword) {
      openModal(makeModal("Info", "As senhas precisam ser iguais." ));
    }

    try {
      setLoading(true);
      await changePassword(singUpUser, token);
      const obj = JSON.parse(atob(token.split(".")[1]));
      await attempLoginUser(obj.username, singUpUser.password)
      openModal(makeModal("Info", "Senha Redefinida com sucesso."));
      history.push("/login")
    } catch (err) {
      if (err.response?.status === 401) {
        openModal(makeModal("Erro", 'Usuario ou senha incorretos' ));
      } else {
        openModal(makeModal("Erro", "Erro interno do Servidor. Código" + err.response?.status ));
      }      
      setLoading(false);
    }
  };	

  return (
    <Background>
      <CenterBox>
        <LoginBox onSubmit={ handleOnSubmit }>
          <Logo
            src="/static/logo-horus-w.png"
            alt="Logo Horus" />

					<Input
            placeholder="Nova senha"
            type='password'
            value={ singUpUser.password }
            onChange={ e => setSingUpUser({ ...singUpUser, password: e.currentTarget.value }) }
            onBlur={ e => verifyValidPassword(e.currentTarget.value) }/>
          <MarkObrigatory>*</MarkObrigatory>   

          <Input
            placeholder="Repita a nova senha"
            type='password'
            value={ singUpUser.confirmPassword }
            onChange={ e => setSingUpUser({ ...singUpUser, confirmPassword: e.currentTarget.value }) }
            onBlur={ e => verifyConfirmPassword(e.currentTarget.value) }/>
          <MarkObrigatory>*</MarkObrigatory> 

          { !loading ? (    
            <>      
            <Button 
              type="submit"
              disabled={ loading }
              children={ loading ? 'Carregando...' : 'Trocar Senha' } /> 

            <SignUpText>
              <SignUpLink onClick={ e => history.push("/login") } style={{cursor: 'pointer'}}>
                Cancelar
              </SignUpLink>
            </SignUpText> 
            </> ) : (
                  <Loading className='fas fa-spinner fa-pulse' />)
          }
        </LoginBox>
        <Trademark>
          2021 © HORUS
        </Trademark>
      </CenterBox>
    </Background>
  )
}

export default ChangePassword