import React, { useContext } from "react";
import { horusLogo } from "../../constants";
import { ResearchContext } from "../context/ResearchContext";
import styles from "./research.module.css";

type IResearch = {
  evaluation: 1 | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  resume: string;
};
const Research: React.FC = () => {
  const [value, setValue] = React.useState({} as IResearch);
  const { visible, answerLater, doNotAnswer, question, research, close } =
    useContext(ResearchContext);

  const isOk = !!value.evaluation && (value.evaluation > 6 || (value.resume && value.resume !== ""));

  return visible ? (
    <>
      <div className={styles.wrapper}>
        <span className={styles.autoClose} onClick={() => close()}></span>
        <section className={styles.wrap}>
        <button className={styles.close} onClick={() => close()}>
          <span>X</span>
        </button>
          <header>
            <svg
              width="75"
              height="19"
              viewBox="0 0 75 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="75" height="19" fill="url(#pattern0)" />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlinkHref="#image0_2_220"
                    transform="translate(0 -1.52632) scale(0.0113636 0.0448565)"
                  />
                </pattern>
                <image
                  id="image0_2_220"
                  width="88"
                  height="88"
                  xlinkHref={horusLogo}
                />
              </defs>
            </svg>

            <h1>Pesquisa sobre a plataforma Horus</h1>
            <p>{question?.question}</p>
          </header>

          <main>
            <div className={styles.values}>
            <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "0" ? styles.active : ""
                }`}
                type="button"
                value="0"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "1" ? styles.active : ""
                }`}
                type="button"
                value="1"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "2" ? styles.active : ""
                }`}
                type="button"
                value="2"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "3" ? styles.active : ""
                }`}
                type="button"
                value="3"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "4" ? styles.active : ""
                }`}
                type="button"
                value="4"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "5" ? styles.active : ""
                }`}
                type="button"
                value="5"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "6" ? styles.active : ""
                }`}
                type="button"
                value="6"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "7" ? styles.active : ""
                }`}
                type="button"
                value="7"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "8" ? styles.active : ""
                }`}
                type="button"
                value="8"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "9" ? styles.active : ""
                }`}
                type="button"
                value="9"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
              <input
                className={`${styles.value} ${
                  value.evaluation?.toString() === "10" ? styles.active : ""
                }`}
                type="button"
                value="10"
                onClick={(e: any) =>
                  setValue({ ...value, evaluation: e.target.value })
                }
              />
            </div>

            <div>
              <textarea
                placeholder="Por favor, nos conte o motivo da sua nota"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setValue({ ...value, resume: e.target.value })
                }
              ></textarea>
            </div>
          </main>

          <footer>
            <div>
              <button className={styles.actions} onClick={() => answerLater()}>
                Responder depois
              </button>
              <button className={styles.actions} onClick={() => doNotAnswer()}>
                Prefiro não responder
              </button>
            </div>

            <div>
              <button
                className={`${ styles.actions } ${ styles.send }`}
                disabled={!isOk}
                onClick={() =>
                  research({
                    evaluation: value.evaluation,
                    resume: value.resume,
                  })
                }
              >
                Enviar
              </button>
            </div>
          </footer>
        </section>
      </div>
    </>
  ) : null;
};

export default Research;
