import React, {useContext, useEffect } from 'react';
import EmbeddedDashboard from './EmbeddedDashboard';
import FgvContext from './FgvContext';
import FgvPage from './FgvPage';

const PLUS_DASHBOARD = "70E104_horus_radar";

export const FgvPlus: React.FC = () => {
 
  const { fgvUser, visits, setVisits, setDashboardLoaded } = useContext(FgvContext);

  useEffect(() => () => setDashboardLoaded(false), [ setDashboardLoaded ]);

  useEffect(() =>  { 
    !fgvUser && (window.location.href = '/');
  });

  function onDashboardLoaded(e: { link: string }) {
    setDashboardLoaded(true);
    setVisits(prev => prev + 1);
  }

  return (
    <FgvPage fullPage>
      {
        (visits <= 10) ? (
          <EmbeddedDashboard 
            className="hd-fgb__body" 
            link={ PLUS_DASHBOARD }
            onLoadDashboard={ onDashboardLoaded }
          />
        ) : (
          <div className="hd-embedded-dash__center">
            <div>
              Fim do Teste
            </div>
            <div>
              Assine nosso plano para ter acesso a este relatório
            </div>
            <div>:(</div>
            <br />
            {
              !fgvUser ? null : (
                <a className="hd-embedded-dash__btn" target="__blank" href="https://dashboard.ehorus.com.br/signup/premium">
                  Assine agora!
                </a>
              )
            }
          </div>
        )
      }
    </FgvPage>
  );
}
