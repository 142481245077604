import { FunctionComponent } from "react"
import { useHistory, useLocation } from "react-router"
import CookiePolicy from "./CookiePolicy"
import PrivacyPolicy from "./PrivacyPolicy"
import TermsOfUse from "./TermsOfUse"

const AllPolicies = function () {
  return (
    <>
      <TermsOfUse />
      <PrivacyPolicy />
      <CookiePolicy />
    </>
  )
}

const Components = {
  cookies: CookiePolicy,
  privacy: PrivacyPolicy,
  termsOfUse: TermsOfUse,
  all: AllPolicies
};

type PolicyType = keyof typeof Components;

const TermsOfUsePage: FunctionComponent<{ type: PolicyType }> = function (props) {
  const { type } = props;
  const history = useHistory();
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const Component = Components[type];
  const full = query.get("full") === "1";

  if (full) {
    return (
      <div style={{ padding: "10px" }}>
        <Component />
      </div>
    )
  }

  return (
    <div className="h-privacy-page">
      <div className="h-privacy-page-header">
        <img src="/static/logo-horus-w.png" alt="Logo Horus" />
      </div>
      <div className="h-privacy-page-nav">
        <a onClick={ () => history.goBack() }>Voltar para página anterior</a>
      </div>
      <div className={ `h-privacy-page-content ${ full ? 'h-privacy-page-content-full-page' : '' }` }>
        <Component />
      </div>
      <div className="h-privacy-footer">
        <span className="h-privacy-footer-title">Em caso de dúvidas, mande um e-mail para:</span><br />
        dpo@ehorus.com.br
      </div>
    </div>
  )
}

export default TermsOfUsePage