import { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import { mainColor } from '../../constants'

const Select = styled.select`
  height: 37px;
  margin: 1% 0px 0px 0px;
  font-size: large;
  appearance: none;
  background-color: white;
  width: 100%;
  padding-left: 10px;
`;

const SelectOption = styled.option``;

const ContainerSelect = styled.div`

  display: flex;

  :after{
    content: "^";
    font-size: 35px;
    font-family: revert;
    color: ${mainColor};
    position: relative;
    top: -6.5px;
    transform: rotateZ(180deg);
    margin-left: -25px;
  }

`;

const Label = styled.span`
  color: ${ mainColor };
  font-weight: bold;
  margin-top: 2%;
  margin-bottom: -5px;
`;

interface SelectComponentProps {
  title?:string,
  value?:number|string,
  label?:string,
  data?:Array<{key?:number, value:number|string, text:string}>,
  style?:CSSProperties,
  onChange?:React.ChangeEventHandler<HTMLSelectElement>
}

const SelectComponent : FunctionComponent<SelectComponentProps> = props => {
  const { label, title, data, onChange, value, style } = props;
  
  return (
    <>
      <Label>{ label }</Label>
      <ContainerSelect>
        <Select
        style={ style }
        onChange={ onChange }
        value={ value }>            
          {title ? (
            <SelectOption value="">{ title }</SelectOption>
          ) : null}
          {data ? (
            data.map((option) => {
              return (
                <>
                  <SelectOption key={option.key} value={option.value}>{ option.text }</SelectOption>
                </>
              )
            })
          ) : null}
        </Select>        
      </ContainerSelect>
    </>
  )
}

export default SelectComponent;