import { FormEvent, FunctionComponent, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { mainColor } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { clearTokens } from "../../service/auth";
import makeModal from "../utils/MakeModal";
import { useModal } from "../utils/Modal";
import { getErrorMessage } from "../utils/Util";

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: auto;
`;

const CenterBox = styled.div`
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoginBox = styled.form`
  background-color: ${mainColor};
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Logo = styled.img`
  max-height: 35px;
  max-width: 95%;
  margin: 60px 0;
`;

const Input = styled.input`
  width: 100%;
  background-color: transparent;
  border: solid 1px white;
  padding: 10px;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;

  :focus {
  }

  ::placeholder {
    color: white;
  }
`;

const Button = styled.button`
  border: none;
  background-color: white;
  color: ${mainColor};
  font-size: 18px;
  padding: 8px 23px;
  margin-top: 10px;
  margin-bottom: 80px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`;

const SignUpText = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
`;

const SignUpLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
  cursor: pointer;
`;

const Trademark = styled.span`
  color: ${mainColor};
  padding: 10px;
  display: block;
`;
const Loading = styled.i`
  font-size: 40px;
  margin: 50px 0 50px 0;
`;

const LoginPage: FunctionComponent = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const { openModal } = useModal();
  const history = useHistory();
  clearTokens();

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await login({ user, password });
      if (response?.status === 206) {
        history.push("/signup/complete?type=legacy");
      }
    } catch (err) {
      openModal(makeModal("Erro", getErrorMessage(err)));
      setLoading(false);
    }
  };

  return (
    <Background>
      <CenterBox>
        <LoginBox onSubmit={handleOnSubmit}>
          <Logo src="/static/logo-horus-w.png" alt="Logo Horus" />
          <Input
            placeholder="E-mail"
            value={user}
            onChange={(e) => setUser(e.currentTarget.value)}
          />
          <Input
            placeholder="Senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          {!loading ? (
            <>
              <Button
                type="submit"
                disabled={loading}
                children={loading ? "Carregando..." : "Entrar"}
              />
              <SignUpText>
                <SignUpLink onClick={(e) => history.push("/forgot/password")}>
                  Esqueceu sua senha? <br />
                  <br />
                </SignUpLink>
                Ainda não tem cadastro?
                <SignUpLink
                  onClick={(e) => {
                    clearTokens();
                    history.push("/signup/complete");
                  }}
                >
                  &nbsp;Crie sua conta <br />
                  <br />
                </SignUpLink>
              </SignUpText>
            </>
          ) : (
            <Loading className="fas fa-spinner fa-pulse" />
          )}
        </LoginBox>
        <Trademark>2023 © HORUS</Trademark>
      </CenterBox>
    </Background>
  );
};

export default LoginPage;
