import { Fragment, FunctionComponent, useState } from "react";
import styled from "styled-components";
import { mainColor } from "../../../constants";
import ModalTitle from "../../ui/ModalTitle";
import { History } from 'history';
import { makeUrl } from '../../utils/Util';
import { Link } from 'react-router-dom';
import { useModal } from "../../utils/Modal";
import { Dashboard } from "../../../model";
import { normalize as reportEvents } from "../../utils/NormalizeGoogleAnalytics";

const DashboardContent = styled.div`
  margin-top: 20px;
`;

const List = styled.ul`
  margin: 10px;
  overflow-y: scroll;
  max-height: 250px;
  padding: 0;

  ::-webkit-scrollbar {
    width: 10px;
  }  
  
  ::-webkit-scrollbar-thumb {
    background: ${ mainColor };
    border-radius: 15px;
  }  

  ::-webkit-scrollbar-track {
    background: none;
  }  
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: lightgray;
  }

`;

const HorusNaMidiaText = styled.p`
  text-align: center;
  margin: 60px auto;
  max-width: 380px;
  font-size: 18px;
`;

const DashboardFilterInputWrapper = styled.div`
  padding: 5px 9px;
`;

const DashboardFilterInput = styled.input`
  width: 100%;
  color: black;
  border: solid 1px black;
  padding: 10px;
  box-sizing: border-box;
`;

const SeusDashboardsModal : FunctionComponent<{ data?: Dashboard[], history: History }> = (props) => {

  const { closeModal } = useModal();
  const { data } = props;

  const [ filter, setFilter ] = useState<string>("");

  const handleSubmit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, url: string, description: string) => {
    reportEvents(description)
    closeModal()
    window.location.href = url
  }

  const filteredDashboards = data?.filter(i => filter === "" || i.description.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  const dashboardsLength = data?.length || 0;

  return (
    <>
      <ModalTitle>
        Meus Dashboards
      </ModalTitle>
      <DashboardContent>
        {
          data ? (
            <>
              {
                dashboardsLength < 5 ? null : (
                  <DashboardFilterInputWrapper>
                    <DashboardFilterInput
                      placeholder="Filtrar..."
                      value={ filter }
                      onChange={ e => setFilter(e.target.value) }
                    />
                  </DashboardFilterInputWrapper>
                )
              }
              {
                filteredDashboards?.length ? (
                  <List>
                    {
                      filteredDashboards.map((dashboard) => {
                        const url = makeUrl(dashboard);
                        return (
                          <Fragment key={ dashboard.description }>
                            {
                              !url ? null : <Link className="hdh-dashboard-link" to={ url } onClick={(e) => handleSubmit(e, url, dashboard.description)} >{ dashboard.description }</Link>
                            }
                          </Fragment>
                        )
                      })
                    }
                  </List>
                ) : (
                  <div style={{ textAlign: "center", marginTop: "5px" }}>
                    Nenhum resultado!
                  </div>
                )
              }
              
            </>
          ) : (
            <HorusNaMidiaText>
              Você não possui DashBoards
            </HorusNaMidiaText>
          )
        }
      </DashboardContent>
    </>
  )
}

export default SeusDashboardsModal;