import React, { useContext, useState } from "react";
import { logo } from "../../../../../constants";
import { clearSudoToken, getTokens } from "../../../../../service/auth";
import HomeContext from "../../../../context/HomeContext";
import { getClaims } from "../../../../utils/JwtReader";
import { parseFromUTF8 } from "../../../../utils/Util";
import styles from "./Styles.module.css";

const HeaderPlataform: React.FC = () => {

  const tokens = getTokens();
  const { home } = useContext(HomeContext);


  const expirePlan = home?.plan ? (
    home?.plan.map((p: any) => {
      if (p.finishedAt) {
        let date = new Date(Date.parse(p.finishedAt));
        let t =
          date.getTime() > new Date().getTime() ? " expira " : " expirou ";
        return (
          <p>
            Seu Plano {p.plan + t} em{" "}
            {date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear()}
          </p>
        );
      }
    })
  ) : (
    <></>
  );

  const logoutSudoUser = async () => {
    clearSudoToken();
    window.location.reload();
  };


  const sudoTokenText = tokens?.sudoToken ? (
    <>
      Você está acessando como: {getClaims(tokens.sudoToken).email} -{" "}
      <button className={styles.logoutSudoUser} onClick={logoutSudoUser}>Sair</button>
    </>
  ) : (
    ""
  );

  const name: string | JSX.Element | undefined = tokens?.firstName ? (
    <>
      {parseFromUTF8(tokens?.firstName)} {parseFromUTF8(tokens?.lastName)}
    </>
  ) : (
    tokens?.user
  );

  



  return (
    <>
    <div className={styles.top}>
          <p className={styles.expirePlan}>{expirePlan}</p>
        </div>
        
      <header className={styles.header}>
        <div>
          <img alt="Horus" src={logo} />
          <p className={styles.welcomeText}>Bem-vindo(a), {name}!</p>
          {sudoTokenText && <p> {sudoTokenText}</p>}
        </div>
      </header>
    </>
  );
};

export default HeaderPlataform;
