import { FunctionComponent } from "react";
import useTitle from "../../../hooks/useTitle";
import FgvPage from "./FgvPage";

const FgvMetodology: FunctionComponent = function () {
  useTitle("Metodologia");

  return (
    <FgvPage>
      <div className="hd-fgv__content">
        <h2 className="hd-fgv__title">Metodologia</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dui turpis, aliquet sed aliquet eget, aliquet eget ex. Nam in venenatis est, aliquet vehicula enim. Sed at odio ornare, dapibus augue iaculis, efficitur mauris. Suspendisse dictum interdum elit ac molestie. Suspendisse ultrices neque in consectetur aliquam. Nunc a suscipit eros. Donec porta massa eget nibh tempor aliquet. Aenean dictum erat id tellus pharetra gravida. Duis eget felis eleifend, auctor purus a, varius quam. Vivamus vestibulum ac libero hendrerit cursus. Curabitur vestibulum urna ac cursus lacinia. Nam ac dictum augue.
        </p>
      </div>
    </FgvPage>
  )
}

export default FgvMetodology;