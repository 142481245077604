import { createContext, useCallback, useContext } from "react";
import { vindiPublicKey, vindiUrl } from "../../constants";
import useFetch from "../../hooks/useFetch";
import { Billing, CompanyRegister, PaymentProfile, PaymentProfileData, ProductWithPrice, UseCheckoutHook, UserRegister, UserStatus } from "./definitions";

type ThenArg<T> = T extends PromiseLike<infer U> ? U : never

export const useCheckoutImpl = () => {
  const fetch = useFetch();

  const getAvailableFgvCategories = useCallback(function (): Promise<{ id: string, name: string }[]>  {
    return fetch<{ id: string, name: string }[]>({ 
      method: "GET", 
      url: "/api/v1/checkout/fgv/categories",
      options: { auth: false }
    }).then(r => r.data);
  }, []);

  const getAvailableMetricsWebCategories = useCallback(() => {
    type Result = ThenArg<ReturnType<ReturnType<UseCheckoutHook>["getAvailableMetricsWebCategories"]>>;
    const categories: Result = [
      { categoryId: 1,   categoryName: 'Bovino', ufs: [] },
      { categoryId: 5,   categoryName: 'Frango', ufs: [] },
      { categoryId: 8,   categoryName: 'Linguiça', ufs: [] },
      { categoryId: 11,  categoryName: 'Batata Congelada', ufs: [] },
      { categoryId: 36,  categoryName: 'Iogurte', ufs: [] },
      { categoryId: 37,  categoryName: 'Leite Fermentado', ufs: [] },
      { categoryId: 39,  categoryName: 'Petit Suisse', ufs: [] },
      { categoryId: 41,  categoryName: 'Sobremesa Láctea', ufs: [] },
      { categoryId: 43,  categoryName: 'Leite UHT', ufs: [] },
      { categoryId: 54,  categoryName: 'Manteiga', ufs: [] },
      { categoryId: 57,  categoryName: 'Queijo Ralado', ufs: [] },
      { categoryId: 60,  categoryName: 'Requeijão', ufs: [] },
      { categoryId: 63,  categoryName: 'Presuntaria', ufs: [] },
      { categoryId: 67,  categoryName: 'Salsicha/Salsichão', ufs: [] },
      { categoryId: 68,  categoryName: 'Achocolatado/Modificador', ufs: [] },
      { categoryId: 69,  categoryName: 'Açúcar', ufs: [] },
      { categoryId: 71,  categoryName: 'Café com Leite', ufs: [] },
      { categoryId: 72,  categoryName: 'Café em Cápsulas', ufs: [] },
      { categoryId: 73,  categoryName: 'Café em Pó e em Grãos', ufs: [] },
      { categoryId: 74,  categoryName: 'Café Solúvel', ufs: [] },
      { categoryId: 79,  categoryName: 'Enlatados e Conservas', ufs: [] },
      { categoryId: 84,  categoryName: 'Arroz', ufs: [] },
      { categoryId: 85,  categoryName: 'Aveia', ufs: [] },
      { categoryId: 88,  categoryName: 'Farinha de Mandioca', ufs: [] },
      { categoryId: 89,  categoryName: 'Farinha de Trigo', ufs: [] },
      { categoryId: 91,  categoryName: 'Feijão', ufs: [] },
      { categoryId: 92,  categoryName: 'Fubá e Farinhas de Milho', ufs: [] },
      { categoryId: 105, categoryName: 'Catchup', ufs: [] },
      { categoryId: 108, categoryName: 'Molho de Tomate', ufs: [] },
      { categoryId: 112, categoryName: 'Maionese', ufs: [] },
      { categoryId: 115, categoryName: 'Mostarda', ufs: [] },
      { categoryId: 116, categoryName: 'Óleo', ufs: [] },
      { categoryId: 123, categoryName: 'Biscoito', ufs: [] },
      { categoryId: 135, categoryName: 'Chocolate', ufs: [] },
      { categoryId: 174, categoryName: 'Água Mineral', ufs: [] },
      { categoryId: 175, categoryName: 'Chá Pronto', ufs: [] },
      { categoryId: 177, categoryName: 'Suco Concentrado', ufs: [] },
      { categoryId: 180, categoryName: 'Suco Pronto', ufs: [] },
      { categoryId: 181, categoryName: 'Refrigerante', ufs: [] },
      { categoryId: 182, categoryName: 'Suco em Pó', ufs: [] },
      { categoryId: 184, categoryName: 'Cachaça', ufs: [] },
      { categoryId: 187, categoryName: 'Cerveja', ufs: [] },
      { categoryId: 189, categoryName: 'Espumante', ufs: [] },
      { categoryId: 197, categoryName: 'Vinho', ufs: [] },
      { categoryId: 198, categoryName: 'Vodka', ufs: [] },
      { categoryId: 199, categoryName: 'Whisky', ufs: [] },
      { categoryId: 203, categoryName: 'Desodorizador e Aromatizante de Ambiente', ufs: [] },
      { categoryId: 215, categoryName: 'Anti-Mofo', ufs: [] },
      { categoryId: 478, categoryName: 'Higiene e Limpeza do Cachorro', ufs: [] },
      { categoryId: 479, categoryName: 'Higiene e Limpeza do Gato', ufs: [] },
      { categoryId: 494, categoryName: 'Aves Natalinas', ufs: [] }
    ].sort((a, b) => (a.categoryName.localeCompare(b.categoryName)) > 0 ? 1 : -1);
    const ufs = [
      { uf: "RO", ufName: "Rondônia" },
      { uf: "AC", ufName: "Acre" },
      { uf: "AM", ufName: "Amazonas" },
      { uf: "RR", ufName: "Roraima" },
      { uf: "PA", ufName: "Pará" },
      { uf: "AP", ufName: "Amapá" },
      { uf: "TO", ufName: "Tocantins" },
      { uf: "MA", ufName: "Maranhão" },
      { uf: "PI", ufName: "Piauí" },
      { uf: "CE", ufName: "Ceará" },
      { uf: "RN", ufName: "Rio Grande do Norte" },
      { uf: "PB", ufName: "Paraíba" },
      { uf: "PE", ufName: "Pernambuco" },
      { uf: "AL", ufName: "Alagoas" },
      { uf: "SE", ufName: "Sergipe" },
      { uf: "BA", ufName: "Bahia" },
      { uf: "MG", ufName: "Minas Gerais" },
      { uf: "ES", ufName: "Espírito Santo" },
      { uf: "RJ", ufName: "Rio de Janeiro" },
      { uf: "SP", ufName: "São Paulo" },
      { uf: "PR", ufName: "Paraná" },
      { uf: "SC", ufName: "Santa Catarina" },
      { uf: "RS", ufName: "Rio Grande do Sul (*)" },
      { uf: "MS", ufName: "Mato Grosso do Sul" },
      { uf: "MT", ufName: "Mato Grosso" },
      { uf: "GO", ufName: "Goiás" },
      { uf: "DF", ufName: "Distrito Federal" }
    ];
    ufs.forEach(uf => categories.forEach(category => {
      category.ufs.push(uf);
    }));
    return new Promise<typeof categories>(resolve => {
      setTimeout(() => {
        resolve(categories);
      }, Math.random() * 1000);
    });
  }, []);

  const createPaymentProfile = async function (data: PaymentProfileData): Promise<PaymentProfile> {
    const result = await fetch<{ payment_profile: { gateway_token: string } }>({
      method: "POST",
      url: `${ vindiUrl }/api/v1/public/payment_profiles`,
      headers: {
        Authorization: `Basic ${ vindiPublicKey }`,
        Accept: 'application/json'
      },
      options: {
        auth: false
      },
      data: {
        holder_name: data.name,
        card_expiration: data.validate,
        card_number: data.number,
        card_cvv: data.cvv
      }
    });
    return {
      gatewayToken: result.data.payment_profile.gateway_token,
      creditCardLabel: "XXXXXXXXXXXX" + data.number.substring(12),
      flag: data.flag,
    };
  }

  const getPaymentProfiles = useCallback(async function (): Promise<PaymentProfile[]> {
    return [];
  }, []);

  const getPrices = useCallback(function (product: ProductWithPrice<unknown>): Promise<Billing[]> {
    return fetch<{ billings: Billing[] }>({
      url: "/api/v1/checkout/price/" + product.type,
      method: "POST",
      data: product.product
    }).then(response => response.data.billings);
  }, []);

  const buy = useCallback(async function (data: { product: ProductWithPrice<unknown>, paymentProfileId: string }): Promise<void> {
    await fetch({
      url: `/api/v1/checkout/preapproval/${ data.product.type }`,
      method: "POST",
      data: { config: data.product.product, gatewayToken: data.paymentProfileId }
    });
    return;
  }, []);

  const checkEmail = function (email: string) {
    return fetch<{ status_message: UserStatus }>({
      data: { email },
      headers: { Token: "YXNfYXV0aF9zZWNyZXRfa2V5X3VzZXJzX2FjY2Vzc192ZXJpZmljYXRpb25fZ3JnaTkuY29t" },
      method: "POST",
      url: "https://auth.grgi9.com/v1/user/status",
      options: { auth: false }
    }).then(response => response.data.status_message );
  }

  const registerUser = useCallback(async function (data: UserRegister) {
    await fetch({ url: "/api/v1/checkout/user", method: "POST", data, options: { auth: false } });
  }, [ fetch ]);

  const registerCompany = useCallback(async function (data: CompanyRegister) {
    await fetch({ url: "/api/v1/checkout/company", method: "POST", data, options: { auth: false } });
  }, [ fetch ]);

  const requestEmailConfirmation = useCallback(async function (email: string) {
    await fetch({ url: "/public/api/v1/confirm-email", method: "POST", data: { email }, options: { auth: false } });
  }, [ fetch ]);

  return { 
    getAvailableFgvCategories,
    getAvailableMetricsWebCategories,
    checkEmail,
    createPaymentProfile,
    getPaymentProfiles,
    getPrices,
    buy,
    registerUser,
    registerCompany,
    requestEmailConfirmation
  };
}

export const Context = createContext<UseCheckoutHook>(useCheckoutImpl);

const useCheckout = () => useContext(Context)();

export default useCheckout;
