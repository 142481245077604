import { FunctionComponent } from 'react';
import useTitle from '../../../hooks/useTitle';
import FgvPage from './FgvPage';

const FgvTermsOfUse: FunctionComponent = () => {
  useTitle("HORUS + FGV IBRE - Termos de Uso");

  return (
    <FgvPage>
      <div className="hd-fgv__content">
        <h1>
            TERMOS E CONDIÇÕES GERAIS DE USO – PLATAFORMA HORUS
        </h1>
        <h2>
            Introdução
        </h2>
        <p>
            Os serviços disponíveis na PLATAFORMA são fornecidos pela <strong>GRG INOVAÇÕES E TECNOLOGIA S/A</strong>, sediada na Rua Otacílio Novais, 172, Rio de Janeiro, CEP 22710-020, devidamente inscrita no CNPJ sob nº 24.019.428-0001/44, doravante denominada apenas HORUS, titular da propriedade intelectual sobre o software, website, conteúdos e demais ativos relacionados à plataforma “Horus”.
        </p>
        <h2>
            OBJETO - PLATAFORMA
        </h2>
        <p>
            A plataforma visa licenciar, mediante pagamento mensal ou anual conforme a escolha do usuário realizada no momento da compra, o uso de seu software, website e demais ativos de propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários concordando com suas disposições. 
        </p>
        <p>
            A plataforma caracteriza-se pela prestação do seguinte serviço:
        </p>    
        <p>
            (i) HORUS-FGV/IBRE_Premium – Métrica de Preço Médio ao consumir em 8 capitais brasileiras (Belo Horizonte, Brasília, Curitiba, Fortaleza, Manaus, Rio de Janeiro, Salvador e São Paulo) com a visão agregada dos canais Autosserviço e Atacarejo. Informação reportada até o nível marca por tamanho da embalagem. Atualização mensal.
        </p>
        <h2>
            DA ACEITAÇÃO
        </h2>
        <p>
            O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, pelo tempo escolhido pelo USUÁRIO no momento da contratação, entre a plataforma e as pessoas físicas ou jurídicas, usuárias da PLATAFORMA. 
        </p>
        <p>
            Ao efetivar a assinatura dos serviços, o USUÁRIO dá seu CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS SUAS INFORMAÇÕES pela HORUS, necessárias para a integral execução das funcionalidades ofertadas pela PLATAFORMA e aceita integralmente as presentes normas e compromete-se a observá-las, sob pena de incidir as sanções cabíveis.
        </p>
        <p>
            Em caso de discordância com os termos aqui apresentados, a utilização da PLATAFORMA deve ser imediatamente interrompida pelo USUÁRIO.
        </p>
        <h2>
            CADASTRO
        </h2>
        <p>
            O acesso às funcionalidades da PLATAFORMA exigirá a realização de um CADASTRO PRÉVIO e o PAGAMENTO de determinado valor conforme o PLANO DE ASSINATURA escolhido. 
        </p>
        <p>
            Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade a manter os referidos dados atualizados. Do mesmo modo o usuário se compromete com a veracidade dos dados fornecidos.
        </p>
        <p>
            O usuário deverá fornecer um endereço de válido, telefone ou qualquer outro meio eletrônico através do qual a HORUS realizará todas as comunicações necessárias. 
        </p>
        <p>
            Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços. 
        </p>
        <p>
            Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter previamente o consentimento expresso de seus responsáveis legais para utilização da PLATAFORMA e dos serviços, sendo de responsabilidade exclusiva destes o eventual acesso por menores de idade e por aqueles não possuem plena capacidade civil sem a prévia autorização. 
        </p>
        <p>
            Após a confirmação do pagamento o usuário receberá um login e uma senha pessoal, os quais lhe asseguram o acesso individual. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais. 
        </p>
        <p>
            O usuário se compromete, ainda, a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito, sujeitando-se às normas internas e penalidades legais civis, penais e administrativas.
        </p>
        <p>
            Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a HORUS em caso de uso indevido da respectiva senha.
        </p>
        <p>
            Não será permitido ceder, vender, alugar, ou transferir, de qualquer forma, a conta que é pessoal e intransferível. 
        </p>
        <p>
            Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.
        </p>
        <h2>
            DOS SERVIÇOS
        </h2>
        <p>
            Será através da "PLATAFORMA HORUS" que o CONTRATANTE poderá acessar os serviços contratados, na seguinte versão: <strong>(i) Horus-FGV/IBRE_Premium</strong>.
        </p>
        <p>
            Antes de finalizar a compra sobre determinado serviço, o usuário deverá se informar sobre as suas especificações e sobre a sua destinação. 
        </p>
        <p>
            A disponibilização dos serviços adquiridos da plataforma será informada no momento da finalização da compra. 
        </p>
        <p>
            O Sistema será disponibilizado ao USUÁRIO por meio de acesso remoto ao servidor da HORUS e por meio de login e senha.
        </p>
        <p>
            O USUÁRIO FICA CIENTE DE QUE A HORUS NÃO GARANTE QUE A PLATAFORMA CONTERÁ E/OU DISPONIBILIZARÁ TODOS OS DADOS QUE O USUÁRIO DESEJA OU ESPERA ACESSAR. Isso porque a disponibilização de dados na Plataforma está sujeita a critérios estatísticos definidos na metodologia Horus e, portanto, a Horus não garante a abertura de todo e qualquer produto da categoria em questão, assim como a presença de todos os municípios e/ou redes de varejo.  
        </p>
        <p>
            No caso da ausência de produtos da categoria em questão, o usuário poderá enviar para a Horus uma lista de SKUS, com os respectivos descritivos e EAN, para análise e possível cadastro pela Horus.
        </p>
        <p>
            <strong>Período de consulta</strong>: o acesso será contínuo, com atualizações mensais. Fica disponível ao USUÁRIO do (i) Horus-FGV/IBRE_Premium, fazer a opção de acesso “Back Data”, isto é: acesso a um período retroativo de 6 meses conforme a sua necessidade e disponibilidade dos dados requeridos. 
        </p>
        <p>
            A periodicidade de atualização se dará, em regra, da seguinte maneira (i) Preços do dia D: atualizados diariamente e disponibilizados mensalmente até 100 dia útil do mês M+1; (ii) em todos os casos, mas excepcionalmente, a depender da categoria e da região, outra periodicidade de atualização poderá ser aplicada
        </p>
        <h2>
            DO ACESSO DOS USUÁRIOS
        </h2>
        <p>
            Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para permitir o acesso ao serviço por 24 (vinte e quatro) horas por dia. No entanto, a navegação na plataforma ou alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ou seu bom funcionamento.
        </p>
        <h2>
            MODELO DE ASSINATURAS
        </h2>
        <p>
            O USUÁRIO poderá optar entre dois tipos de planos de assinatura distintos: PLANO MENSAL e PLANO ANUAL.
        </p>
        <p>
            Todos os planos possuem renovação automática. Sendo assim, havendo interesse na descontinuidade do serviço é necessário que o USUÁRIO solicite/opte/efetive o cancelamento pela PLATAFORMA. 
        </p>
        <p>
            No PLANO MENSAL, o USUÁRIO fará o pagamento via cartão de crédito e receberá o acesso/login, o qual expirará ao final do período se o USUÁRIO solicitar o cancelamento. Caso contrário, ocorrerá a renovação automática e, por consequência nova cobrança. Havendo renovação automática, o acesso permanecerá ativo, desde que a cobrança tenha sido efetivada e o pagamento realizado, para o novo período.
        </p>
        <p>
            No PLANO ANUAL, o USUÁRIO poderá fazer o pagamento via cartão de crédito, pagando de uma vez ou em parcelas de no máximo 3 (três) vezes. O USUÁRIO receberá na data de confirmação do pagamento total ou da primeira parcela o acesso/login, o qual expirará ao final do período se o USUÁRIO solicitar o cancelamento ou se verificada a inadimplência pelo prazo 05 (cinco) contados do vencimento do valor não pago. Caso contrário, ocorrerá a renovação automática e, por consequência nova cobrança. Havendo renovação automática, o acesso permanecerá ativo, desde que a cobrança tenha sido efetivada e o pagamento realizado, para o novo período.
        </p>
        <p>
            O USUÁRIO, depois de optar por um dos planos de assinatura descritos, não poderá trocar de plano. Dessa forma, o USUÁRIO que quiser promover qualquer alteração do plano contrato, precisará cancelar a sua assinatura atual e em seguida acessar https://cestaconsumo.ehorus.com.br/.
        </p>
        <h2>
            PRAZO
        </h2>
        <p>
            O PLANO de assinatura entra em vigor na data de seu aceite/opção pelo USUÁRIO e vigorará pelo prazo contratado conforme o plano escolhido pelo próprio USUÁRIO ou até que o USUÁRIO solicite o cancelamento pela PLATAFORMA. Sua assinatura poderá ser rescindida conforme estabelecido abaixo no tópico específico de “Formas de Cancelamento”. 
        </p>
        <h2>
            PREÇO E FORMA DE PAGAMENTO
        </h2>
        <p>
            Os valores aplicados são os que estão em vigor no momento do pedido.
        </p>
        <p>
            A contratação dos serviços será renovada automaticamente pela plataforma, independentemente de comunicação ao usuário, mediante cobrança periódica da mesma forma de pagamento indicada pelo usuário quando da contratação do serviço.
        </p>
        <p>
            O USUÁRIO deverá pagar à HORUS o valor do plano escolhido e conforme a opção de pagamento selecionada dentre as disponibilizadas ao USUÁRIO no ato da contratação.
        </p>
        <p>
            A falta de pagamento de quaisquer valores nas respectivas datas de vencimento não acarretará a rescisão automática do CONTRATO, mas causará a suspensão do acesso do USUÁRIO à PLATAFORMA até que as pendências financeiras tenham sido regularizadas. 
        </p>
        <p>
            O acesso à PLATAFORMA somente será restabelecido após a identificação pela HORUS do pagamento integral de todos os valores devidos enquanto esteve suspenso. A identificação poderá ocorrer em até 24 (vinte e quatro) horas após a data de pagamento pelo USUÁRIO. Caso o USUÁRIO não resolva a pendência financeira no prazo 05 (cinco) contados do vencimento do valor não pago, a HORUS se reserva o direito de rescindir o presente CONTRATO e manter todas as informações do USUÁRIO que eventualmente estejam armazenadas na PLATAFORMA, caso o USUÁRIO deseje apagá-las, poderá solicitar à HORUS por meio do dpo@ehorus.com.br.
        </p>
        <p>
            O preço de cada plano e as condições específicas de pagamento de cada um estarão amplamente expostos e divulgados no site da HORUS no momento da contratação, e poderão ser reajustados mediante ampla divulgação e informação aos assinantes, o que será feito com antecedência mínima de 30 (trinta) dias. 
        </p>
        <p>
            O reajuste acima mencionado será aplicado automaticamente para a próxima mensalidade no caso de PLANO MENSAL, e o USUÁRIO optante por este plano terá o direito de cancelar sua assinatura, sem ônus, dentro do prazo supracitado, caso discorde da alteração. Findo o prazo sem que haja manifestação do USUÁRIO, o novo valor será considerado aceito e passará a ser cobrado nas mensalidades seguintes.
        </p>
        <p>
            Os optantes do PLANO ANUAL não sofrerão, durante a vigência do seu plano, a incidência do reajuste, que só será aplicado caso haja renovação. 
        </p>
        <p>
            Se o USUÁRIO desejar alterar o cartão de crédito/forma de pagamento onde estão ocorrendo as cobranças, ele poderá fazê-lo entrando em contato através do e-mail contato@ehorus.com.br .
        </p>
        <p>
            Conforme já citado, a renovação do plano é automática. O USUÁRIO que não quiser renovar seu PLANO precisará enviar um e-mail para contato@ehorus.com.br solicitando o cancelamento, antes da data de expiração. A título de exemplo: o usuário deseja cancelar a renovação do seu plano mensal, e a sua data de cobrança é o dia 16 (dezesseis) de abril. Para que o cancelamento seja efetivado no mês de maio, o usuário deverá fazê-lo até o dia 15 (quinze) de abril.
        </p>
        <h2>
            FORMAS DE CANCELAMENTO
        </h2>
        <p>
            O serviço poderá ser cancelado:
        </p>
        <p>
            - Por parte do usuário: nessas condições somente cessarão os serviços quando concluído o ciclo vigente ao tempo do cancelamento, sem alterar o preço. 
        </p>
        <p>
            - Por violação dos termos de uso: serão cessados imediatamente todos os acessos.
        </p>
        <p>
            A HORUS poderá cancelar o acesso à PLATAFORMA a qualquer tempo, desde que comunique ao USUÁRIO, por escrito, com antecedência mínima de 30 (trinta) dias, devendo neste caso restituir ao USUÁRIO o saldo devedor do plano contratado, se existente. Este prazo de antecedência mínima e a obrigação de restituição acima não se aplicarão nos casos de violação do presente instrumento, termos e condições ora acordados, ou em caso de atraso de pagamento não sanado no prazo de 05 (cinco) dias, conforme já explicado acima.
        </p>
        <p>
            O CANCELAMENTO é a opção do USUÁRIO em encerrar sua assinatura. Para isso, o deverá encaminhar e-mail para contato@ehorus.com.br . 
        </p>
        <h2>
            LGPD E COOKIES
        </h2>
        <p>
            Todas as informações, regras e condições acerca dos dados e cookies coletados pela HORUS estão descritos e especificados em tópico específico disponível na Plataforma.
        </p>
        <p>
            De qualquer forma, vale ressaltar que conforme já expressado no tópico de “Aceitação” o USUÁRIO, ao aceitar utilizar  PLATAFORMA, além de aceitar integralmente este termo e também consente, livre e expressamente, que a HORUS colete, use, armazene e faça o tratamento de suas INFORMAÇÕES, incluindo seus dados pessoais, financeiros, bancários, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade, bem como para que a PLATAFORMA execute todas as funções para as quais foi projetado. 
        </p>
        <p>
            O USUÁRIO consente que, ao acessar a PLATAFORMA da HORUS, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas e tempo médio gasto no site. Tais informações poderão ser usadas para orientar o próprio USUÁRIO e melhorar os serviços ofertados.
        </p>
        <p>
            Suspenso o acesso do USUÁRIO à PLATAFORMA, a HORUS manterá as informações do USUÁRIO armazenadas pelo que entender necessário. A HORUS não está obrigada a fornecer cópias de dados, anúncios, informações fiscais, seja por meio de planilhas ou qualquer outro meio ao USUÁRIO.
        </p>
        <p>
            Conforme descrito acima, após a suspensão do acesso do USUÁRIO à PLATAFORMA, todas as INFORMAÇÕES do USUÁRIO, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA e INFORMAÇÕES FINANCEIRAS, em poder da HORUS poderão ser excluídos permanentemente do seu banco de dados, independentemente de terem sido extraídas ou não pelo USUÁRIO.
        </p>
        <h2>
            DIREITOS AUTORAIS
        </h2>
        <p>
            O USUÁRIO não adquire, pelo presente instrumento ou pela utilização da PLATAFORMA nenhum direito de propriedade intelectual ou outros direitos exclusivos, quaisquer direitos não expressamente concedidos sob o presente instrumento são reservados.
        </p>
        <p>
            Ao contratar algum dos planos de assinatura, o USUÁRIO obtém tão somente uma licença temporária, limitada e revogável de uso do Software cuja finalidade dependerá do serviço efetivamente contratado.
        </p>
        <p>
            Também será de propriedade exclusiva da HORUS ou está devidamente licenciado, todo o conteúdo disponibilizado na PLATAFORMA, incluindo, mas não se limitando a: textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material.
        </p>
        <p>
            O uso é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual da HORUS puníveis nos termos da legislação aplicável. 
        </p>
        <h2>
            OBRIGAÇÕES E LIMITAÇÕES 
        </h2>
        <p>
            Obriga-se o USUÁRIO a:
        </p>
        <p>
            -Garantir que a utilização da PLATAFORMA será feita por pessoa autorizada, e que ainda tomará todas as medidas cabíveis para que não haja acesso não autorizado no sistema.
        </p>
        <p>
            -Utilizar corretamente a plataforma e os serviços oferecidos, prezando pela boa convivência, respeito e cordialidade;
        </p>
        <p>
            -Cumprir e respeitar o conjunto de regras disposto neste termo, na política de privacidade, no contrato, na legislação nacional;
        </p>
        <p>
            -Responsabilizar-se pelos defeitos ou vícios técnicos originados no próprio sistema do usuário.
        </p>
        <p>
            Em hipótese alguma é permitido ao USUÁRIO ou a terceiros, de forma geral:
        </p>
        <p>
            Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o PLATAFORMA/SOFTWARE objeto deste termo e seus anexos. 
        </p>
        <p>
            Obriga-se a HORUS a:
        </p>
        <p>
            - Garantir ao USUÁRIO que a PLATAFORMA deverá funcionar regularmente, se respeitadas as condições de uso definidas na documentação. Na ocorrência de falhas de programação (“bugs”), a HORUS obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia dos Programas com falhas por versões devidamente corrigidas, disponibilizar acesso aos serviços de suporte compreendido de segunda a sexta-feira, das 09h00 às 18h00 (horário de Brasília), sem intervalo, via chat; 
        </p>
        <p>
            - Indicar as características do serviço;
        </p>
        <p>
            - Sanar defeitos e vícios encontrados no serviço oferecido desde que lhe tenha dado causa;
        </p>
        <p>
            - Fiscalizar e responsabilizar-se pelas informações que foram pela HORUS divulgadas, ressalvados os comentários ou informações divulgadas por usuários que serão de inteira responsabilidade deles;
        </p>
        <p>
            - Controlar e excluir conteúdo ou atividades ilícitas praticadas através de sua plataforma.
        </p>
        <p>
            A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário à ambiente externo a sua rede.
        </p>
        <h2>
            CONTATO E DÚVIDAS
        </h2>
        <p>
            O USUÁRIO pode resolver a maioria das dúvidas enviando e-mail para contato@ehorus.com.br.
        </p>
        <h2>
            DISPOSIÇÕES GERAIS
        </h2>
        <p>
            O USUÁRIO não poderá prestar serviços a terceiros utilizando a PLATAFORMA da HORUS sem sua autorização prévia e expressa. A autorização de uso da PLATAFORMA é fornecida por CNPJ/CPF.
        </p>
        <p>
            A HORUS ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades da PLATAFORMA, mediante comunicação prévia por escrito ao USUÁRIO, com antecedência mínima de 30 (trinta) dias, disponibilizando formas e alternativas de extrair do Site/PLATAFORMA as informações, salvo nas hipóteses de caso fortuito ou força maior. 
        </p>
        <p>
            A HORUS poderá, por meio de comunicação ao endereço eletrônico indicado pelo USUÁRIO em seu cadastro ou por meio de aviso no Site/PLATAFORMA, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita. Fica CIENTE O USUÁRIO QUE após o referido aviso, a utilização/login/acesso aos serviços será considerada (o) como concordância do USUÁRIO com a cobrança de tais preços. 
        </p>
        <p>
            Fica certo e entendido pelo USUÁRIO que somente a pessoa cadastrada pelo próprio USUÁRIO como administradora de conta poderá solicitar que as informações do USUÁRIO inseridas na PLATAFORMA sejam apagadas. Ainda, o USUÁRIO declara sua ciência de que uma vez apagadas, estas não poderão mais ser recuperadas, ficando a HORUS isenta de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo USUÁRIO. 
        </p>
        <p>
            Este TERMO DE USO constitui o entendimento integral entre as partes e atende, principalmente, às disposições do Código de Defesa do Consumidor (Lei nº 8.078/90) e do Decreto nº 7.962/13.
        </p>
        <p>
            O USUÁRIO declara e garante possuir capacidade jurídica para assinar este TERMO e se compromete a manter seus dados pessoais informados no cadastro devidamente atualizados junto à HORUS, principalmente o e-mail, o contato telefônico e os necessários para cobrança dos pagamentos e eventuais ressarcimentos.
        </p>
        <p>
            A HORUS NÃO SE RESPONSABILIZARÁ, EM NENHUMA HIPÓTESE, CASO O USUÁRIO NÃO OBSERVE O DEVER DE MANTER ATUALIZADOS SEUS DADOS PESSOAIS.
        </p>
        <p>
            A HORUS NÃO SE RESPONSABILIZA PELO USO INADEQUADO DOS SERVIÇOS.
        </p>
        <p>
            A HORUS RESERVA-SE AO DIREITO DE, A SEU CRITÉRIO E A QUALQUER TEMPO, MODIFICAR, ADICIONAR OU REMOVER QUAISQUER CLÁUSULAS OU CONDIÇÕES DESTE TERMO, COMUNICANDO AO USUÁRIO, PREVIAMENTE, POR EMAIL COM ANTECEDÊNCIA MÍNIMA DE 10 (DEZ) DIAS.
        </p>
        <p>
            Ao USUÁRIO fica garantido o direito de cancelar sua ASSINATURA na hipótese de não concordar com as eventuais alterações previstas no item acima, realizadas pela HORUS.
        </p>
        <p>
            O USUÁRIO fica desde logo ciente de que a HORUS não possui autonomia e ingerência sobre os procedimentos de estorno de valores pagos via cartão de crédito, uma vez que são as instituições financeiras que controlam o modo de sua realização.
        </p>
        <p>
            O disposto acima não exclui o direito do USUÁRIO ao ressarcimento pelo cancelamento da compra com a HORUS, mas o procedimento para tanto será verificado caso a caso juntamente com o banco ou a operadora do cartão de crédito em questão.
        </p>
        <p>
            O USUÁRIO aceita que a HORUS, de forma razoável, envie-lhe mensagens de e-mail ou outras correspondências de caráter promocional e informativo.
        </p>
        <p>
            A POLÍTICA DE PRIVACIDADE é parte integrante deste TERMO DE USO, e as partes contratantes comprometem-se a cumpri-lo.
        </p>
        <h2>
            FORO
        </h2>
        <p>
            Este CONTRATO será regido, interpretado e se sujeitará às leis brasileiras e, o USUÁRIO e a HORUS desde logo elegem, de forma irrevogável e irretratável, o foro da Regional de Jacarepaguá da Capital do Estado do Rio de Janeiro, para dirimir quaisquer dúvidas ou controvérsias oriundas deste CONTRATO, com a exclusão de qualquer outro, por mais privilegiado que seja.
        </p>
      </div>
    </FgvPage>
  )
}

export default FgvTermsOfUse;