import { FunctionComponent } from "react";

const TermsOfUse: FunctionComponent = () => {
  return (
    <div>
      <h2>TERMOS E CONDIÇÕES GERAIS DE USO – Plataforma HORUS</h2>
      <h3>Introdução</h3>
      <p>
        Os serviços disponíveis na PLATAFORMA são fornecidos pela GRG INOVAÇÕES
        E TECNOLOGIA S/A, sediada na Rua Otacílio Novais, 172, Rio de Janeiro,
        CEP 22710-020, devidamente inscrita no CNPJ sob nº 24.019.428-0001/44,
        doravante denominada apenas HORUS, titular da propriedade intelectual
        sobre o software, website, conteúdos e demais ativos relacionados à
        plataforma “HORUS”.
      </p>
      <h2>OBJETO - PLATAFORMA</h2>
      <p>
        Esta Plataforma foi criada e desenvolvida com a finalidade de trazer
        conteúdo informativo de alta qualidade e de divulgação e prestação dos
        serviços da HORUS.{" "}
      </p>
      <p>
        {" "}
        Todo o conteúdo presente nesta Plataforma foi desenvolvido buscando
        fontes e materiais próprios de confiabilidade, assim como são baseados
        em estudos sérios e respeitados, através de pesquisa de alto nível, mas
        de caráter meramente informativo e ilustrativo em alguns casos.{" "}
      </p>
      <p>
        Todo o conteúdo é atualizado periodicamente, porém, pode conter, em
        algum artigo, vídeo ou imagem, alguma informação que não reflita a
        realidade do momento, não podendo a HORUS ser responsabilizada de
        nenhuma forma ou meio por qualquer conteúdo que não esteja devidamente
        atualizado.
      </p>
      <p>
        É de responsabilidade do usuário usar todas as informações presentes na
        Plataforma com senso crítico, e sempre buscando especialistas da área
        para a solução concreta de eventual conflito ou necessidades.
      </p>
      <h2>DA ACEITAÇÃO</h2>
      <p>
        O presente Termo estabelece direitos e obrigações mútuas entre o usuário
        e a HORUS, bem como exige que ao acessar a Plataforma da HORUS, o
        usuário leia e compreenda todas as suas cláusulas. Isso porque, ao
        continuar acessando a Plataforma, o usuário declara, expressamente, que
        entende todas estas cláusulas, do mesmo modo que as aceita
        integralmente. Este aceite é imprescindível para a permanência nesta
        Plataforma. Caso o usuário discorde de alguma cláusula ou termo deste
        instrumento, deverá imediatamente interromper sua navegação.{" "}
      </p>
      <h2>CADASTRO</h2>
      <p>
        Caso seja necessário realizar um cadastro junto à Plataforma, o usuário
        deverá preencher um formulário com seus dados e informações, para ter
        acesso a alguma parte restrita, ou realizar alguma compra que,
        porventura, esteja disponível.
      </p>
      <p>
        Sendo assim, fica registrado que o acesso a algumas funcionalidades da
        Plataforma, ou para o recebimento de informações específicas acerca dos
        serviços prestados pela HORUS, exigirá a realização de um CADASTRO
        PRÉVIO.{" "}
      </p>
      <p>
        Ao se cadastrar, o usuário deverá informar dados completos, recentes e
        válidos, sendo de sua exclusiva responsabilidade manter os referidos
        dados atualizados. Do mesmo modo, o usuário se compromete com a
        veracidade dos dados fornecidos.
      </p>
      <p>
        O usuário deverá fornecer um endereço de e-mail válido e/ou telefone por
        meio do qual a HORUS realizará todas as comunicações necessárias.{" "}
      </p>
      <p>
        Mediante a realização do cadastro, o usuário declara e garante
        expressamente ser plenamente capaz, podendo exercer e usufruir
        livremente dos serviços.{" "}
      </p>
      <p>
        Menores de 18 anos e aqueles que não possuírem plena capacidade civil
        deverão obter previamente o consentimento expresso de seus responsáveis
        legais para utilização da Plataforma e dos serviços, sendo de
        responsabilidade exclusiva destes o eventual acesso por menores de idade
        e por aqueles não possuem plena capacidade civil sem a prévia
        autorização.{" "}
      </p>
      <p>
        Após a confirmação do cadastro o usuário receberá um login e uma senha
        pessoal, os quais lhe asseguram o acesso individual. Desta forma,
        compete ao usuário exclusivamente a manutenção de referida senha de
        maneira confidencial e segura, evitando o acesso indevido às informações
        pessoais.{" "}
      </p>
      <p>
        Toda e qualquer atividade realizada com o uso da senha será de
        responsabilidade do usuário, que deverá informar prontamente a HORUS em
        caso de uso indevido da respectiva senha.
      </p>
      <p>
        Não será permitido ceder, vender, alugar, ou transferir, de qualquer
        forma, a conta que é pessoal e intransferível.{" "}
      </p>
      <p>
        Caberá ao usuário assegurar que o seu equipamento seja compatível com as
        características técnicas que viabilize a utilização da plataforma e dos
        serviços ou produtos.
      </p>
      <h2>DOS SERVIÇOS</h2>
      <p>
        Os serviços divulgados nesta Plataforma são prestados através de
        Software específico que o usuário poderá acessar apenas e tão somente se
        baixados e/ou contratados os serviços, se for o caso.
      </p>
      <p>
        Para saber mais, basta acessar a aba “soluções” na home do site ou do
        blog, ou ainda, entrar em contato diretamente com a HORUS, pelos meios
        divulgados nas plataformas disponíveis.
      </p>
      <h2>DO ACESSO DOS USUÁRIOS</h2>
      <p>
        Serão utilizadas todas as soluções técnicas à disposição do responsável
        pela Plataforma para permitir o acesso ao serviço por 24 (vinte e
        quatro) horas por dia. No entanto, a navegação em alguma de suas seções
        poderá ser interrompida, limitada ou suspensa para atualizações,
        modificações ou qualquer ação necessária para seu bom funcionamento.
      </p>
      <h2>LGPD E COOKIES</h2>
      <p>
        Todas as informações, regras e condições acerca dos dados e cookies
        coletados pela HORUS estão descritos e especificados em tópico
        específico dentro da Plataforma.
      </p>
      <p>
        De qualquer forma, vale ressaltar que, conforme já expressado no tópico
        de Aceitação, o usuário, ao navegar pela Plataforma, além de aceitar
        integralmente este termo, também consente, livre e expressamente, que a
        HORUS colete, use, armazene e faça o tratamento de suas INFORMAÇÕES,
        incluindo seus dados pessoais, os quais serão necessários para que o
        serviço ofertado seja prestado em sua integralidade, bem como para que a
        Plataforma execute todas as funções para as quais foi projetado.{" "}
      </p>
      <p>
        O usuário consente que, ao acessar tão somente a Plataforma da HORUS,
        esta poderá coletar informações técnicas de navegação, tais como tipo de
        navegador do computador utilizado para acesso à Plataforma, endereço de
        protocolo de Internet, páginas visitadas e tempo médio gasto na
        Plataforma. Tais informações poderão ser usadas para orientar o próprio
        usuário e melhorar os serviços ofertados.
      </p>
      <p>
        A HORUS manterá as informações do usuário armazenadas pelo período que
        entender necessário desde o primeiro acesso. A HORUS não está obrigada a
        fornecer cópias de dados, anúncios, informações fiscais, seja por meio
        de planilhas ou qualquer outro meio ao usuário.
      </p>
      <p>
        Conforme descrito acima, todas as INFORMAÇÕES do usuário, incluindo as
        INFORMAÇÕES PESSOAIS em poder da HORUS poderão ser excluídos
        permanentemente do seu banco de dados, independentemente de terem sido
        extraídas ou não pelo usuário.
      </p>
      <h2>DIREITO AUTORAIS</h2>
      <p>
        O usuário poderá acessar todo o conteúdo da Plataforma, como artigos,
        vídeos, imagens, produtos e serviços, não significando nenhum tipo de
        cessão de direito ou permissão de uso, ou de cópia destes.
      </p>
      <p>
        Todos os direitos são preservados, conforme a legislação brasileira,
        principalmente na forma da Lei de Direitos Autorais (regulamentada na
        Lei nº 9.610/18), assim como no Código Civil brasileiro (Lei nº
        10.406/02) ou quaisquer outras legislações aplicáveis.
      </p>
      <p>
        Todo o conteúdo da Plataforma é protegido, portanto, por direitos
        autorais, e seu uso, cópia, transmissão, venda, cessão ou revenda, deve
        seguir a lei brasileira, tendo a HORUS todos os seus direitos
        reservados, e não permitindo a cópia ou utilização de nenhuma forma e
        meio, sem autorização expressa e por escrito da HORUS.
      </p>
      <p>
        Também é de propriedade exclusiva da HORUS ou está devidamente
        licenciado, todo o conteúdo disponibilizado na Plataforma, incluindo,
        mas não se limitando a: textos, gráficos, imagens, logos, ícones,
        fotografias, conteúdo editorial, notificações, softwares e qualquer
        outro material.
      </p>
      <p>
        Assim, o usuário não adquire, pelo presente instrumento ou pela
        navegação na Plataforma, nenhum direito de propriedade intelectual ou
        outros direitos exclusivos, quaisquer direitos não expressamente
        concedidos sob o presente instrumento são reservados.
      </p>
      <p>
        O uso é pessoal, individual e intransferível, sendo vedado qualquer uso
        não autorizado, comercial ou não comercial. Tais usos consistirão em
        violação dos direitos de propriedade intelectual da HORUS, puníveis nos
        termos da legislação aplicável.
      </p>
      OBRIGAÇÕES E LIMITAÇÕES Obriga-se o usuário a:
      <ul>
        <li>
          {" "}
          Utilizar corretamente a Plataforma e os serviços eventualmente
          oferecidos, prezando pela boa convivência, respeito e cordialidade;
        </li>
        <li>
          {" "}
          Cumprir e respeitar o conjunto de regras disposto neste termo, na
          política de privacidade, no contrato, na legislação nacional;
        </li>
        <li>
          {" "}
          Responsabilizar-se pelos defeitos ou vícios técnicos originados no
          próprio sistema do usuário.
        </li>
      </ul>
      Em hipótese alguma é permitido ao usuário ou a terceiros, de forma geral:
      <ul>
        <li>
          {" "}
          Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia,
          reproduzir, doar, alienar de qualquer forma, transferir total ou
          parcialmente, sob quaisquer modalidades, gratuita ou onerosamente,
          provisória ou permanentemente, nenhum dos conteúdos da Plataforma
          objeto deste termo e seus anexos;
        </li>
        <li>
          {" "}
          Tentar de qualquer forma ou meio realizar qualquer tipo de ação que
          tente invadir, hackear, destruir ou prejudicar a estrutura da
          Plataforma da HORUS ou de seus parceiros comerciais, incluindo-se, mas
          não se limitando, ao envio de vírus de computador, de ataques de DDOS,
          de acesso indevido por falhas ou quaisquer outras formas possíveis.{" "}
        </li>
      </ul>
      <p>Obriga-se a HORUS a:</p>
      <ul>
        <li>
          Garantir ao usuário que a Plataforma deverá funcionar regularmente,
          se respeitadas as condições de uso definidas na documentação. Na
          ocorrência de falhas de programação (“bugs”), a HORUS obrigar-se-á a
          corrigir tais falhas, podendo, a seu critério, substituir a cópia dos
          Programas com falhas por versões devidamente corrigidas;
        </li>
        <li>Indicar as características do serviço;</li>
        <li>
          Sanar defeitos e vícios encontrados na Plataforma e no serviço
          oferecido desde que lhe tenha dado causa;
        </li>
        <li>
          Fiscalizar e responsabilizar-se pelas informações que foram pela
          HORUS divulgadas, ressalvados os comentários ou informações divulgadas
          por usuários que serão de inteira responsabilidade deles;
        </li>
        <li>
          Controlar e excluir conteúdo ou atividades ilícitas praticadas
          através de sua plataforma.
        </li>
      </ul>
      <p>
        A plataforma não se responsabiliza por links externos contidos em seu
        sistema que possam redirecionar o usuário a ambiente externo à sua rede.
      </p>
      <h2>CONTATO E DÚVIDAS</h2>
      <p>
        O usuário pode resolver a maioria das dúvidas enviando e-mail para
        contato@ehorus.com.br.
      </p>
      <h2>DISPOSIÇÕES GERAIS</h2>
      <p>
        O usuário não poderá prestar serviços a terceiros utilizando a
        Plataforma da HORUS sem sua autorização prévia e expressa.{" "}
      </p>
      <p>
        A HORUS ainda poderá, a seu exclusivo critério, suspender, modificar ou
        encerrar as atividades desta Plataforma, disponibilizando, se
        necessário, formas e alternativas de extrair suas informações, salvo nas
        hipóteses de caso fortuito ou força maior.{" "}
      </p>
      <p>
        A HORUS poderá, por meio de comunicação ao endereço eletrônico indicado
        pelo USUÁRIO em seu cadastro ou por meio de aviso na Plataforma, definir
        preços para a oferta de determinados conteúdos e/ou serviços, ainda que
        inicialmente tais serviços tenham sido ofertados de forma gratuita. Fica
        ciente o usuário que após o referido aviso, a utilização/login/acesso
        aos serviços e/ou conteúdos, será considerada (o) como concordância do
        USUÁRIO com a cobrança de tais preços.
      </p>
      <p>
        Fica certo e entendido pelo usuário que somente a pessoa cadastrada pelo
        próprio USUÁRIO como administradora de conta poderá solicitar que as
        informações do usuário inseridas na Plataforma sejam apagadas. Ainda, o
        usuário declara sua ciência de que, uma vez apagadas, estas não poderão
        mais ser recuperadas, ficando a HORUS isenta de qualquer
        responsabilidade por quaisquer perdas ou danos decorrentes deste
        procedimento solicitado pelo USUÁRIO.{" "}
      </p>
      <p>
        Este TERMO DE USO constitui o entendimento integral entre as partes e
        atende, principalmente, às disposições do Código Civil de 2002, do
        Código de Defesa do Consumidor (Lei nº 8.078/90) e do Decreto nº
        7.962/13.
      </p>
      <p>
        O usuário declara e garante possuir capacidade jurídica para aceitar
        este TERMO e se compromete a manter seus dados pessoais informados no
        cadastro devidamente atualizados junto à HORUS, principalmente o e-mail
        e o contato telefônico.{" "}
      </p>
      <p>
        A HORUS NÃO SE RESPONSABILIZARÁ, EM NENHUMA HIPÓTESE, CASO O USUÁRIO NÃO
        OBSERVE O DEVER DE MANTER ATUALIZADOS SEUS DADOS PESSOAIS.
      </p>
      <p>A HORUS NÃO SE RESPONSABILIZA PELO USO INADEQUADO DOS SERVIÇOS.</p>
      <p>
        A HORUS RESERVA-SE AO DIREITO DE, A SEU CRITÉRIO E A QUALQUER TEMPO,
        MODIFICAR, ADICIONAR OU REMOVER QUAISQUER CLÁUSULAS OU CONDIÇÕES DESTE
        TERMO.
      </p>
      <p>
        {" "}
        Ao usuário fica garantido o direito de cancelar seu cadastro na hipótese
        de não concordar com as eventuais alterações previstas no item acima,
        realizadas pela HORUS.
      </p>
      <p>
        O usuário aceita que a HORUS, de forma razoável, envie-lhe mensagens de
        e-mail ou outras correspondências de caráter promocional e informativo.
      </p>
      <p>
        A POLÍTICA DE PRIVACIDADE é parte integrante deste TERMO DE USO, e as
        partes contratantes comprometem-se a cumpri-lo.
      </p>
      <h2>FORO</h2>
      <p>
        Este CONTRATO será regido, interpretado e se sujeitará às leis
        brasileiras e o usuário e a HORUS, desde logo, elegem, de forma
        irrevogável e irretratável, o foro da Regional de Jacarepaguá da Capital
        do Estado do Rio de Janeiro, para dirimir quaisquer dúvidas ou
        controvérsias oriundas deste TERMO, com a exclusão de qualquer outro,
        por mais privilegiado que seja.
      </p>
    </div>
  );
};

export default TermsOfUse;
