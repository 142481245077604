import Cookies from "js-cookie";
import { AUTH_CREDENTIALS } from "../../../../constants";
import { getCurrentDomain as domain } from "../getCurrentDomain";

export type AuthUserModel = {
  user: string;
  company: string;
  companyId: number;
  email: string;
  exp: number;
  firstName: string;
  groups: Array<number>;
  is_staff: boolean;
  lastName: string;
  role: string;
  userId: number;
  username: string;
};

const accessToken = (token: string) =>
  Cookies.set(AUTH_CREDENTIALS.ACCESS_TOKEN, token, { domain });

const authUser = (user: string, claims: AuthUserModel) =>
  Cookies.set(
    AUTH_CREDENTIALS.AUTH_USER,
    JSON.stringify({
      user: user,
      firstName: claims.firstName,
      lastName: claims.lastName,
      role: claims.role,
      is_staff: claims.is_staff,
      company: claims.company,
      groups: claims.groups,
      userId: claims.userId,
    }),
    { domain }
  );

const refreshToken = (refreshToken: string) =>
  window.localStorage.setItem(AUTH_CREDENTIALS.REFRESH_TOKEN, refreshToken);

const accessTokenSudo = (token: string) =>
  Cookies.set(AUTH_CREDENTIALS.ACCESS_TOKEN_SUDO, token);

export const setCredentials = {
  accessToken,
  authUser,
  refreshToken,
  accessTokenSudo,
};
