import { useEffect, useRef } from "react";

function useStable<T>(value: T): { current: T } {
  const ref = useRef<T>(value);

  useEffect(function () {
    ref.current = value;
  }, [ value ]);

  return ref;
}

export default useStable;