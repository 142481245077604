import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import styled, { CSSProperties } from "styled-components";
import { mainColor } from "../../constants";
import { SingUpUser } from "../domain/SingUpUser";
import HeaderPageGuest from "../home/HeaderPageGuest";
import InputComponent from "../ui/InputComponent";
import useIntegrity from "../../hooks/useIntegrity";
import { useModal } from "../utils/Modal";
import makeModal from "../utils/MakeModal";
import useUser from "../../hooks/useUser";
import useAuth from "../../hooks/useAuth";
import { getTokens, clearTokens, clearToken } from '../../service/auth'
import mask from "../utils/Mask";
import SelectComponent from "../ui/SelectComponent";
import Card from "../utils/SingUpUtils";
import { validCNPJ, validEmail, validPassword, verifyEmptyFields } from "../utils/Util";
import ModalTitle from "../ui/ModalTitle";

const Content = styled.div`
	width: 96%;
	margin: 0 auto;
	max-width: 96%;
	margin-top: -680px;
	position: relative;
	display: flex;
	flex-flow: wrap;
  justify-content: space-between;
`;

const RowFlex = styled.div`
  display: flex;
  justify-content: space-between;
  

  @media (max-width: 1330px) {
    display: block;  
  }
`;

const Row = styled.div`
  text-align-last: center;
`;

const Button = styled.button`
  border: none;
  background-color: white;
  color: ${ mainColor };
  font-size: 20px;
  padding: 8px 23px;
  margin-top: 70px;
  margin-bottom: 50px;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  :hover {
    background-color: lightgray;
  }
`
const SignUpContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0% 2%;
  margin-top: 2%;
  width: 100%;

  @media (max-width: 1330px) {
    margin: 0px!important;  
    width: 96%!important;    
  }
  
`; 

const Separator = styled.span`
  background-color: black;
  height: 100%;
  width: 1px;
`;

const SignUpContentTitle = styled.p`
  text-align: center;
  margin: 30px 0px;
  font-size: 20px;
`;

const Loading = styled.i`
  font-size: 40px;
  margin: 50px 0 50px 48.5%;
`;

const Info = styled.div`
  text-align: center;
`

let styleSelectComponentContactUs:CSSProperties = {
  textAlignLast: 'left',
  paddingLeft: '10px'
}

function b64DecodeUnicode(str: string): string {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

const SingUpCompletePage: FunctionComponent = (props) => {
  const params = new URLSearchParams(useLocation().search);
  const tokenReceived = String(params.get("token") ? params.get("token") : "");
  const typeUserParam = String(params.get("type") ? params.get("type") : "");
  const tokens = getTokens();
  const { checkIntegrity } = useIntegrity();
  const { openModal } = useModal();
  const history  = useHistory();
  const { saveCompleteUser } = useUser();
  const { verifyLegacyCredentials, login } = useAuth();
  const { cnpj } = mask();
  const [ role, setRole ] = useState("");
  const [ singUpUser, setSingUpUser ] = useState<SingUpUser>({
    clientCompany : {
      name:"",
      cnpj:"",
      qtdFunc:undefined,
      companyActivity:undefined
    },
    plan:"",
    corporativeEmail:"",
    firstName:"",
    lastName:"",
		userName:"",
		password:"",
    confirmPassword:"",
    jobTitle:undefined,
    typeUser: (typeUserParam.length > 0) ? typeUserParam : (tokenReceived ? "trial" : "new"),
	})
  const loadRefSingupUserReceived = useRef(true);
  const singupUserReceived = useRef(singUpUser);

  const [ loading, setLoading ] = useState(false);

  let optionsSelect = [
    {
      key: 1,
      value: "0-19",
      text: "Até 19"
    },
    {
      key: 2,
      value: "20-99",
      text: "De 20 a 99"
    },
    {
      key: 3,
      value: "100-499",
      text: "De 100 a 499"
    },
    {
      key: 4,
      value: "+500",
      text: "Acima de 500"
    }]

  useEffect(() => {

    let type = "";
    if (tokenReceived) {
      checkIntegrity(tokenReceived).then(response => {
        const obj = JSON.parse(b64DecodeUnicode(tokenReceived.split(".")[0]));
        let firtLastNameArray = obj.n.split(" ");
        setRole(obj.rl);
        
        attempLoginUser(obj.e, obj.p).then(response => {
          const statusCode = response.status
          if (statusCode === 200) {
            type = "legacy"
            clearToken()
          }
          if (statusCode === 206) {
            type = "trial"
          }


          setSingUpUser({
            ...singUpUser,
            firstName: firtLastNameArray[0],
            lastName: firtLastNameArray[firtLastNameArray.length - 1 !== 0 ? firtLastNameArray.length - 1 : ""],
            corporativeEmail: obj.e,
            userName: obj.e,
            clientCompany: {name: obj.c, cnpj: obj.cnpj, qtdFunc: obj.eq, companyActivity: obj.ac},
            plan: obj.pl,
            jobTitle: obj.jt,
            typeUser: type
          })

        }).catch(err => {
          if (err.response?.status === 401) {
            if (obj.u && obj.u === 'old') {
              type = "legacy";
              let cnpj = obj.cnpj ? (obj.cnpj.length > 0 ? obj.cnpj : "") : "";
              let qtdFunc = obj.eq ? (obj.eq.length > 0 ? obj.eq : undefined) : undefined;
              let companyActivity = obj.ac ? (obj.ac.length > 0 ? obj.ac : undefined) : undefined;
              setSingUpUser({
                ...singUpUser,
                firstName: firtLastNameArray[0],
                lastName: firtLastNameArray[firtLastNameArray.length - 1 !== 0 ? firtLastNameArray.length - 1 : ""],
                corporativeEmail: obj.e,
                userName: obj.e,
                clientCompany: {name: obj.c, cnpj: cnpj, qtdFunc: qtdFunc, companyActivity: companyActivity},
                plan: obj.pl,
                jobTitle: obj.jt,
                typeUser: type
              })
            } else {
              history.push("/login");
              openModal(makeModal("Erro", "Usuário não encontrado."));
            }
          } else {
            history.push("/login");
            openModal(makeModal("Erro", "Erro no servidor"));
          }          
        })
      }).catch(err => {
        history.push("/login");
        openModal(makeModal("Erro", "Houve uma inconsistência nos dados. Por favor, refaça o procedimento."));
      });
    } else {
      if (singUpUser.plan.length === 0) {
        setSingUpUser({...singUpUser, plan: 'trial'});
      }
      if (singUpUser.typeUser === 'legacy') {
        if (tokens != null){
          const obj = JSON.parse(b64DecodeUnicode(tokens.token.split(".")[1]));
          setSingUpUser({ 
            ...singUpUser,
            corporativeEmail: obj.email,
            userName: obj.username,
            firstName: obj.firstName ? obj.firstName : "",
            lastName: obj.lastName ? obj.lastName : "",
            plan: 'trial'
          })
        }
      }
    }
    

  }, [ setSingUpUser ])

  const attempLoginUser = async (user:string, password:string) => {
    return verifyLegacyCredentials({ user, password });
  }

  const loginUser = async (user:string, password:string) => {
    return login({ user, password });
  }

  const submitUser = async () => {

    if (!verifyEmptyFields(singUpUser) || !verifyEmptyFields(singUpUser.clientCompany)) {
      openModal(makeModal("Info", "Preencha o(s) campo(s) obrigatório(s). *"));
      return;
    }

    if (!verifyConfirmPassword(singUpUser.confirmPassword))
      return;
    
    if (!verifyValidPassword(singUpUser.password))
      return;
    
    if (!verifyCnpj(singUpUser.clientCompany.cnpj))
      return;

    if (!verifyEmail(singUpUser.corporativeEmail))
      return;

    try {
      setLoading(true);
      await saveCompleteUser(singUpUser);
      if (role === "MODERATION") {
        window.open("https://intranet.ehorus.com.br/");
        setLoading(false);
        return openModal(makeModal("Cadastro Realizado", "Para começar as atividades de moderação faça login em nossa intranet: https://intranet.ehorus.com.br/" ));
      }
      await loginUser(singUpUser.corporativeEmail, singUpUser.password)
      
      if (singUpUser.typeUser === 'new') {
        openModal(makeModal("Info", "Cadastro concluído." ))
      } 
    } catch (err) {
      if (err.response?.status === 409) {
        openModal(
          <>
            <Info>
              A sua empresa já estava cadastrada no sistema da horus. Entre em contato diretamente 
              com o responsavel pela sua empresa para que ele permita seu acesso.
              Qualquer dúvida entre em contato conosco. webtooldashboard@ehorus.com.br
            </Info>
          </>
        )
        history.push("/login")
      } else {
        openModal(makeModal("Erro", "Erro interno do Servidor." ));
      }
      setLoading(false);
    }

  }

  const verifyConfirmPassword = (password:any) => {
    if (singUpUser.password !== password) {
      openModal(makeModal("Info", "As senhas precisam ser iguais." ));
      return false;
    }
    return true;
  }

  const verifyValidPassword = (password:string) => {
    if (!validPassword(password)) {
      openModal(
        <>
          <ModalTitle> Info </ModalTitle>
          <Info>
            A senha deve ser composta por 8 digítos e conter letras minúsculas, maiúsculas, números e caracter especial
          </Info>
        </>
      )
      return false;
    }
    return true;
  }

  const verifyCnpj = (cnpj:string) => {
    if (!validCNPJ(cnpj)) {
      openModal(makeModal("Info", "Cnpj digitado é inválido." ));
      return false;
    }
    return true;
  }

  const verifyEmail = (email:string) => {
    if (!validEmail(email)) {
      openModal(makeModal("Info", "Este email não é válido." ));
      return false;
    }
    return true;
  }

  const signUpContentTitleLogin = singUpUser.typeUser === 'trial' ? "Defina sua senha" :
  singUpUser.typeUser === 'new' ? "Dados de Acesso" : "Nos informe uma nova senha";

  const signUpContentTitleDadosUsuario = singUpUser.typeUser === 'legacy' && (tokens?.firstName || tokens?.lastName) ?
  "Conte-nos sobre seu cargo" : "Fale mais sobre você";

  const signUpContentTitleDadosEmpresa = singUpUser.typeUser === 'trial' ? `Insira mais dados da empresa: ${singUpUser.clientCompany.name}` : 
  "Conte-nos mais sobre sua empresa";

  const titleCard = singUpUser.typeUser === 'trial' ? `Olá ${singUpUser.corporativeEmail}, continue seu cadastro` :
  singUpUser.typeUser === 'new' ? "Cadastre-se" : 
  tokens?.firstName && tokens?.lastName ? `Olá ${tokens?.firstName} ${tokens?.lastName}. A sua senha expirou` :
  tokens?.user ? `Olá ${tokens?.user}. A sua senha expirou` : 
  `Olá ${singUpUser.corporativeEmail}. Troque sua Senha`;  
  
  if (singUpUser.typeUser !== 'new' && singUpUser.corporativeEmail.length > 0  && loadRefSingupUserReceived.current){
    singupUserReceived.current = singUpUser
    loadRefSingupUserReceived.current = false;
  }

  const fullDataCorporative = !verifyEmptyFields(singupUserReceived.current.clientCompany);

  return (
		<>
			<HeaderPageGuest>
				<Content>
          { (singUpUser.typeUser === 'trial' && singUpUser.corporativeEmail) || singUpUser.typeUser !== 'trial' ? (
            <>
					    <Card titleCard={titleCard} 
            columnSize={'100'} >
            <RowFlex>
              <SignUpContent>
                <SignUpContentTitle>{ signUpContentTitleLogin }</SignUpContentTitle>

                { singUpUser.typeUser === 'new' ? (<InputComponent
                  label='E-mail *'
                  maxLength='50'
                  type='email'
                  placeholder="E-mail para contato"
                  value={ singUpUser.corporativeEmail }
                  onChange={ e => setSingUpUser({ ...singUpUser, corporativeEmail: e.currentTarget.value, userName: e.currentTarget.value }) }
                  onBlur={ e => verifyEmail(singUpUser.corporativeEmail) }
                />) : null}

                <InputComponent
                  label={singUpUser.typeUser === 'new' ? 'Cadastre uma senha *' : 
                  singUpUser.typeUser === 'trial' ? 'Defina sua senha *' : 'Redefina sua senha *'}
                  maxLength='20'
                  type="password"
                  placeholder="Senha"
                  value={ singUpUser.password }
                  onChange={ e => setSingUpUser({ ...singUpUser, password: e.currentTarget.value }) }
                  onBlur={ e => verifyValidPassword(singUpUser.password) }
                />

                <InputComponent
                  label='Repetir nova Senha *'
                  maxLength='20'
                  type="password"
                  placeholder="Senha"
                  onBlur={ e => {
                    verifyConfirmPassword(e.currentTarget.value)
                    setSingUpUser({ ...singUpUser, confirmPassword: e.currentTarget.value })
                    } }
                />

              </SignUpContent>

              <SignUpContent style={{ width: '1%', margin: '180px 0px 110px'}}>
                <Separator></Separator>
              </SignUpContent>

              <SignUpContent>
                <SignUpContentTitle>{ signUpContentTitleDadosUsuario }</SignUpContentTitle>

                  <InputComponent
                    label='Nome *'
                    maxLength='50'
                    placeholder="Nome"
                    value={ singUpUser.firstName }
                    onChange={ e => setSingUpUser({ ...singUpUser, firstName: e.currentTarget.value }) }
                    />                
                        
                    <InputComponent
                      label='Sobrenome *'
                      maxLength='50'
                      placeholder="Sobrenome"
                      value={ singUpUser.lastName }
                      onChange={ e => setSingUpUser({ ...singUpUser, lastName: e.currentTarget.value }) }
                    />                  

                  { !singupUserReceived.current.jobTitle ? (
                  <InputComponent
                    label='Cargo *'
                    maxLength='50'
                    placeholder="Digite seu cargo"
                    value={ singUpUser.jobTitle }
                    onChange={ e => setSingUpUser({ ...singUpUser, jobTitle: e.currentTarget.value }) }
                  /> ) : null
                  }
              </SignUpContent>
                
              { fullDataCorporative ? (
              <>
                <SignUpContent style={{ width: '1%', margin: '180px 0px 110px'}}>
                  <Separator></Separator>
                </SignUpContent>

                <SignUpContent>
                  <SignUpContentTitle>{ signUpContentTitleDadosEmpresa }</SignUpContentTitle>
                  <InputComponent
                    maxLength='18'
                    label='CNPJ *'
                    placeholder="Digite o Cnpj da sua empresa"
                    value={ singUpUser.clientCompany.cnpj }
                    onChange={ e => setSingUpUser({ 
                      ...singUpUser, clientCompany: {...singUpUser.clientCompany, cnpj: cnpj(e.currentTarget.value, e) }
                    }) }
                    onBlur={ e => verifyCnpj(singUpUser.clientCompany.cnpj) }
                  />
                  { !singupUserReceived.current.clientCompany.name ? (
                    <InputComponent
                      label='Nome da sua empresa *'
                      maxLength='50'
                      type='text'
                      placeholder="Digite o nome da sua Empresa"
                      value={ singUpUser.clientCompany.name }
                      onChange={ e => setSingUpUser({ 
                        ...singUpUser, clientCompany: {...singUpUser.clientCompany, name: e.currentTarget.value }
                      }) }
                    /> ) : null
                  }
                  
                  { !singupUserReceived.current.clientCompany.qtdFunc ? (
                  <SelectComponent 
                    label='Quantidade de Funcionários'
                    title='Selecione'
                    style={styleSelectComponentContactUs}
                    data={optionsSelect} 
                    value={ singUpUser.clientCompany.qtdFunc }
                    onChange={ e => setSingUpUser({ 
                      ...singUpUser, clientCompany: {...singUpUser.clientCompany, qtdFunc: e.currentTarget.value }
                    }) }
                  /> ) : null
                  }

                  { !singupUserReceived.current.clientCompany.companyActivity ? (
                    <InputComponent
                      label='Ramo de Atividade'
                      maxLength='50'
                      placeholder="Digite o ramo de atividade da sua empresa"
                      value={ singUpUser.clientCompany.companyActivity }
                      onChange={ e => setSingUpUser({ 
                        ...singUpUser, clientCompany: {...singUpUser.clientCompany, companyActivity: e.currentTarget.value }
                      }) }
                    /> ) : null
                  }              

              </SignUpContent> 
            </>
            ): null}
            </RowFlex>
              <Row>
                { !loading ? (
                  <>
                    <Button
                    type="button"
                    onClick={ submitUser }
                    disabled={ loading }
                    children={ loading ? 'Finalizando...' : 'Finalizar' } />

                    <Button
                      type="button"
                      onClick={ e => {
                        clearTokens()
                        history.push("/login")
                      }
                    }
                    children={ 'Cancelar' } />
                  </>
                ) : (<Loading style={{margin: '50px 0 50px 0px'}} className='fas fa-spinner fa-pulse' />)

                }
              </Row>
           
              
					</Card>
            </> ) : (
              <>
              <Card titleCard={'Aguarde...'} >
                <Loading className='fas fa-spinner fa-pulse' />
              </Card>
              </>
            )
          }
        </Content>
			</HeaderPageGuest>
		</>
	)
}

export default SingUpCompletePage;