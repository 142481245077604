export const FAQ_LIST = [
  {
    title: "O que é Cesta de Consumo Básica HORUS & FGV IBRE ?",
    ga: "perg_cesta_consumo_basica",
    description: (
      <>
        A Cesta de Consumo Básica HORUS & FGV IBRE é composta por produtos de
        consumo típico considerados mais relevantes dentre as categorias de
        alimentos, tais como arroz, feijão, açúcar, café, óleo, dentre outras.
        Dentro de cada categoria, foi selecionado um segmento de produto com
        maior presença no carrinho de compras do shopper para representá-la.
        <br />
        <br />
        Por exemplo, foi selecionado o segmento Arroz Branco como representante
        da categoria Arroz, em todas as cidades. Já para a categoria Feijão,
        considerou-se o segmento Feijão Carioca na maioria das capitais, exceto
        para Rio de Janeiro e Curitiba, onde foi selecionado o Feijão Preto, por
        ser o segmento de produto mais presente na cesta de compras. <br />
        <br />
        Além disso, para cada categoria, foi estimada a quantidade média
        adquirida de produtos (em quilos, litros ou unidades) em uma compra
        típica de abastecimento, em cada capital.
        <br />
        <br />
        Portanto, cada capital terá um perfil específico, de acordo com a
        relevância e a quantidade média adquirida dos produtos dessas
        categorias.
        <br />
        <br />
        Para identificar as categorias que fazem parte de uma compra típica de
        abastecimento, foram consideradas as compras com valor superior a R$
        100,00 e com mais de 12 itens no cupom fiscal.
        <br />
        <br />
        <a target="__blank" href="/static/docs/fgv/Composição Cesta Básica.pdf">
          Veja aqui a composição da Cesta de Consumo Básica
        </a>
      </>
    ),
  },
  {
    title: "O que é Cesta de Consumo Ampliada HORUS  &  FGV IBRE ?",
    ga: "perg_cesta_consumo_ampliada",
    description: (
      <>
        Na Cesta de Consumo Ampliada HORUS & FGV IBRE, além de alimentos, estão
        contemplados outros produtos de consumo típico das categorias mais
        relevantes de bebidas, limpeza e higiene e beleza. Além das categorias
        que compõem a Cesta de Consumo Básica, outras categorias relevantes de
        alimentos são também consideradas. No total, são 51 categorias de
        produtos.
        <br />
        <br />
        Para cada categoria, foi selecionado um segmento com maior presença no
        carrinho de compras do shopper para representá-la.
        <br />
        <br />
        Por exemplo, foi selecionado o segmento Refrigerante tipo Cola como
        representante da categoria Refrigerante, em todas as cidades, por ser o
        mais presente na cesta de compras.
        <br />
        <br />
        Além disso, para cada categoria, foi estimada a quantidade média
        adquirida de produtos (em quilos, litros ou unidades) em uma compra
        típica de abastecimento, em cada capital.
        <br />
        <br />
        Portanto, cada capital terá um perfil específico, de acordo com a
        relevância e a quantidade média adquirida dos produtos dessas
        categorias. <br />
        <br />
        Como compra típica de abastecimento, foram consideradas aquelas com
        valor superior a R$ 100,00 e com mais de 12 itens no cupom fiscal de
        compra.
        <br />
        <br />
        <a
          target="__blank"
          href="/static/docs/fgv/Composição Cesta Ampliada.pdf"
        >
          Veja aqui a composição da Cesta de Consumo Ampliada
        </a>
      </>
    ),
  },
  {
    title: "Como é calculado o valor das cestas?",
    ga: "perg_calculo_valor_cesta",
    description: (
      <>
        Definidos os tipos de produtos (segmentos) que compõem cada uma das
        cestas, em cada capital, considera-se a quantidade média adquirida
        desses produtos nas compras típicas de abastecimento da cidade. O valor
        total da cesta é calculado multiplicando o preço médio de cada um dos
        segmentos pela quantidade média adquirida, em cada capital.
        <br />
        <br />
        <a
          target="__blank"
          href="/static/docs/fgv/Composição Cesta Ampliada.pdf"
        >
          Veja aqui a composição da Cesta de Consumo Ampliada{" "}
        </a>{" "}
        <br />
        <a target="__blank" href="/static/docs/fgv/Composição Cesta Básica.pdf">
          Veja aqui a composição da Cesta de Consumo Básica
        </a>
      </>
    ),
  },
  {
    title: "Como é formado o preço da categoria?",
    ga: "erg_preco_categoria",
    description: (
      <>
        Com uma base de mais de 35 milhões de notas fiscais coletadas
        mensalmente, calcula-se o preço médio de compra de todos os segmentos da
        categoria, a partir do preço médio encontrado dos produtos que compõem
        estes segmentos, nas redes de autosserviço e atacarejo da capital, onde
        houve captura de informações por meio da nota fiscal. Os preços estão
        convertidos para a unidade de medida do produto, podendo ser quilo,
        litro ou unidade.
        <br />
        <br />
        <b>
          Para o cálculo de variação, com o intuito de garantir a
          comparabilidade dos dados, considera-se apenas produtos que constam
          nos 2 meses utilizados no cálculo.
        </b>
      </>
    ),
  },
  {
    title: "Quais categorias estão disponíveis na versão Plus? E na Premium?",
    ga: "perg_versao_plus_premium",
    description: (
      <>
        Todas as versões do produto Cesta HORUS & FGV IBRE abordam as mesmas
        categorias. Nas versões Plus e Premium, é possível visualizar os preços
        médios de cada uma delas em cada capital. Na Premium é possível
        visualizar também os preços dos diferentes segmentos, fabricantes,
        marcas e tamanhos de embalagem. As categorias são:
        <br />
        <br />
        Achocolatado/Modificador, Açúcar, Água Mineral, Água Sanitária,
        Amaciantes para Roupa, Arroz, Azeite, Batata Congelada, Biscoitos,
        Bovino, Café em Pó e em Grãos, Cerveja, Chocolate, Creme de Leite, Creme
        Dental, Desodorante, Detergente Líquido, Enlatados e Conservas, Farinha
        de Mandioca, Farinha de Trigo, Fubá e Farinhas de Milho, Feijão, Frango,
        Frutas (banana, laranja e maçã), Hambúrguer, Iogurte, Legumes (batata
        Inglesa, cebola e cenoura), Leite Condensado, Leite em Pó, Leite UHT,
        Linguiça, Manteiga, Margarina, Massas Alimentícias, Massas Instantâneas,
        Molho de Tomate, Óleo, Ovos, Pão, Papel Higiênico, Queijos,
        Refrigerante, Requeijão, Sabão para Roupa, Sabonete, Shampoo, Snacks e
        Salgadinhos, Suco Pronto, Suíno, Verduras (alface, couve, repolho),
        Vinho.
      </>
    ),
  },
];
