
import { FunctionComponent } from 'react'

export interface Props {
  type?: "fixed" | "contain"
}

const Loading: FunctionComponent<Props> = props => {
  const { type = "fixed" } = props;
  return (
    <div className={ `hdpd-loading ${ type === "contain" ? 'hdpd-loading--contain' : '' }` }>
      <div className="hdpd-loading-center">
        <img src="/static/loading_horus.gif" alt="" style={{ height: "50px" }}/>
        <span className="hdpd-loading-label">
          Carregando...
        </span>
      </div>
    </div>
  )
}

export default Loading