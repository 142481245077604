export const HEADER_MENU_LIST = [
  {
    name: "Sobre",
    to: "#sobre",
  },
  {
    name: "Produtos",
    to: "#produtos",
  },
  {
    name: "Conheça a ferramenta",
    to: "#ferramenta",
  },
  {
    name: "Planos",
    to: "#planos",
  },
];
