import { useHistory } from "react-router-dom";
import { GoogleAnalyticsActions } from "../../../../../../hooks/useGoogleAnalytics";
import useFgv from "../../../useFgv";
import { Alert } from "../../svg/Alert";

export const FgvPricing = () => {
  const { fgvUser } = useFgv();
  const history = useHistory();

  const tagging = new GoogleAnalyticsActions("G-SX5Z63SCPY");

  const onRegister = (e: any) => {
    GoogleAnalyticsActions.event("iniciou_cadastro", "", "planos_plus");

    if (e.target?.getAttribute("id-event")) {
      tagging.eventName(e.target?.getAttribute("id-event"));
    }

    if (fgvUser) {
      history.push("/plus");
      window.scrollTo(0, 0);
      return;
    }
  };

  const onSubscribe = () => {
    GoogleAnalyticsActions.event("assinar_planos", "cestaconsumo", "ㅤ");
    window.open("https://dashboard.ehorus.com.br/signup/premium", "_blank");
  };

  const onLearnMore = () => {
    GoogleAnalyticsActions.event("saiba_mais_planos", "cestaconsumo", "ㅤ");
    window.open("https://www.ehorus.com.br/", "_blank");
  };
  return (
    <section className="fgv-section">
      <i id="subscribe" className="small-break-scroll" />
      <i id="planos" className="small-break-scroll" />
      <div className="fgv-container flex-col">
        <div className="hd-fgv_pricing">
          <h2>Conheça nossos planos</h2>
          <table className="hd-fgv__pricing__table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <div className="hd-fgv_pricing-top_table">
                    <span>
                      Cesta do
                      <br />
                      consumidor
                    </span>
                    <div className="hd-fgv_pricing-top_table-bottom">
                      <span>Grátis</span>
                      <small>Atualização mensal</small>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="hd-fgv_pricing-top_table">
                    <span>Plus</span>
                    <div className="hd-fgv_pricing-top_table-bottom space-between">
                      <div>
                        <span>Grátis</span>
                        <small>Atualização mensal</small>
                      </div>
                      {fgvUser ? null : (
                        <button onClick={onRegister}>Acessar</button>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="hd-fgv_pricing-top_table">
                    <span>Premium</span>
                    <div className="hd-fgv_pricing-top_table-bottom space-between">
                      <div>
                        <span>a partir de</span>
                        <span>R$1.757</span>
                        <small>Atualização mensal</small>
                      </div>
                      <button onClick={onSubscribe}>Assinar</button>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="hd-fgv_pricing-top_table">
                    <span>Outras soluções</span>
                    <div className="hd-fgv_pricing-top_table-bottom content-end">
                      <button onClick={onLearnMore}>Saiba mais</button>
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th rowSpan={5}>
                  <span className="price-variation">
                    Veja preços e variação de:
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cesta de consumo básica</td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Cesta de consumo ampliada</td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Categoria de produtos</td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Segmento de categoria</td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Fabricante</td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Marca</td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Tamanho da embalagem</td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Canal varejista</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Mais métricas de performance</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
              <tr>
                <td>Mais dimensões geográficas</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div className="hd-fgv__pricing__feature hd-fgv__pricing__feature--active-dark" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="fgv-pricing_important">
          <div className="fgv-pricing_important-top">
            <Alert />
            <span>Importante!</span>
          </div>
          <p>
            Nossa metodologia é baseada em uma amostra aleatória de notas
            fiscais de compras dos shoppers em todo o Brasil. As mais de 35
            milhões de notas que processamos mensalmente garantem
            estatisticamente que reportemos preços e comportamentos de milhares
            de produtos com precisão. Porém, ainda é possível que alguns SKUs
            não cumpram critérios estatísticos mínimos em determinados meses,
            ficando de fora das observações. Nada que nos impeça de entregar
            dados e extrair insights extremamente ricos sobre o comportamento
            dos preços ao consumidor nas categorias de bens de consumo em massa.
          </p>
        </div>
      </div>
    </section>
  );
};
