import styled from "styled-components";
import ModalTitle from "../../ui/ModalTitle";

export const DashboardError = () => {
  return (
    <>
      <ModalTitle>Atenção</ModalTitle>
      <Text>
        Atualmente, estamos enfrentando problemas para carregar seus Dashboards.
        Pedimos desculpas pelo transtorno. Por favor, tente novamente mais
        tarde. <br /> Se o problema persistir, não hesite em entrar em contato
        conosco!
      </Text>
    </>
  );
};

const Text = styled.span`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px auto;
  max-width: 640px;
  font-size: 18px;
`;
