import { SVGProps } from "react";

export const DesktopSearch = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="48"
    fill="none"
    viewBox="0 0 56 48"
    {...props}
  >
    <g fill="#00263C" clipPath="url(#clip0_8_158)">
      <path d="M.702 4.16C1.115 1.895 2.825.242 5.132 0h45.65c2.465.185 4.26 1.995 4.53 4.431v30.604c-.014 1.966-2.222 4.43-4.217 4.43H35.038c.243 1.411.684 2.85 1.41 4.09.727 1.24.67 1.068.77 1.154.356.256 2.166 0 2.778.1 1.781.285 1.838 2.992-.2 3.191H16.218c-2.037-.2-1.98-2.92-.2-3.191.6-.1 2.423.156 2.78-.1.355-.257.654-.955.768-1.154.727-1.24 1.169-2.664 1.41-4.09H4.92c-1.938 0-3.99-2.307-4.217-4.16zm51.405 25.703V4.43c0-.356-.77-1.126-1.169-1.183L5.447 3.191c-.513-.1-1.539.784-1.539 1.226v25.432h48.2zm0 3.206h-48.2v1.966c0 .441 1.026 1.325 1.54 1.225l45.492-.057c.384-.029 1.168-.87 1.168-1.183v-1.966zM22.572 44.794h10.885c-.77-1.695-1.468-3.448-1.596-5.328H24.24c-.257 1.866-.855 3.633-1.667 5.328"></path>
      <path d="M35.551 7.067c6.027-.684 10.002 5.243 6.982 10.472l5.998 6.026c.955 1.54-.784 3.178-2.28 2.18l-5.94-5.898c-5.273 2.978-11.584-1.225-10.459-7.295.5-2.693 2.935-5.186 5.7-5.5zm.314 3.206c-1.51.213-2.85 1.837-2.964 3.333-.27 3.69 4.374 5.5 6.668 2.608 2.18-2.764-.214-6.454-3.704-5.941M8.452 6.426h16.613c1.838.427 1.767 2.85-.1 3.177H8.552c-1.738-.428-1.81-2.664-.085-3.177zM8.452 12.823h16.613c1.838.427 1.767 2.85-.1 3.177H8.552c-1.738-.428-1.81-2.664-.085-3.177zM8.452 19.22h16.613c1.838.427 1.767 2.85-.1 3.177H8.552c-1.738-.427-1.81-2.664-.085-3.177z"></path>
    </g>
    <defs>
      <clipPath id="clip0_8_158">
        <path fill="#fff" d="M.702 0h54.597v48H.702z"></path>
      </clipPath>
    </defs>
  </svg>
);
