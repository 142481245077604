import { FunctionComponent } from "react";
import styled from "styled-components";
import { secondColor } from "../../constants";

const ContentCard = styled.div`
  background-color: ${ secondColor };
  height: auto;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0.5% 0.5%;
  padding-bottom: 1%;
`;

const CardBody = styled.div`
  width: 98%;
  height: calc(100% - 70px);
  background-color: white;
  margin: 0 auto 1% auto;
  position: relative;
  display: flow-root;
`;

const Title = styled.div`
	margin: 1% 0;
	position: relative;
	display: inline-block;
	width: 100%;
	text-align: center;
  font-size: 25px;
`;

const Card: FunctionComponent<{titleCard?:string, columnSize?:string, style?:Object}> = (props) => {

	const { titleCard, columnSize, children } = props;

	return (
		<>
			<ContentCard style={{flexBasis: columnSize ? columnSize+"%" : '100%'}}>
				<Title>{ titleCard }</Title>
				<CardBody>
					{ children }
				</CardBody>
			</ContentCard>
		</>
	)
}

export default Card;