import ModalTitle from "../../ui/ModalTitle";

const CronogramaDashboard = () => {
  return (
    <>
      <ModalTitle>Calendário</ModalTitle>
      <div>
        <embed
          className="h-embed-pdf-cronograma-dashboard"
          type="application/pdf"
          src="/static/docs/calendario_liberacao_mensal_2024_.pdf#toolbar=0&navpanes=0"
        />
      </div>
    </>
  );
};

export default CronogramaDashboard;
