import React from "react";
import { toast } from "react-toastify";
import { ResearchContext } from "../components/context/ResearchContext";
import { GoogleAnalyticsActions } from "./useGoogleAnalytics";
import useRequest from "./useRequest";
export interface IResearch {
  evaluation: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  resume: string | null;
}

export interface IQuestion {
  id: number | string;
  question: string;
}

interface ISend {
  event: "ANS" | "DWA" | "SKP" | "RLR";
  rating?: string | number | null;
  reason?: string | null;
}

export const initialQuestion: IQuestion = {
  id: 0,
  question: "",
};

const UseResearch = ({ children }: any) => {
  const [state, setState] = React.useState({} as IResearch);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [question, setQuestion] = React.useState<IQuestion>({
    ...initialQuestion,
  } as IQuestion);

  const sendResearchRequest = useRequest(
    "POST",
    `/research-response/${question.id}`
  );

  const sendResearch = async (data: ISend): Promise<any> => {
    return sendResearchRequest({ data });
  };

  const research = async ({ evaluation, resume = null }: IResearch) => {
    const id = toast.loading("Por favor, aguarde...", {
      position: "top-right",
    });

    setState({
      evaluation: evaluation,
      resume: resume,
    });

    try {
      await sendResearch({
        event: "ANS",
        rating: evaluation,
        reason: resume,
      });

      toast.update(id, {
        render: "Sua resposta foi registrada com sucesso!",
        type: "info",
        isLoading: false,
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      GoogleAnalyticsActions.event("NPS", "", "respondeu_pesquisa");
    } catch (error) {
      toast.update(id, {
        render:
          "Não foi possível finalizar a solicitação, por favor tente novamente!",
        type: "error",
        isLoading: false,
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setVisible(false);
  };

  const addQuestion = ({ id, question }: IQuestion) => {
    GoogleAnalyticsActions.event("NPS", "", "mostrou_pesquisa");
    setQuestion({
      id: id,
      question: question,
    });
    setVisible(true);
  };

  const doNotAnswer = () => {
    GoogleAnalyticsActions.event("NPS", "", "prefiro_nao_responder");
    sendResearch({
      event: "DWA",
    });
    setVisible(false);
  };

  const answerLater = () => {
    GoogleAnalyticsActions.event("NPS", "", "responder_depois");
    sendResearch({
      event: "RLR",
    });
    setVisible(false);
  };

  const close = () => {
    GoogleAnalyticsActions.event("NPS", "", "fechou_pesquisa");
    sendResearch({
      event: "SKP",
    });
    setVisible(false);
  };


  React.useEffect(() => {
    document.addEventListener("keydown", ({key}) => {
      if (key !== "Escape") return;
      close()
    });
  }, [close]);


  return (
    <ResearchContext.Provider
      value={{
        research,
        addQuestion,
        doNotAnswer,
        answerLater,
        close,
        state,
        question,
        visible,
      }}
    >
      {children}
    </ResearchContext.Provider>
  );
};

export default UseResearch;
