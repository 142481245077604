import { createContext } from 'react'
import { Home } from '../../model'

interface HomeContextProps {
  home: Home | null;
  setHome: (home: Home | null) => void;
}

const HomeContext = createContext<HomeContextProps>({
  home: null,
  setHome: () => {}
})

export default HomeContext