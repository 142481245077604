import { ReactNode, FunctionComponent, useState, createContext, useContext, MouseEvent, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { mainColor } from '../../constants'

const ModalWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1000;
`;

const ModalWindow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 1000px;
  max-width: 95%;
  min-height: 200px;
  padding: 20px;
  box-sizing: border-box;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
  color: ${ mainColor };

  :hover {
    color: black;
  }
`

export type OpenModal = (modal: ReactNode, config?: ModalConfig) => void;

export interface ModalContextProps {
  openModal: OpenModal;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextProps>({
  openModal: () => {},
  closeModal: () => {}
});

export const useModal = (): ModalContextProps => {
  return useContext(ModalContext)
}

interface ModalEntry {
  content: any,
  id: number,
  config: ModalConfig
}

interface ModalConfig {
  noClose?: boolean
}

const Modal : FunctionComponent = props => {
  const { children } = props;
  const [ modals, setModals ] = useState<ModalEntry[]>([]);
  const lastId = useRef(0);

  const openModal = useCallback((content: any, config: ModalConfig = {}): void => {
    setModals(modals => [ ...modals, { content, id: ++lastId.current, config} ])
  }, [ setModals ]);

  const closeModal = (): void => {
    if (!modals.length) {
      return;
    }
    setModals(modals.slice(0, modals.length - 1));
  };

  const onModalWrapperClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget !== e.target) {
      return;
    }
    if (!noClose) closeModal();
  };

  const currentModal = modals.length ? modals[modals.length - 1] : undefined;
  const { currentModal: { config: { noClose = false } = {} } = {} } = { currentModal };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') {
        return;
      }
      if (!noClose) closeModal();
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  });

  return (
    <ModalContext.Provider value={{ closeModal, openModal }}>
      { 
        !currentModal ? null : (
          <ModalWrapper onClick={ onModalWrapperClick }>
            <ModalWindow id='modalWindows'>
              {
                noClose ? null : (
                  <CloseButton onClick={ closeModal }>
                    <i className="fa fa-times"></i>
                  </CloseButton>
                )
              }
              {
                typeof currentModal.content === 'function' ? currentModal.content() : currentModal.content
              }
            </ModalWindow>
          </ModalWrapper>
        )
      }
      {
        children
      }
    </ModalContext.Provider>
  );
};

export default Modal