import React from "react";
import { setInterval } from "timers";
import useAuth from "../../hooks/useAuth";
import { sessionValidator } from "../../hooks/useSessionValidator";
import { authCredentials } from "../utils/authCredentials";

export const SessionValidator: React.FC = () => {
  const [time, setTime] = React.useState(" ");
  const { restore, logout } = useAuth();

  const refreshToken = async () => {
    try {
      const set = await restore();
      console.log(set);
    } catch (error) {
      logout();
      window.location.reload();
    }
  };

  const valid = () =>
    (time === " " || time === "") && authCredentials.get.accessToken();

  const modal = () => {
    return (
      <>
        {valid() && (
          <>
            <div id="session-validator">
              <div className="session-validator-wrapper">
                <h3>Sua sessão expirou</h3>
                <p>
                  Clique no botão abaixo para renovar sua sessão e continuar
                  comprando
                </p>
                <button onClick={() => refreshToken()}>
                  CONTINUAR COMPRANDO
                </button>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const setTimer = () => {
    let timer = sessionValidator();
    timer ? setTime(timer) : setTime("");
  };

  React.useEffect(() => {
    setInterval(() => {
      setTimer();
    }, 100);
  });

  return (
    <>
      {time ? (
        <div className="session-info">
          {time && `Sua sessão expira em ${time}`}
        </div>
      ) : (
        modal()
      )}
    </>
  );
};
