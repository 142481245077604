import CATEGORY_PRICE from "../../images/category-price.jpg";
import PRICE_MONITORING from "../../images/price-monitoring.jpg";
import SHOP_CART_PRICE from "../../images/shop-cart-price.jpg";
import { DesktopSearch } from "../../svg/DesktopSearch";
import { ShopCart } from "../../svg/ShopCart";
import { TaxInvoice } from "../../svg/TaxInvoice";

export const FGV_IBRE_LIST = [
  {
    key: "taxInvoice",
    icon: TaxInvoice,
    description: "Coleta de mais de 35 milhões de notas fiscais todos os meses",
  },
  {
    key: "category",
    icon: DesktopSearch,
    description: "Comportamento de preços de mais de 50 categorias",
  },
  {
    key: "shopCart",
    icon: ShopCart,
    description:
      "Divulgação dos preços da cesta de compras do consumidor brasileiro",
  },
];

export const FGV_IBRE_BENEFITS_LIST = [
  {
    title: "Acompanhe os valores de cestas de consumo",
    description:
      "Através de dados de dezenas de milhões de notas fiscais, acompanhe gratuitamente os valores médios gastos pelos consumidores em cestas de consumo básica e ampliada mês a mês em 8 capitais.",
    to: "#ferramenta",
    buttonTitle: "Ver cesta de consumo",
    image: SHOP_CART_PRICE,
  },
  {
    title: "Acesse gratuitamente o preço por categoria de produtos",
    description:
      "Na versão Plus, entenda o valor médio pago pelo consumidor em cada uma das categorias de produto que compõem as cestas.",
    to: "#cadastro",
    buttonTitle: "Acessar versão Plus",
    image: CATEGORY_PRICE,
  },
  {
    title:
      "Monitore em detalhes a dinâmica de preços dentro das categorias de produtos de interesse",
    description:
      "Assinando o Premium, navegue pela plataforma Horus de forma intuitiva e por todos os filtros disponíveis. Você vai acompanhar as movimentações de preço e analisar as diferenças entre fabricantes, marcas e até tamanhos de embalagem.",
    to: "https://dashboard.ehorus.com.br/signup/premium",
    buttonTitle: "Assinar plano Premium",
    image: PRICE_MONITORING,
  },
];
