import { SVGProps } from "react";

export const TaxInvoice = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="48"
    fill="none"
    viewBox="0 0 38 48"
    {...props}
  >
    <g clipPath="url(#clip0_8_139)">
      <path
        fill="#00263C"
        d="M36.084 48h-.748c-1.284-.651-2.245-1.866-3.33-2.805L28.054 48h-.835l-3.853-2.793c-1.384.84-2.507 2.13-3.978 2.793h-.749c-1.459-.651-2.519-2.004-3.878-2.818L10.795 48h-.836l-3.766-2.805c-1.172.927-2.17 2.129-3.516 2.805h-.749c-.71-.213-1.184-.601-1.446-1.315L.419 3.707A4.29 4.29 0 0 1 4.21 0h29.405c2.02.1 3.642 1.628 3.966 3.607v42.84c-.162.814-.724 1.315-1.497 1.54zm-1.31-4.132V3.794c0-.375-.91-1.102-1.346-.989l-29.18.05c-.324-.012-1.023.689-1.023.94v40.16c1.185-.689 1.82-2.129 3.38-1.853 1.01.175 2.78 2.254 3.778 2.78 1.609-.726 3.43-3.594 5.35-2.555 1.92 1.04 2.17 1.879 3.242 2.555 1.721-.739 3.404-3.67 5.475-2.505l3.105 2.505c.374.075 3.18-2.655 4.102-2.793 1.497-.213 2.033 1.09 3.118 1.779"
      ></path>
      <path
        fill="#fff"
        d="M27.218 48h-7.844c1.472-.664 2.606-1.954 3.978-2.793L27.205 48zM18.626 48h-7.844l3.966-2.818c1.359.814 2.419 2.167 3.878 2.818M35.336 48h-7.282l3.953-2.805c1.085.939 2.045 2.154 3.33 2.805M9.947 48H2.664c1.334-.676 2.344-1.879 3.517-2.805z"
      ></path>
      <path
        fill="#00263C"
        d="M12.017 7.288c.05.063.636.226.848.414.71.613.623 1.74-.137 2.279s-1.82-.35-2.731.075c-.91.426-.649.701-.512 1.127.138.426 2.657 1.252 3.218 1.553 3.23 1.678 2.569 6.411-.698 7.526.162 1.703-2.07 2.404-2.744.801-.075-.175-.037-.488-.162-.588s-1.11-.338-1.397-.476-.848-.45-1.085-.789c-.773-1.052.212-2.53 1.497-2.166 1.284.363 1.434.976 2.843.613s1.546-1.816.449-2.43c-1.235-.7-2.731-.826-3.853-1.94-1.46-1.453-1.073-4.158.51-5.373 1.585-1.214 1.023-.538 1.073-.613.1-.176.013-.576.162-.877.437-.926 1.958-1.027 2.445-.062s.187.814.261.901zM8.238 36.304l21.35-.025c1.77.075 2.12 2.379.373 2.817H8.026c-1.646-.375-1.459-2.604.212-2.792M8.238 30.593l21.35-.025c1.758.063 2.12 2.392.373 2.818H8.026c-1.646-.363-1.434-2.605.212-2.793M7.864 24.958l22.098-.025c1.795.45 1.346 2.83-.474 2.818H8.5c-1.72 0-2.257-2.13-.636-2.793M18.314 17.933h11.448c1.696.2 1.833 2.417.187 2.78H18.202c-1.584-.363-1.497-2.467.112-2.78M18.127 5.748h11.822c1.696.388 1.471 2.617-.275 2.793-3.541.363-7.681-.263-11.285 0-1.609-.226-1.82-2.317-.262-2.78zM18.227 11.834h11.635c1.72.35 1.646 2.53-.088 2.793H18.302c-1.609-.326-1.671-2.392-.075-2.793"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_8_139">
        <path fill="#fff" d="M.42 0h37.16v48H.42z"></path>
      </clipPath>
    </defs>
  </svg>
);
