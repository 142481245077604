import { ReactNode, useState } from "react";
import { GoogleAnalyticsActions } from "../../../../../../hooks/useGoogleAnalytics";
import { ChevronUp } from "../../svg/ChevronUp";
import { FAQ_LIST } from "./mapped";

type FaqItemProps = { title: string; ga: string; children: ReactNode };
const FaqItem = (props: FaqItemProps) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`hd-fgv__faq-item ${active ? "hd-fgv__faq-item--open" : ""}`}
    >
      <div
        className="hd-fgv__faq-title"
        onClick={() => {
          setActive(!active);
          GoogleAnalyticsActions.event(
            props.ga,
            active ? "close" : "open",
            "Faq"
          );
        }}
      >
        <span>{props.title}</span>
        <button
          className="hd-fgv__faq-item__expand"
          onClick={() => setActive(!active)}
        >
          <ChevronUp height={20} width={20} />
        </button>
      </div>
      <div className="hd-fgv__faq-description-wrapper">
        <div className="hd-fgv__faq-description">{props.children}</div>
      </div>
    </div>
  );
};

type Props = {
  fullPage: boolean;
};

export const FgvFaq = ({ fullPage }: Props) => {
  if (!fullPage) return null;
  return (
    <section className="fgv-section fgv-faq">
      <div className="fgv-container flex-col">
        <h2 className="hd-fgv__title">Perguntas frequentes</h2>
        <div className="hd-fgv__faq-wrapper">
          {FAQ_LIST.map(({ description, ga, title }) => (
            <FaqItem key={ga} {...{ title, ga }}>
              {description}
            </FaqItem>
          ))}
        </div>
      </div>
    </section>
  );
};
