import styled from "styled-components";
import { mainColor } from "../../../constants";
import ModalTitle from "../../ui/ModalTitle";

const DashboardContent = styled.div``;

const List = styled.ul`
  margin: 10% 2%;
  overflow-y: scroll;
  max-height: 250px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${mainColor};
    border-radius: 15px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: lightgray;
  }
`;

const ListItem = styled.li`
  margin: 10px 0px;
  font-size: x-large;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5em;

  :hover {
    color: ${mainColor};
  }

  :before {
    content: "•";
    display: inline-block;
    position: absolute;
    left: -1em;
    color: ${mainColor};
  }
`;

const TreinamentoHorusModal = () => {
  const goDocument = (document: any) => {
    window.open(`/static/docs/treinamento-horus/${document}`, "_blank");
  };

  return (
    <>
      <ModalTitle>Treinamento Horus</ModalTitle>
      <DashboardContent>
        <List>
          <ListItem
            style={{ cursor: "pointer" }}
            onClick={() => goDocument("Horus Treinamento Tecnico2.pdf")}
          >
            Treinamento de Metodologia 
          </ListItem>

          <ListItem
            style={{ cursor: "pointer" }}
            onClick={() =>
              goDocument("Horus Treinamento Retail Metrics Professional.pdf")
            }
          >
            Retail Metrics Professional - Guia de uso
          </ListItem>

          <ListItem
            style={{ cursor: "pointer" }}
            onClick={() => goDocument("Horus Treinamento Horus Price.pdf")}
          >
            Horus Price - Guia de uso
          </ListItem>
        </List>
      </DashboardContent>
    </>
  );
};

export default TreinamentoHorusModal;
