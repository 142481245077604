import { FunctionComponent, useState } from 'react'
import { FormEvent } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { mainColor } from '../../constants'
import useUser from '../../hooks/useUser'
import { SingUpUser } from '../domain/SingUpUser'
import makeModal from '../utils/MakeModal'
import { useModal } from '../utils/Modal'

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: auto;
`

const CenterBox = styled.div`
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LoginBox = styled.form`
  background-color: ${ mainColor };
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`

const Logo = styled.img`
  max-height: 35px;
  max-width: 95%;
  margin: 60px 0;
`

const Input = styled.input`
  width: 95%;
  background-color: transparent;
  border: solid 1px white;
  padding: 10px;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;

  :focus {
  }

  ::placeholder {
    color: white;
  }
`

const Button = styled.button`
  border: none;
  background-color: white;
  color: ${ mainColor };
  font-size: 18px;
  padding: 8px 23px;
  margin-top: 10px;
  margin-bottom: 80px;
  outline: none;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`

const SignUpText = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
`

const SignUpLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
`

const Trademark = styled.span`
  color: ${ mainColor };
  padding: 10px;
  display: block;
`

const MarkObrigatory = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
  float: left;
  margin-top: 4%;
`

const SingUpPage : FunctionComponent = () => {
	const [ singUpUser, setSingUpUser ] = useState<SingUpUser>({
		clientCompany : {
      name:"",
      cnpj:""
    },
    plan:"",
    corporativeEmail:"",
    firstName:"",
    lastName:"",
		userName:"",
		password:""
	})

  const [ loading, setLoading ] = useState(false);
  const [ singUpOk, setSingUpOk ] = useState(false);
  const { openModal } = useModal();
  const { singUp } = useUser();

  const initSingUp = () => {

    if (singUpUser.corporativeEmail.length === 0) {
      openModal(makeModal("Info", "Campo E-mail Corporativo é obrigatório para continuar o cadastro."));
      return;
    }
    setSingUpOk(true);
  }

  const verifyEmptyFields = ():any => {
    Object.entries(singUpUser).forEach(([key, value]) => {
      if (value.length === 0) {
        return;
      }
    });
    return singUpUser;
  }

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!verifyEmptyFields()) {
      openModal(makeModal("Info", "Preencha o(s) campo(s) obrigatório(s)."));
      return;
    }

    try {
      await singUp(singUpUser);
      openModal(makeModal("Info", "Solicitação de cadastro realizada com sucesso. Verifique seu email."));
    } catch (err) {
      openModal(makeModal("Erro", "Erro interno do Servidor. Código" + err.response?.status ));
      setLoading(false);
    }
  };

	const history = useHistory();

  return (
    <Background>
      <CenterBox>
        <LoginBox onSubmit={ handleOnSubmit }>
          <Logo
            src="/static/logo-horus-w.png"
            alt="Logo Horus" />

					<Input
            placeholder="E-mail Corporativo"
            value={ singUpUser.corporativeEmail }
            onChange={ e => setSingUpUser({ ...singUpUser, corporativeEmail: e.currentTarget.value }) }/>
          <MarkObrigatory>*</MarkObrigatory>        

          { singUpOk ? (
            <>                         
              <Input
                placeholder="Nome"
                value={ singUpUser.firstName }
                onChange={ e => setSingUpUser({ ...singUpUser, firstName: e.currentTarget.value }) } />
              <MarkObrigatory>*</MarkObrigatory>
                
              <Input
                placeholder="Sobrenome"
                value={ singUpUser.lastName }
                onChange={ e => setSingUpUser({ ...singUpUser, lastName: e.currentTarget.value }) } />  
              <MarkObrigatory>*</MarkObrigatory>

              <Input
                placeholder="Login"
                value={ singUpUser.userName }
                onChange={ e => setSingUpUser({ ...singUpUser, userName: e.currentTarget.value }) } /> 
              <MarkObrigatory>*</MarkObrigatory>

              <Input
                placeholder="Senha"
                type="password"
                value={ singUpUser.password }
                onChange={ e => setSingUpUser({ ...singUpUser, password: e.currentTarget.value }) } />
              <MarkObrigatory>*</MarkObrigatory>

              <Button 
                type="submit"
                disabled={ loading }
                children={ loading ? 'Cadastrando...' : 'Cadastrar' } />
            </>
            ) : null }


          { !singUpOk ? (

            <Button 
              type="button"
              onClick={ initSingUp }
              children={ 'Iniciar Cadastro' } />

          ) : null }         

          <SignUpText>
            <SignUpLink onClick={ e => history.push("/login") } style={{cursor: 'pointer'}}>
              { !singUpOk ? 'Voltar' : 'Cancelar' } 
            </SignUpLink>
          </SignUpText>
        </LoginBox>
        <Trademark>
          2021 © HORUS
        </Trademark>
      </CenterBox>
    </Background>
  )
}

export default SingUpPage