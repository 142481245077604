const RECAPTCHA_SITE_KEY = '6Ldb8YUbAAAAAJCYQLK6urf2x8DkA5h34a7vOdFR';

let lastSubscriberId = 0;
const recaptchaListeners: Record<string, () => void> = {};
let recaptchaDidLoad = false;

function setRecaptchaLoaded() {
  recaptchaDidLoad = true;
  var ids = Object.keys(recaptchaListeners);
  for (var i = 0; i < ids.length; i++) {
    try {
      recaptchaListeners[ids[i]]();
    } catch (e) {
      console.error(e);
    } finally {
      delete recaptchaListeners[ids[i]];
    }
  }
}

export function loadRecaptcha() {
  if (recaptchaDidLoad) return;

  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://www.google.com/recaptcha/api.js?onload=setRecaptchaLoaded';

  document.getElementsByTagName('head')[0].appendChild(script);
}

export function onRecaptchaLoaded(listener: () => void): number | undefined {
  if (recaptchaDidLoad) {
    listener();
    return;
  }
  recaptchaListeners[++lastSubscriberId] = listener;
  return lastSubscriberId;
}

export function clearOnRecaptchaLoaded(id?: number) {
  if (!id) return;
  delete recaptchaListeners[id];
}

export function showRecaptcha(el: Element, params: {
  onSuccess: () => void,
  onFail: () => void
}) {
  const grecaptcha = (window as any).grecaptcha;

  return grecaptcha.render(el, {
    'sitekey': RECAPTCHA_SITE_KEY,
    'callback': params.onSuccess,
    'expired-callback': params.onFail,
    'error-callback': params.onFail,
    'size': 'invisible'
  });
}

export function executeRecaptcha(id: any) {
  return (window as any).grecaptcha.execute(id);
}

export function resetRecaptcha(id: any) {
  return (window as any).grecaptcha.reset(id);
}

(window as any).setRecaptchaLoaded = setRecaptchaLoaded;