import { ComponentType, FunctionComponent, ReactNode } from "react";

export type PaymentProfileData = {
  number: string,
  name: string,
  validate: string,
  cvv: string,
  flag: string,
}

export type PaymentProfile = {
  gatewayToken: string,
  flag: string,
  creditCardLabel: string
}

export type UserRegister = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  job: string;
  cnpj: string;
  companyName: string;
  activity: string;
  employeeAmount: string;
  emailConfirmCode: string;
}

export type CompanyRegister = {
  cnpj: string;
  companyName: string;
  activity: string;
  employeeAmount: string;
}

export type UseCheckoutHook = () => {
  getAvailableFgvCategories: () => Promise<{ id: string, name: string }[]>,
  getAvailableMetricsWebCategories: () => Promise<{ categoryId: number, categoryName: string, ufs: { uf: string, ufName: string }[] }[]>,
  getPaymentProfiles: () => Promise<PaymentProfile[]>,
  createPaymentProfile: (data: PaymentProfileData) => Promise<PaymentProfile>,
  getPrices: (product: ProductWithPrice<unknown>) => Promise<Billing[]>,
  buy: (data: { product: ProductWithPrice<unknown>, paymentProfileId: string }) => Promise<void>,
  checkEmail: (email: string) => Promise<UserStatus>,
  registerUser: (register: UserRegister) => Promise<void>,
  registerCompany: (register: CompanyRegister) => Promise<void>,
  requestEmailConfirmation: (email: string) => Promise<void>
}

export type Period = "MONTH" | "YEAR" | "ONCE";

export type UserStatus = "UNREGISTERED" | "UNKNOWN" | "REGISTERED" | "NO_COMPANY" | "OK";

export type Billing = {
  value: number,
  recurrence: Period,
  installments: number
}

export type ProductWithPrice<P> = {
  product: P,
  type: string,
  billings: Billing[]
}

export type ProductSelectionComponent<P extends Product> = FunctionComponent<{
  value: ProductWithPrice<P> | null,
  onChange: (product: ProductWithPrice<P> | null) => void
  onNext: () => void
}>

export type MetricsWebProduct = {
  installments: number,
  recurrence: Period,
  categories: {
    id: number,
    name: string
    ufs: string[]
  }[]
}

export type FGVProduct = {
  installments: number,
  recurrence: Period,
  categories: {
    id: string,
    name: string,
    hasHistory: boolean
  }[]
}

export type Product = MetricsWebProduct | FGVProduct;

export type ProductStrategy<P extends Product> = {
  Component: ProductSelectionComponent<P>,
  header: ReactNode,
  ReviewComponent: ComponentType<{ product: ProductWithPrice<P> }>,
  fotter: ReactNode,
}

export const makeClassName = (classNames: Record<string, boolean>): string => {
  return Object.entries(classNames).filter(entry => entry[1]).map(entry => entry[0]).join(' ');
}