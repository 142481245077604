import { FunctionComponent, useEffect, useState } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth'
import HeaderPageGuest from '../home/HeaderPageGuest';
import Card from '../utils/SingUpUtils'

const Loading = styled.i`
  font-size: 40px;
  margin: 50px 0 50px 48.5%;
`;

const GuestRoute : FunctionComponent<RouteProps> = props => {
  const { children } = props
  const { user, restore } = useAuth()
  const [ restored, setRestored ] = useState(user ? true : false)

  useEffect(() => {
    if (user || restored) {
      return;
    }
    restore().then(response => {
      if (response.status === 206) {
        setRestored(true)
      }
    })
      .catch(() => setRestored(true));
  }, [ restored, user, restore ]);

  return (
    <Route 
      { ...props } 
      children={ user ? <Redirect to="/app" /> : ( restored ? children : (
        <>
          <HeaderPageGuest>
            <Card titleCard={'Aguarde...'} >
              <Loading className='fas fa-spinner fa-pulse' />
            </Card>
          </HeaderPageGuest>
        </>

      ))  }
    />
  )
}

export default GuestRoute