import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import Page404 from "./Page404";

type Props = {
  children: React.ReactNode
}

const ErrorBoundaryWrap: React.FC<Props> = ({ children }: Props) => {
  return (
    <ErrorBoundary FallbackComponent={ Page404 } onError={ e => console.log("Erro: ", e) }>
      { children }
    </ErrorBoundary>
  );
}

export default ErrorBoundaryWrap
