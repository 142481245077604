import { FunctionComponent } from "react";
import { ufs } from "../../constants";
import { MetricsWebProduct, ProductWithPrice } from "./definitions";

const ReviewMetricsWeb: FunctionComponent<{
  product: ProductWithPrice<MetricsWebProduct>;
}> = function (props) {
  return (
    <>
      {props.product.product.categories.map((category) => {
        return (
          <div
            key={category.id}
            className="h-sumw__spacing-after h-sumw__spacing-before"
          >
            <h3 className="h-sumw__sub-title-2">{category.name}</h3>
            <table className="h-sumw__table h-sumw__spacing-after">
              <tbody>
                {category.ufs.map((uf) => (
                  <tr key={uf}>
                    <td>
                      <span className="h-sumw__uf-name">
                        {ufs[uf]}
                        <ul>
                          {props.product.product.categories.map((item) => {
                            <li>{item.id}</li>;
                          })}
                        </ul>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
};

export default ReviewMetricsWeb;
