import { authEvent, eventUrl } from '../constants';
import useRequest from '../hooks/useRequest';

const useDashboardEmbedEvent = () => {
    
  const dashboardEmbedEventRequest = useRequest("POST", eventUrl + "/v1/events/category/report");

  const postDashboardEmbedEvent = async (eventObject: any) : Promise<any> => {
    let data = {
		
      action: eventObject.action,
			label: eventObject.label
    } 
		
		let headers = {
			Authorization: authEvent			
		}
    return dashboardEmbedEventRequest({data, headers});
  };

  return {
    postDashboardEmbedEvent
  };

};

export default useDashboardEmbedEvent;