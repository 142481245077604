import { FunctionComponent } from "react";
import useTitle from "../../../hooks/useTitle";
import FgvPage from "./FgvPage";

const FgvPrivacity: FunctionComponent = function () {
  useTitle("HORUS + FGV IBRE - Política de Privacidade");

  return (
    <FgvPage>
      <div className="hd-fgv__content">
        <div className="hd-fgv__privacity">
          <h1>
            POLÍTICA DE PRIVACIDADE E DE PROTEÇÃO DE DADOS PESSOAIS - LGPD
          </h1>
          <h2>
            Introdução
          </h2>
          <p>
            Esta Política de Privacidade e Proteção de Dados Pessoais foi instituída para estabelecer o compromisso com a segurança das informações dos usuários cadastrados e visitantes do Site e da PLATAFORMA HORUS.
          </p>
          <p>
            Este documento estabelece diretrizes e procedimentos para o tratamento dos dados pessoais pela HORUS em conformidade com a Lei Geral de Proteção de Dados Pessoais - LGPD (Lei Federal nº 13.709, de 14 de agosto de 2018) e com o Marco Civil da Internet (Lei nº 12.965, de 23 de abril de 2014).
          </p>
          <p>
            Estamos empenhados em salvaguardar a sua privacidade ao utilizar o nosso site e plataforma. 
          </p>
          <p>
            Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o titular a consultar periodicamente esta seção.
          </p>
          <p>
            A HORUS DECLARA que respeita os direitos dos titulares dos dados conferidos pela LGPD, em seu artigo 18. São eles:
          </p>
          <p>
            <strong>Direito de confirmação e acesso:</strong> é o direito do titular obter a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for o caso, o direito de acessar os seus dados pessoais;
          </p>
          <p>
            <strong>Direito de retificação:</strong> é o direito do titular de obter, do controlador, sem demora injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;
          </p>
          <p>
            <strong>Direito à eliminação dos dados (direito ao esquecimento):</strong> é o direito do titular de ter seus dados apagados da base de dados do controlador;
          </p>
          <p>
            <strong>Direito à limitação do tratamento dos dados:</strong> é o direito do titular de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando o controlador não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;
          </p>
          <p>
            <strong>Direito de oposição:</strong> é o direito do titular de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento de dados pessoais que lhe digam respeito, podendo se opor, ainda, ao uso de seus dados pessoais para definição de perfil de marketing;
          </p>
          <p>
            <strong>Direito de portabilidade dos dados:</strong> é o direito do titular de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao controlador, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro controlador;
          </p>
          <p>
            <strong>Direito de não ser submetido a decisões automatizadas:</strong> é o direito do titular de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis, que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar.
          </p>
          <p>
            EM RESUMO, o titular dos dados pessoais tem direito a obter do controlador, em relação aos seus dados por ele tratados, a qualquer momento e mediante requisição:
          </p>
          <ul>
            <li>I - confirmação da existência de tratamento;</li>
            <li>II - acesso aos dados;</li>
            <li>III - correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
            <li>V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
            <li>VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;</li>
            <li>VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
            <li>VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
            <li>IX - revogação do consentimento, nos termos do § 5º do art. 8º da LGPD.</li>
          </ul>
          <p>
            A HORUS se compromete, ainda, a cumprir as determinações previstas na LGPD, em respeito aos seguintes princípios:
          </p>
          <p>
            Os dados pessoais do titular serão tratados para propósitos legítimos, específicos,
            explícitos e informados ao titular, sem possibilidade de tratamento posterior de
            forma incompatível com essas finalidades <strong>(Princípio da Finalidade)</strong>; serão tratados
            de forma adequada às finalidades informadas ao titular, de acordo com o contexto de
            tratamento <strong>(Princípio da Adequação)</strong>; serão tratados de forma pertinente e limitada às
            necessidades do objetivo para os quais eles são processados <strong>(Princípio da Necessidade)</strong>;
            serão tratados e garantidos, aos titulares, consulta facilitada e gratuita sobre a
            forma e duração de tratamento, bem como sobre a integralidade dos seus dados
            <strong>(Princípio do Livre Acesso)</strong>; serão exatos e atualizados sempre que necessário, de
            maneira que dados inexatos sejam apagados ou retificados quando possível
            <strong>(Princípio da Qualidade dos Dados)</strong>; serão tratados e garantidos, aos titulares,
            informações claras, precisas e facilmente acessíveis, inclusive sobre os agentes de
            tratamento, ressalvados os segredos comercial e industrial <strong>(Princípio da Transparência)</strong>;
            serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e
            contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas
            ou organizativas adequadas <strong>(Princípio da Segurança)</strong>; serão tratados, utilizando-se, o
            controlador, de todas as medidas para prevenir a ocorrência de danos em virtude desse
            tratamento <strong>(Princípio da Prevenção)</strong>; NÃO serão tratados para fins discriminatórios,
            ilícitos ou abusivos <strong>(Princípio da Não Discriminação)</strong>; serão tratados, pelo
            controlador, de forma capaz de demonstrar as medidas adotadas a fim de comprovar a
            observância e o cumprimento das normas pertinentes à proteção de dados
            <strong>(Princípio da Responsabilização e Prestação de Contas)</strong>. Tudo de acordo com o art.
            6º, incisos I ao X da LGPD.
          </p>
          <h2>
            QUAIS DADOS SERÃO COLETADOS E TRATADOS
          </h2>
          <p>
            A HORUS trata Dados Pessoais e não faz coleta de dados além daqueles que são estritamente necessários para a realização dos negócios a que esses dados se destinam. Garantimos que estes dados serão utilizados para o fim declarado no momento da coleta e que o tratamento aplicado durante todo o ciclo de vida destes dados é adequado à sua finalidade.
          </p>
          <p>
            Os Dados Pessoais tratados variam de acordo com as finalidades de uso, inclusive as indicadas nesta Política de Privacidade, e com as atividades realizadas. Entre estes dados estão dados de contato como: nome, CPF, e-mail, telefone, celular, dados de localização como rua, CEP, bairro, cidade e UF. 
          </p>
          <p>
            A HORUS não faz o tratamento de dados de titulares menores de idade ou adolescentes, mas se acaso vier a fazê-lo, ocorrerá em caráter excepcional e desde que tenha uma base legal lícita atribuída, em conformidade com a LGPD.
          </p>
          <p>
            Ao utilizar esta plataforma, seja no modo gratuito ou pago, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos informações sobre você, tais como:
          </p>
          <p>
            <strong>Informações que você oferece.</strong> Podemos coletar dados fornecidos por você no cadastro, tais como nome e sobrenome, endereço para correspondência, endereço de e-mail, informações de pagamento, endereço IP, outras informações de contato on-line ou número de telefone, foto e demais informações requeridas no cadastro ou vinculados às mídias fornecidos por você no login (Facebook, Gmail, Outlook, e-mail, etc.).
          </p>
          <p>
            <strong>Conteúdos de seu interesse.</strong> Podemos coletar o conteúdo das áreas de seu interesse quando você usa nosso site e/ou nossa PLATAFORMA e cadastra uma conta, para enviarmos por e-mail conteúdos de seu interesse.
          </p>
          <p>
            <strong>Comunicação.</strong> Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
          </p>
          <p>
            <strong>Informações sobre pagamentos.</strong> Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, data de nascimento, CPF e CNPJ.
          </p>
          <p>
            <strong>Certificação de segurança.</strong> Para certificar o uso restrito pelo detentor da conta, eventuais informações podem ser solicitadas por mensagem ou telefone, tais como CNPJ ou CPF, data de nascimento, ou endereço.
          </p>
          <p>
            <strong>Endereço eletrônico (e-mail).</strong> Ao fazer login na plataforma, coletaremos o seu e-mail para fins cadastrais, pelo qual ocorrerão as comunicações de atualizações da plataforma, promoções e gerenciamento de sua conta.
          </p>
          <p>
            <strong>Cookies.</strong> Registramos dados de sua visita ao site e à plataforma através de cookies e outras tecnologias de rastreamento incluindo seu endereço IP e nome de domínio, a versão do seu navegador e do seu sistema operacional, dados de tráfego online, dados de localização, logs da web e outros dados de navegação. Para melhor entender como isso ocorre, acesse nossa política de cookies disponível em tópico específico na Plataforma.
          </p>
          <p>
            Atendendo os requisitos de tratamento de dados disposto no artigo 7º, a HORUS pode processar dados pessoais nas seguintes hipóteses:
          </p>
          <ul>
            <li>- mediante o fornecimento de consentimento pelo titular; </li>
            <li>- para o cumprimento de obrigação legal ou regulatória pelo controlador;</li>
            <li>- quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;</li>
            <li>- para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</li>
            <li>- para a proteção da vida ou da incolumidade física do titular ou de terceiro;</li>
            <li>- quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais; ou</li>
            <li>- para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</li>
          </ul>
          <p>
            Todos os processos operacionais que realizam tratamento de dados pessoais da HORUS, atendem ao menos um requisito legal.
          </p>
          <p>
            <strong>Sobre o Consentimento do titular</strong> (De acordo com o art. 8º da LGPD):
          </p>
          <p>
            O consentimento referente à coleta de dados do usuário é obtido de forma livre, expressa, individual, clara, específica e legítima e poderá ser revogado a qualquer momento pelo usuário.
          </p>
          <p>
            O consentimento é dispensado para o tratamento de dados pessoais tornados manifestamente públicos pelo titular, desde que seja realizado de acordo com a finalidade, a boa-fé e o interesse público, resguardados os direitos do titular.
          </p>
          <p>
            O usuário tem o direito de negar ou retirar o consentimento fornecido à HORUS, o que poderá encerrar a consecução dos serviços relacionados a essa base legal de tratamento de dados pessoais.
          </p>
          <p>
            Quando a base legal for o consentimento expresso, para sua revogação, basta que o usuário registre seu pedido em e-mail dirigido ao DPO, qual seja, dpo@ehorus.com.br.
          </p>
          <h2>
            FINALIDADES
          </h2>
          <p>
            A coleta de dados tem por finalidade precípua a execução do contrato celebrado no momento da assinatura de um dos planos disponíveis na PLATAFORMA, bem como atuar de forma eficaz e proporcionar melhorias na experiência dos usuários com o site e os serviços oferecidos por meio da PLATAFORMA.
          </p>
          <p>
            A maior parte desses dados é solicitada de maneira explícita por meio de formulários eletrônicos e será usada exclusivamente para atender as solicitações enviadas aos serviços prestados pela PLATAFORMA, de modo a agilizar e cumprir sua finalidade.
          </p>
          <p>
            Eventuais análises estatísticas serão efetuadas para interpretar os padrões de utilização do site e serviços disponíveis, a fim de melhorar, de forma contínua, a prestação dos serviços. A informação estatística resultante poderá ser objeto de publicação, sem qualquer identificação pessoal dos usuários. 
          </p>
          <p>
            Caso ocorram mudanças na finalidade do tratamento dos dados pessoais, não compatíveis com a base legal, o titular será informado previamente, garantido o direito de solicitar a eliminação dos dados, se discordar das alterações.
          </p>
          <h2>
            COMPARTILHAMENTO
          </h2>
          <p>
            Compartilhamos os seus dados com empresas parceiras para viabilizar a execução dos serviços contratados, para fins pagamentos, processamento de dados, finalidades fiscais, contábeis e tributárias.
          </p>
          <h2>
            ONDE ARMAZENAMOS E PROCESSAMOS SEUS DADOS
          </h2>
          <p>
            Os dados coletados podem ser armazenados e processados no Brasil e em qualquer outro país escolhido para operar eficientemente, melhorar o desempenho e proteger os dados no caso de uma falha ou outro problema.
          </p>
          <h2>
            TRANSFERÊNCIAS INTERNACIONAIS DE DADOS
          </h2>
          <p>
            A HORUS trata as informações pessoais em território nacional e em países que possuem legislações semelhantes e equivalentes.
          </p>
          <p>
            Além disso, mantém cláusulas específicas para assegurar o correto tratamento, alinhadas com as leis e regulamentações brasileiras.
          </p>
          <h2>
            SEGURANÇA DOS DADOS PESSOAIS 
          </h2>
          <p>
            A HORUS se compromete a aplicar todas as medidas técnicas e organizativas aptas a proteger os dados pessoais coletados de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
          </p>
          <p>
            Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas, os custos de aplicação, a natureza, o âmbito, o contexto e as finalidades do tratamento e os riscos para os direitos e liberdades do titular.
          </p>
          <p>
            A HORUS se exime de responsabilidade por culpa exclusiva de terceiros e do titular.
          </p>
          <p>
            A HORUS se compromete, ainda, a comunicar o titular em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.
          </p>
          <p>
            A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
          </p>
          <p>
            Os dados pessoais armazenados são tratados garantindo a confidencialidade, a integridade e a disponibilidade das informações, dentro dos limites legais.
          </p>
          <h2>
            RESPONSABILIDADES DA HORUS E MEDIDAS DE SEGURANÇA DO USUÁRIO
          </h2>
          <p>
            Todos os dados que você nos fornece são tratados unicamente para atingir as finalidades acima listadas. Estes dados são armazenados em servidores seguros nossos ou de fornecedores contratados, acessados e utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro.
          </p>
          <p>
            Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.
          </p>
          <p>
            O acesso ao seu perfil é de responsabilidade exclusivamente sua, por meio de um login e uma senha que lhe permite acessar nossa PLATAFORMA, sendo de sua responsabilidade manter esta senha confidencial e por cumprir com quaisquer outros procedimentos de segurança. Nunca solicitaremos sua senha, e pedimos que você não a compartilhe com ninguém.
          </p>
          <p>
            A segurança e confiabilidade dos dispositivos os quais você utiliza para acessar os nossos serviços, tais como computadores, celulares, tablets ou outros dispositivos, é de total responsabilidade sua. Você deve por meios próprios buscar proteger os sistemas operacionais desses dispositivos contra qualquer ameaça externa.
          </p>
          <h2>
            DO RESPONSÁVEL PELO TRATAMENTO DOS DADOS PESSOAIS - CONTROLADOR
          </h2>
          <p>
            O controlador/responsável pelo tratamento dos dados pessoais do titular, é a pessoa física ou jurídica, a autoridade pública ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.
          </p>
          <p>
            No presente caso, o responsável pelo tratamento dos dados pessoais tratados é a HORUS, que poderá ser contatada pelo e-mail: dpo@ehorus.com.br.
          </p>
          <h2>
            DO TRATAMENTO DOS DADOS PESSOAIS EM NOME DO CONTROLADOR – OPERADOR
          </h2>
          <p>
            A HORUS, quando na qualidade de operadora dos dados, esclarece que as decisões referentes ao tratamento dos dados pessoais competirão única e exclusivamente aos Controladores, não tendo qualquer poder de decisão no que tange aos dados pessoais, realizando o tratamento destes dados conforme orientações documentadas e emitidas pelos Controladores.
          </p>
          <p>
            Essa relação entre Controladores e a HORUS, se dará através de contrato escrito, asseguradas as garantias e direitos dos titulares.
          </p>
          <p>
            A HORUS afirma que empregará todos os esforços necessários para viabilizar aos controladores o atendimento aos titulares dos dados e o exercício dos seus direitos, relativamente aos dados que estiverem em sua posse.
          </p>
          <h2>
            TEMPO DE MANUTENÇÃO DOS DADOS PESSOAIS
          </h2>
          <p>
            Cada conjunto de dados fica em poder da HORUS apenas o tempo necessário para atingir o objetivo a que se destina e serão mantidos na base da empresa até que e quando a legislação vigente permitir, o titular dos dados pode exercer seu direito de se opor ao processamento ou seu direito de ser esquecido. 
          </p>
          <p>
            Sendo assim, nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os dados necessários ao cumprimento de obrigações legais, tributárias ou judiciais.
          </p>
          <p>
            Caso você venha a cadastrar-se novamente na plataforma após a solicitação de exclusão de dados, nós utilizaremos os dados novamente concedidos.
          </p>
          <h2>
            DO ENCARREGADO DE PROTEÇÃO DE DADOS (DATA PROTECTION OFFICER - DPO)
          </h2>
          <p>
            O encarregado de proteção de dados (Data Protection Officer – DPO) é a pessoa indicada pelo controlador e operador para atuar como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD), conforme disposição do artigo 5º, VIII.
          </p>
          <p>
            No caso da HORUS, o encarregado de proteção de dados (Data Protection Officer – DPO) é Alexandre Coelho, que poderá ser contatado através do e-mail dpo@ehorus.com.br.
          </p>
          <p>
            Você também tem o direito de contatar diretamente a Autoridade de Proteção de Dados brasileira.
          </p>
          <h2>
            INFORMAÇÕES DE CONTATO: COMO ACESSAR E CONTROLAR SEUS DADOS
          </h2>
          <p>
            Você pode descadastrar-se de forma total ou parcial da Plataforma, por meio do e-mail: dpo@ehorus.com.br.
          </p>
          <p>
            Você pode igualmente solicitar informações, alteração, esclarecimentos ou exclusão de seus dados pelo e-mail citado acima.
          </p>
          <h2>
            BOAS PRÁTICAS DE SEGURANÇA E GOVERNANÇA
          </h2>
          <p>
            Nos termos da LGPD, em especial, seus artigos 46 a 51, e observados seus princípios norteadores, e ainda, em conformidade com as boas práticas de segurança da informação e de proteção de dados pessoais, a HORUS garante que os dados pessoais coletados são tratados de forma íntegra e segura, de acordo com padrões de segurança da informação, confidencialidade e integridade.
          </p>
          <p>
            Faz-se necessário, entretanto, destacar que nenhum sistema é completamente seguro. Conforme previsto na própria legislação, as medidas de segurança existentes consideram a natureza dos dados e do tratamento, os riscos envolvidos, a tecnologia existente e sua disponibilidade.
          </p>
          <p>
            Os dados pessoais tratados pela HORUS são considerados sigilosos e somente serão acessados por pessoas autorizadas e capacitadas a lhes conferir o tratamento próprio, conforme medidas de segurança adequadas para a proteção contra acesso não autorizado, alteração, divulgação ou destruição de dados pessoais coletados e armazenados.
          </p>
          <p>
            Caso haja solicitação do titular, os dados pessoais coletados via site ou PLATAFORMA serão excluídos. No entanto, por motivos legais, por determinação judicial ou para fins de auditoria e segurança, eles poderão ser mantidos pelo período necessário, findo o qual serão excluídos com o uso de métodos de descarte seguro.
          </p>
          <p>
            Em caso de incidente de segurança que envolva dados pessoais, a ocorrência será comunicada à ANPD e ao titular sempre que envolver risco ou dano relevante.
          </p>
          <p>
            A comunicação sobre a descrição da natureza, dos riscos, dos titulares envolvidos e das medidas técnicas e de segurança utilizadas para o tratamento do incidente será feita conforme o prazo definido pela ANPD.
          </p>
          <h2>
            DAS ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
          </h2>
          <p>
            A presente Política de Privacidade foi atualizada pela última vez em outubro de 2021.
          </p>
          <p>
            A HORUS poderá modificar esta Política de Privacidade a qualquer momento, então, é recomendável que o titular a revise com frequência.
          </p>
          <p>
            As alterações e esclarecimentos vão surtir efeito imediatamente após a sua publicação no site da empresa.
          </p>
        </div>
      </div>
    </FgvPage>
  )
}

export default FgvPrivacity;