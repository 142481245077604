import { ChangeEvent, FunctionComponent, HTMLProps, RefObject, useEffect, useRef, useState } from "react";
import useFirstValue from "../../hooks/useFirstValue";

import { makeClassName } from "./definitions";

const Input: FunctionComponent<Omit<HTMLProps<HTMLSelectElement>, "onChange"> & { 
  label?: string,
  validate?: (value: string) => string | null,
  onValidate?: (result: boolean) => void,
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  inputRef?: RefObject<HTMLSelectElement>,
  options: { key: string, value: string }[]
}> = function (props) {
  const [ { validationMessage, touched }, setState ] = useState<{ 
    validationMessage?: string,
    touched: boolean
  }>({ touched: false });
  const { options, label, className, onChange, validate, onValidate, required, inputRef: originalInputRef, ...inputProps } = props;

  const internalInputRef = useRef<HTMLSelectElement>(null);
  const inputRef = originalInputRef || internalInputRef;

  const firstValidate = useFirstValue(props.validate);
  const firstOnValidate = useFirstValue(props.onValidate);

  const doValidation = useFirstValue((value: string, touch: boolean) => {
    if (!validate) {
      return;
    }
    const message = validate(value);
    setState(prev => ({ ...prev, 
      validationMessage: message || undefined, 
      touched: touch || prev.touched
    }));
    if (onValidate) onValidate(!message);
  });

  const theOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (props.value !== undefined) {
      setState(prev => ({ ...prev, touched: true }));
    } else {
      doValidation(e.target.value, true);
    }
    if (onChange) onChange(e);
  }

  // This useEffect do the initial first validation immediately
  // after the input is rendered
  useEffect(() => {
    if (firstValidate && firstOnValidate) {
      firstOnValidate(!firstValidate(inputRef.current!.value));
    }
  }, [ firstValidate, firstOnValidate, inputRef ])

  const value = props.value as string;
  useEffect(() => {
    if (value === undefined) return;
    doValidation(value, false);
  }, [ value, doValidation ]);

  return (
    <div 
      className={ makeClassName({
        'h-sumw__input-wrapper': true,
        'h-sumw__input-wrapper--error': !!validationMessage && touched,
        [className || ""]: true
      }) } >
      {
        label && <label 
          htmlFor={ inputProps.id }
          className="h-sumw__label"
        >{ label }</label>
      }
      <select 
        { ...inputProps }
        onChange={ theOnChange }
        className="h-sumw__input"
        ref={ inputRef }
      >
        {
          required ? null : (
            <option value="">
              { props.placeholder || "Selecione..." }
            </option>
          )
        }
        {
          options.map(entry => {
            return (
              <option value={ entry.key } key={ entry.key }>
                { entry.value }
              </option>
            )
          })
        }
      </select>
      {
        !touched || !validationMessage ? null : (
          <div className="h-sumw__input__message">
            { validationMessage }
          </div>
        )
      }
    </div>
  )
}

export default Input