export const Spotify = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    fill="none"
    viewBox="0 0 20 19"
  >
    <path
      fill="#fff"
      d="M9.654 0C4.474 0 .274 4.196.274 9.371s4.2 9.371 9.38 9.371c5.182 0 9.381-4.195 9.381-9.37 0-5.176-4.2-9.372-9.38-9.372m4.302 13.516a.585.585 0 0 1-.804.194c-2.202-1.344-4.975-1.649-8.24-.904a.585.585 0 0 1-.26-1.139c3.573-.816 6.638-.464 9.11 1.045a.584.584 0 0 1 .194.804m1.149-2.552a.73.73 0 0 1-1.006.241c-2.522-1.549-6.366-1.997-9.348-1.093a.732.732 0 1 1-.425-1.398c3.407-1.032 7.642-.532 10.538 1.246a.73.73 0 0 1 .24 1.004m.098-2.657C12.18 6.513 7.191 6.347 4.305 7.223a.877.877 0 0 1-.51-1.677C7.11 4.54 12.618 4.735 16.1 6.799a.877.877 0 0 1-.895 1.508"
    ></path>
  </svg>
);
