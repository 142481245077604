import Router from "./components/routes/Router";
import AuthContext from "./components/context/AuthContext";
import { useEffect, useRef, useState } from "react";
import { User } from "./model";
import "./App.css";
import { withDialogs } from "./service/dialogs";
import ErrorBoundaryWrap from "./components/Page404/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseResearch from "./hooks/useResearch";
import { getTokens, getUser } from "./service/auth";
import useAuth from "./hooks/useAuth";

function App() {
  const [user, setUser] = useState<User | null>(getUser());
  const { setRefreshTokenTimeout } = useAuth();

  useEffect(() => {
    if (getTokens()) {
      setRefreshTokenTimeout()
    }
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <UseResearch>
        <ErrorBoundaryWrap>
          <Router />
          <ToastContainer></ToastContainer>
        </ErrorBoundaryWrap>
      </UseResearch>
    </AuthContext.Provider>
  );
}

export default withDialogs(App);
