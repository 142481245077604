import React from "react";
import { SessionValidator } from "./SessionValidator";

type Props = {
  showDescription?: boolean;
};
const CheckoutSubHeader: React.FC<Props> = ({
  showDescription = true,
}: Props) => {
  return (
    <>
      <div className="h-sumw__subheader">
        <h3>Carrinho de Compras</h3>
        {showDescription && (
          <>
            <p>
              Para acompanhar os preços detalhados por fabricante e marca
              selecione as categorias de interesse
            </p>
          </>
        )}

        <SessionValidator />
      </div>
    </>
  );
};

export default CheckoutSubHeader;
