import React, { FunctionComponent, useState } from 'react'
import { FormEvent } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { mainColor } from '../../constants'
import useAuth from '../../hooks/useAuth'
import { ForgetPassword } from '../../service/auth'
import ModalTitle from '../ui/ModalTitle'
import makeModal from '../utils/MakeModal'
import { useModal } from '../utils/Modal'
import { getErrorMessage } from '../utils/Util'

const Background = styled.div`
  position: relative;
  height: 100vh;
  overflow: auto;
`

const CenterBox = styled.div`
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LoginBox = styled.form`
  background-color: ${ mainColor };
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`

const Logo = styled.img`
  max-height: 35px;
  max-width: 95%;
  margin: 60px 0;
`

const Input = styled.input`
  width: 95%;
  background-color: transparent;
  border: solid 1px white;
  padding: 10px;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;

  :focus {
  }

  ::placeholder {
    color: white;
  }
`

const Button = styled.button`
  border: none;
  background-color: white;
  color: ${ mainColor };
  font-size: 18px;
  padding: 8px 23px;
  margin-top: 10px;
  margin-bottom: 80px;
  outline: none;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`

const SignUpText = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
`

const SignUpLink = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
`

const Trademark = styled.span`
  color: ${ mainColor };
  padding: 10px;
  display: block;
`

const MarkObrigatory = styled.a`
  color: white;
  text-decoration: none;
  font-weight: bolder;
  float: left;
  margin-top: 4%;
`

const Loading = styled.i`
  font-size: 40px;
  margin: 50px 0 50px 0;
`;


const Info = styled.div`
  text-align: center;
`

const ForgotPassword : FunctionComponent = () => {
	const [ forgotPassword, setforgotPassword ] = useState<ForgetPassword>({
		email: ""
	})

  const [ loading, setLoading ] = useState(false);
  const { forgetPassword } = useAuth();
  const { openModal } = useModal();

  const handleOnSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (forgotPassword.email.length === 0) {
      openModal(makeModal("Info", "Campo E-mail Coporativo é obrigatório."));
      return;
    }

    setLoading(true);

    try {
      await forgetPassword(forgotPassword);
      openModal(
        <>
          <ModalTitle> Info </ModalTitle>
          <Info>
            Solicitação de recuperação de senha enviado com sucesso. <br /> 
            Favor verificar seu email cadastrado.
          </Info>
        </>
      )
      history.push("/login")
    } catch (err: any) {
      openModal(makeModal("Erro", getErrorMessage(err)));
      setLoading(false);
    }
  };

	const history = useHistory();

  return (
    <Background>
      <CenterBox>
        <LoginBox onSubmit={ handleOnSubmit }>
          <Logo
            src="/static/logo-horus-w.png"
            alt="Logo Horus" />

					<Input
					placeholder="E-mail Corporativo"
					value={ forgotPassword.email }
					onChange={ e => setforgotPassword({ ...forgotPassword, email: e.currentTarget.value }) }/>
          <MarkObrigatory>*</MarkObrigatory>
          { !loading ? (
            <>
              <Button 
                type="submit"
                disabled={ loading }
                children={ loading ? 'Carregando...' : 'Alterar Senha' } />

              <SignUpText>
                <SignUpLink onClick={ e => history.push("/login") } style={{cursor: 'pointer'}}>
                  Voltar
                </SignUpLink>
              </SignUpText> 
            </> ) : (
          <Loading className='fas fa-spinner fa-pulse' /> )
          }
        </LoginBox>
        <Trademark>
          2021 © HORUS
        </Trademark>
      </CenterBox>
    </Background>
  )
}

export default ForgotPassword