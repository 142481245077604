import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import { parseQuery } from "../utils/Util";
import "./CheckoutPage.css";
import {
  PaymentProfile,
  ProductStrategy,
  ProductWithPrice,
} from "./definitions";
import FGVForm from "./FGVForm";
import CheckoutFooter from "./Footer";
import CheckoutHeader from "./Header";
import MetricsWebFooter from "./MetricsWebFooter";
import MetricsWebForm from "./MetricsWebForm";
import PaymentStep from "./PaymentStep";
import ReviewFGV from "./ReviewFGV";
import ReviewMetricsWeb from "./ReviewMetricsWeb";
import ReviewStep from "./ReviewStep";
import StepRegister from "./StepRegister";
import CheckoutSubHeader from "./SubHeader";
import Thankyou from "./Thankyou";

interface State {
  productStep: ProductWithPrice<unknown> | null;
  paymentProfile: PaymentProfile | null;
}

const productTypeMap: Record<string, ProductStrategy<any>> = {
  metricsweb: {
    Component: MetricsWebForm,
    ReviewComponent: ReviewMetricsWeb,
    header: (
      <>
        <CheckoutSubHeader showDescription={false} />
      </>
    ),
    fotter: <MetricsWebFooter /> ,
  },
  premium: {
    Component: FGVForm,
    ReviewComponent: ReviewFGV,
    header: (
      <>
        <CheckoutSubHeader />
      </>
    ),
    fotter: <CheckoutFooter pageName="fgv" />,
  },
};

const useCurrentPage = () => {
  const location = useLocation();

  if (!location.search) return 1;
  const params = parseQuery(location.search);
  if (!params["page"]) return 1;

  return parseInt(params["page"]);
};

const CheckoutPage: FunctionComponent = function (props) {
  const { productType } = useParams<{ productType: string }>();

  const [storage, setStorage] = useLocalStorage<State>(
    {
      productStep: null,
      paymentProfile: null,
    },
    "checkout." + productType
  );

  const { user } = useAuth();

  const [state, setState] = useState({ checkoutEnd: false });

  const history = useHistory();
  const page = useCurrentPage();

  useEffect(() => {
    if (state.checkoutEnd) {
      if (page !== 5) history.push("?page=5");
    } else if (page > 1 && !storage.productStep) {
      history.push("?page=1");
    } else if (page > 2 && !user) {
      history.push("?page=2");
    } else if (page > 3 && !storage.paymentProfile) {
      history.push("?page=3");
    } else if (page > 4 && !state.checkoutEnd) {
      history.push("?page=4");
    }
  });

  const {
    Component: ProductComponent,
    header,
    ReviewComponent,
    fotter,
  } = productTypeMap[productType];

  const handleProductOnChange = useCallback(
    (product: ProductWithPrice<unknown> | null) =>
      setStorage((prev) => ({ ...prev, productStep: product })),
    [setStorage]
  );
  const goToRegisterStep = useCallback(
    () => history.push("?page=2"),
    [history]
  );
  const goToPaymentStep = useCallback(() => history.push("?page=3"), [history]);

  const goToReviewStep = useCallback(
    ({ paymentProfile }: { paymentProfile: PaymentProfile }) => {
      setStorage((prev) => ({ ...prev, paymentProfile }));
      history.push("?page=4");
    },
    [history, setStorage]
  );

  const goToFinalStep = useCallback(() => {
    setState((prev) => ({ ...prev, checkoutEnd: true }));
    setStorage({ productStep: null, paymentProfile: null });
  }, [history]);

  useEffect(() => {
    switch (page) {
      case 1:
        document.title = "Seleção - Horus";
        break;
      case 2:
        document.title = "Cadastro - Horus";
        break;
      case 3:
        document.title = "Pagamento - Horus";
        break;
      case 4:
        document.title = "Revisar Compra - Horus";
        break;
      case 5:
        document.title = "Finalização - Horus";
        break;
      default:
        document.title = "Carrinho de Compras - Horus";
        break;
    }
  });

  return (
    <>
      <div className="h-sumw__wrapper">
        <CheckoutHeader />
        {header}
        <div className="h-sumw__body h-sumw__margin h-sumw__spacing-before">
          <div className="h-sumw__spacing-after"></div>
          <div className="h-sumw__grid">
            <div className="h-sumw__col-steps">
              <div className="h-sumw__spacing-after"></div>
              <div className="h-sumw__steps">
                <div
                  className={`h-sumw__step ${
                    page === 1 ? "h-sumw__step--active" : ""
                  } ${page < 1 ? "h-sumw__step--disabled" : ""}`}
                >
                  <div className="h-sumw__step__label">1</div>
                  <div className="h-sumw__step__description">Seleção</div>
                </div>
                <div
                  className={`h-sumw__step ${
                    page === 2 ? "h-sumw__step--active" : ""
                  } ${page < 2 ? "h-sumw__step--disabled" : ""}`}
                >
                  <div className="h-sumw__step__label">2</div>
                  <div className="h-sumw__step__description">Cadastro</div>
                </div>
                <div
                  className={`h-sumw__step ${
                    page === 3 ? "h-sumw__step--active" : ""
                  } ${page < 3 ? "h-sumw__step--disabled" : ""}`}
                >
                  <div className="h-sumw__step__label">3</div>
                  <div className="h-sumw__step__description">Pagamento</div>
                </div>
                <div
                  className={`h-sumw__step ${
                    page === 4 ? "h-sumw__step--active" : ""
                  } ${page < 4 ? "h-sumw__step--disabled" : ""}`}
                >
                  <div className="h-sumw__step__label">4</div>
                  <div className="h-sumw__step__description">
                    Revisar Compra
                  </div>
                </div>
                <div
                  className={`h-sumw__step ${
                    page === 5 ? "h-sumw__step--active" : ""
                  } ${page < 5 ? "h-sumw__step--disabled" : ""}`}
                >
                  <div className="h-sumw__step__label">5</div>
                  <div className="h-sumw__step__description">Finalização</div>
                </div>
              </div>
            </div>

            <div className="h-sumw__col-content">
              {page !== 1 ? null : (
                <ProductComponent
                  value={storage.productStep}
                  onChange={handleProductOnChange}
                  onNext={goToRegisterStep}
                />
              )}
              {page !== 2 ? null : <StepRegister onNext={goToPaymentStep} />}
              {page !== 3 ? null : <PaymentStep onNext={goToReviewStep} />}
              {page !== 4 ||
              !storage.productStep ||
              !storage.paymentProfile ? null : (
                <ReviewStep
                  onDiscountApplied={(product) =>
                    setStorage((prev) => ({ ...prev, productStep: product }))
                  }
                  product={storage.productStep}
                  component={ReviewComponent}
                  onNext={goToFinalStep}
                  paymentProfile={storage.paymentProfile.gatewayToken}
                />
              )}
              {page !== 5 ? null : <Thankyou />}
            </div>
          </div>
        </div>
      </div>
      {fotter}
    </>
  );
};

export default CheckoutPage;
